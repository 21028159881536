import { css } from 'styled-components';

const sizes = {
  tabletLandscape: 1200,
  tablet: 900,
  phone: 600,
};

// TODO(Ivan): add ${} functionality inside media query
export const media = Object.keys(sizes).reduce((acc, device) => {
  acc[device] = (...args) => css`
    @media (max-width: ${sizes[device]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

media.sizes = sizes;

media.getScreenSize = () => {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  const x = w.innerWidth || e.clientWidth || g.clientWidth;
  const y = w.innerHeight || e.clientHeight || g.clientHeight;
  return { x, y };
};
