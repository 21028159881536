import styled from 'styled-components';

import { media } from 'theme';

import { Row } from '../Row';
import { FooterSection } from './FooterSection';

export const FooterSectionsContainer = styled(Row).attrs({
  className: 'space-between',
})`
  flex-wrap: wrap;
  & > ${FooterSection} {
    display: none;
  }
  ${media.tablet`
    & > ${FooterSection} {
      display: flex;
    }
    & > .wrapper {
      display: none;
    }
  `};
`;
