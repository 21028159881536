import React from 'react';
import _ from 'lodash';

import ScrollOrchestratorContext from './ScrollOrchestratorContext';

export class ScrollOrchestrator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldScrollY: 0,
      currentScrollY: 0,
      scrollSpeedY: 0,
      handlerRegistry: [],
    };
    this.addScrollListener = this.addScrollListener.bind(this);
    this.removeScrollListener = this.removeScrollListener.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  addScrollListener(handlerCallback) {
    const { handlerRegistry } = this.state;
    if (_.find(handlerRegistry, handlerCallback)) {
      console.warn(`addScrollListener: handler already exists ${handlerCallback}`);
      return;
    }
    handlerRegistry.push(handlerCallback);
    this.setState({ handlerRegistry });
  }

  removeScrollListener(handlerCallback) {
    const { handlerRegistry } = this.state;
    _.remove(handlerRegistry, handlerCallback);
    this.setState({ handlerRegistry });
  }

  handleScroll(event) {
    const { oldScrollY, handlerRegistry } = this.state;
    const currentScrollY = window.scrollY;
    const scrollSpeedY = currentScrollY - oldScrollY;
    const scroll = { scrollSpeedY, scrollY: currentScrollY };
    this.setState({
      oldScrollY: currentScrollY,
      scrollSpeedY,
    });
    handlerRegistry.forEach(handlerCallback => handlerCallback(event, scroll));
  }

  render() {
    const providerValue = {
      addScrollListener: this.addScrollListener,
      removeScrollListener: this.removeScrollListener,
    };
    return (
      <ScrollOrchestratorContext.Provider value={providerValue}>
        {this.props.children}
      </ScrollOrchestratorContext.Provider>
    );
  }
}
