import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import { Column } from '../Column';
import { HeroContent } from './HeroContent';
import { HeroContainer } from './HeroContainer';
import { ElementsContainer } from './ElementsContainer';
import { createElement } from '../Element';

export class HeroBackground extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loaded: false,
    };

    if (!this.props.hideElements) {
      this.elements = _.map(Array(10), () => createElement(100, 2, false));
    }
  }

  componentDidMount() {
    this.setState({ loaded: true });
  }

  render() {
    const { className, children, hideElements, textual, noNavigation } = this.props;
    return (
      <HeroContainer className={classNames(className, { textual })}>
        <ElementsContainer>
          {_.times(6, i => (
            <Column className="space-around" key={i}>
              {!hideElements &&
                _.times(2, j => {
                  const index = ((i + 1) * (j + 1)) % 10;
                  const Element = this.elements[index];
                  return <Element index={index} loaded={this.state.loaded} key={index} />;
                })}
            </Column>
          ))}
        </ElementsContainer>
        <HeroContent className={classNames({ textual, noNavigation })}>{children}</HeroContent>
      </HeroContainer>
    );
  }
}
