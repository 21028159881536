import styled from 'styled-components';

import {
  Column,
  H2,
  H3,
  Row,
  media,
  spacing
} from 'playful-ui';

export const TestimonialsContainer = styled(Column)`
  & > ${H2} {
    margin-top: ${spacing.XLARGE};
    margin-bottom: ${spacing.XLARGE};
    max-width: 754px;
    ${media.tablet`
      max-width: 624px;
      margin-top: ${spacing.LARGE};
      margin-bottom: ${spacing.LARGE};
    `};
    ${media.phone`
      max-width: 335px;
      margin-top: ${spacing.BASE_PLUS};
      margin-bottom: ${spacing.BASE_PLUS};
    `}
    text-align: center;
  };
  & > ${Row} {
    margin-bottom: ${spacing.XLARGE};
    ${media.phone`
      margin-bottom: ${spacing.LARGE};
    `};
  }
  & > ${Row} > ${Column} {
    flex: unset;
    width: 460px;
    height: 270px;
    ${media.tablet`
      width: 598px;
      height: auto;
    `} ${media.phone`
      width: 335px;
    `};
  }
  & > ${Row} > .testimonial-video-modal {
    flex: unset;
    width: 460px;
    height: 270px;
    margin-right: ${spacing.BASE_PLUS};
    border-radius: 4px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
    ${media.tablet`
      width: 598px;
      height: 351px;
      margin-right: unset;
    `} ${media.phone`
      width: 335px;
      height: 197px;
    `};
  }
  & > ${Row} > ${Column} > ${H3} {
    margin-top: ${spacing.BASE};
    margin-bottom: ${spacing.TINY};
    ${media.tablet`
      margin-top: ${spacing.SMALL};
    `};
  }
`;

