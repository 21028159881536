import { once, snapshot, addTraits } from 'stemi-firebase';
import { combineReducers } from 'redux';

export function onceAdventures() {
  return once('adventures');
}

export function onceQuests() {
  return once('quests');
}

export function onceLessons() {
  return once('lessons');
}

export function onceSections() {
  return once('sections');
}

export const adventures = snapshot('adventures');

export const quests = snapshot('quests');

export const lessons = snapshot('lessons');

export const sections = snapshot('sections');

export const reducer = combineReducers({
  adventures,
  quests,
  lessons,
  sections
});

export const getAllAdventures = state => addTraits(state.lab.adventures);

export const getAllQuests = state => addTraits(state.lab.quests);

export const getAllLessons = state => addTraits(state.lab.lessons);

export const getAllSections = state => addTraits(state.lab.sections);
