import React from 'react';
import { Provider } from 'react-redux';
import { StemiI18nProvider } from 'stemi-i18n';

import { configureStore } from 'modules/app';

import en from './locale/en/messages';
import hr from './locale/hr/messages';

const store = configureStore();

export default ({ element }) => (
  <Provider store={store}>
    <StemiI18nProvider catalogsObject={{ en, hr }}>{element}</StemiI18nProvider>
  </Provider>
);
