import React from 'react';
import classNames from 'classnames';
import { Caption, Icon, ItemListDropdown } from 'playful-ui';

import { SubpageMenuItem } from './SubpageMenuItem';
import { SubpageMenuContainer } from './SubpageMenuContainer';

export class SubpageMenu extends React.Component {
  constructor(props) {
    super(props);
    this.subpageMenuRef = React.createRef();
    this.state = {
      showSubpageMenu: false,
    };
  }

  handleClick = e => {
    if (
      this.subpageMenuRef.current.contains(e.target) &&
      !this.state.showSubpageMenu
    ) {
      this.setState({ showSubpageMenu: true });
      return;
    }
    this.setState({ showSubpageMenu: false });
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

  render() {
    const { activeIndex, activeSubpage, subpages, onClick } = this.props;
    const { showSubpageMenu } = this.state;
    return (
      <div ref={this.subpageMenuRef}>
        <SubpageMenuContainer className="v-center">
          <Caption className="subpage-caption">{activeSubpage}</Caption>
          <Icon className="subpage-icon" name="ExpandMore" />
          <div
            className={classNames(
              'subpage-menu-container',
              { hidden: !showSubpageMenu },
              { displayed: showSubpageMenu },
            )}
          >
            <ItemListDropdown>
              {showSubpageMenu &&
                subpages.map((subPage, i) => (
                  <SubpageMenuItem
                    activeIndex={activeIndex}
                    subpageName={subPage.pageName}
                    key={i}
                    index={i}
                    onClick={onClick}
                  />
                ))}
            </ItemListDropdown>
          </div>
        </SubpageMenuContainer>
      </div>
    );
  }
}
