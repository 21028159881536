import styled from 'styled-components';

import { media, spacing } from 'theme';

export const FooterContainer = styled.div`
  margin-bottom: ${spacing.BASE};
  width: 1146px;
  ${media.tabletLandscape`
    width: 974px;
  `};
  ${media.tablet`
    width: 708px;
  `};
  ${media.phone`
    width 335px;
  `};
`;
