// import React from 'react';
// import styled from 'styled-components';
// import { Row, H4, colors, spacing, media } from 'playful-ui';
// import Countdown from 'react-countdown-now';

// const CannerContainer = styled(Row)`
//   padding: ${spacing.TINY} 0;
//   background-color: ${colors.crimson};
//   justify-content: center;
//   align-items: center;
//   & h4 {
//     color: ${colors.white};
//     ${media.phone`
//       max-width: 334px;
//       text-align: center;
//     `};
//   }
//   & h4 span {
//     --color: ${colors.limegreen};
//     font-weight: 800;
//   }
// `;

export const LearnMoreBanner = () => {
  return null;

  // return (
  //   <CannerContainer>
  //     <H4>
  //       Check out our STEM program for forward-thinking middle schools and high
  //       schools.{' '}
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://robotics.milestonec.com/"
  //       >
  //         Learn more
  //       </a>
  //       .
  //     </H4>
  //   </CannerContainer>
  // );
};

export const CountdownBanner = () => {
  return null;

  // const endDate = new Date('2020-01-21T23:00:00Z');
  // if (Date.now() > endDate) {
  //   return null;
  // }
  // return (
  //   <CannerContainer>
  //     <H4>
  //       Stemi is running a crowd investing campaign. To find out how to invest
  //       click <a href="https://www.funderbeam.com/syndicate/stemi">HERE</a>.
  //       Ends in: <Countdown date={endDate} />
  //     </H4>
  //   </CannerContainer>
  // );
};
