import styled from 'styled-components';

import { zIndex } from 'theme';

export const ElementsContainer = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.ELEMENTS_CONTAINER};
`;
