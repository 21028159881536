import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { auth, saveUser } from 'stemi-firebase';
import { onceUser, getCurrentUser } from '../redux';

export const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isAuthInitialized: false
      };
    }

    componentDidMount() {
      const { onceUser, saveUser, user } = this.props;
      const { isAuthInitialized } = this.state;

      auth.onAuthStateChanged(authUser => {
        if (!isAuthInitialized) {
          this.setState({ isAuthInitialized: true });
        }

        if (authUser && authUser.uid === _.get(user, 'id')) {
          return;
        }

        authUser ? onceUser(authUser) : saveUser(null);
      });
    }

    render() {
      const { user } = this.props;
      const { isAuthInitialized } = this.state;

      return (
        isAuthInitialized && !user.isLoading() && <Component {...this.props} />
      );
    }
  }

  return connect(state => ({ user: getCurrentUser(state) }), {
    onceUser,
    saveUser
  })(WithAuthentication);
};
