import React from 'react';
import { Column, H2, HeroBackground, Tabs } from 'playful-ui';

import { Hexapod, Container } from './components';

const categoryNames = ['Hexapod'];

const categories = {
  Hexapod: <Hexapod />,
}

export class ProductsPage extends React.Component {
  static route = 'products';
  
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
  }

  handleClick = i => this.setState({ activeIndex: i });

  render() {
    return (
      <Container>
        <HeroBackground className="hero">
          <Column className="v-center hero-content">
            <H2>Browse our products</H2>
            <Tabs
              activeIndex={this.state.activeIndex}
              onClick={this.handleClick}
              tabNames={categoryNames}
            />
          </Column>
        </HeroBackground>
        {categories[categoryNames[this.state.activeIndex]]}
      </Container>
    );
  }
}
