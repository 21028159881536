import React from 'react';
import { Trans } from '@lingui/react';
import { colors } from 'playful-ui';

import ImgServo from '../assets/img-servo-motor.jpg';

const style = { borderRadius: '50%', height: '32px', width: '32px' };

export const buyingOptions = {
  buyingOptionsText: <Trans>Chose your STEMI Servo style</Trans>,
  optionsCaption: <Trans>Color</Trans>,
  options: [
    {
      displayImage: ImgServo,
      displayName: 'Blue',
      icon: <div style={{ backgroundColor: colors.persianblue, ...style }} />,
      price: {
        USD: {
          amount: 0.0,
        },
        GBP: {
          amount: 0.0,
        },
        EUR: {
          amount: 0.0,
        },
        HRK: {
          amount: 0.0,
        },
      },
    }
  ],
}
