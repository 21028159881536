import React from 'react';
import { Trans } from '@lingui/react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { navigate } from 'gatsby';

import {
  withStemiI18n,
  resolveProductGroups,
  resolveShippingZone,
  resolveShippingModel,
  resolvePriceString,
} from 'stemi-i18n';
import {
  BodyMediumText,
  BodySmallText,
  BodyXSmallText,
  Button,
  Column,
  Radio,
  Row,
} from 'playful-ui';

import { AppLinkButton, PlayfulLink } from 'components';
import { secondCheckoutStep, addShippingInfo } from 'modules/analytics';
import { CartPage, isCartEmpty as isCartEmptySelector, getDeSanitizedCart } from 'modules/cart';

import { updateCheckout, addCheckoutAttempt, getCheckout } from '../redux';
import { Checkout } from '../components/Checkout';
import { RoundedBox } from '../components/RoundedBox';
import { ShippingMethodContainer } from '../components/ShippingMethodContainer';
import { CustomerInformationPage } from './CustomerInformationPage';
import { PaymentMethodPage } from './PaymentMethodPage';
import { content } from './content/ShippingMethodPage';

class PageComponent extends React.Component {
  static route = '/checkout/shipping-method';
  static title = 'Shipping method';

  constructor(props) {
    super(props);
    const { checkout, cart, secondCheckoutStep, isCartEmpty } = props;
    if (isCartEmpty) {
      return;
    } else if (!checkout.shipping.countryCodeAlpha2) {
      return;
    }
    const productGroups = resolveProductGroups(Object.values(cart.getData()));
    const shippingZone = resolveShippingZone(checkout.shipping.countryCodeAlpha2);
    this.state = {
      shippingModel: resolveShippingModel(shippingZone, productGroups),
    };
    secondCheckoutStep();
  }

  componentDidMount() {
    const { checkout, isCartEmpty } = this.props;
    if (isCartEmpty) {
      navigate(CartPage.route);
    } else if (!checkout.shipping.countryCodeAlpha2) {
      navigate(CustomerInformationPage.route);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { addCheckoutAttempt, cart, checkout, addShippingInfo } = this.props;
    const { customerEmail, shipping, billing } = checkout;
    addCheckoutAttempt({ customerEmail, shipping, billing }, cart.getData(), PageComponent.route);
    addShippingInfo({ shippingModel: this.state.shippingModel });
    navigate(PaymentMethodPage.route);
  };

  handleRadioChange = e => {
    const { updateCheckout, currency, cart } = this.props;
    const { shippingModel } = this.state;
    updateCheckout({ shippingModel: shippingModel }, { currency, cart });
  };

  handleGoBack = e => {
    const { updateCheckout, currency, cart } = this.props;
    updateCheckout({ shippingModel: null, vatRate: null }, { currency, cart });
  };

  render() {
    const { i18n, checkout, currency, isCartEmpty } = this.props;
    if (!checkout.shipping.countryCodeAlpha2 || isCartEmpty || !this.state) {
      return null;
    }
    const { shippingModel } = this.state;
    const { customerEmail } = checkout;
    const { firstName, lastName, address, postal, city, countryCodeAlpha2 } = checkout.shipping;
    const transPlaceholder = content(i18n);
    const shippingAmountString = shippingModel
      ? resolvePriceString(shippingModel.price, currency)
      : 0;
    return (
      <Checkout activePageIndex={2}>
        <ShippingMethodContainer>
          <RoundedBox>
            <Column>
              <Row className="space-between v-center">
                <BodySmallText>{transPlaceholder.contact}</BodySmallText>
                <BodyXSmallText>
                  <PlayfulLink onClick={this.handleGoBack} to={CustomerInformationPage.route}>
                    {transPlaceholder.change}
                  </PlayfulLink>
                </BodyXSmallText>
              </Row>
              <BodyXSmallText className="margin-top">{customerEmail}</BodyXSmallText>
            </Column>
            <Column>
              <Row className="space-between v-center">
                <BodySmallText>{transPlaceholder.shipTo}</BodySmallText>
                <BodyXSmallText>
                  <PlayfulLink onClick={this.handleGoBack} to={CustomerInformationPage.route}>
                    {transPlaceholder.change}
                  </PlayfulLink>
                </BodyXSmallText>
              </Row>
              <BodyXSmallText className="margin-top">
                {`${firstName} ${lastName}, ${address}, ${postal} ${city}, ${countryCodeAlpha2}`}
              </BodyXSmallText>
            </Column>
          </RoundedBox>

          <BodyMediumText>{transPlaceholder.shippingMethod}</BodyMediumText>

          <form name="shipping-method" onSubmit={this.handleSubmit}>
            <RoundedBox>
              <Row className="space-between">
                <Row>
                  <Radio
                    onChange={this.handleRadioChange}
                    name="radio_group"
                    id="r1"
                    checked={this.props.checkout.shippingModel !== null}
                    required
                  />
                  <label htmlFor="r1">
                    <BodySmallText>
                      <Trans id={shippingModel ? shippingModel.description.props.id : null} />
                    </BodySmallText>
                  </label>
                </Row>
                <BodySmallText className="bold">
                  {shippingModel.price.HRK.amount === 0.0 ? (
                    <Trans>Free</Trans>
                  ) : (
                    shippingAmountString
                  )}
                </BodySmallText>
              </Row>
            </RoundedBox>

            <Row className="space-between buttons">
              <AppLinkButton
                className="secondary large"
                onClick={this.handleGoBack}
                to={CustomerInformationPage.route}
              >
                {transPlaceholder.returnToCustomerInformation}
              </AppLinkButton>
              <Button className="large" type="submit">
                {transPlaceholder.continueToPayment}
              </Button>
            </Row>
          </form>
        </ShippingMethodContainer>
      </Checkout>
    );
  }
}

const mapStateToProps = state => ({
  isCartEmpty: isCartEmptySelector(state),
  cart: getDeSanitizedCart(state),
  checkout: getCheckout(state),
});

const mapDispatchToProps = {
  secondCheckoutStep,
  addShippingInfo,
  updateCheckout,
  addCheckoutAttempt: (checkout, cart, route) => addCheckoutAttempt(checkout, cart, route),
};

export const ShippingMethodPage = compose(
  withStemiI18n,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PageComponent);
