import styled, { css } from 'styled-components';
import { Column, spacing, media, colors, fonts } from 'playful-ui';

export const BillingAddressContainer = styled(Column)`
  margin-top: ${spacing.SMALL};
  display: ${props => props.hide ? 'none' : 'flex'};

  & select {
    margin-bottom: ${spacing.TINY};
    height: 46px;
    background-color: ${colors.snow};
    padding-left: ${spacing.SMALL};
    border-radius: 4px;
    ${css`
      ${fonts.textInput};
    `}
    ${media.phone`
      min-width: 300px;
    `};
  }
  & select:focus {
    outline-width: 0;
    border: solid 2px ${colors.applyOpacity(colors.persianblue, 0.5)};
  }

  & div.input {
    margin-bottom: ${spacing.TINY};
    flex: 1;
    ${media.phone`
      min-width: 300px;
    `};
  };
  & div.input:last-child {
    margin-bottom: 0;
  };
`;
