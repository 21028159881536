import styled, { css } from 'styled-components';

import { colors, media, spacing } from 'theme';

import { Column } from '../Column';
import { Row } from '../Row';
import { BodyMediumText, Tag } from '../Typography';

export const Container = styled(Column)`
  flex: unset;
  width: 558px;
  height: 379px;
  overflow: hidden;
  border-radius: 4px;
  background-color: ${colors.white};
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
  transition-delay: 0.1s;
  &:hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
  ${media.tablet`
    width: 335px;
    height: 245px;
  `} & > .product-card-container {
    overflow: hidden;
    width: 558px;
    height: 281px;
    ${media.tablet`
      width: 335px;
      height: 169px;
    `};
  }
  & > .product-card-container > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  & > ${Row} {
    margin: 34px ${spacing.BASE};
    align-items: center;
    ${({ tag }) =>
      tag &&
      css`
        margin: 21px ${spacing.BASE};
      `} ${media.tablet`
      margin: ${spacing.BASE};
      ${({ tag }) =>
        tag &&
        css`
          margin: 12px ${spacing.BASE};
        `}
    `};
  }
  & > ${Row} > ${BodyMediumText} {
    font-weight: 600;
  }
  & > ${Row} > ${Column} {
    flex: unset;
  }
  & > ${Row} > ${Column} > ${Tag} {
    width: fit-content;
    background-color: ${colors.mischka};
    padding: 4px 8px;
    margin: 0px 0px 4px 0px;
  }
`;
