import styled from 'styled-components';

import { spacing, colors, media } from 'theme';

import { BodyXSmallText, H4, Label } from '../Typography';

export const ArticleCardContainer = styled.div`
  height: ${props => (props.small ? '232px' : '494px')};
  max-height: ${props => (props.small ? '232px' : 'initial')};
  width: 558px;
  cursor: pointer;
  border-radius: 4px;
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out, background-color 0.3s ease-out;
  transition-delay: 0.1s;
  transform: translateY(0);

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
    background-color: ${colors.applyOpacity(colors.mischka, 0.2)};
    transform: translateY(-2px);
  }
  ${media.phone`
    height: 494px;
    max-height: initial;
  `}
  & .small-article-card {
    display: ${props => (props.small ? 'flex' : 'none')};
    ${media.phone`
      display: none;
    `};
  }
  & .article-card {
    display: ${props => (props.small ? 'none' : 'flex')};
    ${media.phone`
      display: flex;
    `};
  }
  width: 558px;
  cursor: pointer;
  display: inline-flex;
  ${media.phone`
    height: 378px;
    width: 345px;
  `} & div.content {
    padding: ${spacing.BASE};
    ${media.phone`
      padding: ${spacing.SMALL};
    `};
  }

  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  & div.article-image {
    width: 510px;
    height: 238px;
    margin-bottom: ${spacing.SMALL};
    border-radius: 4px;
    overflow: hidden;
    ${media.phone`
      height: 146px;
      width: 313px;
    `};
  }

  & .small-article-card div.article-image {
    width: 184px;
    height: 184px;
    margin-right: ${spacing.SMALL};
    margin-bottom: unset;
  }

  & ${Label} {
    margin-bottom: ${spacing.SMALL};
  }

  & ${H4} {
    margin-bottom: ${spacing.SMALL};
  }

  & ${BodyXSmallText} {
    opacity: 0.5;
  }
`;
