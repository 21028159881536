import React from 'react';
import { Link } from 'gatsby';

import { resolveRemoteResource } from './index';
import { LESSON_LINK_RE } from './regex';

/**
 * Overrides an `<a>` tag in Lesson content.
 * 
 * Handles 3 distinct cases:
 *   Lesson link - we need a Link component from `@reach/router`
 *   Resource link - download link for lesson files
 *   Outbound link - outbound link to other servers
 *
 * @class
 */
export class Anchor extends React.Component {
  render() {
    const { resolvedURLs, children, href, ...linkProps } = this.props;
    if (LESSON_LINK_RE.test(href)) {
      return <Link {...linkProps} to={href}>{children}</Link>;
    }
    return <a {...linkProps} href={resolveRemoteResource(resolvedURLs, href)}>{children}</a>;
  }
}
