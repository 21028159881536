import React from 'react';
import classNames from 'classnames';

import { Caption } from '../Typography';
import { Container } from './Container';
import { TabsMenu } from './TabsMenu';

class NameLi extends React.Component {
  handleClick = () => {
    const { onClick, index, handleCloseModal } = this.props;
    handleCloseModal && handleCloseModal();
    return onClick(index);
  };

  render() {
    const { isActive, tabName, Li } = this.props;
    return (
      <li className={classNames({ active: isActive })} onClick={this.handleClick}>
        <Li>{tabName}</Li>
      </li>
    );
  }
}

export class Tabs extends React.Component {
  render() {
    const { tabNames, activeIndex, onClick } = this.props;
    const moduloActiveIndex = Math.abs(activeIndex % tabNames.length);
    return (
      <Container>
        <ul className="hide-phone">
          {tabNames.map((tabName, i) => (
            <NameLi
              key={i}
              Li={Caption}
              tabName={tabName}
              isActive={i === moduloActiveIndex}
              index={i}
              onClick={onClick}
            />
          ))}
        </ul>
        <TabsMenu
          className="show-phone"
          activeIndex={moduloActiveIndex}
          activeTab={tabNames[moduloActiveIndex]}
          tabs={tabNames}
          onClick={onClick}
        />
      </Container>
    );
  }
}
