import styled from 'styled-components';
import { colors, spacing, fonts, media } from 'theme';

export const Button = styled.button`
  cursor: pointer;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  min-width: 100px;
  width: fit-content;
  border-radius: 4px;
  border: none;
  text-decoration: none;
  ${fonts.button};

  transition: all 0.2s ease-out;
  transition-delay: 0.05s;

  &:hover {
    background: ${colors.persianblue};
  }
  &:focus {
    outline: 0;
  }

  & svg path[fill] {
    fill: currentColor;
  }
  & svg {
    margin-right: ${spacing.TINY};
  }

  /* default type - PRIMARY */
  background: ${colors.darkblue};
  color: ${colors.white};

  &.secondary {
    background: transparent;
    color: ${colors.darkblue};
    border: 2px solid ${colors.darkblue};
    &:hover {
      background: ${colors.persianblue};
      color: ${colors.white};
      border: 2px solid ${colors.persianblue};
    }
  }

  &.navigation {
    font-size: 15px;
    text-transform: none;
    letter-spacing: normal;
  }

  &.promoted {
    background: ${colors.tomato};
    color: ${colors.white};
    &:hover {
      background: #ff450d;
    }
  }

  &.danger {
    background: ${colors.crimson};
    color: ${colors.white};
    &:hover {
      background: #f11c31;
    }
  }

  &.text {
    background: transparent;
    color: ${colors.darkblue};
    &:hover {
      background: ${colors.whitesmoke};
    }
  }

  &.facebook {
    background: ${colors.facebook};
  }

  &.google {
    background: ${colors.google};
  }

  &.only-icon {
    & svg {
      margin-right: 0;
    }
    justify-content: center;
  }

  /* default size - large */
  font-size: 14px;
  max-height: 48px;
  min-height: 48px;
  padding: 0 ${spacing.BASE};
  ${media.phone`
    max-height: 40px;
    min-height: 40px;
  `};
  &.small {
    max-height: 40px;
    min-height: 40px;
    font-size: 12px;
  }
  &.extra-large {
    font-size: 15px;
    max-height: 64px;
    min-height: 64px;
    padding: 0 ${spacing.BASE_PLUS};
    ${media.tablet`
      font-size: 14px;
      max-height: 48px;
      min-height: 48px;
      padding: 0 ${spacing.BASE};
    `};
    ${media.phone`
      font-size: 14px;
      max-height: 48px;
      min-height: 48px;
      padding: 0 ${spacing.BASE};
    `};
  }

  text-transform: uppercase;
  letter-spacing: 1px;

  &:disabled {
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
  }
`;

export const LinkButton = Button.withComponent('a');

export const ButtonWithoutStyle = styled.button`
  display: inline-flex;
  align-items: center;
  font-size: 100%;
  font-family: inherit;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  color: inherit;
  border: none;
  text-decoration: none;
  &:focus {
    outline: 0;
  }
  &:hover {
    background: transparent;
  }
`;
