import React from 'react';
import { Trans } from '@lingui/react';

import ImgWalterWhite from '../assets/hexapod-options/img-walter-white.jpg';
import ImgBananaSplit from '../assets/hexapod-options/img-banana-split.jpg';
import ImgCherryBlossom from '../assets/hexapod-options/img-cherry-blossom.jpg';
import ImgDarkNight from '../assets/hexapod-options/img-dark-night.jpg';
import ImgDiamondBlue from '../assets/hexapod-options/img-diamond-blue.jpg';
import ImgInvisibleWalker from '../assets/hexapod-options/img-invisible-walker.jpg';
import ImgMistyMint from '../assets/hexapod-options/img-misty-mint.jpg';

const buyingOptionsPrice = Object.freeze({
  USD: {
    amount: 10.0,
  },
  GBP: {
    amount: 8.00,
  },
  EUR: {
    amount: 9.00,
  },
  HRK: {
    amount: 66.00,
  },
});

const style = { borderRadius: '50%', height: '32px', width: '32px' };

export const buyingOptions = Object.freeze({
  buyingOptionsText: <Trans>Chose your Hexapod style</Trans>,
  optionsCaption: <Trans>Color</Trans>,
  options: [
    {
      displayImage: ImgWalterWhite,
      displayName: 'Walter White',
      icon: <div style={{ backgroundColor: '#c5c4c2', ...style }} />,
      price: {
        USD: {
          amount: 0.00,
        },
        GBP: {
          amount: 0.00,
        },
        EUR: {
          amount: 0.00,
        },
        HRK: {
          amount: 0.00,
        },
      },
    },
    {
      displayImage: ImgBananaSplit,
      displayName: 'Banana Split',
      icon: <div style={{ backgroundColor: '#FFC61F', ...style }} />,
      price: buyingOptionsPrice,
    },
    {
      displayImage: ImgCherryBlossom,
      displayName: 'Cherry Blossom',
      icon: <div style={{ backgroundColor: '#FAB7CE', ...style }} />,
      price: buyingOptionsPrice,
    },
    {
      displayImage: ImgDarkNight,
      displayName: 'Dark Night',
      icon: <div style={{ backgroundColor: '#12123B', ...style }} />,
      price: buyingOptionsPrice,
    },
    {
      displayImage: ImgDiamondBlue,
      displayName: 'Diamond Blue',
      icon: <div style={{ backgroundColor: '#3191D6', ...style }} />,
      price: buyingOptionsPrice,
    },
    {
      displayImage: ImgInvisibleWalker,
      displayName: 'Invisible Walker',
      icon: <div style={{ backgroundColor: '#ffff', ...style }} />,
      price: buyingOptionsPrice,
    },
    {
      displayImage: ImgMistyMint,
      displayName: 'Misty Mint',
      icon: <div style={{ backgroundColor: '#3FCECB', ...style }} />,
      price: buyingOptionsPrice,
    },
  ]
});
