import React from 'react';
import { PlayfulLink } from 'components';
import { Trans } from '@lingui/react';
import { BodyMediumText, A } from 'playful-ui';

import { PersonalDataProtection } from '../PersonalDataProtection';

export const paymentConditions = {
  pageTitle: <Trans>Payment Conditions</Trans>,
  general: <Trans>General Terms and Conditions of Payments via Internet</Trans>,
  generalParagraph: (
    <Trans>
      Please read these General Terms and Conditions of Payments via Internet
      before beginning of use of any payment services (hereinafter: the service)
      via web pages: <PlayfulLink to="/">www.stemi.education</PlayfulLink> and all of its
      subdomains (such as learn.stemi.education, hereinafter: Pages) (provider
      of the service is the company STEMI d.o.o. with registered seat in the
      Republic of Croatia, Rijeka, Radmile Matejčić 10, OIB: 91713184849,
      ("STEMI")). Should you have any questions or uncertainties regarding
      conditions of use, please contact us at the e-mail address:{' '}
      <A href="mailto:info@stemi.education">info@stemi.education</A>
    </Trans>
  ),
  acceptance: (
    <Trans>
      Acceptance of General Terms and Conditions of Payments via the Internet
    </Trans>
  ),
  acceptanceParagraph: link => (
    <Trans>
      By using the service through Pages, you accept these General Terms and
      Conditions of Payments via Internet (hereinafter: General Terms and
      Conditions). In case you do not agree with the above mentioned, please do
      not use the given service.<br />
      <br />
      To all matters not specifically regulated by this General Terms and
      Conditions, General Terms and Conditions of using Web pages and Internet
      Trade Service which is <PlayfulLink to={link}>available here</PlayfulLink> apply.
    </Trans>
  ),
  changes: <Trans>Changes to General Terms and Conditions</Trans>,
  changesParagraph: (
    <Trans>
      STEMI reserves the right to make amendments or addendums to this General
      Terms and Conditions at any time and such amendments enter into force on
      the day of their publication on the Pages. By continuing to use the
      service it shall be considered you agree to such amended General Terms and
      Conditions. STEMI recommends you to examine these General Terms and
      Conditions periodically in order to become acquainted with possible
      changes of the same.
    </Trans>
  ),
  payment: <Trans>Payment Methods</Trans>,
  paymentParagraph: (
    <Trans>
      <BodyMediumText>
        STEMI provides the following payment methods of products and services:<br />
      </BodyMediumText>
      <ul>
        <li>
          <BodyMediumText>
            payment via the Internet – payment via BrainTree system by using credit and debit cards
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>bank transfer payment</BodyMediumText>
        </li>
      </ul>
      <br />
      <BodyMediumText>
        <b>Payment via the Internet by Banker Cards</b>
        <br />
        <br />
        Products and services can be paid by using one of the following cards:<br />
      </BodyMediumText>
      <ul>
        <li>
          <BodyMediumText>Mastercard credit card</BodyMediumText>
        </li>
        <li>
          <BodyMediumText>Visa credit and debit card</BodyMediumText>
        </li>
        <li>
          <BodyMediumText>Maestro debit card</BodyMediumText>
        </li>
        <li>
          <BodyMediumText>American Express credit card.</BodyMediumText>
        </li>
      </ul>
      <br />
      <BodyMediumText>
        The purchase process is conducted in the following manner:<br />
      </BodyMediumText>
      <ul>
        <li>
          <BodyMediumText>
            place products/services in the cart by selecting «Add to cart»
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            correct your order if necessary and click on «Checkout»
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            insert customer information and click on «Continue to shipping
            method»
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            select the shipping method and click on «Continue to payment method»
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            select payment method, billing address and click on «Complete order»
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            by selecting «pay» the purchase is concluded
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>wait for your delivery.</BodyMediumText>
        </li>
      </ul>
      <BodyMediumText>
        Bank Transfer Payment<br />
      </BodyMediumText>
      <br />
      <BodyMediumText>
        Payment by performing payment to the bank account of STEMI, pursuant to
        the proposal you will receive via electronic mail after submitting the
        order, and which will include all the necessary data for payment and
        delivery of a product.
    </BodyMediumText>
    </Trans>
  ),
  bank: <Trans>Bank Transfer Payment</Trans>,
  bankParagraph: (
    <Trans>
      Payment by performing payment to the bank account of STEMI, pursuant to
      the proposal you will receive via electronic mail after submitting the
      order, and which will include all the necessary data for payment and
      delivery of a product.
    </Trans>
  ),
  protection: <Trans>Protection of Your Data</Trans>,
  protectionParagraph: (
    <Trans>
      In order to acquire a right to payment via Internet, it is necessary, at
      the time of the purchase process, to give certain personal data and the
      data on the bank card and collection of such personal data is a subject to
      the General Terms and Conditions of Personal Data Protection which are{' '}
      <PlayfulLink to={PersonalDataProtection.route}>available here.</PlayfulLink>
    </Trans>
  ),
  complaints: <Trans>Complaints</Trans>,
  complaintsParagraph: (
    <Trans>
      All complaints can also be delivered in a written form to the following
      address: <br />
      <br />
      STEMI d.o.o<br />
      Radmile Matejčić 10, 51000 Rijeka<br />
      OIB: 91713184849
    </Trans>
  ),
  other: <Trans>Other provisions</Trans>,
  otherParagraph: (
    <Trans>
      These General Terms and Conditions are written in Croatian and English
      language version. In the case of any discrepancy between the Croatian and
      English version of these General Terms and Conditions, the Croatian
      version shall prevail.
    </Trans>
  )
};
