import styled from 'styled-components';
import { Button, Column, Row, BodySmallText, BodyXSmallText, media, zIndex, spacing, colors } from 'playful-ui';

export const OrderSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  z-index: ${zIndex.CONTENT_BANNER_CONTAINER};
  border-left: 1px solid ${colors.mischka};

  max-width: 440px;

  ${media.tablet`
    width: 100%;
    display: ${props => props.hide ? 'none' : 'flex'};
    max-width: 532px;
    border-left: 0;
    position: relative;
  `};
  ${media.phone`
    max-width: 343px;
  `};

  & > ${Column} {
    margin-left: ${spacing.LARGE};
    margin-top: ${spacing.XLARGE};
    ${media.tablet`
      margin-top: ${spacing.BASE};
      margin-left: 0;
    `};
  };

  & ${Column}.product-items {
    flex: unset;
    border-bottom: 1px solid ${colors.mischka};
  };

  & ${Column}.expenses, & ${Column}.discount-codes {
    flex: unset;
    padding: ${spacing.BASE} 0;
    border-bottom: 1px solid ${colors.mischka};
  }
  & ${Column}.discount-codes .promo-code-input {
    min-width: 150px;
    ${media.tablet`
      min-width: 300px;
    `}
    ${media.phone`
      min-width: 143px;
    `}
  };
  & ${Column}.discount-codes ${Button} {
    min-height: 44px;
    margin: 2px;
  };
  & ${Column}.discount-codes > ${BodySmallText} {
    margin-top: ${spacing.TINY};
  }
  & .promo-code-input input {
    ${media.tablet`
      min-width: 300px;
    `}
    ${media.phone`
      min-width: 143px;
    `}
  }
  & ${Column}.expenses ${Row} {
    margin-top: ${spacing.TINY};
  };
  & ${Column}.expenses ${Row}:first-child {
    margin-top: 0;
  };
  & ${Column}.expenses ${BodySmallText}:first-child {
    margin-top: 0;
  };

  & > ${Column} > ${BodyXSmallText} {
    margin-top: ${spacing.SMALL};
    margin-bottom: ${spacing.SMALL};
    opacity: 0.4;
  };

  & ${Row}.total {
    flex: unset;
    padding: ${spacing.BASE} 0;
    border-bottom: 1px solid ${colors.mischka};
  };

  & p.bold {
    font-weight: 600;
  };

  & ::after {
    content: "";
    display: block;
    width: 300%;
    position: absolute;
    top: 0;
    bottom: 0;
    background: ${colors.whitesmoke};
    z-index: -1;
    ${media.tablet`
      left: -100%;
      border-bottom: 1px solid ${colors.mischka};
    `}
  }
`;
