import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

import { media, zIndex } from 'theme';

import { Modal } from '../Modal';
import { Column } from '../Column';
import playImage from './assets/ic-play-medium@3x.png';

const PlayerContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 960px;
  ${media.tablet`
    width: 680px;
  `} ${media.phone`
    width: 360px;
  `}
  & .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const ThumbnailContainer = styled(Column)`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  & .modal-video-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  & .modal-video-play-icon {
    width: 48px;
    height: 48px;
    object-fit: cover;
    z-index: ${zIndex.MODAL_VIDEO_PLAY_ICON};
  }
`;

export class VideoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
  }

  handleOpenModal(event) {
    this.setState({ isModalOpen: true });
    event.preventDefault();
    event.stopPropagation();
  }

  handleCloseModal(event) {
    this.setState({ isModalOpen: false });
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    const {
      thumbnail,
      thumbnailVideo,
      className,
      mediaOverlay,
      overlayIndex,
      ...props
    } = this.props;
    let MediaOverlay;
    let MediaOverlayProps;
    if (mediaOverlay) {
      MediaOverlay = mediaOverlay;
      MediaOverlayProps = { index: overlayIndex };
    } else {
      MediaOverlay = React.Fragment;
      MediaOverlayProps = {};
    }
    return (
      <React.Fragment>
        <Modal isOpen={this.state.isModalOpen} onRequestClose={this.handleCloseModal}>
          <PlayerContainer>
            <ReactPlayer className="react-player" width="100%" height="100%" {...props} />
          </PlayerContainer>
        </Modal>
        <ThumbnailContainer
          className={`h-center v-center ${className}`}
          onClick={this.handleOpenModal}
        >
          <MediaOverlay {...MediaOverlayProps}>
            {!thumbnailVideo ? (
              <img alt="video-thumbnail" className="modal-video-thumbnail" src={thumbnail} />
            ) : (
              <video
                className="modal-video-thumbnail"
                autoPlay
                muted="muted"
                loop
                preload="true"
                type="video/mp4"
                src={thumbnailVideo}
                poster={thumbnail}
              />
            )}
            <img alt="play-image" className="modal-video-play-icon" src={playImage} />
          </MediaOverlay>
        </ThumbnailContainer>
      </React.Fragment>
    );
  }
}
