// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/vercel/path0/web/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adventure-js": () => import("/vercel/path0/web/src/pages/adventure.js" /* webpackChunkName: "component---src-pages-adventure-js" */),
  "component---src-pages-index-js": () => import("/vercel/path0/web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lab-index-js": () => import("/vercel/path0/web/src/pages/lab/index.js" /* webpackChunkName: "component---src-pages-lab-index-js" */),
  "component---src-pages-legal-personal-data-protection-js": () => import("/vercel/path0/web/src/pages/legal/personal-data-protection.js" /* webpackChunkName: "component---src-pages-legal-personal-data-protection-js" */),
  "component---src-pages-lesson-index-js": () => import("/vercel/path0/web/src/pages/lesson/index.js" /* webpackChunkName: "component---src-pages-lesson-index-js" */),
  "component---src-pages-stemi-lab-js": () => import("/vercel/path0/web/src/pages/stemi-lab.js" /* webpackChunkName: "component---src-pages-stemi-lab-js" */)
}

exports.data = () => import("/vercel/path0/web/.cache/data.json")

