import React from 'react';
import styled from 'styled-components';
import { Column, media, H3, spacing } from 'playful-ui';

const StyledColumn = styled(Column)`
  & div.content {
    margin-bottom: 120px;
    width: 754px;
    ${media.tablet`
      width: unset;
      margin-left: 56px;
      margin-right: 56px;
    `};
    ${media.phone`
      width: unset;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 80px;
    `};
  }
  & ${H3} {
    margin-top: ${spacing.LARGE};
    margin-bottom: ${spacing.BASE};
    ${media.phone`
      margin-top: ${spacing.BASE_PLUS};
      margin-bottom: ${spacing.SMALL};
    `};
  }
`;

export const LegalContainer = props => (
  <StyledColumn className="h-center">
    <div className="content" {...props}>
      {props.children}
    </div>
  </StyledColumn>
);
