import _ from 'lodash';
import uuidv4 from 'uuid/v4';

import { once, set } from 'stemi-firebase';

import { getCurrentUser, getCurrentUserUid } from 'modules/auth';

import { getCart } from './selectors';

export function onceCart(userId) {
  return once('carts', { id: userId });
}

export function setCart(userId, cart) {
  return set('carts', { id: userId, ...cart });
}

function sanitizeOrderItem(orderItem) {
  return {
    productName: orderItem.productName,
    option: orderItem.options.displayName,
    quantity: orderItem.quantity,
  };
}

export const addOrderItem = orderItem => (dispatch, getState) => {
  const state = getState();
  const user = getCurrentUser(state);
  const userId = getCurrentUserUid(state);
  const cart = getCart(state);
  if (user.isValid() && cart.isValid()) {
    const newCart = { ...cart.getData(), [uuidv4()]: sanitizeOrderItem(orderItem) };
    dispatch(setCart(userId, newCart));
  }
};

export const removeOrderItem = cartItemId => (dispatch, getState) => {
  const state = getState();
  const user = getCurrentUser(state);
  const userId = getCurrentUserUid(state);
  const cart = getCart(state);
  if (user.isValid() && cart.isValid()) {
    const newCart = _.omit(cart.getData(), [cartItemId]);
    dispatch(setCart(userId, newCart));
  }
};

export const updateOrderItem = (cartItemId, quantity) => (dispatch, getState) => {
  const state = getState();
  const user = getCurrentUser(state);
  const userId = getCurrentUserUid(state);
  const cart = getCart(state);
  if (user.isValid() && cart.isValid()) {
    const newCart = { ...cart.getData(), [cartItemId]: { ...cart[cartItemId], quantity } };
    dispatch(setCart(userId, newCart));
  }
};

export const removeAllOrderItems = () => (dispatch, getState) => {
  const state = getState();
  const user = getCurrentUser(state);
  const userId = getCurrentUserUid(state);
  if (user.isValid()) {
    dispatch(setCart(userId, {}));
  }
};
