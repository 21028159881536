import styled from 'styled-components';

import {
  Button,
  Column,
  H3,
  Label,
  Row,
  colors,
  spacing,
  media,
} from 'playful-ui';

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  height: 100vh;
  justify-content: center;
  padding-bottom: 80px;
  ${media.phone`
    padding-top: ${spacing.XLARGE_PLUS};
    padding-bottom: 40px;
  `} & form {
    display: flex;
    width: 700px;
    height: fit-content;
    flex-direction: row;
    ${media.phone`
      flex-direction: column;
      width: 100%;
    `};
  }
  & > form > ${Column} {
    ${media.phone`
      margin: 0 ${spacing.BASE};
    `};
  }
  & ${H3} {
    margin-bottom: ${spacing.BASE};
    color: ${colors.darkblue};
  }
  & .text-input {
    margin-bottom: ${spacing.BASE};
  }
  & ${H3}.dummy {
    color: transparent;
    ${media.phone`
      display: none;
    `};
  }
  & ${Button} {
    margin-top: 0px;
    ${media.phone`
      margin: 0px;
    `};
  }
  & .submit-button {
    margin-top: ${spacing.BASE_PLUS};
  }
  & ${Button}.facebook {
    margin-bottom: ${spacing.BASE};
    ${media.phone`
      margin-bottom: 0px;
    `};
  }
  & .mobile-horizontal {
    max-width: 170px;
    ${media.phone`
      max-width: unset;
      justify-content: space-between;
    `};
  }
  & .mobile-horizontal > ${Column} > ${Label} {
    margin: 8px 0;
  }
  & .mobile-horizontal > ${Column} {
    width: fit-content;
    max-height: 80px;
    margin-bottom: ${spacing.BASE};
    ${media.phone`
      max-width: 162px;
      margin-bottom: 0px;
    `};
  }
  & ${Row} > .newsletterCheckbox {
    margin-right: 8px;
  }
  & ${Row} > label,
  & > form > ${Column} > p {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: 0.2px;
    color: #12123b;
  }
  & ${Row} > p a {
    color: #ff6230;
  }
`;
