import React from 'react';
import styled from 'styled-components';

import { NotificationCard, Column, media, spacing, zIndex } from 'playful-ui';

const Container = styled(Column)`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  & > ${Column} {
    align-items: flex-end;
    width: 1146px;
    margin-top: ${spacing.XLARGE_PLUS};
    ${media.tablet`
      width: 708px;
    `}
    ${media.phone`
      width: 335px;
      margin-top: 66px;
    `}
    & ${NotificationCard} {
      z-index: ${zIndex.NOTIFICATION_AREA};
    }
  }
`;

export const NotificationArea = ({ children }) => (
  <Container className="h-center">
    <Column>
      {children}
    </Column>
  </Container>
);
