import React from 'react';
import styled from 'styled-components';
import { H3, Row, spacing, media, BodyMediumText } from 'playful-ui';

import { LegalContainer } from '../components/LegalContainer';
import { onlineSecurity } from './content/OnlineSecurity';
import ImgAmerican from './assets/im-american-express.png';
import ImgMaestro from './assets/im-maestro.png';
import ImgMasterCard from './assets/im-master-card.png';
import ImgVisa from './assets/im-visa.png';

const ContentStyle = styled.div`
  & img {
    height: 60px;
    margin-top: ${spacing.BASE};
    margin-right: ${spacing.BASE_PLUS};
    ${media.phone`
      height: 42px;
      margin-top: ${spacing.SMALL};
      margin-right: ${spacing.BASE};
    `};
  }
`;

export class OnlineSecurity extends React.Component {
  static route = '/legal/online-security';
  static title = onlineSecurity.pageTitle;

  render() {
    return (
      <LegalContainer>
        <ContentStyle>
          <H3>{onlineSecurity.creditCards}</H3>
          <Row>
            <a href="https://www.americanexpress.com/" target="_blank">
              <img src={ImgAmerican} />
            </a>
            <a href="https://www.mastercard.com" target="_blank">
              <img src={ImgMaestro} />
            </a>
            <a href="http://www.maestrocard.com" target="_blank">
              <img src={ImgMasterCard} />
            </a>
            <a href="https://www.visaeurope.com/" target="_blank">
              <img src={ImgVisa} />
            </a>
          </Row>
          <H3>{onlineSecurity.americanExpress}</H3>
          <BodyMediumText>
            {onlineSecurity.americanExpressParagraph}
          </BodyMediumText>
          <H3>{onlineSecurity.masterSecureCode}</H3>
          <BodyMediumText>
            {onlineSecurity.masterSecureCodeParagraph}
          </BodyMediumText>
          <H3>{onlineSecurity.verifiedVisa}</H3>
          <BodyMediumText>
            {onlineSecurity.verifiedVisaParagraph}
          </BodyMediumText>
        </ContentStyle>
      </LegalContainer>
    );
  }
}
