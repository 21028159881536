import React from 'react';

import { Product } from '../components/Product';
import { Overview } from '../servo-motor-triple-pack/Overview';
import { Features } from '../servo-motor-triple-pack/Features';
import imgServoMotor from '../servo-motor-triple-pack/assets/img-servo-triple-pack.jpg';
import { content } from '../servo-motor-triple-pack/content/ServoMotorPage';
import { buyingOptions } from '../servo-motor-triple-pack/content/BuyingOptions';
import { shippingModels } from '../servo-motor-triple-pack/content/shippingModels';
import { BuyingOptions } from '../servo-motor-triple-pack/BuyingOptions';

const subpages = [
  Overview,
  Features,
];

const productMedia = [
  <img alt="servo-motor-triple-pack" key={1} src={imgServoMotor} />,
];

export class ServoTriplePackPage extends React.Component {
  static route = '/products/servo-motor-triple-pack';
  static title = 'Servo motor triple package';
  static name = content.productName;
  static price = content.price;
  static buyingOptions = buyingOptions;
  static shippingModels = shippingModels;

  render() {
    return (
      <Product
        {...content}
        subpages={subpages}
        productMedia = {productMedia}
        BuyingOptions={BuyingOptions}
      />
    )
  }
}
