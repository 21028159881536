import styled from 'styled-components';
import { Row, Column, BodyMediumText, ButtonWithoutStyle, colors, media, spacing } from 'playful-ui';

export const CartItemContainer = styled(Row)`
  position: relative;
  width: 100%;
  border-bottom: 1px solid ${colors.whitesmoke};
  
  ${media.phone`
    flex-wrap: wrap;
    justify-content: space-between;
  `};
  
  & > ${Column} {
    min-height: 111px;
    ${media.phone`
      min-height: unset;
    `};
  };
  & > ${Column}:nth-child(1) {
    ${media.phone`
      min-width: 335px;
    `};
  };
  & > ${Column}:nth-child(2), & > ${Column}:nth-child(3) {
    ${media.phone`
      min-width: 100px;
      flex: 1;
    `};
  };

  & > ${Column}:nth-child(4) {
    ${media.phone`
      align-self: flex-start;
      flex: unset;
    `};
  };

  & img {
    display: inline-flex;
    align-self: flex-start;
    width: 102px;
    margin-top: 26px;
    border-radius: 3px;
  };

  & ${BodyMediumText}.product {
    margin-top: 26px;
    margin-left: ${spacing.BASE};
  };

  & div.small {
    margin-top: 40px;
    ${media.phone`
      margin: ${spacing.BASE} 0;
    `};
  };

  & ${BodyMediumText}.price {
    text-align: end;
    font-weight: 600;
    margin-top: 42px;
    ${media.phone`
      margin: 34px 0;
      margin-right: -24px;
      align-self: flex-end;
    `};
  };

  & ${ButtonWithoutStyle}.x {
    ${media.phone`
      margin-top: -120px;
    `}
  }

`;
