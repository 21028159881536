import React from 'react';
import { BodyMediumText } from 'playful-ui';

import { Anchor, Img } from 'modules/remote-resources';

export function markdownOptions(resolvedURLs) {
  return {
    overrides: {
      img: {
        component: Img,
        props: { resolvedURLs },
      },
      a: {
        component: Anchor,
        props: { resolvedURLs },
      },
      p: {
        component: BodyMediumText,
      },
      li: {
        component: ({children}) => <li><BodyMediumText>{children}</BodyMediumText></li>,
      }
    },
  }
};
