import styled, { css } from 'styled-components';
import { Column, Row, BodyMediumText, spacing, media, colors, fonts } from 'playful-ui';

export const CustomerInfoContainer = styled(Column)`
  & ${Row}.text-input {
    ${media.phone`
      flex-direction: column;
    `}; 
  };
  & ${Row}.text-input div.input:last-child {
    margin-left: ${spacing.TINY};
    ${media.phone`
      margin-left: 0;
    `};
  };
  & ${Row}.text-input select:last-child {
    margin-left: ${spacing.TINY};
    ${media.phone`
      margin-left: 0;
    `};
  };

  & ${BodyMediumText} {
    margin-bottom: ${spacing.BASE};
  };

  & form > ${BodyMediumText} {
    margin-top: ${spacing.BASE};
  };

  & form select {
    flex: 1;
    margin-bottom: ${spacing.TINY};
    min-width: 175px;
    height: 46px;
    background-color: ${colors.snow};
    padding-left: ${spacing.SMALL};
    border-radius: 4px;
    ${css`
      ${fonts.textInput};
    `}
    ${media.phone`
      max-width: 343px;
      min-width: 343px;
    `};
  }
  & form select:focus {
    outline-width: 0;
    border: solid 2px ${colors.applyOpacity(colors.persianblue, 0.5)};
  }

  & div.input {
    margin-bottom: ${spacing.TINY};
    min-width: 175px;
    flex: 1;
    ${media.phone`
      min-width: 328px;
    `};
  };

  & div.buttons {
    margin-top: ${spacing.BASE};
    margin-bottom: ${spacing.BASE};
    ${media.phone`
      flex-direction: column-reverse;
      align-items: flex-end;
    `};
  }
  & div.buttons a, div.buttons button {
    text-align: center;
    margin-right: ${spacing.TINY};
    margin-bottom: ${spacing.TINY};
    ${media.phone`
      margin-right: 0;
      min-height: 48px;
    `};
  };
  & div.buttons a {
    ${media.phone`
      margin-bottom: 0;
    `};
  };

  & div.buttons button { 
    margin-right: 0;
  }
`;
