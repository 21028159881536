import React from 'react';
import { Link } from 'gatsby';
import { H6, Icon, Caption, Row } from 'playful-ui';

import { EduHeaderContainer } from './EduHeaderContainer';

export const EduHeader = ({
  activeAdventure,
  activeQuest,
  onClickExpand,
  navTabOpened,
  children,
}) => (
  <EduHeaderContainer>
    <Row className="h-center header">
      <Row className="media-widths bottom">
        <H6>{activeQuest ? activeQuest.name.toUpperCase() : ''}</H6>
      </Row>
    </Row>
    <Row className="h-center navigation">
      <Row className="media-widths space-between">
        {activeAdventure && (
          <Link to={`/adventure/${activeAdventure.id}`}>
            <Icon name="ArrowBack" />
            <Caption className="hide-phone-tablet">
              ALL {activeAdventure.name} QUESTS
            </Caption>
          </Link>
        )}
        <Row className="v-center show-phone-tablet" onClick={onClickExpand}>
          <Caption>SELECT LESSON</Caption>
          <Icon name={navTabOpened ? 'ExpandLess' : 'ExpandMore'} />
        </Row>
        {children}
      </Row>
    </Row>
  </EduHeaderContainer>
);
