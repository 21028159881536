import styled from 'styled-components';

import { spacing, colors } from 'theme';

import { H4 } from '../Typography';
import { Row } from '../Row';
import { Column } from '../Column';

export const ExpandableHeadingContainer = styled(Column)`
  width: 100%;
  max-width: 950px;
  box-shadow: 0 1px ${colors.whitesmoke};

  & ${Row}.heading {
    padding: ${spacing.BASE} ${spacing.TINY};
    cursor: pointer;
    transition: color 0.2s cubic-bezier(0, 0, 0.2, 1);
  }
  & ${Row}.heading ${H4} {
    transition: color 0.2s cubic-bezier(0, 0, 0.2, 1);
  }
  & ${Row}.heading svg {
    min-width: 24px;
    margin-left: ${spacing.BASE};
  }
  & ${Row}.heading svg path[fill] {
    fill: currentColor;
  }
  & ${Row}.heading.expanded {
    color: ${colors.darkblue};
  }
  & ${Row}.heading ${H4} {
    color: inherit;
  }
  & ${Row}.heading:hover {
    color: ${colors.darkblue};
  }

  & div.children-outer-container {
    display: block;
    overflow: hidden;
  }
  & div.children {
    transition: margin-top 0.2s cubic-bezier(0, 0, 0.2, 1);
    padding-left: ${spacing.TINY};
    padding-right: ${spacing.BASE_PLUS};
    padding-bottom: ${spacing.LARGE};
  }
`;
