import React from 'react';
import HelmetHeader from 'react-helmet';
import { withStemiI18n } from 'stemi-i18n';

import { STEMI_WEB_SERVER } from 'consts';
import imgHeroHomepage from 'modules/products/hexapod/assets/img-girl-assembly.jpg';

export const Helmet = withStemiI18n(
  ({
    i18n,
    title = 'STEMI - Learning by creating',
    route = '/',
    description,
    image = imgHeroHomepage,
  }) => {
    const pageDescription =
      description ||
      i18n.t`
    STEMI offers DIY projects for kids, big or small, with a cool factor through the roof.
    Start an adventure of a lifetime and build coolest things on the planet.
  `;
    return (
      <HelmetHeader
        title={title}
        meta={[
          { property: 'og:site_name', content: title },
          { property: 'og:url', content: `https://${STEMI_WEB_SERVER}${route}` },
          { property: 'og:title', content: title },
          { property: 'og:type', content: 'website' },
          { property: 'og:description', content: pageDescription },
          { property: 'og:image', content: `https://${STEMI_WEB_SERVER}${image}` },
          { property: 'og:image:secure_url', content: `https://${STEMI_WEB_SERVER}${image}` },
          { property: 'fb:app_id', content: '1830727983881525' },
          { name: 'twitter:site', content: '@stemi_edu' },
          { name: 'twitter:card', content: 'summery_large_image' },
          { name: 'twitter:title', content: title },
          { name: 'twitter:description', content: pageDescription },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1, maximum-scale=1',
          },
        ]}
      />
    );
  }
);
