import styled from 'styled-components';
import { Column, Row, spacing, colors } from 'playful-ui';

export const RoundedBox = styled(Column)`
  flex: unset;
  box-sizing: border-box;
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${colors.mischka};
  padding: 0 ${spacing.SMALL};

  & > ${Row}, & > ${Column} {
    padding: ${spacing.SMALL} 0;
    border-bottom: 1px solid ${colors.mischka};
  }
  & > ${Row}:last-child, & > ${Column}:last-child {
    border-bottom: 0;
  }

  & .margin-top {
    margin-top: ${spacing.TINY};
  }
`;
