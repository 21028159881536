import styled from 'styled-components';
import { Column, BodyXSmallText, spacing, colors, media } from 'playful-ui';

export const OrderCompletedContainer = styled(Column)`
  & a {
    margin-top: ${spacing.BASE};
  };
  & svg {
    margin-bottom: ${spacing.BASE};
  };
  & svg path {
    fill: ${colors.limegreen};
  };
  & h2, & p {
    margin-bottom: ${spacing.TINY};
    text-align: center;
    max-width: 500px;
    ${media.phone`
      max-width: 350px;
    `};
  };
`;
