import styled from 'styled-components';

import { BodyXSmallText, CloseButton, Row, Rule, colors, media, spacing } from 'playful-ui';

export const EuBannerContainer = styled(Row)`
  height: 100%;
  border: 3px black;
  background-color: ${colors.snow};
  box-shadow: 0 -4px 16px 0 rgba(0, 0, 0, 0.05);
  ${media.phone`
    padding: ${spacing.TINY} ${spacing.SMALL};
  `}
  & ${Row}#eu-images {
    padding: 16px 24px;
    ${media.tablet`
      height: 104px;
    `}
    ${media.tablet`
      padding: 0px 24px;
    `}
    ${media.phone`
      width: 100%;
      justify-content: flex-start;
      padding: 0px;
    `}
  }
  & svg#eu {
    width: 152px;
    min-width: 152px;
    height: 31px;
    margin-top: 17px;
    ${media.tablet`
      height: 28px;
      width: 136px;
      min-width: 136px;
      margin-top: 49px;
    `}
    ${media.phone`
      margin-top: 25px;
    `}
  }
  & svg#eu-funds {
    width: 141px;
    min-width: 141px;
    height: 47px;
    margin-left: ${spacing.SMALL};
    ${media.tablet`
      height: 43px;
      width: 126px;
      min-width: 126px;
      margin-top: 32px;
    `}
    ${media.phone`
      margin-top: 8px;
      margin-right: ${spacing.SMALL};
    `}
  }
  & ${Rule} {
    margin: 0;
    height: 100%;
  }
  & .hide-mobile {
    ${media.phone`
      display: none;
    `}
  }
  & ${CloseButton} {
    margin: ${spacing.SMALL} ${spacing.BASE};
    ${media.tablet`
      margin: ${spacing.BASE_PLUS} 20px;
    `}
    ${media.phone`
      position: absolute;
      top: 0;
      right: 0;
      margin: ${spacing.SMALL} 12px;
    `}
  }
  & ${BodyXSmallText} {
    color: ${colors.applyOpacity(colors.midnight, 0.69)};
    margin: 16px 24px;
    max-width: 640px;
    line-height: 18px;
    ${media.tablet`
      max-width: 316px;
    `}
    ${media.phone`
      max-width: unset;
      width: 100%;
    `}
  }
  & ${BodyXSmallText} > a {
    text-decoration: none;
    color: ${colors.tomato};
  }
`;
