import React from 'react';

import { BuyingOptions as BuyingOptionsComponent } from '../components/BuyingOptions';
import { buyingOptions } from './content/BuyingOptions';
import { content } from './content/ServoMotorPage';
import { shippingModels } from './content/shippingModels';

export const BuyingOptions = ({ productName, handleBackClick }) => (
  <BuyingOptionsComponent
    productName={productName}
    buyingOptionsText={buyingOptions.buyingOptionsText}
    optionsCaption={buyingOptions.optionsCaption}
    options={buyingOptions.options}
    price={content.price}
    handleBackClick={handleBackClick}
    shippingModels={shippingModels}
  />
)
