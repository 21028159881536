import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { I18nProvider } from '@lingui/react';
import LanguageDetector from 'i18next-browser-languagedetector';

import { I18nContext } from './I18nContext';
import { resolveLanguage, resolveCurrency, resolveCurrencySymbol } from './resolvers';
import { currencyChanged } from './redux';

const preferredLangCookie = 'stemi-i18n.preferredLang';
const preferredCurrencyCookie = 'stemi-i18n.preferredCurrency';

/**
 * Component for wrapping React app in I18nProvider and I18nContext.Provider.
 * I18nContext provides currently selected language, currency, as well as a function
 * to change them.
 */
class StemiI18nProvider extends React.Component {
  languageDetector = new LanguageDetector();
  constructor(props) {
    super(props);
    const { catalogsObject } = props;

    const cookieLang = this.languageDetector.detectors.cookie.lookup({
      lookupCookie: preferredLangCookie,
    });
    const queryLang = this.languageDetector.detectors.querystring.lookup({
      lookupQuerystring: 'lang',
    });
    const navigatorLang = this.languageDetector.detectors.navigator.lookup({}) || [];
    const { resolvedLang, browserLang } = resolveLanguage(
      catalogsObject,
      queryLang,
      cookieLang,
      ...navigatorLang
    );

    if (resolvedLang) {
      console.log(`Resolved language: ${resolvedLang}. Browser language: ${browserLang}`);
    }

    const cookieCurrency = this.languageDetector.detectors.cookie.lookup({
      lookupCookie: preferredCurrencyCookie,
    });
    const resolvedCurrency = resolveCurrency(browserLang);

    if (resolvedCurrency) {
      console.log(`Resolved currency ${resolvedCurrency}. Cookie currency ${cookieCurrency}.`);
    }

    this.state = {
      language: resolvedLang,
      currency: cookieCurrency || resolvedCurrency,
      currencySymbol: resolveCurrencySymbol(cookieCurrency || resolvedCurrency),
    };
    this.setCurrency(this.state.currency);
    this.providerFunctions = {
      handleLanguageChanged: this.handleLanguageChanged,
      handleCurrencyChanged: this.handleCurrencyChanged,
      resolvePrice: this.resolvePrice,
      resolvePriceString: this.resolvePriceString,
    };
  }

  setCurrency = currency => this.props.currencyChanged({ currency });

  handleLanguageChanged = language => {
    this.setState({ language });
    this.languageDetector.detectors.cookie.cacheUserLanguage(language, {
      lookupCookie: preferredLangCookie,
    });
  };

  handleCurrencyChanged = currency => {
    this.setState({ currency });
    this.setState({ currencySymbol: resolveCurrencySymbol(currency) });
    this.languageDetector.detectors.cookie.cacheUserLanguage(currency, {
      lookupCookie: preferredCurrencyCookie,
    });
  };

  resolvePrice = priceObject => priceObject[this.state.currency];

  resolvePriceString = priceObject => {
    const resolvedPriceObject = this.resolvePrice(priceObject);
    return `${this.state.currencySymbol}${resolvedPriceObject.amount}`;
  };

  render() {
    const { children, catalogsObject } = this.props;
    return (
      <I18nProvider language={this.state.language} catalogs={catalogsObject}>
        <I18nContext.Provider value={{ ...this.state, ...this.providerFunctions }}>
          {children}
        </I18nContext.Provider>
      </I18nProvider>
    );
  }
}

StemiI18nProvider.propTypes = {
  currencyChanged: PropTypes.func.isRequired,
};

const mapDispatchToProps = { currencyChanged };

export default connect(
  null,
  mapDispatchToProps
)(StemiI18nProvider);
