import React from 'react';
import ReactModal from 'react-modal';
import { createGlobalStyle } from 'styled-components';

import { zIndex } from 'theme';

import { PlayfulProvider } from '../PlayfulProvider';

// eslint-disable-next-line
const ModalGloabStyle = createGlobalStyle`
  .playful-ui-modal {
    position: absolute;
    outline: 0;
  }
  .playful-ui-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.69);
    z-index: ${zIndex.MODAL};
  }
  .playful-ui-modal-right {
    justify-content: flex-end;
  }
`;

export class Modal extends React.Component {
  // eslint-disable-next-line
  componentDidMount() {
    const appElementId = PlayfulProvider.rootElement.substr(1);

    if (document) {
      ReactModal.setAppElement(document.getElementById(appElementId));
    }
  }

  render() {
    const overlayClassName = [
      'playful-ui-modal-overlay',
      this.props.modalRight ? 'playful-ui-modal-right' : '',
    ];
    return (
      <React.Fragment>
        <ModalGloabStyle />
        <ReactModal
          className="playful-ui-modal"
          overlayClassName={overlayClassName.join(' ')}
          {...this.props}
        >
          {this.props.children}
        </ReactModal>
      </React.Fragment>
    );
  }
}
