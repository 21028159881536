import styled from 'styled-components';

import { media } from 'theme';

export const Row = styled.div`
  width: auto;
  display: flex;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  &.mobile-vertical {
    ${media.phone`
      flex-direction: column;
    `};
  }
  &.tablet-vertical {
    ${media.tablet`
      flex-direction: column;
    `};
  }
  &.mobile-vertical.h-center {
    ${media.phone`
      flex-direction: column;
      align-items: center;
    `};
  }
  &.tablet-vertical.h-center {
    ${media.tablet`
      flex-direction: column;
      align-items: center;
    `};
  }
  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
  &.h-center {
    justify-content: center;
  }
  &.h-start {
    justify-content: flex-start;
  }
  &.h-end {
    justify-content: flex-end;
  }
  &.v-center {
    align-items: center;
  }
  &.top {
    align-items: flex-start;
  }
  &.bottom {
    align-items: flex-end;
  }
`;
