import React from 'react';
import { saveAs } from 'file-saver';
import { connect } from 'react-redux';
import { Trans } from '@lingui/react';

import {
  HeroBackground,
  H2,
  H3,
  RoundImage,
  BodySmallText,
  HorizontalRule,
  Caption,
  Column,
  spacing,
  TextInput,
  Icon,
  Button,
} from 'playful-ui';
import {
  changePassword,
  deleteAccount,
  updateDisplayName,
  uploadPhoto,
  updatePhotoURL,
} from 'stemi-firebase';

import { getCurrentUser } from 'modules/auth';

import { FormContainer } from '../components/FormContainer';
import { changePasswordErrors } from '../services/profileErrors';

const INITIAL_STATE = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
  passwordError: null,
  username: '',
  newPhotoURL: '',
  deletePassword: '',
  deletePasswordError: null,
};

class ProfilePage extends React.Component {
  static route = '/profile';

  constructor(props) {
    super(props);

    const { displayName, photoURL } = this.props.user;
    this.state = {
      ...INITIAL_STATE,
      username: displayName,
      newPhotoURL: photoURL,
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  passwordErrors = () => {
    const { newPassword, newPasswordConfirm, currentPassword } = this.state;

    const passwordError = [];

    if (newPassword !== newPasswordConfirm) {
      passwordError.push({ code: 'password/passwords-dont-match' });
    }
    if (newPassword === '') {
      passwordError.push({ code: 'password/empty' });
    }
    if (newPasswordConfirm === '') {
      passwordError.push({ code: 'password/empty-confirm' });
    }
    if (currentPassword === '') {
      passwordError.push({ code: 'auth/wrong-password' });
    }

    return passwordError;
  };

  changePassword = e => {
    const { newPassword, currentPassword } = this.state;
    e.preventDefault();

    const passwordErrors = this.passwordErrors();

    if (passwordErrors.length > 0) {
      this.setState({ passwordErrors });
      console.log(passwordErrors);
    } else {
      this.props
        .changePassword(currentPassword, newPassword)
        .then(() => {
          this.setState({
            passwordErrors: null,
            currentPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
          });
          alert('Password changed');
        })
        .catch(error => this.setState({ passwordErrors: [error] }));
    }
  };

  deleteAccount = e => {
    const { deletePassword } = this.state;
    e.preventDefault();

    this.props
      .deleteAccount(deletePassword)
      .then(() => console.log('Done'))
      .catch(error => {
        if (error.code === 'auth/wrong-password') {
          this.setState({
            deletePasswordError: 'The password is invalid',
          });
        } else {
          console.error(error);
        }
      });
  };

  downloadData = () => {
    console.log(this.props.user);
  };

  changeUsername = e => {
    const { username } = this.state;
    e.preventDefault();

    this.props.updateDisplayName(username);
  };

  uploadPhoto = e => {
    const file = e.target.files[0];
    if (file) {
      this.props.uploadPhoto(file);
    }
  };

  changePhoto = e => {
    const { newPhotoURL } = this.state;
    e.preventDefault();
    this.props.updatePhotoURL(newPhotoURL);
  };

  downloadUserData = () => {
    const { user } = this.props;
    var blob = new Blob([JSON.stringify(user, null, 2)], {
      type: 'text/plain;charset=utf-8',
    });
    saveAs(blob, 'data.json');
  };

  render() {
    const {
      currentPassword,
      newPassword,
      newPasswordConfirm,
      passwordErrors,
      deletePassword,
      deletePasswordError,
      newPhotoURL,
    } = this.state;
    const { displayName, photoURL } = this.props.user;
    const userPhoto = photoURL ? (
      <RoundImage alt="profile" src={photoURL} />
    ) : (
      <Icon name="Account" size="80px" />
    );
    return (
      <>
        <HeroBackground textual>
          {userPhoto}
          <H2>{displayName}</H2>
          <BodySmallText>{this.props.user.email}</BodySmallText>
        </HeroBackground>
        <HorizontalRule />
        <Column className="h-center">
          <Caption style={{ marginBottom: '20px' }}>
            <Trans>EDIT YOUR PROFILE</Trans>
          </Caption>
        </Column>
        <HorizontalRule />
        <Column className="h-center" style={{ marginBottom: spacing.XLARGE }}>
          <FormContainer>
            <form onSubmit={this.changePhoto}>
              <Column>
                <H3>Change profile photo URL</H3>
                <TextInput
                  className="text-input"
                  value={newPhotoURL}
                  name="newPhotoURL"
                  onChange={this.onChange}
                  type="text"
                >
                  <label>PROFILE IMAGE</label>
                </TextInput>
                <Button className="submit-button" type="submit">
                  UPDATE PROFILE PHOTO
                </Button>
              </Column>
            </form>
          </FormContainer>
          <FormContainer>
            <form onSubmit={this.changePassword} method="POST">
              <Column>
                <H3>Password</H3>
                <TextInput
                  className="text-input"
                  value={currentPassword}
                  name="currentPassword"
                  onChange={this.onChange}
                  type="password"
                  errorMessage={changePasswordErrors(
                    ['auth/wrong-password'],
                    passwordErrors,
                  )}
                >
                  <label>YOUR CURRENT PASSWORD</label>
                </TextInput>
                <TextInput
                  className="text-input"
                  value={newPassword}
                  name="newPassword"
                  onChange={this.onChange}
                  type="password"
                  errorMessage={changePasswordErrors(
                    ['password/empty'],
                    passwordErrors,
                  )}
                >
                  <label>YOUR NEW PASSWORD</label>
                </TextInput>
                <TextInput
                  className="text-input"
                  value={newPasswordConfirm}
                  name="newPasswordConfirm"
                  onChange={this.onChange}
                  type="password"
                  errorMessage={changePasswordErrors(
                    ['password/passwords-dont-match', 'password/empty-confirm'],
                    passwordErrors,
                  )}
                >
                  <label>CONFIRM YOUR NEW PASSWORD</label>
                </TextInput>
                <Button className="submit-button" type="submit">
                  UPDATE
                </Button>
              </Column>
            </form>
          </FormContainer>
          <FormContainer>
            <form onSubmit={this.deleteAccount}>
              <Column>
                <H3>Delete account</H3>
                <TextInput
                  className="text-input"
                  value={deletePassword}
                  name="deletePassword"
                  onChange={this.onChange}
                  type="password"
                  errorMessage={deletePasswordError}
                >
                  <label>CONFIRM YOUR PASSWORD TO CONFIRM</label>
                </TextInput>
                <Button className="submit-button" type="submit">
                  CONFIRM
                </Button>
              </Column>
            </form>
          </FormContainer>
          <Caption
            style={{ cursor: 'pointer' }}
            onClick={this.downloadUserData}
          >
            <Trans>Download your personal data</Trans>
          </Caption>
        </Column>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: getCurrentUser(state),
});

const mapDispatchToProps = {
  changePassword,
  deleteAccount,
  updateDisplayName,
  uploadPhoto,
  updatePhotoURL,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfilePage);
