import styled from 'styled-components';
import { Column, Caption, H6, colors, spacing, media } from 'playful-ui';

export const EduHeaderContainer = styled(Column)`
  & > div.header {
    height: 132px;
    background-color: rgb(238, 240, 248);
    border-bottom: 1px solid ${colors.applyOpacity(colors.mischka, 0.5)};
  };
  & > div.header ${H6} {
    margin-bottom: 10px;
  };
  & div.media-widths {
    max-width: 1146px;
    flex-grow: 1;
    ${media.tabletLandscape`
      max-width: 974px;
    `}
    ${media.tablet`
      max-width: 708px;
    `}
    ${media.phone`
    max-width: 335px;
    `}
  };
  & > div.navigation {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${colors.applyOpacity(colors.mischka, 0.5)};
    margin-bottom: ${spacing.LARGE};
  };
  & > div.navigation svg {
    margin-right: ${spacing.TINY};
  };
  & > div.navigation a {
    display: flex;
    flex-direction: row;
    align-items: center;
  };
  & > div.navigation ${Caption} {
    color: ${colors.midnight};
  };
  & .hide-phone-tablet {
    ${media.phone`
      display: none;
    `}
    ${media.tablet`
      display: none;
    `}
  };
  & .show-phone-tablet {
    display: none;
    cursor: pointer;
    ${media.phone`
      display: flex;
    `}
    ${media.tablet`
      display: flex;
    `}
  };
  & .media-widths > .top-nav {
    display: none;
    position: absolute;
    z-index: 1;
    right: 0;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
    background-color: rgba(244, 244, 247, 0.98);
    margin: 44px 0 0 0;
    padding: 16px 0 16px 37px;
    height: min-content;
    width: 375px;
    box-sizing: border-box;
  };
  & .media-widths > .show-top-nav {
    ${media.phone`
      display: block;
    `}
    ${media.tablet`
      display: block;
    `}
  };
`;
