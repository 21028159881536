import React from 'react';
import styled from 'styled-components';
import { spacing, colors } from 'theme';

import { SectionName } from './SectionName';
import { ExpandableDiv } from '../ExpandableDiv';
import { Column } from '../Column';
import { Row } from '../Row';
import { LessonName } from './LessonName'

const LessonsContainer = styled(Column)`
  position: relative;
  margin-left: 14px;
  padding-top: ${spacing.SMALL};

  & > ${Row} {
    margin-bottom: ${spacing.SMALL};
  };
  & > ${Row}:last-child {
    margin-bottom: 0;
  };

  & ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 2px;
    background-color: ${colors.applyOpacity(colors.mischka, 0.2)};
    margin-left: 6px;
  }
`;

const headingContent = (active, sectionIndex, open, sectionName) => () => (
  <SectionName active={active} index={sectionIndex} open={open}>
    {sectionName}
  </SectionName>
)

export class ExpandableEduTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false || props.active
    }
  }

  handleOpenCloseClick = () => (
    this.setState({ open: !this.state.open })
  );

  componentDidUpdate(prevProps) {
    if (this.state.open && prevProps.active && !this.props.active) {
      this.setState({ open: false });
    }
    if (!this.state.open && !prevProps.active && this.props.active) {
      this.setState({ open: true });
    }
  }

  render() {
    const { open } = this.state;
    const { sectionName, sectionIndex, children, active } = this.props;
    return (
      <ExpandableDiv
        HeadingContent = {headingContent(active, sectionIndex, open, sectionName)}
        open={open}
        onOpenCloseClick={this.handleOpenCloseClick}
      >
        <LessonsContainer>
          {children}
        </LessonsContainer>
      </ExpandableDiv>
    );
  }
}

export { LessonName };
