import React from 'react';

import { Column } from '../Column';
import { Row } from '../Row';
import { BodyMediumText, H4, Tag } from '../Typography';

import { Container } from './Container';

export const ProductCard = ({ productName, tag, productImage, className, price }) => (
  <Container className={className} tag={tag}>
    <div className="product-card-container">
      <img alt="product" src={productImage} />
    </div>
    <Row className="space-between">
      <Column>
        {tag && <Tag>{tag}</Tag>}
        <H4>{productName}</H4>
      </Column>
      <BodyMediumText>{price}</BodyMediumText>
    </Row>
  </Container>
);
