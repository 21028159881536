import React from 'react';

import { Row } from '../Row';

import { FooterNewsletter } from './FooterNewsletter';
import { FooterSection } from './FooterSection';
import { FooterContainer } from './FooterContainer';
import { FooterSocialLinksAndSelectors } from './FooterSocialLinksAndSelectors';
import { FooterFinePrint } from './FooterFinePrint';
import { FooterSectionsContainer } from './FooterSectionsContainer';
import { FooterSectionsWrapper } from './FooterSectionsWrapper';

export const Footer = ({
  footerSections,
  newsletter,
  copyrightText,
  socialLinks,
  Eu,
  languages,
  currencies,
  onLanguageChanged,
  onCurrencyChanged,
  selectedLanguage,
  selectedCurrency,
}) => (
  <Row className="h-center">
    <FooterContainer>
      <FooterSectionsContainer>
        <FooterSectionsWrapper>
          {footerSections.map((footerSection, index) => (
            <FooterSection key={`${index}footerSection`} footerSection={footerSection} />
          ))}
        </FooterSectionsWrapper>
        <FooterNewsletter
          title={newsletter.title}
          text={newsletter.text}
          placeholderText={newsletter.placeholderText}
          onEmailButtonClicked={newsletter.onEmailButtonClicked}
          subscribeSuccessMessage={newsletter.successMessage}
        />
      </FooterSectionsContainer>
      <FooterSocialLinksAndSelectors
        socialLinks={socialLinks}
        languages={languages}
        currencies={currencies}
        onLanguageChanged={onLanguageChanged}
        onCurrencyChanged={onCurrencyChanged}
        selectedLanguage={selectedLanguage}
        selectedCurrency={selectedCurrency}
      />
      <FooterFinePrint Eu={Eu} copyright={copyrightText} />
    </FooterContainer>
  </Row>
);

// TODO: Put correct link for more details about EU
