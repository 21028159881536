import React from 'react';
import { Trans } from '@lingui/react';

import videoLeds from '../assets/videos/vid-leds.mp4';
import videoMobileControl from '../assets/videos/vid-mobile-control.mp4';
import videoTouchControl from '../assets/videos/vid-touch-control.mp4';

export const pageName = <Trans>Features</Trans>;

export const features = [
  {
    text: <Trans>Unique movements, controlled by your smartphone.</Trans>,
    media: videoMobileControl,
  },
  {
    text: <Trans>Sensors. When you tickle, your robot shakes, rattles, or rolls.</Trans>,
    media: videoTouchControl,
  },
  {
    text: <Trans>6 programmable RGB LED lights. Add your own 'cool' factor.</Trans>,
    media: videoLeds,
  },
];

export const technicalSpecifications = {
  title: <Trans>Technical specifications</Trans>,
  specifications: [
    {
      left: <Trans>Modern yet open design</Trans>,
      right: [
        <Trans>Plexiglass links and body</Trans>,
        <Trans>Modular PCB</Trans>
      ]
    },
    {
      left: <Trans>Moves like a living being</Trans>,
      right: [<Trans>6 Legs</Trans>, <Trans>Multiple walking styles</Trans>]
    },
    {
      left: <Trans>Battery and power</Trans>,
      right: [<Trans>1h walking time</Trans>, <Trans>USB charging</Trans>]
    },
    {
      left: <Trans>Programing enviroment</Trans>,
      right: [<Trans>App inventor</Trans>, <Trans>Arduino IDE</Trans>]
    },
    {
      left: <Trans>Communication</Trans>,
      right: [<Trans>Bluetooth</Trans>, <Trans>iOS and Android app</Trans>]
    },
    {
      left: <Trans>Educational topics</Trans>,
      right: [
        <Trans>3D modeling</Trans>,
        <Trans>Arduino programing</Trans>,
        <Trans>Mobile app development</Trans>
      ]
    }
  ]
};
