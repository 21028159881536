import React from 'react';

import styled from 'styled-components';

import { Row } from '../Row';
import { FinePrintText } from '../Typography';
import { HorizontalRule } from '../HorizontalRule';
import { spacing, media } from 'theme';

const FinePrintsContainer = styled(Row)`
  & ${FinePrintText}:nth-of-type(1) {
    max-width: 558px;
  }

  ${media.tablet`
    & ${FinePrintText}:nth-of-type(1) {
      max-width: 339px;
    };
  `};

  ${media.phone`
    flex-wrap: wrap;
    & ${FinePrintText}:nth-of-type(2) {
      margin-top: ${spacing.BASE};
    };
  `};
`;

export const FooterFinePrint = ({ copyright, Eu }) => (
  <div>
    <HorizontalRule style={{ marginBottom: spacing.SMALL }} />
    <FinePrintsContainer className="space-between">
      {Eu && <FinePrintText>{Eu.text}</FinePrintText>}
      <FinePrintText> {copyright} </FinePrintText>
    </FinePrintsContainer>
  </div>
);
