import React from 'react';

/**
 * React Context injected to components by using withTranslation HOC.
 */
export const I18nContext = React.createContext({
  language: 'en',
  currency: 'EUR',
  currencySymbol: '€',
  handleLanguageChanged: () => {
    throw new Error('Context missing. handleLanguageChanged implementation not found.');
  },
  handleCurrencyChanged: () => {
    throw new Error('Context missing. handleCurrencyChanged implementation not found.');
  },
});
