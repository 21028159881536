import React from 'react';
import { PlayfulLink } from 'components';
import { Trans } from '@lingui/react';

export const text = {
  pageNotFound: <Trans>Page not found</Trans>,
  brokenOrRemoved: 
    <Trans>The link you clicked may be broken or the page may have been removed.</Trans>,
  goBack: <Trans>Let's go back to <PlayfulLink to='/'>homepage</PlayfulLink></Trans>,
};
