import styled from 'styled-components';

import { Column, Caption, H2, Section, spacing, media } from 'playful-ui';

export const HeroContainer = styled(Column)`
  flex: unset;
  align-items: center;
  & ${Caption} {
    margin-bottom: ${spacing.SMALL};
  }
  & ${H2} {
    margin-bottom: ${spacing.BASE_PLUS};
  }
  & .media {
    width: 754px;
    height: 425px;
    max-height: unset;
    max-width: unset;
    ${media.tablet`
      width: 624px;
      height: 351px;
    `}
    ${media.phone`
      width: 335px;
      height: 189px;
    `}
  }
  & .media video, .media img {
    max-height: unset;
    max-width: unset;
    object-fit: cover;
    flex: unset;
    box-shadow: unset;
  }
  & img.modal-video-play-icon {
    max-height: 48px;
    max-width: 48px;
    border-radius: unset;
    box-shadow: unset;
  }
  & ${Section} ${H2} {
    text-align: center;
  }
`;
