import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, navigate } from 'gatsby';

import { signIn, signInWithFacebook, signInWithGoogle } from 'stemi-firebase';
import { Button, Column, H3, Icon, Label, TextInput } from 'playful-ui';

import { getCurrentUser } from '../redux';
import { ForgotPasswordPage } from './ForgotPassword';
import { registerErrors } from '../services/authErrors';
import { AuthFormContainer } from '../components/AuthFormContainer';
import { Ruler } from '../components/Ruler';

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const successRoute = '/';

class SignInForm extends Component {
  static title = 'Sign In';
  static route = 'sign-in';

  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    const { signIn } = this.props;

    signIn(email, password)
      .then(credentials => {
        credentials.user.getIdToken(true).then(idToken => {
          fetch('/api/discourseSessionCookie', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ idToken }),
          });
        });
        return Promise.resolve(credentials);
      })
      .then(() => {
        navigate(successRoute);
      })
      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();
  };

  handleFacebookSignIn = event => {
    const { signInWithFacebook } = this.props;

    signInWithFacebook()
      .then(() => {
        navigate(successRoute);
      })
      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();
  };

  handleGoogleSignIn = event => {
    const { signInWithGoogle } = this.props;

    signInWithGoogle()
      .then(() => {
        navigate(successRoute);
      })
      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();
  };

  handleEmailChange = event =>
    this.setState(updateByPropertyName('email', event.target.value));

  handlePasswordChange = event =>
    this.setState(updateByPropertyName('password', event.target.value));

  render() {
    const { email, password, error } = this.state;

    const { user } = this.props;

    if (!user.isAnonymous && user.isValid() && !user.isEmpty()) {
      navigate(successRoute);
      return null;
    }

    const isInvalid = password === '' || email === '';

    return (
      <AuthFormContainer>
        <form onSubmit={this.onSubmit}>
          <Column>
            <H3>{SignInForm.title}</H3>
            <TextInput
              className="text-input"
              value={email}
              onChange={this.handleEmailChange}
              type="text"
              placeholder="Email Address"
              errorMessage={registerErrors(
                ['auth/user-not-found', 'auth/invalid-email'],
                error,
              )}
            >
              <label>EMAIL ADDRESS</label>
            </TextInput>
            <TextInput
              value={password}
              onChange={this.handlePasswordChange}
              type="password"
              placeholder="Password"
              errorMessage={registerErrors(['auth/wrong-password'], error)}
            >
              <label>PASSWORD</label>
              <Link to={ForgotPasswordPage.route}>Forgot password?</Link>
            </TextInput>
            <Button
              className="submit-button"
              disabled={isInvalid}
              type="submit"
            >
              {SignInForm.title}
            </Button>
          </Column>
          {/*<Ruler />
          <Column className="mobile-horizontal">
            <H3 className="dummy">DUMMY</H3>
            <Column>
              <Label>SIGN IN WITH FACEBOOK</Label>
              <Button className="facebook" onClick={this.handleFacebookSignIn}>
                <Icon name="Facebook" />
                {'CONNECT'}
              </Button>
            </Column>
            <Column>
              <Label>SIGN IN WITH GOOGLE</Label>
              <Button className="google" onClick={this.handleGoogleSignIn}>
                <Icon name="Google" />
                {'CONNECT'}
              </Button>
              </Column>
          </Column>*/}
        </form>
      </AuthFormContainer>
    );
  }
}

const mapStateToProps = state => ({
  user: getCurrentUser(state),
});

export const SignInPage = connect(
  mapStateToProps,
  {
    signIn,
    signInWithFacebook,
    signInWithGoogle,
  },
)(SignInForm);
