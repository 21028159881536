import React from 'react';
import { Trans } from '@lingui/react';

export const shippingModels = {
  1: {
    HR: {
      price: {
        USD: { amount: 0.0 },
        GBP: { amount: 0.0 },
        EUR: { amount: 0.0 },
        HRK: { amount: 0.0 },
      },
      description: <Trans>Free shipping with DPD Classic - 2 to 5 working days delivery</Trans>,
    },
    EU: {
      price: {
        USD: { amount: 18.0 },
        GBP: { amount: 14.0 },
        EUR: { amount: 16.0 },
        HRK: { amount: 120.0 },
      },
      description: <Trans>DHL Express shipping - 2 to 4 working days delivery</Trans>,
    },
    NA: {
      price: {
        USD: { amount: 23.0 },
        GBP: { amount: 17.5 },
        EUR: { amount: 20.0 },
        HRK: { amount: 150.0 },
      },
      description: <Trans>DHL Express shipping - 2 to 4 working days delivery</Trans>,
    },
    default: {
      price: {
        USD: { amount: 27.0 },
        GBP: { amount: 21.0 },
        EUR: { amount: 24.0 },
        HRK: { amount: 180.0 },
      },
      description: <Trans>DHL Express shipping - 2 to 4 working days delivery</Trans>,
    },
  },
  2: {
    HR: {
      price: {
        USD: { amount: 0.0 },
        GBP: { amount: 0.0 },
        EUR: { amount: 0.0 },
        HRK: { amount: 0.0 },
      },
      description: <Trans>Free shipping with DPD Classic - 2 to 5 working days delivery</Trans>,
    },
    EU: {
      price: {
        USD: { amount: 20.5 },
        GBP: { amount: 16.0 },
        EUR: { amount: 18.0 },
        HRK: { amount: 133.0 },
      },
      description: <Trans>DHL Express shipping - 2 to 4 working days delivery</Trans>,
    },
    NA: {
      price: {
        USD: { amount: 26.0 },
        GBP: { amount: 20.0 },
        EUR: { amount: 23.0 },
        HRK: { amount: 170.0 },
      },
      description: <Trans>DHL Express shipping - 2 to 4 working days delivery</Trans>,
    },
    default: {
      price: {
        USD: { amount: 30.0 },
        GBP: { amount: 24.0 },
        EUR: { amount: 27.0 },
        HRK: { amount: 200.0 },
      },
      description: <Trans>DHL Express shipping - 2 to 4 working days delivery</Trans>,
    },
  },
  3: {
    HR: {
      price: {
        USD: { amount: 0.0 },
        GBP: { amount: 0.0 },
        EUR: { amount: 0.0 },
        HRK: { amount: 0.0 },
      },
      description: <Trans>Free shipping with DPD Classic - 2 to 5 working days delivery</Trans>,
    },
    EU: {
      price: {
        USD: { amount: 34.0 },
        GBP: { amount: 26.0 },
        EUR: { amount: 30.0 },
        HRK: { amount: 222.0 },
      },
      description: <Trans>DHL Express shipping - 2 to 4 working days delivery</Trans>,
    },
    NA: {
      price: {
        USD: { amount: 36.5 },
        GBP: { amount: 28.0 },
        EUR: { amount: 32.0 },
        HRK: { amount: 237.0 },
      },
      description: <Trans>DHL Express shipping - 2 to 4 working days delivery</Trans>,
    },
    default: {
      price: {
        USD: { amount: 40.0 },
        GBP: { amount: 30.0 },
        EUR: { amount: 35.0 },
        HRK: { amount: 260.0 },
      },
      description: <Trans>DHL Express shipping - 2 to 4 working days delivery</Trans>,
    },
  },
  default: {
    HR: {
      price: {
        USD: { amount: 0.0 },
        GBP: { amount: 0.0 },
        EUR: { amount: 0.0 },
        HRK: { amount: 0.0 },
      },
      description: <Trans>Free Shipping with DPD Classic - 2 to 5 working days delivery</Trans>,
    },
    EU: {
      price: {
        USD: { amount: 45.5 },
        GBP: { amount: 35.5 },
        EUR: { amount: 40.0 },
        HRK: { amount: 297.0 },
      },
      description: <Trans>DHL Express shipping - 2 to 4 working days delivery</Trans>,
    },
    NA: {
      price: {
        USD: { amount: 57.0 },
        GBP: { amount: 44.5 },
        EUR: { amount: 50.0 },
        HRK: { amount: 372.0 },
      },
      description: <Trans>DHL Express shipping - 2 to 4 working days delivery</Trans>,
    },
    default: {
      price: {
        USD: { amount: 57.0 },
        GBP: { amount: 44.5 },
        EUR: { amount: 50.0 },
        HRK: { amount: 372.0 },
      },
      description: <Trans>DHL Express shipping - 2 to 4 working days delivery</Trans>,
    },
  },
};
