import React from 'react';

import { Product } from '../components/Product';
import { Overview } from '../plexi-skins/Overview';
import { Features } from '../plexi-skins/Features';
import imgPlexiSkins from '../plexi-skins/assets/img-plexi.jpg';
import { content } from '../plexi-skins/content/PlexiSkinsPage';
import { buyingOptions } from '../plexi-skins/content/BuyingOptions';
import { shippingModels } from '../plexi-skins/content/shippingModels';
import { BuyingOptions } from '../plexi-skins/BuyingOptions';

const subpages = [
  Overview,
  Features,
];

const productMedia = [
  <img alt="Hexapod-skins" key={1} src={imgPlexiSkins} />,
];

export class PlexiSkinsPage extends React.Component {
  static route = '/products/hexapod-color-skins';
  static title = 'Hexapod color skins';
  static name = content.productName;
  static price = content.price;
  static buyingOptions = buyingOptions;
  static shippingModels = shippingModels;

  render() {
    return (
      <Product
        {...content}
        subpages={subpages}
        productMedia = {productMedia}
        BuyingOptions={BuyingOptions}
      />
    )
  }
}
