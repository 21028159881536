import styled from 'styled-components';

import { colors } from 'theme';

import CheckboxTick from './assets/ic-checkbox-tick.svg';

export const CheckboxContainer = styled.div`
  width: 18px;
  min-width: 18px;
  height: 18px;
  margin: 3px;
  position: relative;
  display: flex;
`;

export const CheckboxLabel = styled.label`
  cursor: pointer;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 16px;
  height: 16px;
  top: 0px;
  left: 0px;
  border-radius: 2px;
  border: solid 1px ${colors.slategray};
  background-color: ${colors.white};
  transition: all 0.3s ease;

  &::after {
    opacity: 0;
    content: '';
    width: 12px;
    height: 9.3px;
    background: url(${CheckboxTick});
    background-repeat: no-repeat;
  }

  &:hover::after {
    opacity: 0.5;
  }

  &:hover {
    border-color: ${colors.midnight};
  }
`;

export const CheckboxInput = styled.input`
  width: 10px;
  height: 10px;
  visibility: hidden;

  &:checked + ${CheckboxLabel}:after {
    opacity: 1;
  }

  &:checked ~ ${CheckboxLabel} {
    border-color: ${colors.darkblue};
    background-color: ${colors.darkblue};
  }

  &:disabled ~ ${CheckboxLabel} {
    opacity: 0.3;
    border-color: ${colors.slategray};
    background-color: ${colors.white};
  }

  &:disabled ~ ${CheckboxLabel} {
    cursor: auto;
  }
`;
