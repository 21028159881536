export const content = i18n => ({
  returnToShippingMethod: i18n.t`Return to shipping method`,
  completeOrder: i18n.t`Complete order`,
  paymentMethod: i18n.t`Payment method`,
  contact: i18n.t`Contact`,
  shipTo: i18n.t`Ship to`,
  change: i18n.t`Change`,
  method: i18n.t`Method`,
  allTransactions: i18n.t`All transactions are secure and encrypted`,
  billing: i18n.t`Billing address`,
  differentBilling: i18n.t`Use a different billing address`,
  SameAsShipping: i18n.t`Same as shipping address`,
  firstName: i18n.t`First name`,
  lastName: i18n.t`Last name`,
  companyName: i18n.t`Company (optional)`,
  address: i18n.t`Address`,
  apartment: i18n.t`Apartment, suite, etc. (optional)`,
  city: i18n.t`City`,
  country: i18n.t`Country`,
  postalCode: i18n.t`Postal code`,
  phone: i18n.t`Phone`,
  errorMessage: i18n.t`No payment method detected, please select one and make sure there are no errors`,
});
