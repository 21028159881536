import styled from 'styled-components';

import { media, zIndex } from 'theme';

export const StickyBanner = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 80px;
  z-index: ${zIndex.STICKY_BANNER};
  &.bottom {
    bottom: 0px;
  }
  &.top {
    top: 0px;
    bottom: unset;
  }
  ${media.tablet`
    height: 104px;
  `} ${media.phone`
    height: 143px;
  `};
`;
