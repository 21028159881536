import React from 'react';
import { H2, Section, SectionHeader, BodyLargeText } from 'playful-ui';

import { overview } from './content/Overview';
import { OverviewContainer } from '../components/OverviewContainer';

export class Overview extends React.Component {
  static pageName = overview.pageName;

  render() {
    return (
      <OverviewContainer>
        <Section className="textual">
          <SectionHeader index={2}/>
          <H2>{overview.title}</H2>
          <BodyLargeText>{overview.text}</BodyLargeText>
        </Section>
        <Section className="large textual">
          <SectionHeader index={1}/>
          <H2>{overview.title2}</H2>
          <BodyLargeText>{overview.text2}</BodyLargeText>
        </Section>
      </OverviewContainer>
    )
  }
}
