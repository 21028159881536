import React from 'react';

import { H4, BodyXSmallText, Label, Caption } from '../Typography';
import { Column } from '../Column';

import { Container } from './Container';
import { ProgressBar } from './ProgressBar';

export const QuestCard = ({ className, numberOfLessons, questImage, title, percent, prerequisites }) => (
  <Container className={className}>
    <Column className="image-column space-between">
      <H4>{title}</H4>
      <Caption>{numberOfLessons} Lessons</Caption>
      <BodyXSmallText>{percent ? `${percent}% Complete` : 'Start Quest'}</BodyXSmallText>
      <ProgressBar percent={percent} />
      {questImage}
    </Column>
    <Column className="info-column space-between">
      <H4>{title}</H4>
      <Column>
        <Label>Pre-requisites</Label>
        <BodyXSmallText>{prerequisites.map(item => item.name).join(', ')}</BodyXSmallText>
      </Column>
    </Column>
  </Container>
);
