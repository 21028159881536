import React from 'react';

import { Parallax } from '../Parallax';
import { Row } from '../Row';
import { Column } from '../Column';

import { Image } from './Image';
import { CardContainer } from './CardContainer';
import { ImageSlider } from './ImageSlider';

export const Card = ({ children, images, className }) => (
  <Row className="top h-center tablet-vertical">
    <ImageSlider>
      {images.map((image, i) => (
        <div key={i}>
          <Image src={image} />
        </div>
      ))}
    </ImageSlider>
    <Parallax x={['-24px', '24px']}>
      <CardContainer className={className}>
        <Column className="v-start h-start">{children}</Column>
      </CardContainer>
    </Parallax>
  </Row>
);
