import React from 'react';
import { resolveRemoteResource } from './index';

/**
 * Overrides an `<img>` tag for images possibly located on Firebase Storage.
 * If `resolvedURLs` props is provided, component tries to resolve the URL.
 * 
 * @class
 */
export class Img extends React.Component {
  render() {
    const { alt, resolvedURLs, src, ...imgProps } = this.props;
    return <img alt={alt} {...imgProps} src={resolveRemoteResource(resolvedURLs, src)} />
  }
}
