import React from 'react';
import styled from 'styled-components';
import { colors, spacing } from 'theme';
import { motion } from 'framer-motion';

import { Row } from '../Row';
import { H5, H4 } from '../Typography';
import { Icon } from '../Icon';

const variants = {
  inactive: {
    border: `1px solid ${colors.applyOpacity(colors.mischka, 0.8)}`,
  },
  active: {
    border: `1px solid ${colors.midnight}`,
  },
};

const SectionNameRow = styled(Row)`
  position: relative;

  & > div.circle {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: transparent;
  }

  & > ${H4} {
    width: 42px;
    text-align: center;
    position: absolute;
    left: 0px;
    font-weight: 300;
    color: ${({ active }) => (active ? colors.midnight : colors.mischka)};
  }

  & > ${H5} {
    margin-left: ${spacing.SMALL};
    margin-right: ${spacing.TINY};
    max-width: 220px;
    min-width: 220px;
    color: ${colors.midnight};
  }
`;

export const SectionName = ({ index, children, active, open }) => (
  <SectionNameRow className="v-center" active={active}>
    <motion.div
      className="circle"
      initial={active ? 'active' : 'inactive'}
      animate={active ? 'active' : 'inactive'}
      variants={variants}
    />
    <H4>{index}</H4>
    <H5>{children}</H5>
    <Icon name={open ? 'ExpandLess' : 'ExpandMore'} />
  </SectionNameRow>
);
