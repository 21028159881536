import styled, { css } from 'styled-components';
import { fonts, colors, spacing } from 'theme';

const focusColor = colors.applyOpacity(colors.persianblue, 0.5);

function borderCSS(props) {
  if (props.errorMessage) {
    return `
      margin: 0px;
      border: solid 2px ${colors.crimson};
    `;
  }
  return `
    margin: 1px;
    border: solid 1px ${colors.mischka};
  `;
}

export const TextInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 335px;
  & input[type=text], & input[type=password], & input[type=email] {
    ${css`
      ${fonts.textInput};
    `}
    color: ${colors.midnight};
    min-height: 28px;
    padding-left: ${spacing.SMALL};
    padding-top: ${spacing.TINY};
    padding-bottom: ${spacing.TINY};
    margin: 1px;
    border-radius: 4px;
    background-color: ${colors.applyOpacity('#ffffff', 0.8)};
    ${props => borderCSS(props)}
  }
  & input[type=text]:focus, & input[type=password]:focus, & input[type=email]:focus {
    outline-width: 0;
    margin: 0px;
    border: solid 2px ${props => (props.errorMessage ? colors.crimson : focusColor)};
  }
  & input[type=text]:disabled, & input[type=password]:disabled, & input[type=email]:disabled {
    background-color: ${colors.applyOpacity(colors.whitesmoke, 0.4)};
    color: ${colors.applyOpacity(colors.midnight, 0.4)};
  }
  & svg {
    right: 12px;
    top: 12px;
    position: absolute;
    opacity: 0.3;
  }
  label + svg, label ~ svg {
    top: 43px;
  }
  & label, & a {
    margin-top: ${spacing.TINY};
    margin-bottom: ${spacing.TINY};
    opacity: 0.8;
    ${css`
      ${fonts.label};
    `}
    color: ${colors.slategray};
  }
  & a {
    position: absolute;
    right: 0;
    text-decoration: none;
  }
  & p {
    width: fit-content;
    ${fonts.bodyXSmall}
    margin-top: 4px;
    margin-bottom: 4px;
    color: ${colors.crimson};
  }
`;
