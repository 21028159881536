import styled from 'styled-components';

import { spacing, media } from 'theme';

import { H2 } from '../Typography';
import { Column } from '../Column';
import { Button } from '../Button';
import { RoundImage } from '../RoundImage';

export const HeroContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 152px;
  &.noNavigation {
    padding-top: 0px;
  }
  & h1 + p {
    margin-top: ${spacing.BASE};
  }
  & img.hero-image,
  & video.hero-video,
  & .media {
    display: flex;
    max-width: 558px;
    max-height: 314px;
    flex: 1;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
  }
  & img + ${Column}, & video + ${Column}, & .media + ${Column} {
    display: flex;
    box-sizing: border-box;
    max-width: 558px;
    flex: 1;
    margin-left: 30px;
  }
  & ${Button} {
    margin-top: ${spacing.BASE_PLUS};
  }
  ${media.tablet`
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 160px;
    padding-bottom: 32px;
    & img + ${Column},
    & video + ${Column},
    & .media + ${Column} {
      max-width: 624px;
      max-height: 351px;
      margin-top: ${spacing.BASE_PLUS};
      text-align: center;
      padding: 0 8px;
      margin-left: 0;
    }
    & ${Button} {
      margin: ${spacing.BASE} auto auto auto;
    }
  `} ${media.phone`
    justify-content: center;
    padding-top: ${spacing.XLARGE_PLUS};
    padding-bottom: 24px;
    & img,
    & video,
    & .media {
      max-width: 335px;
      max-height: 188px;
    }
    & img + ${Column},
    & video + ${Column},
    & .media + ${Column} {
      margin-top: ${spacing.BASE_PLUS};
      max-width: 335px;
      text-align: center;
      margin-left: 0;
    }
    & h1 + p {
      margin-top: ${spacing.SMALL};
    }
    &.textual > ${RoundImage} {
      margin-bottom: ${spacing.SMALL};
    }
    &.textual > ${H2} {
      margin-bottom: ${spacing.TINY};
    }
  `};
`;
