import React from 'react';
import { Trans } from '@lingui/react';

export const overview = {
  pageName: <Trans>Overview</Trans>,
  title: <Trans>Give movement to your inventions</Trans>,
  text: <Trans>Do you want to upgrade your Hexapod with additional features that require multiple servos? Or you are just working on your DIY project that requires multiple servo motors? In all these cases STEMI Servo Motor - Ten Pack is a great choice for you.</Trans>,
  title2: <Trans>We've got you covered</Trans>,
  text2: <Trans>Ten reliable and well built Servo Motors packed into one package. Each servo comes with a servo horn / arm that ensures a tight fit and good hold on any surface. Also, all the nuts and bolts are included so you won’t have to search for them in the local store. Start creating right away with STEMI Servo motors - Ten Pack!</Trans>,
};
