import React from 'react';
import Link from 'gatsby-link';
import { Icon, Row, ButtonWithoutStyle, BodySmallText, BodyMediumText } from 'playful-ui';

import { CheckoutHeaderContainer } from './CheckoutHeaderContainer';
import Logo from '../../navigation/assets/img-stemi-logo.svg';

export const CheckoutHeader = ({buttonText, buttonIconName, onButtonClick, totalAmountString}) => (
  <CheckoutHeaderContainer>
    <div className="home">
      <Link to="/">
        <img src={Logo} alt="logo" />
      </Link>
    </div>
    <ButtonWithoutStyle onClick={onButtonClick}>
      <Row className="space-between v-center">
        <Row className="v-center">
          <Icon name="ShoppingCart" />
          <BodySmallText>{buttonText}</BodySmallText>
          <Icon name={buttonIconName} />
        </Row>
        <BodyMediumText className="bold">{totalAmountString}</BodyMediumText>
      </Row>
    </ButtonWithoutStyle>
  </CheckoutHeaderContainer>
)
