import React from 'react';
import { connect } from 'react-redux';

import { Column, H2, HeroBackground, PulsatingLogo } from 'playful-ui';

import { onceAdventures, getAllAdventures } from '../redux';
import { Adventure } from '../components/Adventure';

export class Component extends React.Component {
  componentDidMount() {
    this.props.onceAdventures();
  }

  renderAdventure(adventureId) {
    const { adventures } = this.props;
    if (adventureId in adventures) {
      return <Adventure adventure={adventures[adventureId]} />;
    }
    return <H2>{`Adventure ${adventureId} non existent.`}</H2>;
  }

  render() {
    const { adventures, adventureId } = this.props;
    if (adventures.isValid()) {
      return this.renderAdventure(adventureId);
    }
    return (
      <Column style={{ marginBottom: '300px' }}>
        <HeroBackground>
          <PulsatingLogo />
        </HeroBackground>
      </Column>
    );
  }
}

const mapStateToProps = state => ({
  adventures: getAllAdventures(state),
});

const mapDispatchToProps = {
  onceAdventures,
};

export const AdventurePage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
