import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStemiI18n } from 'stemi-i18n';

import { AppLinkButton } from 'components';
import { H2, BodyLargeText, BodyXSmallText, Icon } from 'playful-ui';

import { content } from './content/OrderCompleted';
import { CheckoutContainer } from '../components/CheckoutContainer';
import { OrderCompletedContainer } from '../components/OrderCompletedContainer';

import { updateCheckout, initialState } from '../redux';
import { removeAllOrderItems } from 'modules/cart';

class PageComponent extends React.Component {
  static route = '/checkout/order-complete';
  static title = 'Order completed';

  constructor(props) {
    super(props);
    const { updateCheckout, removeAllOrderItems } = { ...props };
    updateCheckout({ ...initialState });
    removeAllOrderItems();
  }

  render() {
    return (
      <CheckoutContainer>
        <OrderCompletedContainer className="h-center v-center">
          <Icon name="Check" size="100px" />
          <H2>{content.orderCompleted}</H2>
          <BodyLargeText>{content.thankYou}</BodyLargeText>
          <BodyXSmallText>{content.emailText}</BodyXSmallText>
          <AppLinkButton className="extra-large" to='/'>
            {content.buttonText}
          </AppLinkButton>
        </OrderCompletedContainer>
      </CheckoutContainer>
    );
  }
}

export const OrderCompletePage = compose(
  withStemiI18n,
  connect(
    undefined,
    {
      removeAllOrderItems: () => removeAllOrderItems(),
      updateCheckout,
    }
  ),
)(PageComponent);
