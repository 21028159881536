import styled from 'styled-components';

import { spacing, media } from 'theme';

import { Row } from '../Row';
import { BodySmallText } from '../Typography';

const ProductHighlightItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 224px;
  flex: 1;
  & svg,
  & img {
    width: 130px;
    height: 130px;
    margin-bottom: ${spacing.TINY};
  }
  ${media.tablet`
    margin-top: ${spacing.BASE};
  `};
  ${media.phone`
    margin-top: ${spacing.BASE};
  `};
  & ${BodySmallText} {
    text-align: center;
    max-width: 224px;
    ${media.tablet`
      min-width: 224px;
    `};
    ${media.phone`
      min-width: 224px;
    `};
  }
`;

export const ProductHighlight = styled(Row)`
  justify-content: center;
  ${media.tablet`
    flex-wrap: wrap;
  `};
  & ${ProductHighlightItem} {
    margin-right: 40px;
    margin-left: 40px;
  }
`;

ProductHighlight.Item = ProductHighlightItem;
