import styled from 'styled-components';
import { H4, Column, Row, Caption, BodyMediumText, colors, spacing, media } from 'playful-ui';

export const BuyingOptionsContainer = styled(Column)`
  background-color: ${colors.white};
  border-top: 96px solid #eff1f9;
  margin-bottom: 200px;
  ${media.phone`
  border-top: 72px solid #eff1f9;
  margin-bottom: 100px;
`}

  & div.subpage {
    height: ${spacing.XLARGE};
  }
  & div.buy {
    min-height: 178px;
    max-height: 178px;
    background-color: ${colors.applyOpacity(colors.whitesmoke, 0.5)};
    border-radius: 4px;
  }
  & div.buy > ${BodyMediumText} {
    font-weight: 600;
    margin-bottom: ${spacing.SMALL};
    margin-right: ${spacing.BASE};
    align-self: flex-end;
  }
  & div.buy > ${BodyMediumText}:first-child {
    font-weight: 400;
    margin-bottom: 0;
  }
  & div.buy > a {
    justify-content: center;
    width: calc(100% - 2 * ${spacing.BASE});
  }
  & img.product-image {
    display: inline-flex;
    border-radius: 4px;
    max-width: 694px;
    flex-grow: 1;
    align-self: flex-start;
    margin-right: 60px;
    ${media.tabletLandscape`
    width: 450px;
  `};
    ${media.tablet`
    max-width: 624px;
    min-width: 624px;
    margin-right: 0;
    margin-bottom: ${spacing.LARGE};
  `};
    ${media.phone`
    max-width: 335px;
    min-width: 335px;
    margin-right: 0;
    margin-bottom: ${spacing.BASE_PLUS};
  `};
  }

  & ${Caption} {
    color: ${colors.midnight};
  }
  & ${Caption}.product {
    margin-left: ${spacing.TINY};
  }
  & ${Caption}.margin-bottom-top {
    margin-bottom: ${spacing.SMALL};
    margin-top: ${spacing.BASE};
  }
  & ${Caption}.margin-top {
    margin-top: ${spacing.BASE};
  }

  & ${Row}.product {
    cursor: pointer;
  }
  & ${Row}.buying-options {
    margin-top: ${spacing.LARGE};
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    ${media.tabletLandscape`
    max-width: 974px;
  `};
    ${media.tablet`
    max-width: 708px;
  `};
    ${media.phone`
    width: 100%;
    margin-top: ${spacing.BASE};
  `};
  }

  & ${Column}.buying-options-selection {
    max-width: 392px;
    min-width: 392px;
    ${media.tablet`
    min-width: 624px;
    max-width: 624px;
  `};
    ${media.phone`
    min-width: 335px;
    max-width: 335px;
  `};
  }

  & ${H4} {
    margin-bottom: ${spacing.TINY};
  }
`;
