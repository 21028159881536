// FB Pixel tracking reference:
// https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/

import { enableAnalytics } from './config';

function standardEvent(eventName, eventObject) {
  if (enableAnalytics && typeof fbq === 'function') {
    // eslint-disable-next-line no-undef
    fbq('track', eventName, eventObject);
    console.log(`FB Pixel standardEvent - ${eventName}`);
  }
}

function customEvent(eventName, eventObject) {
  if (process.env.NODE_ENV === 'production' && typeof fbq === 'function') {
    // eslint-disable-next-line no-undef
    fbq('trackCustom', eventName, eventObject);
    console.log(`FB Pixel customEvent - ${eventName}`);
  }
}

function addToCart(orderItem) {
  const { productName, options } = orderItem;
  standardEvent('AddToCart', { content_name: productName, content_type: options.displayName });
}

function initiateCheckout(currency, cart, totalAmount) {
  const orderItemIds = Object.keys(cart);
  const content_ids = orderItemIds.map(k => cart[k].productName);
  const content = orderItemIds.map(k => ({
    id: cart[k].productName,
    item_price: cart[k].price[currency],
    quantity: cart[k].quantity,
  }));
  standardEvent('InitiateCheckout', { content_ids, currency, value: totalAmount, content });
}

function addShippingInformation(shippingModel) {
  customEvent('AddShippingInformation', shippingModel);
}

function addPaymentInfo(currency, cart, totalAmount) {
  standardEvent('AddPaymentInfo', { currency, value: totalAmount });
}

function purchase(currency, cart, totalAmount) {
  const orderItemIds = Object.keys(cart);
  const content_ids = orderItemIds.map(k => cart[k].productName);
  const content = orderItemIds.map(k => ({
    id: cart[k].productName,
    item_price: cart[k].price[currency],
    quantity: cart[k].quantity,
  }));
  standardEvent('Purchase', {
    currency,
    value: totalAmount,
    content_ids,
    content,
    num_items: orderItemIds.length,
  });
}

function viewTestimonial(productName) {
  customEvent('ViewTestimonial', productName);
}

function viewFeatures(productName) {
  customEvent('ViewFeatures', productName);
}

function viewFaq(productName) {
  customEvent('ViewFaq', productName);
}

export const facebookPixel = {
  addToCart,
  initiateCheckout,
  addShippingInformation,
  addPaymentInfo,
  purchase,
  viewTestimonial,
  viewFeatures,
  viewFaq,
};
