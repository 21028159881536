import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { withStemiI18n } from 'stemi-i18n';
import { ProductCard, Row } from 'playful-ui';

import { productClick, productList } from 'modules/analytics';
import { HexapodPage } from '../../HexapodPage';
import { ServoMotorPage } from '../../ServoMotorPage';
import { ServoTriplePackPage } from '../../ServoTriplePackPage';
import { ServoTenPackPage } from '../../ServoTenPackPage';
import { PlexiSkinsPage } from '../../PlexiSkinsPage';

import { content as hexapodContent } from 'modules/products/hexapod/content/HexapodPage';
import { content as servoContent } from 'modules/products/servo-motor/content/ServoMotorPage';
import { content as tripleServoContent } from 'modules/products/servo-motor-triple-pack/content/ServoMotorPage';
import { content as tenServoContent } from 'modules/products/servo-motor-ten-pack/content/ServoMotorPage';
import { content as plexiSkinsContent } from 'modules/products/plexi-skins/content/PlexiSkinsPage';

import imgServo from 'modules/products/servo-motor/assets/img-servo-motor.jpg';
import imgServoTriple from 'modules/products/servo-motor-triple-pack/assets/img-servo-triple-pack.jpg';
import imgServoTen from 'modules/products/servo-motor-ten-pack/assets/img-servo-ten-pack.jpg';
import imgHexapod from 'modules/products/hexapod/assets/hexapod-options/img-walter-white.jpg';
import imgPlexiSkins from 'modules/products/plexi-skins/assets/img-plexi.jpg';

class CategoryComponent extends React.Component {
  constructor(props) {
    super(props);
    const { currency, dispatch } = { ...props };
    dispatch(
      productList({
        currency,
        productList: [
          hexapodContent,
          plexiSkinsContent,
          servoContent,
          tripleServoContent,
          tenServoContent,
        ],
        productCategory: 'Category Hexapod',
      }),
    );
  }

  handleOnProductClick = product => () => {
    const { currency, dispatch } = { ...this.props };
    dispatch(
      productClick({ currency, productCategory: 'Category Hexapod', product }),
    );
  };

  render() {
    const { currencySymbol, currency } = this.props;
    return (
      <React.Fragment>
        <Row className="h-center mobile-vertical">
          <Link
            onClick={this.handleOnProductClick(hexapodContent)}
            to={HexapodPage.route}
          >
            <ProductCard
              className="product-card"
              productImage={imgHexapod}
              productName={hexapodContent.productName}
            />
          </Link>
          <Link
            onClick={this.handleOnProductClick(plexiSkinsContent)}
            to={PlexiSkinsPage.route}
          >
            <ProductCard
              className="product-card"
              productImage={imgPlexiSkins}
              productName={plexiSkinsContent.productName}
              price={`${currencySymbol}${plexiSkinsContent.price[
                currency
              ].amount.toFixed(2)}`}
            />
          </Link>
        </Row>
        <Row className="h-center mobile-vertical">
          <Link
            onClick={this.handleOnProductClick(servoContent)}
            to={ServoMotorPage.route}
          >
            <ProductCard
              className="product-card"
              productImage={imgServo}
              productName={servoContent.productName}
              price={`${currencySymbol}${servoContent.price[
                currency
              ].amount.toFixed(2)}`}
            />
          </Link>
          <Link
            onClick={this.handleOnProductClick(tripleServoContent)}
            to={ServoTriplePackPage.route}
          >
            <ProductCard
              className="product-card"
              productImage={imgServoTriple}
              productName={tripleServoContent.productName}
              price={`${currencySymbol}${tripleServoContent.price[
                currency
              ].amount.toFixed(2)}`}
            />
          </Link>
        </Row>
        <Row className="h-center mobile-vertical">
          <Link
            onClick={this.handleOnProductClick(tenServoContent)}
            to={ServoTenPackPage.route}
          >
            <ProductCard
              className="product-card"
              productImage={imgServoTen}
              productName={tenServoContent.productName}
              price={`${currencySymbol}${tenServoContent.price[
                currency
              ].amount.toFixed(2)}`}
            />
          </Link>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = undefined;

const mapDispatchToProps = dispatch => ({ dispatch });

export const Hexapod = compose(
  withStemiI18n,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CategoryComponent);
