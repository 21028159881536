import { auth, once, set, stemiUser } from 'stemi-firebase';

export const GET_AUTH_REQUEST = 'stemi.web.GET_AUTH_REQUEST';
export const GET_AUTH_SUCCESS = 'stemi.web.GET_AUTH_SUCCESS';
export const GET_AUTH_ERROR = 'stemi.web.GET_AUTH_ERROR';

export function onceUser(authUser) {
  return once('users', { id: authUser.uid });
}

export function updateUser(authUser) {
  return set('users', stemiUser(authUser));
}

export const getAuth = () => dispatch => {
  dispatch({ type: GET_AUTH_REQUEST });
  auth.onAuthStateChanged(authUser => {
    if (authUser) {
      dispatch(onceUser(authUser)).then(user => {
        dispatch(updateUser(authUser));
      });
      dispatch({ type: GET_AUTH_SUCCESS, payload: { uid: authUser.uid } });
    } else {
      auth
        .signInAnonymously()
        .catch(error => dispatch({ type: GET_AUTH_ERROR, error }));
    }
  });
};
