import React from 'react';
import { Link } from 'gatsby';
import { H4, Caption, Row, Icon, BodyXSmallText } from 'playful-ui';

import { EduContentContainer } from './EduContentContainer';

export const EduContent = ({ lesson, children, quest }) => {
  const { title, youtubeVideoId, nextLessonId, previousLessonId, id } = lesson;
  const { lessons } = quest;
  return (
    <EduContentContainer>
      <H4>{title}</H4>
      {
        youtubeVideoId &&
        <div className="video-wrapper">
          <iframe
            title={title}
            src={`https://www.youtube.com/embed/${youtubeVideoId}?rel=0&enablejsapi=1`}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      }
      <Caption>Overview</Caption>
      <div className="children">
        {children}
      </div>
      <Row className="space-between v-center buttons">
        {
          previousLessonId &&
          <Link to={`/lesson/${previousLessonId}`}>
            <Row className="v-center button-back">
              <Icon name="ArrowBack" />
              <Caption>PREVIOUS</Caption>
            </Row>
          </Link>
        }
        <BodyXSmallText>
          {lessons.findIndex((x) => x === id) + 1} / {lessons.length}
        </BodyXSmallText>
        {
          nextLessonId &&
          <Link to={`/lesson/${nextLessonId}`}>
            <Row className="v-center button-fw">
              <Caption>NEXT</Caption>
              <Icon name="ArrowBack" />
            </Row>
          </Link>
        }
      </Row>
    </EduContentContainer>
  );
}
