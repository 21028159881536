import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import _ from 'lodash';

import { push } from 'stemi-firebase';
import { reducer as currency } from 'stemi-i18n';

import { reducer as auth, middleware as authMiddleware } from 'modules/auth';
import { reducer as lab } from 'modules/lab';
import { reducer as cart, middleware as cartMiddleWare } from 'modules/cart';
import { reducer as checkout } from 'modules/checkout';
import { analyticsMiddleware } from 'modules/analytics';
import { IS_BROWSER } from 'consts';

const reducer = combineReducers({
  auth,
  lab,
  cart,
  checkout,
  currency,
});

const middlewares = [
  thunk,
  cartMiddleWare,
  analyticsMiddleware,
  authMiddleware,
];

export function addNewsletterSubscriber(email) {
  return push('newsletterSubscribers', { email });
}

function loadDevTools() {
  if (
    process.env.NODE_ENV === 'development' &&
    IS_BROWSER &&
    _.get(window, '__REDUX_DEVTOOLS_EXTENSION__') &&
    typeof _.get(window, '__REDUX_DEVTOOLS_EXTENSION__') === 'function'
  ) {
    return _.get(window, '__REDUX_DEVTOOLS_EXTENSION__')();
  }
  return f => f;
}

export function configureStore(preloadedState) {
  return createStore(
    reducer,
    preloadedState,
    compose(
      applyMiddleware(...middlewares),
      loadDevTools(),
    ),
  );
}

export { rootElementMounted } from './actions';

export { ROOT_ELEMENT_MOUNTED } from './actions';
