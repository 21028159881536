import React from 'react';
import { Trans } from '@lingui/react';

export const content = Object.freeze({
  productName: 'Servo Motor Triple Pack',
  buyButtonText: <Trans>BUY NOW</Trans>,
  tagline: <Trans>Triple is better than double</Trans>,
  price: {
    USD: {
      amount: 15.00,
      previousAmount: null,
    },
    GBP: {
      amount: 11.7,
      previousAmount: null,
    },
    EUR: {
      amount: 13.4,
      previousAmount: null,
    },
    HRK: {
      amount: 99.0,
      previousAmount: null,
    },
  },
});
