import React from 'react';
import { Trans } from '@lingui/react';

export const content = Object.freeze({
  productName: 'Skin',
  buyButtonText: <Trans>BUY NOW</Trans>,
  tagline: <Trans>Colorful is fun</Trans>,
  price: {
    USD: {
      amount: 27.00,
      previousAmount: null,
    },
    GBP: {
      amount: 21.00,
      previousAmount: null,
    },
    EUR: {
      amount: 24.00,
      previousAmount: null,
    },
    HRK: {
      amount: 175.50,
      previousAmount: null,
    },
  },
});
