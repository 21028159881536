import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Button } from 'playful-ui';

export const AppLinkButton = styled(({ to, replace, ref, innerRef, getProps, state, ...props }) => (
  <Link to={to} replace={replace} ref={ref} innerRef={innerRef} getProps={getProps} state={state}>
    <Button {...props}/>
  </Link>
))``;
