import React from 'react';
import styled from 'styled-components';

import { media } from 'playful-ui';

import { withStemiI18n } from 'stemi-i18n';

import imgHexapodDesktop from './assets/img-hexapod-desktop@2x.jpg';
import imgHexapodDesktopHr from './assets/img-hexapod-desktop-hr@2x.jpg';
import imgHexapodMobile from './assets/img-hexapod-mobile@3x.jpg';
import imgFeaturesMobile from './assets/img-hexapod-features-mobile.png';
import imgFeaturesMobileHr from './assets/img-hexapod-features-mobile-hr.png';

const RobotImg = styled.img`
  width: 100%;
  ${media.phone`
    display: none;
  `}
`;

const RobotImgMobile = styled.img`
  width: 100%;
  display: none;
  ${media.phone`
    display: inherit;
  `}
`;

const FeaturesImg = styled.img`
  display: block;
  width: 279px;
  height: 126px;
  object-fit: contain;
  position: relative;
  margin: 48px auto auto;
  display: none;
  ${media.phone`
    display: inherit;
  `}
`;

export const Features = withStemiI18n(({ language }) => (
    language === 'hr' ?
      (
        <React.Fragment>
          <RobotImgMobile src={imgHexapodMobile} />
          <FeaturesImg src={imgFeaturesMobileHr} />
          <RobotImg src={imgHexapodDesktopHr} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <RobotImgMobile src={imgHexapodMobile} />
          <FeaturesImg src={imgFeaturesMobile} />
          <RobotImg src={imgHexapodDesktop} />
        </React.Fragment>
      )
    )
);
