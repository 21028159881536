import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { CookiesProvider } from 'react-cookie';

import { PlayfulProvider } from 'playful-ui';
import { withStemiI18n } from 'stemi-i18n';

import { IS_BROWSER } from 'consts';

import { rootElementMounted } from '../redux';
import { Helmet } from './Helmet';
import { NotificationArea } from './NotificationArea';
import { CookieNotification } from './CookieNotification';
import { EuBanner } from './EuBanner';
// import { Footer } from './Footer';
import { LearnMoreBanner } from './Banners';

const appStyle = {
  height: '100%',
  minWidth: '360px',
  position: 'relative',
};

class App extends React.Component {
  componentDidMount() {
    const { rootElementMounted, currency } = this.props;
    rootElementMounted({ currency });
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { children, hideFooter, hideBanner } = this.props;

    return (
      <PlayfulProvider>
        <LearnMoreBanner />
        <div>
          <Helmet />
          <div style={appStyle} className="app">
            <CookiesProvider>
              <NotificationArea>
                {IS_BROWSER && <CookieNotification />}
              </NotificationArea>
              {children}
              {/* {!hideFooter && <Footer />} */}
              {IS_BROWSER && <EuBanner />}
            </CookiesProvider>
          </div>
        </div>
      </PlayfulProvider>
    );
  }
}

App.propTypes = {
  children: PropTypes.node.isRequired,
  hideFooter: PropTypes.bool,
  hideBanner: PropTypes.bool,
};

export default compose(
  withStemiI18n,
  connect(
    null,
    { rootElementMounted },
  ),
)(App);
