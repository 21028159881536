import React from 'react';
import { Trans } from '@lingui/react';

import { shippingModels } from './shippingModels';

import { ReactComponent as Build } from '../assets/icons/ic-build.svg';
import { ReactComponent as Design } from '../assets/icons/ic-design.svg';
import { ReactComponent as Code } from '../assets/icons/ic-code.svg';
import { ReactComponent as Community } from '../assets/icons/ic-community.svg';

export const content = Object.freeze({
  productName: 'Hexapod',
  buyButtonText: <Trans>BUY NOW</Trans>,
  tagline: <Trans>Make your own walking robot</Trans>,
  productHighlights: [
    {
      icon: Build,
      text: <Trans><b>Build</b> the robot yourself</Trans>
    },
    {
      icon: Design,
      text: <Trans>Make your own <b>Design</b></Trans>
    },
    {
      icon: Code,
      text: <Trans><b>Code</b> robot’s movements</Trans>
    },
    {
      icon: Community,
      text: <Trans>Join our <b>Community</b></Trans>
    },
  ],
  price: {
    USD: {
      amount: 244.0,
      previousAmount: 306.0,
    },
    GBP: {
      amount: 191.0,
      previousAmount: 239.0,
    },
    EUR: {
      amount: 216.0,
      previousAmount: 268.0,
    },
    HRK: {
      amount: 1599.2,
      previousAmount: 1999.0,
    },
  },
  shippingModels,
});
