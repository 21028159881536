import styled from 'styled-components';

import icActionRadioOn from './assets/ic-action-radio-on.svg';
import icActionRadioOff from './assets/ic-action-radio-off.svg';

export const RadioContainer = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
`;

export const RadioLabel = styled.label`
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  background-image: url(${icActionRadioOff});
  transition: background-image 0.3s ease;
`;

export const RadioInput = styled.input`
  width: 20px;
  height: 20px;
  opacity: 0;

  &:checked ~ ${RadioLabel} {
    background-image: url(${icActionRadioOn});
  }

  &:disabled ~ ${RadioLabel} {
    opacity: 0.3;
    cursor: auto;
  }
`;
