import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import {
  Caption,
  Navigation,
  media,
} from 'playful-ui';

const StyledCaption = styled(Caption)`
  ${media.tablet`
    width: min-content;
  `}
`;

export class SubpageItem extends React.Component {
  handleClick = () => {
    const { onClick, index } = this.props;
    onClick(index);
  }

  render() {
    const { index, activeIndex, subpageName } = this.props;
    return (
      <Navigation.Item
        key={index}
        variant="left"
        className={classNames({ active: index == activeIndex }, 'hide-phone')}
      >
        <StyledCaption className="subpage-caption" onClick={this.handleClick}>
          {subpageName}
        </StyledCaption>
      </Navigation.Item>
    );
  }
}

SubpageItem.defaultProps = {
  isNavigationItem: true,
  variant: 'left',
};
