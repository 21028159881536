/**
 * Map for language-currency resolution.
 */
const languageToCurrencyMap = Object.freeze({
  'hr': 'HRK',
  'hr-HR': 'HRK',
  'en': 'USD',
  'en-US': 'USD',
  'en-GB': 'GBP',
  'sq': 'EUR', // Albania
  'hy': 'EUR', // Armenia
  'eu': 'EUR', // Basque
  'bg': 'EUR', // Bulgarian
  'be': 'EUR', // Belorussian
  'bs': 'EUR', // Bosnian
  'br': 'EUR', // Breton
  'ca': 'EUR', // Catalan
  'ce': 'EUR', // Chechen
  'co': 'EUR', // Corsican
  'cs': 'EUR', // Czech
  'da': 'EUR', // Danish
  'de': 'EUR', // German
  'nl': 'EUR', // Dutch
  'nl-be': 'EUR', // Dutch-Belgian
  'en-ie': 'EUR', // English-Ireland
  'et': 'EUR', // Estonian
  'el': 'EUR', // Greek
  'fi': 'EUR', // Finnish
  'fr': 'EUR', // French
  'fr-be': 'EUR', // French-Belgium
  'fr-ca': 'EUR', // French-Canada
  'fr-fr': 'EUR', // French-France
  'fr-mc': 'EUR', // French-Monaco
  'fr-ch': 'EUR', // French-Switzerland
  'de-ch': 'EUR', // German-Switzerland
  'hu': 'EUR', // Hungarian
  'is': 'EUR', // Icelandic
  'ga': 'EUR', // Irish
  'it': 'EUR', // Italian
  'lv': 'EUR', // Latvian
  'lt': 'EUR', // Lithuanian
  'lb': 'EUR', // Luxembourgian
  'mk': 'EUR', // Macedonian
  'no': 'EUR', // Norwegian
  'pl': 'EUR', // Polish
  'pt': 'EUR', // Portuguese
  'ro': 'EUR', // Romanian
  'sr': 'EUR', // Serbian
  'sk': 'EUR', // Slovak
  'sl': 'EUR', // Slovenian
  'es': 'EUR', // Spanish
  'sv': 'EUR', // Swedish
  'tr': 'EUR', // Turkish
  'uk': 'EUR', // Ukrainian
});

/**
 * Symbols of supported currencies.
 */
export const currencyToSymbolMap = Object.freeze({
  'EUR': '€',
  'USD': '$',
  'GBP': '£',
  'HRK': 'kn',
});

/**
 * List of the supported currencies.
 */
export const supportedCurrencies = Object.freeze(Object.keys(currencyToSymbolMap));

/**
 * Tries to resolve the currency symbol from the `currency` argument. Throws an Error if unsupported
 * currency is passed.
 * @param {string} currency 
 */
export function resolveCurrencySymbol(currency) {
  if (currencyToSymbolMap[currency]) {
    return currencyToSymbolMap[currency];
  }
  throw new Error(`stemi-i18n: Unsupported currency ${currency}.`);
}

/**
 * Tries to resolve the first language from `priorityList` that can be found as the key in the
 * object of available translations - `catalogsObject`. Defaults to `en`.
 * @param {*} catalogsObject 
 * @param  {...string} priorityList 
 */
export function resolveLanguage(catalogsObject, ...priorityList) {
  for (let lang of priorityList) {
    if (lang) {
      for (let catalogLang of Object.keys(catalogsObject)) {
        if (lang.includes(catalogLang)) {
          return { resolvedLang: catalogLang, browserLang: lang };
        }
      }
    }
  }
  return 'en';
}

/**
 * Tries to resolve the currency according to the `browserLang`. Defaults to `USD`.
 * @param {*} browserLang 
 */
export function resolveCurrency(browserLang) {
  if (languageToCurrencyMap[browserLang]) {
    return languageToCurrencyMap[browserLang];
  }
  return 'USD';
}

/**
 * Get a price object according to selected currency.
 * @param {*} priceObject 
 * @param {*} currency 
 */
export function resolvePrice(priceObject, currency) {
  if (priceObject[currency]) {
    return priceObject[currency];
  }
  throw new Error(`stemi-i18n: Undefined currency in price object - ${currency}.`);
}

/**
 * Get a price string containig an appropriate currency symbol.
 * @param {*} priceObject 
 * @param {*} currency 
 * @param {*} options product options, also containing a price object
 */
export function resolvePriceString(priceObject, currency, options = null, quantity = 1) {
  const resolvedPriceObj = resolvePrice(priceObject, currency);
  const resolvedOptionsAmount = options ? resolvePrice(options.price, currency).amount : 0;
  const amount = resolvedPriceObj.amount + resolvedOptionsAmount;
  return `${resolveCurrencySymbol(currency)}${amount * quantity}`;
}

/**
 * Group OrderItem object from cart into groups by same products. Used to determine biggest shipping
 * expense.
 * @param {OrderItem} orderItems 
 */
export function resolveProductGroups(orderItems) {
  let productGroups = {};
  orderItems.forEach(orderItem => {
    if (typeof productGroups[orderItem.productName] === 'undefined') {
      productGroups[orderItem.productName] = {
        shippingModels: orderItem.shippingModels,
        quantity: orderItem.quantity,
      };
    } else {
      productGroups[orderItem.productName].quantity += orderItem.quantity;
    }
  });
  return productGroups;
}

/**
 * Object for country to shipping zonne lookup.
 */
const shippingZones = {
  EU: [
    // Northern Europe
    'AX', // Åland
    'DK', // Denmark
    'EE', // Estonia
    'FO', // Faroe Islands
    'FI', // Finland
    'GG', // Guernsey
    'IS', // Iceland
    'IE', // Republic of Ireland
    'JE', // Jersey (UK)
    'IM', // Isle of Man
    'LV', // Latvia
    'LT', // Lithuania
    'NO', // Norway
    'SJ', // Svalbard and Jan Mayen
    'SE', // Sweden
    'GB', // United Kingdom

    // Southern Europe
    'AL', // Albania
    'AD', // Andorra
    'BA', // Bosnia and Herzegovina
    //'HR', // Croatia, special zone
    'CY', // Cyprus
    'GI', // Gibraltar
    'GR', // Greece
    'IT', // Italy
    'MK', // Republic of Macedonia
    'VA', // Vatican City
    'MT', // Malta
    'ME', // Montenegro
    'PT', // Portugal
    'SM', // San Marino
    'RS', // Serbia
    'SI', // Slovenia
    'ES', // Spain

    // Eastern Europe
    'BY', // Belarus
    'BG', // Bulgaria
    'CZ', // Czech Republic
    'GE', // Georgia
    'HU', // Hungary
    'MD', // Moldova
    'PL', // Poland
    'RO', // Romania
    'RU', // Russia
    'SK', // Slovakia
    'UA', // Ukraine

    // Western Europe
    'AT', // Austria
    'BE', // Belgium
    'FR', // France
    'DE', // Germany
    'LI', // Liechtenstein
    'LU', // Luxembourg
    'MC', // Monaco
    'NL', // Netherlands
    'CH', // Switzerland
  ],
  NA: [ // North America
    'US', // United States
    'CA', // Canada
  ]
}

/**
 * Resolve shipping zones by country
 * 
 * @param {string} country
 * @returns {string} Shipping Zone
 */
export function resolveShippingZone(country) {
  if (country === 'HR') {
    return country;
  }
  if (shippingZones['EU'].includes(country)) {
    return 'EU';
  }
  if (shippingZones['NA'].includes(country)) {
    return 'NA';
  }
  return 'default';
}

export function resolveShippingModel(shippingZone, productGroups) {
  let models = {};
  Object.keys(productGroups).forEach(k => {
    const { shippingModels, quantity } = productGroups[k];
    if (shippingModels[quantity]) {
      models[k] = shippingModels[quantity][shippingZone];
    } else {
      models[k] = shippingModels.default[shippingZone];
    }
  });
  const max = Object.values(models).reduce(
    (prev, current) => (prev.price.EUR.amount > current.price.EUR.amount) ? prev : current
  );
  return max;
}

const vatZones = {
  EU: [
    'DK', // Denmark
    'EE', // Estonia
    'FI', // Finland
    'IS', // Iceland
    'IE', // Republic of Ireland
    'IM', // Isle of Man
    'LV', // Latvia
    'LT', // Lithuania
    'SE', // Sweden
    'GB', // United Kingdom
    'HR', // Croatia
    'CY', // Cyprus
    'GR', // Greece
    'IT', // Italy
    'MT', // Malta
    'PT', // Portugal
    'SI', // Slovenia
    'ES', // Spain
    'BG', // Bulgaria
    'CZ', // Czech Republic
    'HU', // Hungary
    'PL', // Poland
    'RO', // Romania
    'SK', // Slovakia
    'AT', // Austria
    'BE', // Belgium
    'FR', // France
    'DE', // Germany
    'LU', // Luxembourg
    'MC', // Monaco
    'NL', // Netherlands
  ],
}

/**
 * Resolve VAT by country.
 * 
 * @param {string} country
 * @returns {number} Value Added Tax
 */
export function resolveVAT(country) {
  if (vatZones.EU.includes(country)) {
    return 0.25;
  }
  return 0.0;
}
