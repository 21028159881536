import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { createGlobalStyle } from 'styled-components';
import { FontsGlobalStyle } from 'theme';

import { ScrollOrchestrator } from '../ScrollOrchestrator';

const ROOT_ID = 'playful-app-root';

// eslint-disable-next-line
const PlayfulProviderGlobalStyle = createGlobalStyle`
  html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  body {
    background-image: linear-gradient(to top,
      rgba(247, 107, 28, 0.06) 80px,
      rgba(249, 212, 145, 0.06) 500px,
      rgba(250, 232, 167, 0) 600px);
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  a {
    text-decoration: none;
  }
`;

export class PlayfulProvider extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div id={ROOT_ID}>
        <FontsGlobalStyle />
        <PlayfulProviderGlobalStyle />
        <ScrollOrchestrator>
          <ParallaxProvider>{children}</ParallaxProvider>
        </ScrollOrchestrator>
      </div>
    );
  }
}

PlayfulProvider.rootElement = `#${ROOT_ID}`;
