// HACK to solve Error: The XMLHttpRequest compatibility library was not found.
global.XMLHttpRequest = require('xhr2');

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';

import config from './config';

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();

const facebookProvider = new firebase.auth.FacebookAuthProvider();
facebookProvider.addScope('email');

const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.addScope('profile');
googleProvider.addScope('email');

const emailProvider = firebase.auth.EmailAuthProvider;

export { db, auth, storage, facebookProvider, googleProvider, emailProvider };
