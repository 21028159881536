import styled from 'styled-components';
import { colors, zIndex } from 'playful-ui';

export const CheckoutContainer = styled.div`
  background-color: ${colors.white};
  z-index: ${zIndex.MODAL};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
`;
