const config = {
  develop: {
    apiKey: 'AIzaSyDk9HRzBJExu8tKeSOS9Uun6QgseZF9gTA',
    authDomain: 'stemi-web-develop.firebaseapp.com',
    databaseURL: 'https://stemi-web-develop.firebaseio.com',
    projectId: 'stemi-web-develop',
    storageBucket: 'stemi-web-develop.appspot.com',
    messagingSenderId: '372759863896',
  },
  staging: {
    apiKey: 'AIzaSyD2SH92bCuUtloSwGOcI4H6d6gl8t4gktw',
    authDomain: 'stemi-web-staging.firebaseapp.com',
    databaseURL: 'https://stemi-web-staging.firebaseio.com',
    projectId: 'stemi-web-staging',
    storageBucket: 'stemi-web-staging.appspot.com',
    messagingSenderId: '300723157088',
  },
  production: {
    apiKey: 'AIzaSyAA5nLRwuadUyJqXA7DJdzgOum6qwMv_Q4',
    authDomain: 'stemi-web-production.firebaseapp.com',
    databaseURL: 'https://stemi-web-production.firebaseio.com',
    projectId: 'stemi-web-production',
    storageBucket: 'stemi-web-production.appspot.com',
    messagingSenderId: '817284452107',
  },
};

// Create config fallbacks for all branch types
config.feature = config.develop;
config.hotfix = config.staging;

function getConfigForBranch(branch) {
  const branchType = branch.split('/')[0];

  if (!config[branchType]) {
    throw new Error(
      `[ERROR] There is no Firebase config for branch name: ${branch}.`,
    );
  }

  return config[branchType];
}

const branch =
  process.env.GATSBY_FIREBASE_ENV ||
  process.env.CI_COMMIT_REF_NAME ||
  'develop';

export default getConfigForBranch(branch);
