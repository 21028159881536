import React from 'react';

import { TextInputContainer } from './TextInputContainer';

export const TextInput = props => {
  const { children, errorMessage, style, className, ...inputProps } = props;
  return (
    <TextInputContainer className={className} style={style} errorMessage={errorMessage}>
      {children}
      <input type="text" {...inputProps} />
      {errorMessage && <p>{errorMessage}</p>}
    </TextInputContainer>
  );
};
