import React from 'react';
import { Trans } from '@lingui/react';

export const pageName = <Trans>Features</Trans>;

export const technicalSpecifications = {
  title: <Trans>Technical specifications</Trans>,
  specifications: [
    {
      left: <Trans>Production Material</Trans>,
      right: ['PERSPEX® acrylic']
    },
    {
      left: <Trans>Material thickness</Trans>,
      right: ['3 mm']
    },
    {
      left: <Trans>Cutting method</Trans>,
      right: ['Laser']
    },
    {
      left: <Trans>Available Colors</Trans>,
      right: [
        'Invisible Walker',
        'Banana Split',
        'Dark Night',
        'Misty Mint',
        'Diamond Blue',
        'Cherry Blossom',
      ]
    },
    {
      left: <Trans>Includes</Trans>,
      right: [
        '1 x Hexapod Top plate',
        '1 x Hexapod Bottom plate',
        '6 x Hexapod Bone link',
        '6 x Hexapod Foot link',
      ]
    },
  ]
};
