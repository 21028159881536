import React from 'react';
import { spacing } from 'theme';

import { StepperContainer } from './StepperContainer';
import { ControlButton } from './ControlButton';
import { Icon } from '../Icon';

export class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: typeof this.props.initialValue !== 'undefined' ? this.props.initialValue : 0,
    };
  }

  clampMin = value => {
    if (typeof this.props.min !== 'undefined') {
      return value < this.props.min ? this.props.min : value;
    }
    return value;
  };

  clampMax = value => {
    if (typeof this.props.max !== 'undefined') {
      return value < this.props.max ? value : this.props.max;
    }
    return value;
  };

  onClickDecreaseIncrease = (e, action = 'sub') => {
    const step = typeof this.props.step !== 'undefined' ? this.props.step : 1;
    if (action === 'add') {
      this.setState({ value: this.clampMax(this.state.value + step) });
    } else if (action === 'sub') {
      this.setState({ value: this.clampMin(this.state.value - step) });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value && typeof this.props.onValueChanged !== 'undefined') {
      this.props.onValueChanged(this.state.value);
    }
  }

  render() {
    const { children, errorMessage, style, className } = this.props;
    const { value } = this.state;
    return (
      <StepperContainer className={className} style={style} errorMessage={errorMessage}>
        {children}
        <div className="input">
          <input type="text" disabled value={value} />
          <div className="buttons">
            <ControlButton className="left" onClick={e => this.onClickDecreaseIncrease(e)}>
              <Icon name="BtnMinus" size={spacing.BASE_PLUS} />
            </ControlButton>
            <ControlButton className="right" onClick={e => this.onClickDecreaseIncrease(e, 'add')}>
              <Icon name="BtnPlus" size={spacing.BASE_PLUS} />
            </ControlButton>
          </div>
        </div>
        {errorMessage && <p>{errorMessage}</p>}
      </StepperContainer>
    );
  }
}
