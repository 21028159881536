import React from 'react';
import { PlayfulLink } from 'components';
import { Trans } from '@lingui/react';
import { A, BodyMediumText } from 'playful-ui';

import { TermsAndConditions } from '../TermsAndConditions';
import withdrawalPdf from '../assets/STEMI_Withdrawal_Form_ENG.pdf';

export const consumerInformation = {
  pageTitle: <Trans>Consumer Information</Trans>,
  title: <Trans>Pre-contractual consumer information</Trans>,
  titleParagraph: (
    <Trans>
      STEMI d.o.o. with registered seat in the Republic of Croatia, Rijeka,
      Radmile Matejčić 10, OIB: 91713184849, registered with court registry of
      Commercial Court in Rijeka, under register number (MBS): 040364942, is a
      company engaged in activities from field of science, technology,
      engineering and mathematics and the products of which are mainly intended
      for youth as learning tools for robotics, electronic and programming.
    </Trans>
  ),
  products: <Trans>Products and Services</Trans>,
  productsParagraph: (
    <Trans>
      Internet Trade Service is available exclusively to registered, adult Users
      with the capacity to exercise rights, what they confirm by entering their
      own personal data.<br />
      <br />
      You can learn more on registration process in{' '}
      <PlayfulLink to={TermsAndConditions.route}>General Terms and Conditions</PlayfulLink> of
      Using Pages and the Internet Trade Service.<br />
      <br />
      More detailed description of products and services is available on our web
      pages with the particular product before placing your order.
    </Trans>
  ),
  selection: <Trans>Selection and purchase of products and services</Trans>,
  selectionParagraph: (
    <Trans>
      The ordering of products is conducted by electronic means. By click on the
      icon button “Add to the cart”, the selected product is stored in the
      User's bag. For continuation of the process, it is necessary to select
      “View cart”, where all selected products are found. It is considered the
      product is ordered at the moment the User completes the entire ordering
      process. The User will receive the message by which the Service Provider
      will confirm the order is received on the registered electronic mail
      address.
    </Trans>
  ),
  prices: <Trans>Prices</Trans>,
  pricesParagraph: (
    <Trans>
      Product prices are stipulated in Croatian Kuna (HRK), American Dollar (USD), 
      British Pound and European Euro (EUR) and payable in each of the currencies. 
      Users can view the amount of product price converted to Croatian Kuna (HRK), 
      American Dollar (USD), British Pound and European Euro (EUR) on the page. 
      Payment is conducted in the currency that is selected in the time of the checkout.<br />
      <br />
      Prices of products do not include taxes and delivery cost. The total amount of 
      price the User has to pay for the ordered product (including delivery and taxes) 
      is visible at the end of the process of placing orders.<br />
      <br />
      Product prices are subject to changes until the moment of placing the
      order of which change the Service Provider will inform the User in clear
      and understandable manner.
    </Trans>
  ),
  payment: <Trans>Payment methods</Trans>,
  paymentParagraph: (
    <Trans>
      <BodyMediumText>
        The user can pay ordered products in the following manners, whereat the
        payment method is selected at the moment it will be requested on Pages:<br />
      </BodyMediumText>
      <ul>
        <li>
          <BodyMediumText>
            bank transfer payment by payment on the bank account of the Service
            Provider
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            credit or debit cards of credit cards companies with whom the
            Service Provider collaborates.
          </BodyMediumText>
        </li>
      </ul>
      <br />
      <BodyMediumText>
        You can learn more about payment methods in General Terms and Conditions
        of Payment via the Internet.
      </BodyMediumText>
    </Trans>
  ),
  delivery: <Trans>Delivery</Trans>,
  deliveryParagraph: (
    <Trans>
      Price of delivery is calculated based on a product and it’s visible to the
      Users in the procedure of placing orders.<br />
      <br />
      The term of delivery is 30 days from sending the product. STEMI will send
      an e-mail to the User notifying him/her about sending the product.
    </Trans>
  ),
  complaints: <Trans>Complaints</Trans>,
  complaintsParagraph: (
    <Trans>
      The user is able to submit complaints by the post to the address STEMI
      d.o.o. Radmile Matejcic 10 51000 Rijeka Croatia, or electronic mail
      info@stemi.education to which complaint Service Provider is obliged to
      answer within 15 days as of the day of receiving the complaint.
    </Trans>
  ),
  consumers: <Trans>Consumer’s right of withdrawal from the agreement</Trans>,
  consumersParagraph: (
    <Trans>
      You can find everything on Consumer’s right of withdrawal from the
      agreement in{' '}
      <PlayfulLink to={TermsAndConditions.route}>General Terms and Conditions</PlayfulLink> of
      Using Pages and the Internet Trade Service, title: „Right of Withdrawal
      from the Agreement and Return of the Purchased Product by the
      User-consumer in accordance with the Consumers Protection Act“.<br />
      <br />
      You can find the Form for withdrawal from the agreement{' '}
      <A target="_blank" href={withdrawalPdf}>
        here
      </A>.<br />
      <br />
      Copy of form for the withdrawal from the agreement which can be found on
      our web page can be filled in and sent electronically.<br />
      <br />
      STEMI will deliver a confirmation of receipt of your notice of withdrawal
      from the agreement without delay, via e-mail.
    </Trans>
  ),
  liability: (
    <Trans>Liability for material defects of products and services</Trans>
  ),
  liabilityParagraph: (
    <Trans>
      Service Provider is liable for material defects of products in accordance
      with the Croatian Obligations Act.
    </Trans>
  ),
  alternative: (
    <Trans>Alternative dispute resolution of consumers disputes</Trans>
  ),
  alternativeParagraph: (
    <Trans>
      In case of a dispute between the User-consumer and trader, User-consumer
      is entitled to file a complaint with Court of Honour with the Croatian
      Chamber of Economy.<br />
      <br />
      In addition to above, User-consumer who is a resident of one of the member
      states of European Union, may initiate the procedure using the EU Online
      platform for Internet dispute settlement.<br />
      <br />
      You can access to EU Online platform via{' '}
      <A target="_blank" href="http://ec.europa.eu/odr">
        http://ec.europa.eu/odr
      </A>
      <br />
      <br />
      NOTE: The bodies for the settlement of disputes are not currently
      available on this page for some sectors and the following countries:
      Croatia, Poland, Romania and Spain. As a consumer, you will not be able to
      resolve your dispute with traders in those countries.
    </Trans>
  ),
  governing: <Trans>Governing law and dispute resolution with court</Trans>,
  governingParagraph: (
    <Trans>
      The relation between you and STEMI is governed by positive regulations of
      the Republic of Croatia.<br />
      <br />
      The agreement can be executed in Croatian and English language.<br />
      <br />
      In case of a dispute, the competent court shall be the competent court in
      Zagreb, Republic of Croatia.<br />
      <br />
      You can find more on the manner of purchasing our products and services in
      the{' '}
      <PlayfulLink to={TermsAndConditions.route}>General Terms and Conditions</PlayfulLink> of
      Using Pages and the Internet Trade Service.
    </Trans>
  )
};
