import React from 'react';
import { H3, BodyMediumText } from 'playful-ui';

import { LegalContainer } from '../components/LegalContainer';
import { consumerInformation } from './content/ConsumerInformation';

export class ConsumerInformation extends React.Component {
  static route = '/legal/consumer-information';
  static title = consumerInformation.pageTitle;

  render() {
    return (
      <LegalContainer>
        <H3>{consumerInformation.title}</H3>
        <BodyMediumText>{consumerInformation.titleParagraph}</BodyMediumText>
        <H3>{consumerInformation.products}</H3>
        <BodyMediumText>{consumerInformation.productsParagraph}</BodyMediumText>
        <H3>{consumerInformation.selection}</H3>
        <BodyMediumText>
          {consumerInformation.selectionParagraph}
        </BodyMediumText>
        <H3>{consumerInformation.prices}</H3>
        <BodyMediumText>{consumerInformation.pricesParagraph}</BodyMediumText>
        <H3>{consumerInformation.payment}</H3>
        {consumerInformation.paymentParagraph}
        <H3>{consumerInformation.delivery}</H3>
        <BodyMediumText>{consumerInformation.deliveryParagraph}</BodyMediumText>
        <H3>{consumerInformation.complaints}</H3>
        <BodyMediumText>
          {consumerInformation.complaintsParagraph}
        </BodyMediumText>
        <H3>{consumerInformation.consumers}</H3>
        <BodyMediumText>
          {consumerInformation.consumersParagraph}
        </BodyMediumText>
        <H3>{consumerInformation.liability}</H3>
        <BodyMediumText>
          {consumerInformation.liabilityParagraph}
        </BodyMediumText>
        <H3>{consumerInformation.alternative}</H3>
        <BodyMediumText>
          {consumerInformation.alternativeParagraph}
        </BodyMediumText>
        <H3>{consumerInformation.governing}</H3>
        <BodyMediumText>
          {consumerInformation.governingParagraph}
        </BodyMediumText>
      </LegalContainer>
    );
  }
}
