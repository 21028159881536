export const zIndex = Object.freeze({
  ELEMENTS_CONTAINER: -1,
  CONTENT_BANNER_CONTAINER: 1,
  MODAL_VIDEO_PLAY_ICON: 1,
  SLIDER_CONTAINER: 2,
  NAVIGATION: 1000,
  STICKY_BANNER: 1000,
  NOTIFICATION_AREA: 9999,
  HERO_CONTAINER: 9999,
  MODAL: 10000,
});
