import { createGlobalStyle } from 'styled-components';

// eslint-disable-next-line
export const FontsGlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Nunito:400,400i,600,600i,700,700i,800,800i&subset=latin-ext');
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i&subset=latin-ext');
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: sans-serif;
  }
`;

const PRIMARY_FONT_FAMILY = 'Nunito, sans-serif';
const SECONDARY_FONT_FAMILY = 'Open Sans, sans-serif';

export const fonts = {
  primaryFontFamily: PRIMARY_FONT_FAMILY,
  secondaryFontFamily: SECONDARY_FONT_FAMILY,
  common: `
    &.centered {
      text-align: center;
    }
    &.left {
      text-align: right;
    }
    &.right {
      text-align: right;
    }
    & * {
      margin: 0;
    }
  `,
  button: `
    font-family: ${PRIMARY_FONT_FAMILY};
    font-size: 13px;
    font-weight: 600;
    line-height: 1.5;
  `,
  h1: `
    font-family: ${PRIMARY_FONT_FAMILY};
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -0.5px;
    font-size: 60px;
    line-height: 1.07;
  `,
  h2: `
    font-family: ${PRIMARY_FONT_FAMILY};
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -0.5px;
    font-size: 46px;
    line-height: 1.13;
  `,
  h3: `
    font-family: ${PRIMARY_FONT_FAMILY};
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -0.5px;
    font-size: 36px;
    line-height: 1.17;
  `,
  h4: `
    font-family: ${PRIMARY_FONT_FAMILY};
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-size: 24px;
    line-height: 1.25;
  `,
  h5: `
    font-family: ${PRIMARY_FONT_FAMILY};
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.2px;
    font-size: 15px;
    line-height: 1.47;
  `,
  h6: `
    font-family: ${PRIMARY_FONT_FAMILY};
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1px;
    font-size: 11px;
    line-height: 1.64;
  `,
  body: `
    font-family: ${SECONDARY_FONT_FAMILY};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-size: 20px;
    line-height: 1.5;
  `,
  bodyMedium: `
    font-family: ${SECONDARY_FONT_FAMILY};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-size: 18px;
    line-height: 1.67;
  `,
  bodySmall: `
    font-family: ${SECONDARY_FONT_FAMILY};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.2px;
    font-size: 15px;
    line-height: 1.6;
  `,
  bodyXSmall: `
    font-family: ${SECONDARY_FONT_FAMILY};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.2px;
    font-size: 13px;
    line-height: 1.38;
  `,
  caption: `
    font-family: ${PRIMARY_FONT_FAMILY};
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 1.38;
  `,
  captionSmall: `
    font-family: ${PRIMARY_FONT_FAMILY};
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 1.64;
  `,
  blockquote: `
    font-family: ${PRIMARY_FONT_FAMILY};
    font-size: 46px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26;
    letter-spacing: -0.5px;
    text-align: center;
  `,
  footerSection: `
    font-family: ${PRIMARY_FONT_FAMILY};
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: 0.3px;
  `,
  footerCategory: `
    font-family: ${PRIMARY_FONT_FAMILY};
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: 0.2px;
  `,
  finePrint: `
    font-family: ${PRIMARY_FONT_FAMILY};
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
  `,
  placeholder: `
    font-family: ${PRIMARY_FONT_FAMILY};
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  `,
  label: `
    font-family: ${PRIMARY_FONT_FAMILY};
    font-size: 11px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
  `,
  navigationLink: `
    font-family: ${PRIMARY_FONT_FAMILY};
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.13;
    letter-spacing: normal;
  `,
  textInput: `
    font-family: ${SECONDARY_FONT_FAMILY};
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
  `,
  tag: `
  font-family: ${PRIMARY_FONT_FAMILY};
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
  `,
  tabNavigation: `
     font-family: ${PRIMARY_FONT_FAMILY};
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 1px;
  `,
};
