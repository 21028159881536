import styled from 'styled-components';

import { colors } from 'theme';

import arrowDown from './assets/ic-arrow-down.svg';
import { fonts } from '../../theme/fonts';

export const Select = styled.select`
  cursor: pointer;
  appearance: none;
  padding-right: 16px;
  padding-left: 2px;
  user-select: none;
  border: none;
  background: transparent url(${arrowDown}) no-repeat right center;
  height: 19px;
  ${fonts.navigationLink} line-height: 1.27;
  text-align-last: right;
  color: ${colors.darkblue};
`;
