import styled from 'styled-components';

import { colors, spacing, media } from 'theme';

export const Rule = styled.div`
  height: 0px;
  width: 100%;
  border-top: 1px solid ${colors.applyOpacity(colors.mischka, 0.5)};
  margin-top: ${spacing.LARGE};
  margin-bottom: ${spacing.LARGE};
  ${media.phone`
    margin-top: ${spacing.BASE};
    margin-bottom: ${spacing.BASE};
  `}

  &.vertical {
    height: 100%;
    width: 0px;
    border-left: 1px solid ${colors.applyOpacity(colors.mischka, 0.5)};
    margin-left: ${spacing.LARGE};
    margin-right: ${spacing.LARGE};
    ${media.phone`
      margin-left: ${spacing.BASE};
      margin-right: ${spacing.BASE};
    `}
    margin-top: unset;
    margin-bottom: unset;
  }
`;
