import React from 'react';
import styled from 'styled-components';
import { colors, zIndex } from 'theme';

import { BodyXSmallText } from '../Typography';
import { Row } from '../Row';

const StyledRow = styled(Row)`
  position: relative;

  & > ${BodyXSmallText} {
    margin-left: 30px;
    color: ${colors.applyOpacity(colors.midnight, 0.8)};
    max-width: 252px;
    min-width: 252px;
    color: ${ props => props.active ? colors.darkblue : colors.applyOpacity(colors.midnight, 0.8) };
  }

  & > div.circle {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: ${colors.white};
    border: 1px solid ${colors.applyOpacity(colors.mischka, 0.8)};
    border-color: ${ props => props.active ? colors.darkblue : colors.applyOpacity(colors.mischka, 0.8) };
  }
`;

export const LessonName = ({ className, children, active, onClick }) => {
  return (
    <StyledRow className={`v-center ${className}`} active={active} onClick={onClick}>
      <div className="circle" />
      <BodyXSmallText>{children}</BodyXSmallText>
    </StyledRow>
  );
}
