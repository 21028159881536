import styled from 'styled-components';
import { Column, media } from 'playful-ui';

export const EduContainer = styled(Column)`
  margin-bottom: 58px;

  & > div.edu-content,
  & div > iframe {
    max-width: 1146px;
    margin: auto;
    ${media.tabletLandscape`
      max-width: 974px;
    `}
    ${media.tablet`
      max-width: 708px;
    `}
    ${media.phone`
      max-width: 335px;
    `}
  }; 
& div.edu-content > .side-nav { 
    ${media.tablet`
      display: none;   
    `}
    ${media.phone`
      display: none;   
    `}
  };
`;
