import styled from 'styled-components';
import { Column, BodyMediumText, spacing, media } from 'playful-ui';

export const ShippingMethodContainer = styled(Column)`
  & > ${BodyMediumText} {
    margin: ${spacing.BASE} 0;
  };

  & p.bold {
    font-weight: 600;
  }

  & div.buttons {
    margin-top: ${spacing.BASE};
    margin-bottom: ${spacing.SMALL};
    ${media.phone`
      flex-direction: column-reverse;
      align-items: flex-end;
    `};
  }
  & div.buttons a, div.buttons button  {
    text-align: center;
    margin-right: ${spacing.TINY};
    margin-bottom: ${spacing.TINY};
    ${media.phone`
      margin-right: 0;
      min-height: 48px;
    `};
  };
  & div.buttons a:last-child { 
    margin-right: 0;
  }
`;
