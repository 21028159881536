import { IS_BROWSER } from 'consts';

import { enableAnalytics } from './config';

export function dataLayerPush(object) {
  if (enableAnalytics && IS_BROWSER) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(object);
    console.log(`GTM pushed to dataLayer - ${object.event}`);
  }
}

function productList(currencyCode, products, listName) {
  dataLayerPush({
    event: 'productImpressions',
    ecommerce: {
      currencyCode,
      impressions: products.map(p => ({
        name: p.productName,
        price: p.price[currencyCode].amount,
        list: listName,
      })),
    },
  });
}

function productClick(currencyCode, listName, product) {
  dataLayerPush({
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: { list: listName },
        products: [
          {
            name: product.productName,
            price: product.price[currencyCode].amount,
          },
        ],
      },
    },
  });
}

function productDetails(currencyCode, product) {
  dataLayerPush({
    event: 'productDetailView',
    ecommerce: {
      detail: {
        products: [
          {
            name: product.productName,
            price: product.price[currencyCode].amount,
            variant: product.variant,
          },
        ],
      },
    },
  });
}

function addToCart(currencyCode, orderItem) {
  dataLayerPush({
    event: 'addToCart',
    ecommerce: {
      currencyCode,
      add: {
        products: [
          {
            name: orderItem.productName,
            price: orderItem.price[currencyCode].amount,
            variant: orderItem.options.displayName,
            quantity: 1,
          },
        ],
      },
    },
  });
}

function removeFromCart(currencyCode, orderItem) {
  dataLayerPush({
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: [
          {
            name: orderItem.productName,
            price: orderItem.price[currencyCode].amount,
            variant: orderItem.options.displayName,
            quantity: 1,
          },
        ],
      },
    },
  });
}

function firstCheckoutStep(currencyCode, cart) {
  const products = Object.keys(cart).map(k => {
    const orderItem = cart[k];
    const { productName, price, options, quantity } = orderItem;
    return {
      name: productName,
      price: price[currencyCode].amount,
      quantity,
      variant: options.displayName,
    };
  });
  dataLayerPush({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 1, option: 'checkout' },
        products,
      },
    },
  });
}

function secondCheckoutStep() {
  dataLayerPush({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 2, option: 'checkout' },
      },
    },
  });
}

function thirdCheckoutStep() {
  dataLayerPush({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 3, option: 'checkout' },
      },
    },
  });
}

function purchaseSuccess(currencyCode, cart, { id, totalAmount, vatAmount, shippingAmount }) {
  const products = Object.keys(cart).map(k => {
    const orderItem = cart[k];
    const { productName, price, options, quantity } = orderItem;
    return {
      name: productName,
      price: price[currencyCode].amount,
      quantity,
      variant: options.displayName,
    };
  });
  dataLayerPush({
    event: 'purchaseSuccess',
    ecommerce: {
      currencyCode,
      purchase: {
        actionField: {
          id,
          revenue: totalAmount,
          tax: vatAmount,
          shipping: shippingAmount,
        },
        products,
      },
    },
  });
}

export const googleTagManager = Object.freeze({
  productList,
  productClick,
  productDetails,
  addToCart,
  removeFromCart,
  firstCheckoutStep,
  secondCheckoutStep,
  thirdCheckoutStep,
  purchaseSuccess,
});
