import { ProductsPage } from './pages/ProductsPage';
import { HexapodPage } from './pages/HexapodPage';
import { ServoMotorPage } from './pages/ServoMotorPage';
import { ServoTriplePackPage } from './pages/ServoTriplePackPage';
import { ServoTenPackPage } from './pages/ServoTenPackPage';
import { PlexiSkinsPage } from './pages/PlexiSkinsPage';

export const products = Object.freeze({
  [ProductsPage.name]: ProductsPage,
  [HexapodPage.name]: HexapodPage,
  [ServoMotorPage.name]: ServoMotorPage,
  [ServoTriplePackPage.name]: ServoTriplePackPage,
  [ServoTenPackPage.name]: ServoTenPackPage,
  [PlexiSkinsPage.name]: PlexiSkinsPage,
});

export {
  ProductsPage,
  HexapodPage,
  ServoMotorPage,
  ServoTriplePackPage,
  ServoTenPackPage,
  PlexiSkinsPage,
};
