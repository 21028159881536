import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, navigate } from 'gatsby';

import {
  Button,
  Column,
  Checkbox,
  H3,
  Icon,
  Label,
  Row,
  TextInput,
} from 'playful-ui';
import { signUp, signUpWithFacebook, signUpWithGoogle } from 'stemi-firebase';
import { TermsAndConditions, PersonalDataProtection } from 'modules/legal';

import { getCurrentUser } from '../redux';
import { Ruler } from '../components/Ruler';
import { AuthFormContainer } from '../components/AuthFormContainer';
import { registerErrors } from '../services/authErrors';

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  username: '',
  email: '',
  password: '',
  showPassword: false,
  receiveUpdates: false,
  error: null,
};

class SignUpForm extends Component {
  static route = 'sign-up';
  static title = 'Sign Up';

  state = { ...INITIAL_STATE };

  handleFacebookSignUp = event =>
    this.handleSignUp(event, this.props.signUpWithFacebook);

  handleGoogleSignUp = event =>
    this.handleSignUp(event, this.props.signUpWithGoogle);

  onSubmit = event => this.handleSignUp(event, this.props.signUp);

  handleSignUp = (event, signUpMethod) => {
    const { username, email, password, receiveUpdates } = this.state;

    signUpMethod(username, email, password, receiveUpdates)
      .then(credentials => {
        credentials.user.getIdToken(true).then(idToken => {
          console.log(idToken);

          fetch('/api/discourseSessionCookie', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ idToken }),
          });
        });
        return Promise.resolve(credentials);
      })
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
        navigate('/');
      })
      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();
  };

  handleChangeEmail = event =>
    this.setState(updateByPropertyName('email', event.target.value));

  handleChangeUsername = event =>
    this.setState(updateByPropertyName('username', event.target.value));

  handleChangePassword = event =>
    this.setState(updateByPropertyName('password', event.target.value));

  handleEyeIconClick = () =>
    this.setState(
      updateByPropertyName('showPassword', !this.state.showPassword),
    );

  handleReceiveUpdatesClick = event =>
    this.setState(updateByPropertyName('receiveUpdates', event.target.checked));

  render() {
    const { username, email, password, error } = this.state;
    const { user } = this.props;

    const successRoute = '/';

    if (!user.isAnonymous && user.isValid() && !user.isEmpty()) {
      navigate(successRoute);
      return null;
    }

    const isInvalid = password === '' || username === '' || email === '';

    return (
      <AuthFormContainer>
        <form onSubmit={this.onSubmit}>
          <Column>
            <H3>Get started for free</H3>
            <TextInput
              className="text-input"
              value={email}
              onChange={this.handleChangeEmail}
              placeholder="Email Address"
              errorMessage={registerErrors(
                ['auth/invalid-email', 'auth/email-already-in-use'],
                error,
              )}
            >
              <label>EMAIL ADDRESS</label>
            </TextInput>
            <TextInput
              className="text-input"
              value={username}
              onChange={this.handleChangeUsername}
              placeholder="Pick a friendly nickname"
            >
              <label>NICKNAME</label>
            </TextInput>
            <TextInput
              className="text-input"
              value={password}
              onChange={this.handleChangePassword}
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder="Password"
              errorMessage={registerErrors(['auth/weak-password'], error)}
            >
              <label>PASSWORD</label>
              <Icon
                onClick={this.handleEyeIconClick}
                name={this.state.showPassword ? 'EyeHide' : 'EyeShow'}
              />
            </TextInput>
            <Row className="v-center">
              <Checkbox
                className="newsletterCheckbox"
                id="newsletter-checkbox"
                onClick={this.handleReceiveUpdatesClick}
              />
              <label htmlFor="newsletter-checkbox">
                I'm interested in updates about STEMI products and services.
              </label>
            </Row>
            <p>
              By clicking "Continue" you agree to our&nbsp;
              <Link to={TermsAndConditions.route}>
                {TermsAndConditions.title}
              </Link>{' '}
              and&nbsp;
              <Link to={PersonalDataProtection.route}>
                {PersonalDataProtection.title}
              </Link>{' '}
              policy.
            </p>
            <Button
              className="submit-button"
              disabled={isInvalid}
              type="submit"
            >
              CONTINUE
            </Button>
          </Column>
          {/*<Ruler />
          <Column className="mobile-horizontal">
            <H3 className="dummy">DUMMY</H3>
            
            <Column>
              <Label>SIGN UP WITH FACEBOOK</Label>
              <Button className="facebook" onClick={this.handleFacebookSignUp}>
                <Icon name="Facebook" />
                {'CONNECT'}
              </Button>
            </Column>
            <Column>
              <Label>SIGN UP WITH GOOGLE</Label>
              <Button className="google" onClick={this.handleGoogleSignUp}>
                <Icon name="Google" />
                {'CONNECT'}
              </Button>
              </Column>
          </Column>*/}
        </form>
      </AuthFormContainer>
    );
  }
}

const mapStateToProps = state => ({
  user: getCurrentUser(state),
});

export const SignUpPage = connect(
  mapStateToProps,
  {
    signUp,
    signUpWithFacebook,
    signUpWithGoogle,
  },
)(SignUpForm);
