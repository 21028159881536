import styled from 'styled-components';
import { 
  BodyMediumText,
  BodySmallText,
  ProductHighlight,
  Column,
  Row,
  colors,
  spacing,
  media,
  zIndex
} from 'playful-ui';

export const HeroContainer = styled(Column)`
  flex: unset;
  width: 754px;
  ${media.tablet`
    width: 624px;
  `}
  ${media.phone`
    width: 335px;
  `}
  & ${BodyMediumText}.reduced-price {
    font-weight: 600;
    line-height: 1.67;
  }
  & ${BodySmallText}.full-price {
    opacity: 0.5;
    text-decoration: line-through;
    line-height: 1.67;
  }
  & ${Row} > ${Row} > ${Column} {
    margin-right: ${spacing.BASE};
  }
  & .image-slider {
    border-radius: 4px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
    margin-top: ${spacing.BASE_PLUS};
    margin-bottom: ${spacing.XLARGE_PLUS};
    ${media.tablet`
      margin-bottom: ${spacing.BASE};
    `}
    ${media.phone`
      margin-bottom: ${spacing.BASE};
    `}
  }
  & .slick-list {
    height: 425px;
    ${media.tablet`
      height: 352px;
    `}
    ${media.phone`
      height: 189px;
    `}
    overflow: hidden;
    border-radius: 4px;
  }
  & .image-slider .slick-slide img,
  & .image-slider .slick-slide video {
    max-height: unset;
    max-width: unset;
    object-fit: cover;
    flex: unset;
    box-shadow: unset;
  }
  & .image-slider .slick-slide > div > img,
  & .image-slider .slick-slide > div > .media {
    height: 425px;
    width: 754px;
    max-height: unset;
    max-width: unset;
    ${media.tablet`
      width: 624px;
      height: 352px;
    `}
    ${media.phone`
      width: 335px;
      height: 189px;
    `}
  }
  & .card-image-slider-slick-dots {
    bottom: -${spacing.BASE_PLUS};
  }
  & ul.card-image-slider-slick-dots > li > button::before,
  & ul.card-image-slider-slick-dots > li.slick-active > button::before {
    font-size: 10px;
    color: ${colors.midnight};
  }
  & #product-title {
    ${media.phone`
      align-items: center;
    `}
  }
  & #buy-now-row {
    ${media.phone`
      position: fixed;
      z-index: ${zIndex.HERO_CONTAINER};
      justify-content: space-between;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-color: ${colors.white};
      box-shadow: 0 -4px 16px 0 rgba(0, 0, 0, 0.15), inset 0 1px 0 0 ${colors.whitesmoke};
      padding: ${spacing.SMALL};
    `}
  }
  & #buy-now-row > ${Column} {
    flex: unset;
  }
  & #buy-now-row > ${Column} > ${BodyMediumText},
  & #buy-now-row > ${Column} > ${BodySmallText} {
    line-height: 1.39;
  };

  & ${ProductHighlight} {
    margin-bottom: 80px;
    ${media.phone`
      margin-bottom: ${spacing.BASE};
    `};
  }
`;
