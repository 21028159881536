import styled from 'styled-components';
import { colors, spacing } from 'theme';

import { Column } from '../Column';

export const Option = styled(Column)`
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  min-width: 120px;
  max-width: 120px;
  min-height: 120px;
  max-height: 120px;

  background-color: ${colors.applyOpacity(colors.whitesmoke, 0.5)};
  border-radius: 4px;

  cursor: pointer;

  &:hover {
    background-color: ${colors.applyOpacity(colors.mischka, 0.5)};
  }

  & svg,
  & png,
  & img {
    margin-bottom: ${spacing.TINY};
    width: ${spacing.BASE_PLUS};
    height: ${spacing.BASE_PLUS};
  }

  &.active {
    background-color: rgba(49, 145, 214, 0.15);
    border: solid 2px ${colors.darkblue};
  }
`;
