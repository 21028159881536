function shouldEnableAnalytics() {
  const NODE_ENV = process.env.NODE_ENV;
  const GATSBY_ANALYTICS = process.env.GATSBY_ANALYTICS;
  if (GATSBY_ANALYTICS === 'enable') {
    return true;
  } else if (GATSBY_ANALYTICS === 'disable') {
    return false;
  }
  if (NODE_ENV === 'production') {
    return true;
  }
  return false;
}

export const enableAnalytics = shouldEnableAnalytics();
