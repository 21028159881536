import styled from 'styled-components';
import { spacing, fonts, colors } from 'theme';
import bullet1Url from './assets/bullet-shape-1.svg';
import bullet2Url from './assets/bullet-shape-2.svg';
import bullet3Url from './assets/bullet-shape-3.svg';
import bullet4Url from './assets/bullet-shape-4.svg';

export const Ul = styled.ul`
  margin: ${spacing.BASE} 0;
  padding: 0 20px;
  ${fonts.body};
  color: ${colors.midnight};
  & li:first-of-type {
    padding-top: 0;
  }
  & li {
    padding: ${spacing.SMALL} 6px 0;
  }
  & li:nth-of-type(n) {
    list-style: square outside url(${bullet1Url});
  }
  & li:nth-of-type(2n) {
    list-style: square outside url(${bullet2Url});
  }
  & li:nth-of-type(3n) {
    list-style: square outside url(${bullet3Url});
  }
  & li:nth-of-type(4n) {
    list-style: square outside url(${bullet4Url});
  }
`;
