import styled from 'styled-components';
import { media } from 'playful-ui';

export const WrapperContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;

  justify-content: center;
  padding: 0 3%;

 ${media.tablet`
    flex-direction: column-reverse;
    padding: 0 1em;
    align-items: center;
    justify-content: flex-end;
  `};
`;
