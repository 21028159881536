import React from 'react';
import { Trans } from '@lingui/react';
import { compose } from 'recompose';
import { withStemiI18n } from 'stemi-i18n';
import {
  Navigation,
  BodyMediumText,
  BodySmallText,
  Column,
  LinkButton,
  spacing,
  withScroll,
} from 'playful-ui';

import { SubpageItem } from './SubpageItem';
import { SubpageMenu } from './SubpageMenu';

export class Nav extends React.Component {
  render() {
    const {
      currency,
      currencySymbol,
      id,
      handleSubpageNavClick,
      handleBuyNowClick,
      activeIndex,
      buyButtonText,
      price,
      hidePrice,
      productName,
      subpages,
    } = this.props;
    return (
      <Navigation className="subpage" id={id}>
        <Navigation.Item key="product" variant="left" className="hide-tablet">
          <BodySmallText className="product-name">{productName}</BodySmallText>
        </Navigation.Item>

        {subpages.map((subPage, i) => (
          <SubpageItem
            subpageName={subPage.pageName}
            onClick={handleSubpageNavClick}
            key={i}
            index={i}
            activeIndex={activeIndex}
          />
        ))}

        <Navigation.Item
          key="subpage-menu"
          variant="right"
          className="hide-tablet hide-tablet-landscape hide-desktop"
          style={{ marginRight: 0 }}
        >
          <SubpageMenu
            activeIndex={activeIndex}
            activeSubpage={subpages[activeIndex].pageName}
            subpages={subpages}
            onClick={handleSubpageNavClick}
          />
        </Navigation.Item>

        {!hidePrice && (
          <Navigation.Item
            key="prices"
            variant="right"
            className="hide-phone"
            style={{ marginRight: spacing.BASE }}
          >
            <Column className="right v-center">
              <BodyMediumText className="reduced-price">
                {`${currencySymbol}${price[currency].amount.toFixed(2)} + `}
                <Trans>Tax</Trans>
              </BodyMediumText>
              {price[currency].previousAmount ? (
                <BodySmallText className="full-price">
                  {`${currencySymbol}${price[currency].previousAmount.toFixed(
                    2,
                  )}`}
                </BodySmallText>
              ) : (
                ''
              )}
            </Column>
          </Navigation.Item>
        )}
        {!hidePrice && (
          <Navigation.Item key="buyLink" variant="right" className="hide-phone">
            <LinkButton onClick={handleBuyNowClick}>{buyButtonText}</LinkButton>
          </Navigation.Item>
        )}
      </Navigation>
    );
  }
}

export const SubpageNav = compose(
  withScroll,
  withStemiI18n,
)(Nav);
