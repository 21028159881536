import React from 'react';

import { ArticleCardContainer } from './ArticleCardContainer';
import { Column } from '../Column';
import { Row } from '../Row';
import { BodySmallText, BodyXSmallText, H4, Label } from '../Typography';

export const ArticleCard = ({ image, date, title, short, author, small }) => (
  <ArticleCardContainer small={small}>
    <Row className="small-article-card content mobile-vertical">
      <div className="article-image">
        <img src={image} />
      </div>
      <Column className="space-between">
        <Column>
          <Label>{date}</Label>
          <H4>{title}</H4>
        </Column>
        <BodyXSmallText>{author}</BodyXSmallText>
      </Column>
    </Row>
    <Column className="article-card space-between content">
      <Column>
        <div className="article-image">
          <img src={image} />
        </div>
        <Label>{date}</Label>
        <H4>{title}</H4>
        <BodySmallText>{short}</BodySmallText>
      </Column>
      <BodyXSmallText>{author}</BodyXSmallText>
    </Column>
  </ArticleCardContainer>
);
