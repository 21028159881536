import { ReactComponent as Facebook } from './assets/ic-facebook.svg';
import { ReactComponent as Google } from './assets/ic-google-plus.svg';
import { ReactComponent as Instagram } from './assets/ic-instagram.svg';
import { ReactComponent as Twitter } from './assets/ic-twitter.svg';
import { ReactComponent as Youtube } from './assets/ic-youtube.svg';
import { ReactComponent as Play } from './assets/ic-play.svg';
import { ReactComponent as ArrowDown } from './assets/ic-arrow-down.svg';
import { ReactComponent as ArrowBack } from './assets/ic-arrow-back.svg';
import { ReactComponent as ChevronRight } from './assets/ic-chevron-right.svg';
import { ReactComponent as Menu } from './assets/ic-menu.svg';
import { ReactComponent as ShoppingCart } from './assets/ic-shopping-cart.svg';
import { ReactComponent as ShoppingCartFull } from './assets/ic-shopping-cart-added.svg';
import { ReactComponent as EmptyCart } from './assets/ic-empty-cart.svg';
import { ReactComponent as EyeHide } from './assets/ic-eye-hide.svg';
import { ReactComponent as EyeShow } from './assets/ic-eye-show.svg';
import { ReactComponent as Home } from './assets/ic-home.svg';
import { ReactComponent as Close } from './assets/ic-close.svg';
import { ReactComponent as UserPicture } from './assets/stemi-user-pic.svg';
import { ReactComponent as Account } from './assets/ic-account.svg';
import { ReactComponent as Cookie } from './assets/ic-cookie.svg';
import { ReactComponent as ExpandMore } from './assets/ic-expand-more.svg';
import { ReactComponent as ExpandLess } from './assets/ic-expand-less.svg';
import { ReactComponent as BtnMinus } from './assets/ic-btn-minus.svg';
import { ReactComponent as BtnPlus } from './assets/ic-btn-plus.svg';
import { ReactComponent as Left } from './assets/ic-left.svg';
import { ReactComponent as Email } from './assets/ic-email.svg';
import { ReactComponent as LinkedIn } from './assets/ic-linkedin.svg';
import { ReactComponent as Check } from './assets/ic-check.svg';

const names = {
  Facebook,
  Google,
  Instagram,
  Twitter,
  Youtube,
  Play,
  ArrowDown,
  ArrowBack,
  ChevronRight,
  Menu,
  ShoppingCart,
  ShoppingCartFull,
  EyeHide,
  EyeShow,
  Home,
  Close,
  UserPicture,
  Account,
  Cookie,
  ExpandMore,
  ExpandLess,
  BtnMinus,
  BtnPlus,
  Left,
  EmptyCart,
  Email,
  LinkedIn,
  Check,
};

const sizes = {
  SMALL: 16,
  MEDIUM: 24,
  LARGE: 48,
  X_LARGE: 64,
};

export const icons = {
  names,
  sizes,
};
