import React from 'react';
import { Trans } from '@lingui/react';
import { A } from 'playful-ui';

export const onlineSecurity = {
  pageTitle: <Trans>Online Security</Trans>,
  creditCards: <Trans>Credit cards</Trans>,
  americanExpress: (
    <Trans>American Express – online fraud protection guarantee</Trans>
  ),
  americanExpressParagraph: (
    <Trans>
      Shop online with confidence when you pay with the{' '}
      <A
        href="https://www.americanexpress.com/hr/networkwebtrgovina/Jamstvo_sigurne_online_kupnje_en.html"
        target="_blank"
      >
        American Express® Card.
      </A>{' '}
      We'll stand behind your online purchases just as we do when you purchase
      items over the phone or in person. So you can make purchases with
      confidence anywhere on the internet. You can still feel confident using
      your American Express Card online.<br />
      <br />
      When you use the American Express Card you will not be held responsible
      for unauthorised charges online.<br />
      <br />
      In fact, American Express takes specific measures to protect your safety
      and privacy online: We use the latest technology to keep your account
      number secure. We have a clearly stated online privacy protection policy
      and we stand by it.
    </Trans>
  ),
  masterSecureCode: <Trans>Mastercard secure code</Trans>,
  masterSecureCodeParagraph: (
    <Trans>
      <A href="http://www.pbzcard.hr/media/53818/mcsc_en.html" target="_blank">
        MasterCard® SecureCode™
      </A>{' '}
      is a new service from MasterCard and your card issuer that provides added
      protection when you buy online. There is no need to get a new MasterCard
      or Maestro® card. You choose your own personal MasterCard SecureCode and
      it is never shared with any merchant. A private code means added
      protection against unauthorized use of your credit or debit card when you
      shop online.<br />
      <br />
      Every time you pay online with your MasterCard or Maestro card, a box pops
      up from your card issuer asking you for your personal SecureCode, just
      like the bank does at the ATM. In seconds, your card issuer confirms it's
      you and allows your purchase to be completed.
    </Trans>
  ),
  verifiedVisa: <Trans>Verified by Visa</Trans>,
  verifiedVisaParagraph: (
    <Trans>
      <A href="http://www.pbzcard.hr/media/53824/vbv_en.html" target="_blank">
        Verified by Visa
      </A>{' '}
      is a new service that lets you shop online with added confidence.<br />
      <br />
      Through a simple checkout process, Verified by Visa confirms your identity
      when you make purchases at participating online stores. It's convenient
      and it works with your existing Visa card.<br />
      <br />
      Plus, Verified by Visa is a snap to use. You register your card just once,
      and create your own password. Then, when you make purchases at
      participating online stores, a Verified by Visa window will appear. Simply
      enter your password and click submit. Your identity is verified and the
      purchase is secure.<br />
      <br />
      To activate Verified by Visa in your Visa card, or to learn more, contact
      the financial institution that issued your Visa card.
    </Trans>
  ),
};
