import React from 'react';

import { withI18n } from '@lingui/react';
import hoistNonReactStatics from 'hoist-non-react-statics';

import { I18nContext } from './I18nContext';

/**
 * HOC injecting I18nContext's value and i18n object from @lingui/react
 * to wrapped Component's props.
 * @param {React.Component} Component 
 */
export function withStemiI18n(Component) {
  class WithStemiI18n extends React.Component {
    render() {
      return (
        <I18nContext.Consumer>
          {i18nContext => <Component {...i18nContext} {...this.props} />}
        </I18nContext.Consumer>
      );
    }
  }

  WithStemiI18n.displayName = `WithStemiI18n(${getDisplayName(Component)})`;
  hoistNonReactStatics(WithStemiI18n, Component);

  return withI18n()(WithStemiI18n);
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
