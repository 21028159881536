import styled, { css } from 'styled-components';
import { fonts, colors, media } from 'theme';

export const H1 = styled.h1`
  ${css`
    ${fonts.h1};
  `};
  ${fonts.common};
  color: ${colors.midnight};
  margin: 0;
  ${media.tablet`
    font-size: 58px;
    line-height: 1.07;
  `};
  ${media.phone`
    font-size: 42px;
    line-height: 1.1;
  `};
`;

export const H2 = styled.h2`
  ${css`
    ${fonts.h2};
  `};
  ${fonts.common};
  color: ${colors.midnight};
  margin: 0;
  ${media.tablet`
    font-size: 44px;
    line-height: 1.14;
  `};
  ${media.phone`
    font-size: 36px;
    line-height: 1.17;
  `};
`;

export const H3 = styled.h3`
  ${css`
    ${fonts.h3};
  `};
  ${fonts.common};
  color: ${colors.midnight};
  margin: 0;
  ${media.tablet`
    font-size: 33px;
    line-height: 1.18;
  `};
  ${media.phone`
    font-size: 26px;
    line-height: 1.15;
  `};
`;

export const H4 = styled.h4`
  ${css`
    ${fonts.h4};
  `};
  ${fonts.common};
  color: ${colors.midnight};
  margin: 0;
  ${media.tablet`
    font-size: 23px;
    line-height: 1.26;
  `};
  ${media.phone`
    font-size: 22px;
    line-height: 1.27;
  `};
`;

export const H5 = styled.h5`
  ${css`
    ${fonts.h5};
  `};
  ${fonts.common};
  color: ${colors.midnight};
  margin: 0;
  ${media.phone`
    font-size: 16px;
    line-height: 1.38;
  `};
`;

export const H6 = styled.h6`
  ${css`
    ${fonts.h6};
  `};
  ${fonts.common};
  color: ${colors.midnight};
  margin: 0;
`;

export const BodyLargeText = styled.p`
  ${fonts.body};
  ${fonts.common};
  color: ${colors.midnight};
  margin: 0;
  ${media.tablet`
    font-size: 19px;
    line-height: 1.58;
  `};
  ${media.phone`
    font-size: 17px;
    line-height: 1.65;
  `};
`;

export const BodyMediumText = styled.p`
  ${fonts.bodyMedium};
  ${fonts.common};
  color: ${colors.midnight};
  margin: 0;
  ${media.phone`
    font-size: 17px;
    line-height: 1.59;
  `};
`;

export const BodySmallText = styled.p`
  ${fonts.bodySmall};
  ${fonts.common};
  color: ${colors.midnight};
  margin: 0;
  ${media.phone`
    font-size: 14px;
    line-height: 1.57;
  `};
`;

export const BodyXSmallText = styled.p`
  ${fonts.bodyXSmall};
  ${fonts.common};
  color: ${colors.midnight};
  margin: 0;
`;

export const Caption = styled.p`
  ${fonts.caption};
  ${fonts.common};
  color: ${colors.darkblue};
  margin: 0;
  ${media.tablet`
    font-size: 13px;
    line-height: 1.38;
  `};
  ${media.phone`
    font-size: 12px;
    line-height: 1.33;
  `};
`;

export const CaptionSmall = styled.p`
  ${fonts.captionSmall};
  ${fonts.common};
  color: ${colors.darkblue};
  margin: 0;
`;

export const Blockquote = styled.blockquote`
  display: inline-block;
  position: relative;
  ${fonts.blockquote};
  color: ${colors.darkblue};
  margin: 0 auto;
  &:before {
    content: '“';
    position: absolute;
    font-size: 5.5em;
    top: -60px;
    left: -20px;
    opacity: 0.06;
  }
  &:after {
    content: '”';
  }
  ${media.tablet`
    font-size: 44px;
    line-height: 1.14;
  `};
  ${media.phone`
    font-size: 36px;
    line-height: 1.17;
  `};
`;

export const FooterSectionText = styled.p`
  ${fonts.footerSection};
  color: ${colors.darkblue};
  margin: 0;
`;

export const FooterCategoryText = styled.p`
  ${fonts.footerCategory};
  color: ${colors.midnight};
  opacity: 0.8;
  margin: 0;
`;

export const FinePrintText = styled.p`
  ${css`
    ${fonts.finePrint};
  `};
  color: ${colors.slategray};
  opacity: 0.8;
  margin: 0;
`;

export const Label = styled.p`
  ${css`
    ${fonts.label};
  `};
  color: ${colors.slategray};
  margin: 0;
  text-transform: uppercase;
`;

export const Tag = styled.p`
  ${css`
    ${fonts.tag};
  `};
  margin: 0;
  color: ${colors.slategray};
  text-transform: uppercase;
`;

export const TabNavigation = styled.p`
  ${css`
    ${fonts.tabNavigation};
  `} margin: 0;
  color: ${colors.midnight};
  text-transform: uppercase;
`;
