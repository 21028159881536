import styled from 'styled-components';

import { spacing, colors, media } from 'theme';

import { H2, Caption } from '../Typography';
import { Button, LinkButton } from '../Button';

import bullet1Url from './assets/bullet-shape-1.svg';
import bullet2Url from './assets/bullet-shape-2.svg';
import bullet3Url from './assets/bullet-shape-3.svg';
import bullet4Url from './assets/bullet-shape-4.svg';

export const CardContainer = styled.div`
  & ${H2}, & ${Caption}, & ul {
    color: ${colors.white};
  }
  & ul {
    margin-bottom: 0;
    ${media.tablet`
      margin-top: ${spacing.SMALL};
      padding-right: 0px;
    `};
    ${media.phone`
      margin-top: ${spacing.SMALL};
      padding-right: 0px;
    `};
  }
  & ${H2} {
    margin-top: 8px;
  }
  display: flex;
  flex: 564;
  max-width: 564px;
  box-sizing: border-box;
  border-radius: 0 4px 4px 0;
  margin-top: 50px;
  padding: 56px ${spacing.BASE_PLUS};
  padding-left: 64px;
  background-image: linear-gradient(206deg, #ed7f5d, #f57830);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
  transform: translateX(-32px);
  & ul li:first-child {
    padding-top: 0px;
  }
  &.silver {
    background-image: linear-gradient(210deg, #f4f4f4, #ebebeb);
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05);
  }
  &.silver ${H2}, &.silver ${Caption} {
    color: ${colors.midnight};
  }
  &.silver ul {
    color: ${colors.midnight};
  }
  &.silver ul li:nth-of-type(n) {
    list-style: square outside url(${bullet1Url});
  }
  &.silver ul li:nth-of-type(2n) {
    list-style: square outside url(${bullet2Url});
  }
  &.silver ul li:nth-of-type(3n) {
    list-style: square outside url(${bullet3Url});
  }
  &.silver ul li:nth-of-type(4n) {
    list-style: square outside url(${bullet4Url});
  }
  & ${Button}, & ${LinkButton}, .link-button {
    margin-top: ${spacing.BASE};
    margin-left: ${spacing.BASE};
  }
  ${media.tablet`
    margin-top: 0;
    align-items: center;
    border-radius: 0 0 4px 4px;
    transform: translateX(0px);
    padding-left: ${spacing.BASE_PLUS};
    padding-right: ${spacing.BASE_PLUS};
  `} ${media.phone`
    max-width: 345px;
    width: 345px;
    margin-top: 0;
    align-items: center;
    padding-left: ${spacing.SMALL};
    padding-right: ${spacing.SMALL};
  `};
`;
