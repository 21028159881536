import styled from 'styled-components';

import { media } from 'theme';

export const Image = styled.img`
  display: flex;
  flex: 624;
  width: 624px;
  height: 724px;
  object-fit: cover;
  ${media.phone`
    width: 360px;
    height: 380px;
  `};
`;
