import React from 'react';
import _ from 'lodash';

import { NavItem } from './NavItem';
import { NavContainer } from './NavContainer';
import { NavOuterContainer } from './NavOuterContainer';

import { Row } from '../Row';

function recursivelyGroupChildren(children, groupedChildren) {
  React.Children.map(children, child => {
    if (child === null) {
      return null;
    }
    if (!_.get(child, 'props.isNavigationItem')) {
      if (!_.isEmpty(_.get(child, 'props.children'))) {
        return recursivelyGroupChildren(child.props.children, groupedChildren);
      }

      return null;
    }
    const { props } = child;
    groupedChildren[props.variant].push(child);
    return null;
  });
}

export const Navigation = ({ children, className, style, id }) => {
  const groupedChildren = {
    left: [],
    center: [],
    right: [],
  };

  recursivelyGroupChildren(children, groupedChildren);

  return (
    <NavOuterContainer className={`h-center ${className}`} style={style} id={id}>
      <NavContainer className={className}>
        <Row>{groupedChildren.left}</Row>
        <Row className="h-center">{groupedChildren.center}</Row>
        <Row className="h-end">{groupedChildren.right}</Row>
      </NavContainer>
    </NavOuterContainer>
  );
};

Navigation.Item = NavItem;
Navigation.displayName = 'Navigation';
