export { Img } from './Img'; 
export { Anchor } from './Anchor';

export function resolveRemoteResource(resolvedURLs, src) {
  let url = src;
  if (typeof resolvedURLs !== 'undefined') {
    resolvedURLs.forEach(element => {
      if (element.fileName === src) {
        url = element.url;
      }
    });
  }
  return url;
}
