import React from 'react';

import styled from 'styled-components';
import { spacing, media } from 'theme';

import { Select } from '../Select';
import { Row } from '../Row';
import { Icon } from '../Icon';
import { HorizontalRule } from '../HorizontalRule';

const FooterSocialLinksAndSelectorsContainer = styled(Row)`
  margin-bottom: ${spacing.SMALL};
  margin-top: ${spacing.SMALL};

  ${Row} {
    width: 262px;
  }

  ${media.phone`
    justify-content: flex-end;
  `};
`;

const StyledSelect = styled(Select)`
  margin-right: ${spacing.SMALL};
`;

const SelectorsContainer = styled(Row)`
  & ${StyledSelect}:last-of-type {
    margin-right: 0;
  }
`;

export const FooterSocialLinksAndSelectors = styled(
  ({
    className,
    languages = [],
    currencies = [],
    onLanguageChanged = () => {},
    onCurrencyChanged = () => {},
    selectedLanguage,
    selectedCurrency,
    socialLinks,
  }) => (
    <div className={className}>
      <HorizontalRule style={{ marginTop: spacing.SMALL, marginBottom: 0 }} />
      <FooterSocialLinksAndSelectorsContainer className="v-center space-between">
        <Row>
          {socialLinks.map((icon, index) => (
            <a key={index} href={icon.href} target={icon.target}>
              <Icon name={icon.name} style={{ marginRight: spacing.BASE }} />
            </a>
          ))}
        </Row>
        <SelectorsContainer className="h-end">
          {languages.length !== 0 && (
            <StyledSelect
              onChange={e => onLanguageChanged(e.target.value)}
              value={selectedLanguage}
            >
              {languages.map((lang, i) => (
                <option key={i} value={lang.value}>
                  {lang.name}
                </option>
              ))}
            </StyledSelect>
          )}
          {currencies.length !== 0 && (
            <StyledSelect
              onChange={e => onCurrencyChanged(e.target.value)}
              value={selectedCurrency}
            >
              {currencies.map((currency, i) => (
                <option key={i} value={currency.value}>
                  {currency.name}
                </option>
              ))}
            </StyledSelect>
          )}
        </SelectorsContainer>
      </FooterSocialLinksAndSelectorsContainer>
    </div>
  )
)``;
