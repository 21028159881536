import styled from 'styled-components';

import {
  spacing,
  H1,
  H2,
  BodyLargeText,
  BodySmallText,
  HeroBackground,
  zIndex,
} from 'playful-ui';

export const ErrorContainer = styled(HeroBackground)`
  height: 100vh;

  & svg.stemi-logo {
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: none;
    box-shadow: none;
    position: absolute;
    object-fit: contain;
    width: 1043px;
    height: 892px;
    z-index: ${zIndex.ELEMENTS_CONTAINER};
  }

  & ${H1} {
    font-size: 96px;
    line-height: 0.67;
    letter-spacing: -1.3px;
  }

  & ${H2} {
    margin-bottom: ${spacing.BASE};
  }

  & ${BodySmallText} {
    margin-top: ${spacing.SMALL};
  }

  & ${BodyLargeText} {
    text-align: center;
    margin-bottom: ${spacing.BASE};
    max-width: 441px;
  }
`;
