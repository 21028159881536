export {  googleTagManager } from './google-tag-manager';
export { facebookPixel } from './facebook-pixel';

export {
  addToCart,
  removeFromCart,
  initiateCheckout,
  secondCheckoutStep,
  thirdCheckoutStep,
  purchaseSuccess,
  productClick,
  productList,
  productDetails,
  addPaymentInfo,
  addShippingInfo,
  viewFeatures,
  viewFAQ,
  viewTestimonial,
} from './redux';
export { analyticsMiddleware } from './redux';
