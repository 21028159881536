import React from 'react';

import _ from 'lodash';

import { BodySmallText } from '../Typography';
import { Row } from '../Row';

import { SkillsHighlightContainer } from './SkillsHighlightContainer';
import { Skill } from './Skill';

export const SkillsHighlight = ({ skills, skillImprovement, skillHighlightText }) => (
  <Row className="h-center">
    <SkillsHighlightContainer>
      <Row className="h-center before-after">
        <BodySmallText className="before">Before</BodySmallText>
        <BodySmallText className="after">After</BodySmallText>
      </Row>
      {skills.map((productSkill, i) => <Skill skill={productSkill} key={i} />)}
      <div className="blue-dot">
        <h2>{skillImprovement}x</h2>
        <BodySmallText>{skillHighlightText}</BodySmallText>
      </div>
    </SkillsHighlightContainer>
  </Row>
);
