import React from 'react';
import { Trans } from '@lingui/react';

import ImageDesign from '../assets/img-3d-modeling.jpg';
import ImageBuild from '../assets/img-build.jpg';
import ImageDevelop from '../assets/img-develop.jpg';

import { ReactComponent as Age } from '../assets/icons/ic-12.svg';
import { ReactComponent as Lessons } from '../assets/icons/ic-150-lessons.svg';
import { ReactComponent as MoneyBack } from '../assets/icons/ic-money-back.svg';
import { ReactComponent as FreeShipping } from '../assets/icons/ic-shipping.svg';

import { ReactComponent as Controller } from '../assets/icons/ic-controller.svg';
import { ReactComponent as TouchSensor } from '../assets/icons/ic-touch.svg';
import { ReactComponent as Rgb } from '../assets/icons/ic-rgb.svg';
import { ReactComponent as Voice } from '../assets/icons/ic-voice.svg';

export const adventure = {
  pageName: <Trans>Adventure</Trans>,
  build: {
    img: ImageBuild,
    title: <Trans>Build the robot yourself</Trans>,
    text: <Trans> A step-by-step video guide to build your first walking robot? It is fascinating
      what a few servos, a battery and handful of nuts and bolts can become. <b>Do it yourself!</b> </Trans>
  },
  btnWatchReview: <Trans>Watch review</Trans>,
  btnFindOutMore: <Trans>Find out more</Trans>,
  rimac: {
    img: 'https://proxy.duckduckgo.com/iu/?u=http%3A%2F%2F2015.startupaddventure.co%2Fwp-content%2Fuploads%2F2015%2F03%2FMate-Rimac-300x300.jpg&f=1',
    quote: <Trans>I really like Stemi Hexapod, this should be a part of every science class.</Trans>,
    name: 'Mate Rimac,',
    position: 'CEO of Rimac Automobili'
  },
  design: {
    img: ImageDesign,
    title: <Trans>Make your own design</Trans>,
    text: <Trans>Learn <b>3D modeling</b>, rethink and redesign everything! <b>Unchain your creativity</b>, 
      customize the robot and with 3D printing bring your ideas to life!</Trans>,
  },
  khan: {
    img: 'https://yt3.ggpht.com/a-/AN66SAx3UEPJT4kwKNspXXPII9FU7b4798E0MbpmvQ=s288-mo-c-c0xffffffff-rj-k-no',
    quote: <Trans>I love everything about it! It is a very unique and well rounded robotic set for STEM education.</Trans>,
    name: 'Khan Flicks,',
    position: 'Product reviewer / animator'
  },
  coding: {
    img: ImageDevelop,
    title: <Trans>Master coding</Trans>,
    text: <Trans>Make the mobile app. Program movements. Make it dance. Show its emotions with LEDs 
      and sensors. Control the robot with your voice.</Trans>,
  },
  onlineMentors: {
    title: <Trans>Our online Mentors will guide you</Trans>,
    text: <Trans><b>Join</b> the community, <b>share</b> your creations and <b>get inspired</b> by others. Enter the 
      forum and <b>build upon</b> other member’s ideas. Settle with nothing, <b>tweak everything</b>!</Trans>
  },
  productHighlights: [
    {
      icon: Age,
      text: <Trans>Recommended age <b>12+</b></Trans>
    },
    {
      icon: Lessons,
      text: <Trans><b>150</b> educational <b>lessons</b></Trans>
    },
    {
      icon: MoneyBack,
      text: <Trans><b>30-day</b> money back guarantee</Trans>
    },
    {
      icon: FreeShipping,
      text: <Trans><b>Fast shipping</b> around the world</Trans>
    },
  ],
  guidedAdventure: <Trans>Guided adventure</Trans>,
  neverFeelAlone: (
    <Trans>
      Never feel alone on your journey – you will get online guidance of a Stemi
      mentor. Equipped with the hardware and software you will be able to make
      outlandish things become reality. Share your ideas as a part of a
      community. <b>Experience satisfaction of creating a product.</b>
    </Trans>
  ),
  featuresHighlight: [
    {
      icon: Controller,
      text: <Trans>Unique movements controlled with your smartphone</Trans>
    },
    {
      icon: TouchSensor,
      text: <Trans>Touch sensors</Trans>
    },
    {
      icon: Rgb,
      text: <Trans>6 programmable RGB LED lights. Add your own 'cool' factor</Trans>
    },
    {
      icon: Voice,
      text: <Trans>Voice control</Trans>
    },
  ],
  skillsHighlight: {
    title: <Trans>Enhance your skills</Trans>,
    skillHighlightText: <Trans>Skill development</Trans>,
    skillImprovement: '6.7',
    skills: [
      {
        title: <Trans>3D modeling</Trans>,
        before: 12,
        after: 22,
        description: (
          <Trans>
            Practice the solving problems skill through STEMI 3D modeling
            challenges
          </Trans>
        )
      },
      {
        title: <Trans>Mobile app development</Trans>,
        before: 8,
        after: 45,
        description: (
          <Trans>
            Understand the process of developing a mobile application
          </Trans>
        )
      },
      {
        title: <Trans>Microcontroller programming</Trans>,
        before: 4,
        after: 34,
        description: (
          <Trans>
            Expand creativity by programming different robot walks and LEDs light patterns
          </Trans>
        )
      },
      {
        title: <Trans>Creative thinking</Trans>,
        before: 20,
        after: 40,
        description: (
          <Trans>
            Know how to use the environment around you to boost your creative
            thinking
          </Trans>
        )
      }
    ]
  },
  latestArticles: <Trans>Latest articles</Trans>,
};
