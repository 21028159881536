import styled from 'styled-components';
import PropTypes from 'prop-types';

export const RoundImage = styled.img`
  width: ${({ width }) => width}px;
  height: ${({ width }) => width}px;
  border-radius: ${({ width }) => width / 2}px;
  object-fit: cover;
`;

RoundImage.propTypes = {
  width: PropTypes.number.isRequired,
};

RoundImage.defaultProps = {
  width: 80,
};
