import React from 'react';
import { PlayfulLink } from 'components';
import { Trans } from '@lingui/react';
import { A } from 'playful-ui';

export const personalDataProtection = {
  pageTitle: (
    <Trans>General Terms and Conditions of Personal Data Protection</Trans>
  ),
  general: <Trans>General provisions</Trans>,
  generalParagraph: (
    <Trans>
      These General Terms and Conditions of Personal Data Protection relate to
      the personal data which can be found and which are by means of using web
      pages: <PlayfulLink to="/">www.stemi.education</PlayfulLink> and all of its subdomains
      (such as learn.stemi.education, hereinafter: “Pages’’) processed i.e.
      collected and stored by the personal data collection manager the company
      STEMI d.o.o., with registered seat in the Republic of Croatia, Rijeka,
      Radmile Matejčić 10, OIB: 91713184849 (hereinafter: ‘’STEMI’’), from you
      as the examinee.<br />
      <br />
      STEMI as the provider of services of <PlayfulLink to="/">Pages</PlayfulLink> is
      dedicated to the protection of your privacy and your personal data and
      especially personal data of your children. Please, read these General
      Terms and Conditions of Personal Data Protection so you could better
      understand which data we collect from you and in what manner we use such
      data.<br />
      <br />
      Should you have any questions regarding personal data protection, please
      contact us by e-mail message on the address:{' '}
      <A href="mailto:info@stemi.education">info@stemi.education</A>
      <br />
      <br />
      Read these General Terms and Conditions of Personal Data Protection
      carefully. By giving your personal data and personal data of your children
      via Pages and indicating your consent to these General Terms and
      Conditions of Personal Data Protection via Pages, you confirm you have
      read, understood and that you accept these General Terms and Conditions of
      Personal Data Protection and that you agree to and accept the collection,
      processing and use of your personal data and personal data of your
      children in accordance with these General Terms and Conditions of Personal
      Data Protection.<br />
      <br />
      In case you do not agree with these General Terms and Conditions of
      Personal Data Protection, please leave and do not access or use Pages.<br />
      <br />
      To all matters not specifically regulated by this General Terms and
      Conditions of Personal Data Protection, the General Terms, and Conditions
      of using Pages and Internet Trade Service apply.
    </Trans>
  ),
  changes: (
    <Trans>
      Changes to General Terms and Conditions of Personal Data Protection
    </Trans>
  ),
  changesParagraph: (
    <Trans>
      STEMI may make amendments or addendums to these General Terms and
      Conditions of Personal Data Protection at any time by publishing the
      amended text on Pages. Amendments and addendums of General Terms and
      Conditions of Personal Data Protection enter into force immediately after
      their publication on Pages.<br />
      <br />
      As an examinee, you are liable and obliged to examine and read the General
      Terms and Conditions of Personal Data Protection in force, which are
      available on Pages, before every submission of your personal data and
      personal data of your children.
    </Trans>
  ),
  personal: (
    <Trans>Personal data being processed and use of personal data</Trans>
  ),
  personalParagraph: (
    <Trans>
      STEMI collects only personal data essential for achieving a purpose of
      collection, stated in item 13 and 15 of these General Terms and
      Conditions, to the extent necessary for achieving the mentioned purpose of
      collection.<br />
      <br />
      STEMI collects the following personal data of the examinees in purchasing
      process: name, surname, address, city/town, zip code, country, the email
      address, phone number, and data on credit/debit card necessary for the
      performance of payment via Internet.<br />
      <br />
      STEMI collects the following personal data of the examinees when opening
      STEMI learning account: first name, last name, e-mail address, date of
      birth, password, city/town and gender.<br />
      <br />
      STEMI collects only the necessary personal data about children on Pages:
      first name, last name, e-mail address, date of birth, password, city/town
      and gender.<br />
      <br />
      By giving personal data of your children from item 12 of these General
      Terms and Conditions, as a legal representative of your juvenile child,
      you give STEMI an explicit approval to collect and process personal data
      of your children from item 12 of these General Terms and Conditions.<br />
      <br />
      When you engage in certain activities on Pages, such as creating user
      account, taking surveys, posting comments, publishing contents and/or
      publishing texts in forums, access to online platform for learning and
      collaboration, participation in competitions or contests, sending us
      feedback, requiring information on our services or applying for job
      offers, we may require certain additional personal data about you or your
      child. In that case, before submitting such personal data, you are obliged
      to read these General Terms and Conditions of Personal Data Protection
      carefully and accept and agree to their application in relation to such
      data.<br />
      <br />
      Depending on types of activity, some data we require from you are
      designated as compulsory and some as voluntary. In case you do not provide
      the compulsory data for certain activity requesting it, you will not be
      allowed to engage in such activity.<br />
      <br />
      STEMI collects and uses personal data in order to enable you the use of
      its services and activities via Pages, to advance the operation of Pages,
      to create the database of its users, for marketing purposes, in order to
      contact you and deliver marketing messages, to improve our advertisement
      and promotional efforts, to analyse the use of Pages. Furthermore, we may
      use your personal data for solving problems, performing administrative
      tasks, to establish a contact with you.<br />
      <br />
      By giving your personal data and personal data of your children and by
      accepting these General Terms and Conditions of Personal Data Protection,
      you agree and accept that STEMI and persons stated hereafter inform you of
      promotional activities, products, and services they offer.<br />
      <br />
      STEMI undertakes to process collected data properly and legally for
      purposes stated in items 14 and 16 of these General Terms and Conditions
      and will process such data when necessary exclusively for purposes
      compliant with the purpose of collection.
    </Trans>
  ),
  submission: <Trans>Submission of personal data</Trans>,
  submissionParagraph: (
    <Trans>
      By giving your personal data pursuant to these General Terms and
      Conditions of Personal Data Protection you agree and accept that we
      provide such personal data for use to third parties, including, but not
      limited to associated persons of STEMI, its members and their associated
      persons for purposes of marketing, marketing and advertising activities
      and delivery of marketing messages.<br />
      <br />
      Any other disclosure of personal data to third parties may be done
      exclusively in accordance with legislation regulating personal data
      protection.<br />
      <br />
      STEMI will not provide personal data of children to third parties.
    </Trans>
  ),
  additions: <Trans>Additions and corrections of personal data</Trans>,
  additionsParagraph: (
    <Trans>
      STEMI considers that every examinee should have the possibility to ensure
      his/her personal data is accurate, complete and up-to-date. In case you
      consider your personal data or personal data of children to be inaccurate,
      incomplete or not up-to-date, we ask you to contact us by sending us an
      e-mail message to info@stemi.education, and require the adequate
      addition, change or deletion of personal data.<br />
      <br />
      We recommend you to supplement your personal data and personal data of
      your children when they change. You may request for deletion or removal of
      information on the user account.
    </Trans>
  ),
  data: <Trans>Data protection</Trans>,
  dataParagraph: (
    <Trans>
      STEMI takes the protection of your data and personal data of your children
      very seriously and has conducted various precaution measures in order to
      protect your personal data. The user may access the personal data on the
      Pages with password and e-mail address. User's password is encrypted. It
      is recommended that the user does not disclose the password to anyone.
      Moreover, user’s personal identification information is stored on the
      server to which only designated persons and services providers may access.
      STEMI encrypts certain sensitive information using Secure Socket Layer
      (SSL) technology, in order to ensure the user’s personal data is safe.<br />
      <br />
      Unfortunately, no Internet data transmission or any wireless network
      transmission can be 100% secured and STEMI cannot guarantee the protection
      of any information transmitted to or from Pages and is not liable for
      actions of any third party to which such data becomes available too.
    </Trans>
  ),
  confidentiality: <Trans>Confidentiality of third parties data</Trans>,
  confidentialityParagraph: (
    <Trans>
      These General Terms and Conditions of Personal Data Protection relate only
      to the use of data which STEMI collects from the examinees and their
      children. Other web pages which may be accessed via the Pages have their
      own statements on confidentiality and data collection and the manners of
      their use and publishing on them. In case you are redirected to any of
      such web pages, we recommend you to read the data privacy statement of
      such pages. STEMI d.o.o. is not liable for the manners and conditions of
      operation of third parties.
    </Trans>
  ),
  otherData: <Trans>Other data</Trans>,
  otherDataParagraph: (
    <Trans>
      In addition to your personal data and personal data of your children,
      STEMI may also collect other data from you and your children which cannot
      identify you or your child and is not considered as personal data (e.g.
      data on the manner of using Pages, data on your computer, Internet
      provider, your preferences, hobbies, interests, activities) and which
      allows us to choose information for the user with better quality and
      precision and in a more personal manner i.e. in order to advance Pages and
      additionally direct and adjust their contents to the visiting audience. On
      the grounds of such data, we come to know which contents are the most
      popular amongst which audience. <br />
      <br />
      We use the analysis software for our Pages analysis by which we analyze
      the use of Pages and thus gain valuable knowledge on the needs of our
      users with the intention of improving the quality of our offer. In
      relation to this, so called cookies are also being used. Cookies are text
      files which are stored on the computer of the web page visitor. They allow
      the recognition of user upon revisiting the web page. This allows us to
      conduct above mentioned usage analysis. Cookies can usually be rejected or
      deleted by adequate browser preferences but this could lead to the
      inability to use completely some of our Pages functions. The mentioned
      software in addition to this also collects and stores some technical data,
      including IP address of the user. We emphasize that in no case there is an
      identification of persons behind such data or the collection of personal
      data. Such data are also not being connected with personal data the user
      has possibly disclosed. By using these Pages you give your approval for
      the above-mentioned manner of conducting an analysis of using these Pages.
    </Trans>
  ),
  otherProvision: <Trans>Other provisions</Trans>,
  otherProvisionParagraph: (
    <Trans>
      These General Terms and Conditions are written in Croatian and English
      language version. In the case of any discrepancy between the Croatian and
      English version of these General Terms and Conditions, the Croatian
      version shall prevail.
    </Trans>
  )
};
