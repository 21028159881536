import { ROOT_ELEMENT_MOUNTED } from '../../app/redux/actions';

import { getAuth } from './actions';
import { getCurrentUser } from './index';

const whiteList = [ROOT_ELEMENT_MOUNTED];

export default store => next => action => {
  const result = next(action);
  if (!whiteList.includes(action.type)) {
    return result;
  }
  switch (action.type) {
    case ROOT_ELEMENT_MOUNTED:
      const user = getCurrentUser(store.getState());
      if (!user.isValid()) {
        store.dispatch(getAuth());
      }
      break;
    default:
  }

  return result;
};
