import React from 'react';
import { connect } from 'react-redux';
import { PulsatingLogo, Row } from 'playful-ui';
import Markdown from 'markdown-to-jsx';
import Helmet from 'react-helmet';
import classNames from 'classnames';

import {
  onceAdventures,
  onceQuests,
  onceLessons,
  onceSections,
  getAllAdventures,
  getAllLessons,
  getAllQuests,
  getAllSections,
} from '../redux';
import { markdownOptions } from '../components/markdownOptions';
import { EduContent } from '../components/EduContent';
import { EduNavTab } from '../components/EduNavTab';
import { EduContainer } from '../components/EduContainer';
import { EduHeader } from '../components/EduHeader';

export class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEduNavTab: false,
    };
  }

  onClickExpand = () => {
    this.setState({
      showEduNavTab: !this.state.showEduNavTab,
    });
  };

  componentDidMount() {
    this.props.onceAdventures();
    this.props.onceQuests();
    this.props.onceLessons();
    this.props.onceSections();
  }

  render() {
    const { showEduNavTab } = this.state;
    const { lessons, sections, lessonId, quests, adventures } = this.props;
    if (lessons.isValid() && quests.isValid() && sections.isValid()) {
      const { markdown, title, sectionId, questId, adventureId } = lessons[
        lessonId
      ];
      const activeSection = sections[sectionId];
      const activeQuest = quests[questId];
      const activeAdventure = adventures[adventureId];
      return (
        <React.Fragment>
          <Helmet route={`/lesson/${lessonId}`} title={title} />
          <EduContainer>
            <EduHeader
              activeAdventure={activeAdventure}
              activeQuest={activeQuest}
              onClickExpand={this.onClickExpand}
              navTabOpened={showEduNavTab}
            >
              <EduNavTab
                className={classNames({
                  'top-nav': true,
                  'show-top-nav': showEduNavTab,
                })}
                sectionIds={activeQuest.sections}
                sections={sections.getData()}
                lessons={lessons.getData()}
                lessonId={lessonId}
                handleLessonClick={this.onClickExpand}
              />
            </EduHeader>
            <Row className="edu-content">
              <EduNavTab
                className="side-nav"
                sectionIds={activeQuest.sections}
                sections={sections.getData()}
                lessons={lessons.getData()}
                lessonId={lessonId}
              />
              <EduContent lesson={lessons[lessonId]} quest={activeQuest}>
                <Markdown options={markdownOptions(activeSection.resolvedURLs)}>
                  {markdown}
                </Markdown>
              </EduContent>
            </Row>
          </EduContainer>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <EduContainer style={{ height: '500px', marginBottom: '250px' }}>
          <EduHeader>
            <EduNavTab
              className={classNames({
                'top-nav': true,
                'show-top-nav': showEduNavTab,
              })}
            />
          </EduHeader>
          <Row className="edu-content">
            <PulsatingLogo />
          </Row>
        </EduContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  adventures: getAllAdventures(state),
  quests: getAllQuests(state),
  lessons: getAllLessons(state),
  sections: getAllSections(state),
});

const mapDispatchToProps = {
  onceAdventures,
  onceLessons,
  onceQuests,
  onceSections,
};

export const LessonPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
