import React from 'react';

import classNames from 'classnames';

import _ from 'lodash';

import { H4 } from '../Typography';
import { Row } from '../Row';
import { Icon } from '../Icon';

import { ExpandableHeadingContainer } from './ExpandableHeadingContainer';

export class ExpandableHeading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      id: _.uniqueId('ExpandableHeading_'),
      childrenHeight: '-10000px',
    };

    this.handleClick = this.handleClick.bind(this);
    this.updateChildrenHeight = this.updateChildrenHeight.bind(this);
  }

  updateChildrenHeight() {
    const element = document.getElementById(this.state.id);
    this.setState({ childrenHeight: `-${_.get(element, 'clientHeight')}px` });
  }

  componentDidMount() {
    this.updateChildrenHeight();
    window.addEventListener('resize', this.updateChildrenHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateChildrenHeight);
  }

  handleClick() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { title, children } = this.props;
    const { expanded, id, childrenHeight } = this.state;
    return (
      <ExpandableHeadingContainer>
        <Row
          className={classNames('space-between v-center heading', { expanded })}
          onClick={this.handleClick}
        >
          <H4>{title}</H4>
          {expanded ? <Icon name="ExpandLess" /> : <Icon name="ExpandMore" />}
        </Row>
        <div className="children-outer-container">
          <div className="children" id={id} style={{ marginTop: expanded ? 0 : childrenHeight }}>
            {children}
          </div>
        </div>
      </ExpandableHeadingContainer>
    );
  }
}
