import { createSelector } from 'reselect';

import { getCurrency } from 'stemi-i18n';

import {
  getCart,
  isCartEmpty,
  deSanitizeCart,
  calcTotalAmount,
} from 'modules/cart/redux/selectors';

export const getCheckout = state => state.checkout;

export const getCartBreakdown = createSelector(
  getCurrency,
  getCheckout,
  getCart,
  isCartEmpty,
  (currency, checkout, cart, isEmpty) => {
    if (isEmpty) {
      return {
        totalAmount: 0,
        cartAmount: 0,
        shippingAmount: null,
        vatAmount: null,
        discountAmount: 0,
      };
    }
    const deSanitizedCart = deSanitizeCart(cart.getData());

    const total = calcTotalAmount(
      deSanitizedCart,
      currency,
      checkout && checkout.shippingModel,
      checkout && checkout.vatRate,
      checkout && checkout.discountPercentage
    );
    return total;
  }
);
