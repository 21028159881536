import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { navigate } from 'gatsby';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import { withStemiI18n, resolveVAT, currencyChanged } from 'stemi-i18n';
import { Button, Checkbox, BodyMediumText, BodyXSmallText, Row, TextInput } from 'playful-ui';

import { IS_BROWSER } from 'consts';
import { AppLinkButton } from 'components';
import { addPaymentInfo } from 'modules/analytics';
import { CartPage, isCartEmpty as isCartEmptySelector, getDeSanitizedCart } from 'modules/cart';

import { CustomerInfoContainer } from '../components/CustomerInfoContainer';
import { Checkout } from '../components/Checkout';
import {
  updateCheckout,
  detectIpinfo,
  generateClientToken,
  addCheckoutAttempt,
  getCheckout,
} from '../redux';
import { braintreeConfig } from '../braintree';
import { content } from './content/CustomerInformationPage';
import { ShippingMethodPage } from './ShippingMethodPage';

const { merchants } = braintreeConfig;

class PageComponent extends React.Component {
  static route = '/checkout/customer-information';
  static title = 'Customer information';

  constructor(props) {
    super(props);
    const { currency, checkout, generateClientToken, isCartEmpty } = props;
    if (isCartEmpty) {
      return;
    }
    this.state = { ...checkout };
    let merchantAccountId = merchants[currency];
    if (IS_BROWSER) {
      props.detectIpinfo().then(res => {
        if (res && !res.error && res.country === 'HR') {
          this.handleCurrencyChanged('HRK');
          merchantAccountId = merchants['HRK'];
        }
      });
      generateClientToken(merchantAccountId);
    }
  }

  componentDidMount() {
    if (this.props.isCartEmpty) {
      navigate(CartPage.route);
      return;
    }
  }

  handleCurrencyChanged = currency => {
    const { currencyChanged, handleCurrencyChanged } = this.props;
    handleCurrencyChanged(currency);
    currencyChanged({ currency });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.checkout.shipping.countryCodeAlpha2 !==
      this.props.checkout.shipping.countryCodeAlpha2
    ) {
      this.setState({ ...this.props.checkout });
    }
  }

  updateStateProperty = event => {
    this.setState({
      shipping: {
        ...this.state.shipping,
        [event.target.name]: event.target.value,
      },
    });
  };

  updateCountry = countryCodeAlpha2 => {
    if (countryCodeAlpha2 === 'HR') {
      // if customer is from HR, allow only HRK checkout
      this.props.handleCurrencyChanged('HRK');
    }
    this.setState({
      shipping: { ...this.state.shipping, countryCodeAlpha2 },
    });
  };

  updateRegion = region => {
    this.setState({
      shipping: { ...this.state.shipping, region },
    });
  };

  updateEmail = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { addCheckoutAttempt, cart, currency, checkout, updateCheckout } = this.props;
    const { shipping, customerEmail } = this.state;
    const vatRate = resolveVAT(shipping.countryCodeAlpha2);
    updateCheckout({ shipping, customerEmail, vatRate }, { cart: cart.getData(), currency });
    addPaymentInfo({
      currency,
      cart,
      totalAmount: checkout.cartBreakdown.totalAmount,
    });
    addCheckoutAttempt({ shipping, customerEmail }, cart.getData(), CustomerInformationPage.route);
    navigate(ShippingMethodPage.route);
  };

  render() {
    const { i18n, isCartEmpty } = this.props;
    if (isCartEmpty) {
      return null;
    }
    const {
      firstName,
      lastName,
      companyName,
      address,
      apartment,
      city,
      countryCodeAlpha2,
      region,
      postal,
      phone,
    } = this.state.shipping;
    const { customerEmail } = this.state;
    const transPlaceholder = content(i18n);
    return (
      <Checkout activePageIndex={1}>
        <CustomerInfoContainer>
          <BodyMediumText>{transPlaceholder.contactInformation}</BodyMediumText>
          <form name="customer-information" onSubmit={this.handleSubmit}>
            <TextInput
              type="email"
              name="customerEmail"
              className="input"
              onChange={this.updateEmail}
              value={customerEmail}
              placeholder="Email"
              required
            />
            <Row className="v-center">
              <Checkbox id="keepMeUpToDate" />
              <label htmlFor="keepMeUpToDate">
                <BodyXSmallText>{transPlaceholder.keepMeUpToDate}</BodyXSmallText>
              </label>
            </Row>
            <BodyMediumText>{transPlaceholder.shipping}</BodyMediumText>
            <Row className="text-input">
              <TextInput
                name="firstName"
                className="input"
                placeholder={transPlaceholder.firstName}
                value={firstName}
                onChange={this.updateStateProperty}
                required
              />
              <TextInput
                name="lastName"
                className="input"
                placeholder={transPlaceholder.lastName}
                value={lastName}
                onChange={this.updateStateProperty}
                required
              />
            </Row>
            <TextInput
              name="companyName"
              className="input"
              placeholder={transPlaceholder.companyName}
              value={companyName}
              onChange={this.updateStateProperty}
            />
            <TextInput
              name="address"
              className="input"
              placeholder={transPlaceholder.address}
              value={address}
              onChange={this.updateStateProperty}
              required
            />
            <TextInput
              name="apartment"
              className="input"
              placeholder={transPlaceholder.apartment}
              value={apartment}
              onChange={this.updateStateProperty}
            />
            <Row className="v-center text-input">
              <TextInput
                name="city"
                className="input"
                placeholder={transPlaceholder.city}
                value={city}
                onChange={this.updateStateProperty}
                required
              />
              <TextInput
                name="postal"
                className="input"
                placeholder={transPlaceholder.postalCode}
                value={postal}
                onChange={this.updateStateProperty}
                required
              />
            </Row>
            <Row className="v-center text-input">
              <CountryDropdown
                valueType="short"
                value={countryCodeAlpha2}
                onChange={this.updateCountry}
                required
              />
              <RegionDropdown
                valueType="short"
                countryValueType="short"
                country={countryCodeAlpha2}
                value={region}
                onChange={this.updateRegion}
                required
              />
            </Row>
            <TextInput
              name="phone"
              className="input"
              placeholder={transPlaceholder.phone}
              value={phone}
              onChange={this.updateStateProperty}
              required
            />

            <Row className="space-between buttons">
              <AppLinkButton className="secondary large" to={CartPage.route}>
                {transPlaceholder.returnToCart}
              </AppLinkButton>
              <Button className="large" type="submit">
                {transPlaceholder.continueToShipping}
              </Button>
            </Row>
          </form>
        </CustomerInfoContainer>
      </Checkout>
    );
  }
}

const mapStateToProps = state => ({
  cart: getDeSanitizedCart(state),
  checkout: getCheckout(state),
  isCartEmpty: isCartEmptySelector(state),
});

const mapDispatchToProps = {
  detectIpinfo,
  generateClientToken,
  addCheckoutAttempt,
  updateCheckout,
  addPaymentInfo,
  currencyChanged,
};

export const CustomerInformationPage = compose(
  withStemiI18n,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PageComponent);
