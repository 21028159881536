import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { spacing, media } from 'theme';

import shape1 from './assets/ic-shape-1.svg';
import shape2 from './assets/ic-shape-2.svg';
import shape3 from './assets/ic-shape-3.svg';
import shape4 from './assets/ic-shape-4.svg';

const elements = [shape1, shape2, shape3, shape4];

const SectionHeaderContainer = styled.div`
  width: ${spacing.XLARGE};
  height: ${spacing.XLARGE};
  position: relative;
  margin: auto;
  margin-bottom: ${spacing.SMALL};
  ${media.phone`
    width: ${spacing.LARGE};
    height: ${spacing.LARGE};
  `}
`;

export class SectionHeader extends React.Component {
  render() {
    const element = elements[this.props.index - 1];
    return (
      <SectionHeaderContainer>
        <img src={element} style={{ width: '100%', height: '100%' }} />
      </SectionHeaderContainer>
    );
  }
}
