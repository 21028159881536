import React from 'react';
import styled from 'styled-components';

import { withStemiI18n, resolvePriceString } from 'stemi-i18n';
import { Row, BodySmallText, BodyXSmallText, spacing, colors } from 'playful-ui';

const ProductItemContainer = styled(Row)`
  justify-content: space-between;
  align-items: center;
  height: 64px;
  margin-bottom: ${spacing.BASE};

  & > ${Row} img {
    height: 64px;
    border-radius: 3px;
    margin-right: ${spacing.BASE};
  }
  & > ${BodySmallText} {
    font-weight: 600;
  }
  & div.container {
    position: relative;
  }
  & div.circle {
    position: absolute;
    top: -12px;
    border-radius: 50%;
    background-color: ${colors.darkblue};
    left: calc(100% - 24px - 12px);
    height: 24px;
    width: 24px;
  }
  & div.circle ${BodyXSmallText} {
    color: white;
  }
`;

export const OrderItem = withStemiI18n(({ currency, orderItem, className }) => {
  const { price, quantity, productName, options } = orderItem;
  return (
    <ProductItemContainer className={className}>
      <Row className="v-center">
        <div className="container">
          <img src={options.displayImage} alt={productName} />
          <Row className="v-center h-center circle">
            <BodyXSmallText>{quantity}</BodyXSmallText>
          </Row>
        </div>
        <BodySmallText>{`${productName} (${options.displayName})`}</BodySmallText>
      </Row>
      <BodySmallText>{resolvePriceString(price, currency, options)}</BodySmallText>
    </ProductItemContainer>
  );
});
