export const CURRENCY_CHANGED = 'stemi.i18n.CURRENCY_CHANGED';

export const currencyChanged = meta => ({
  type: CURRENCY_CHANGED,
  meta,
});

export const getCurrency = state => state.currency;

export const reducer = (state = 'USD', action) => {
  const { type, meta } = action;
  switch (type) {
    case CURRENCY_CHANGED:
      return meta.currency;
    default:
      return state;
  }
};
