import React from 'react';
import { Option } from 'playful-ui';

export class OptionWithIndex extends React.Component {
  handleClick = () => (
    this.props.onClick(this.props.index)
  )

  render() {
    const { className, index } = this.props;
    return (
      <Option className={className} key={`option${index}`} onClick={this.handleClick}>
        {this.props.children}
      </Option>
    )
  }
}
