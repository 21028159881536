import styled from 'styled-components';
import { spacing, colors } from 'theme';

import { ButtonWithoutStyle } from '../Button';

export const ControlButton = styled(ButtonWithoutStyle)`
  height: ${spacing.BASE_PLUS};
  width: ${spacing.BASE_PLUS};
  border: solid 1px ${colors.mischka};
  background-color: ${colors.applyOpacity(colors.whitesmoke, 0.5)};
  &:hover {
    background-color: ${colors.applyOpacity(colors.mischka, 0.5)};
  }
  &.right {
    border-radius: 0px 3.2px 3.2px 0px;
    border-left: 0px;
  }
  &.left {
    border-radius: 3.2px 0px 0px 3.2px;
  }
`;
