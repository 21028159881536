import React from 'react';
import { AppLinkButton } from 'components';

import { Column, H2, Icon } from 'playful-ui';
import { Trans } from '@lingui/react';

import { ProductsPage } from 'modules/products';

export const EmptyCart = () => (
  <Column className="h-center content">
    <H2 className="empty-cart"><Trans>Your shopping cart is empty</Trans></H2>
    <AppLinkButton className="secondary" to={ProductsPage.route}>
      <Trans>CONTINUE SHOPPING</Trans>
    </AppLinkButton>
    <Icon className="empty-cart" name="EmptyCart" size="188px" />
  </Column>
);
