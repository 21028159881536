import styled from 'styled-components';

import { spacing, fonts, colors, media } from 'theme';
import { Column } from '../Column';
import { H4, BodySmallText } from '../Typography';

export const QuestsHighlightContainer = styled(Column)`
  & ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: ${spacing.BASE} 0;
  }
  & ul li {
    ${fonts.captionSmall};
    color: ${colors.midnight};
    margin-left: ${spacing.LARGE};
    cursor: pointer;
    opacity: 0.7;
    will-change: opacity;
    transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1);
  }
  & ul li.active {
    opacity: 1;
    padding-bottom: ${spacing.TINY};
    border-bottom: 3px solid ${colors.midnight};
  }
  & ul li:hover {
    opacity: 1;
  }
  & ul li:first-child {
    margin-left: 0;
  }

  & div.content-container {
    position: relative;
    margin: 0 10px;
    margin-bottom: 200px;
    ${media.phone`
      margin: 0 5px;
      margin-bottom: 150px;
    `};
  }
  & div.screen-pictures-container {
    border-radius: 5px;
    overflow: hidden;
    width: 75.67%;
    height: 83.85%;
    position: absolute;
    top: 7.6%;
    left: 12.2%;
  }
  & img {
    width: 100%;
    max-width: 707px;
  }
  & img.quest-image {
    position: absolute;
    top: 0;
    left: 0;
    will-change: opacity;
    transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1) 0.1s;
  }
  & img.active {
    opacity: 1;
  }
  & img.hidden {
    opacity: 0;
  }

  & div.descriptions-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  & div.description-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  & ${H4} {
    margin-top: ${spacing.BASE};
    margin-bottom: ${spacing.BASE};
  }
  & ${H4}, ${BodySmallText} {
    text-align: center;
    will-change: opacity;
    transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1);
    margin-left: 20px;
    margin-right: 20px;
    ${media.phone`
      margin-left: 15px;
      margin-right: 15px;
    `};
  }
  & div.active {
    opacity: 1;
  }
  & div.hidden {
    display: none;
  }
`;
