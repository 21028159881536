import React from 'react';
import { Trans } from '@lingui/react';

export const text = {
  homeTitle: <Trans>Transform your classroom into an innovation lab</Trans>,
  homeSubtext: (
    <Trans>
      Industry-based STEAM programs for forward-thinking middle schools and high
      schools
    </Trans>
  ),
  getStarted: <Trans>GET STARTED</Trans>,
  productHighlight1: <Trans>Hardware and software cloud based ecosystem</Trans>,
  productHighlight2: (
    <Trans>
      Seamless program implementation supported by state of the art online
      learning management system
    </Trans>
  ),
  productHighlight3: (
    <Trans>Relevant industry technical skills development</Trans>
  ),
  productHighlight4: (
    <Trans>
      Teacher training and support to the school staff whenever you need it
    </Trans>
  ),
  prepareYourStudents: (
    <Trans>Prepare your students for the 21st century tech industry</Trans>
  ),
  weRadicallyReduce: (
    <Trans>
      We radically reduce the time needed to transfer relevant skills and
      industry knowledge into educational systems. With our{' '}
      <b>plug and play STEAM solutions,</b> we help schools around the world to
      transform their classrooms into innovation labs where students can
      experience hands-on projects emulating real-world practices.
    </Trans>
  ),
  bioInspired: <Trans>Bio-inspired robotics program</Trans>,
  unrivaledSolution: (
    <Trans>
      An unrivaled solution to maximize student engagement based on
      career-connected projects, and experiential learning. Students learn
      important industry technical skills like 3D modeling, mobile app
      development, and embedded programming.
    </Trans>
  ),
  exploreEducation: <Trans>Explore education</Trans>,
  moreAboutTheProgram: <Trans> MORE ABOUT THE PROGRAM</Trans>,
  getHexapodNow: <Trans>Get Hexapod now</Trans>,
  joinTheAdventure: <Trans>Join the adventure!</Trans>,
  becomePart: (
    <Trans>
      Reduce resources wasted on antiquated content and maximize student
      engagement
    </Trans>
  ),
  personalizedGuidance: (
    <Trans>
      Overcome budget constraints and help your teachers stay current with
      industry-based practices
    </Trans>
  ),
  getAll: (
    <Trans>
      Get your school and district recognized with unique, experiential STEAM
      programs
    </Trans>
  ),
  everythingThat: (
    <Trans>
      Everything students, teachers, and schools need to be successful:
      training, classroom tech, curriculum products, and support.
    </Trans>
  ),
  getStarted: <Trans>Get started</Trans>,
  latestArticles: <Trans>Latest articles</Trans>,
  viewAllArticles: <Trans>VIEW ALL ARTICLES</Trans>,
};
