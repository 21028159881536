import styled from 'styled-components';
import { colors, spacing } from 'theme';

import { H5, Caption } from '../../Typography';
import { Row } from '../../Row';

export const TabMenuContainer = styled(Row)`
  margin: ${spacing.BASE} 0;

  & div.tab-menu-container {
    position: relative;
    transition: opacity 0.1s linear;
    left: -50%;
  }
  & div.tab-menu-container.hidden {
    opacity: 0;
  }
  & div.tab-menu-container.displayed {
    opacity: 1;
  }
  & svg {
    padding-left: ${spacing.TINY};
    cursor: pointer;
  }
  & ${Caption}.tab-caption {
    color: ${colors.midnight};
  }

  & svg g path:last-child {
    fill: ${colors.midnight};
  }

  & ul {
    padding: 0;
    margin: 4px 0;
    width: 211px;
    max-width: 211px;
    position: absolute;
    left: -91px;
    top: -34px;

    border-radius: 4px;
    background-color: rgba(244, 244, 247, 0.98);
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
    list-style: none;
  }

  & li {
    cursor: pointer;
    padding: 24px 21px;
    padding-right: 7px;
    box-shadow: 0 1px rgba(213, 214, 222, 0.3);
  }
  & li:hover {
    background-color: rgba(253, 253, 254, 0.69);
  }
  & li.active {
    color: ${colors.persianblue};
  }
  & li:last-child {
    box-shadow: none;
  }
  & li ${H5} {
    color: inherit;
  }
`;
