import styled from 'styled-components';
import { Row, media, BodyXSmallText, zIndex, spacing, colors, Column } from 'playful-ui';

export const InputContainer = styled.div`  
  width: 60%;
  z-index: ${zIndex.CONTENT_BANNER_CONTAINER};
  display: flex;
  flex-direction: column;
  flex: 1;

  max-width: 660px;
  ${media.tablet`
    width: 100%
    max-width: 532px;
  `};
  ${media.phone`
    width: 100%
    max-width: 343px;
  `};

  & a.logo > img {
    height: 80px;
  };
  & ${Column}.desktop-header {
    flex: unset;
    ${media.tablet`
      display: none;
    `};
  }
  & > ${Column} {
    margin-right: ${spacing.LARGE};
    margin-top: ${spacing.XLARGE};
    ${media.tablet`
      margin-top: ${spacing.BASE};
      margin-right: 0;
    `};
  };

  & ${BodyXSmallText}.bold {
    font-weight: 600;
  }

  & ${Row}.navigator {
    margin-top: ${spacing.TINY};
    margin-bottom: ${spacing.BASE};
  };
  & ${Row}.navigator svg g path:nth-child(2) {
    fill: ${colors.midnight};
  }

  & div.copyright {
    padding-top: 16px;
    border-top: 1px solid ${colors.mischka};
    flex: unset;
  };
  & div.copyright ${BodyXSmallText} {
    opacity: 0.4;
    margin-bottom: ${spacing.BASE};
  };
`;
