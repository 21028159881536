import tinycolor from 'tinycolor2';

export const colors = Object.freeze({
  darkblue: '#102e8e',
  facebook: '#39569a',
  google: '#ea5650',
  persianblue: '#153ebf',
  tomato: '#ff6230',
  gold: '#ffc61f',
  dodgerblue: '#3191d6',
  limegreen: '#75d640',
  crimson: '#dc3545',
  skyblue: '#7fd8ed',
  turquoise: '#3fcecb',
  pink: '#fab7ce',
  steel: '#8092b0',
  mediumpurple: '#8e80b5',
  black: '#060615',
  midnight: '#12123b',
  slategray: '#8e8fa2',
  mischka: '#d5d6de',
  whitesmoke: '#f4f4f7',
  snow: '#fdfdfe',
  white: '#ffffff',
  applyOpacity: (color, opacity) =>
    tinycolor(color)
      .setAlpha(opacity)
      .toRgbString(),
});
