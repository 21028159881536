import styled from 'styled-components';

export default styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  animation: pulsate 3s ease-out infinite;
  @keyframes pulsate {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  & svg {
    width: 100%;
    height: 100%;
  }
`;
