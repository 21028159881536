import React from 'react';
import {H2, ExpandableHeading} from 'playful-ui';

import { FaqContainer } from '../components/FaqContainer';
import { faq, faqTitle, pageName } from './content/FAQ';

export class FAQ extends React.Component {
  static pageName = pageName;

  render() {
    return (
      <FaqContainer className="h-center">
        <H2>{faqTitle}</H2>
        {faq.map((item, i) => (
          <ExpandableHeading title={item.question} key={i}>
            {item.answer}
          </ExpandableHeading>
        ))}
      </FaqContainer>
    );
  }
}
