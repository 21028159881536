import _ from 'lodash';

import {
  db,
  auth,
  storage,
  facebookProvider,
  googleProvider,
  emailProvider,
} from './firebase';

export const ONCE_GET_REQUEST = 'stemi.firebase.ONCE_GET_REQUEST';
export const ONCE_GET_SUCCESS = 'stemi.firebase.ONCE_GET_SUCCESS';
export const ONCE_GET_ERROR = 'stemi.firebase.ONCE_GET_ERROR';

export const SET_REQUEST = 'stemi.firebase.SET_REQUEST';
export const SET_SUCCESS = 'stemi.firebase.SET_SUCCESS';
export const SET_ERROR = 'stemi.firebase.SET_ERROR';

export const REMOVE_REQUEST = 'stemi.firebase.REMOVE_REQUEST';
export const REMOVE_SUCCESS = 'stemi.firebase.REMOVE_SUCCESS';
export const REMOVE_ERROR = 'stemi.firebase.REMOVE_ERROR';

export const PUSH_REQUEST = 'stemi.firebase.PUSH_REQUEST';
export const PUSH_SUCCESS = 'stemi.firebase.PUSH_SUCCESS';
export const PUSH_ERROR = 'stemi.firebase.PUSH_ERROR';

export const SIGN_IN_REQUEST = 'stemi.firebase.SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'stemi.firebase.SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'stemi.firebase.SIGN_IN_ERROR';

export const SIGN_UP_REQUEST = 'stemi.firebase.SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'stemi.firebase.SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'stemi.firebase.SIGN_UP_ERROR';

export const VERIFY_REQUEST = 'stemi.firebase.VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'stemi.firebase.VERIFY_SUCCESS';
export const VERIFY_ERROR = 'stemi.firebase.VERIFY_ERROR';

const schemeRegister = {
  users: {
    ref: 'users',
  },
  carts: {
    ref: 'carts',
  },
  newsletterSubscribers: {
    ref: 'newsletterSubscribers',
  },
  checkoutAttempt: {
    ref: 'checkoutAttempt',
  },
  discountCodes: {
    ref: 'discountCodes',
  },
  savedBrowserCarts: {
    ref: 'savedBrowserCarts',
  },
};

function getFirebaseRef(schemeName, object, denormalized) {
  const scheme = schemeRegister[schemeName];
  const id = object.id || '';

  if (typeof schemeName !== 'string') {
    throw new Error(`
      Wrong scheme definition, schemeName should be string. schemeName: ${schemeName}
    `);
  }

  if (scheme) {
    return `${scheme.ref}/${id}`;
  }

  return `${denormalized ? 'denormalized' : 'normalized'}/${schemeName}/${id}`;
}

/**
 * Helper function which converts scheme name into path
 * @returns {String} path in firebase RealTime database
 */
function getFirebaseRefFromAction(action) {
  const { meta, payload = {} } = action;
  const { ref, schemeName, denormalized } = meta;
  return ref || getFirebaseRef(schemeName, payload, denormalized);
}

/**
 * Action creator for getting data from firebase realtime database.
 * @param {String} schemeName - type of the object in firebase RealTime database
 * @param {boolean} denormalized - should response be denormalized
 */
export function onceGet(schemeName, object = {}, denormalized) {
  return {
    type: ONCE_GET_REQUEST,
    payload: object,
    meta: {
      schemeName,
      denormalized,
      id: object.id,
      ref: getFirebaseRef(schemeName, object, denormalized),
    },
  };
}

/**
 * Action creator for successful firebase once requests
 * @param {String} meta - meta object from ONCE_GET_REQUEST action
 * @param {object} snapshot - snapshot of ref from Firebase RealTime database
 */
export function onceLoaded(meta, snapshot) {
  return {
    type: ONCE_GET_SUCCESS,
    payload: snapshot.val(),
    meta,
  };
}

/**
 * Action creator for failed firebase once requests
 * @param {Object} meta - meta object from ONCE_GET_REQUEST action
 * @param {*} error - error which caused the request to fail
 */
export function onceError(meta, error) {
  return {
    type: ONCE_GET_ERROR,
    error,
    meta,
  };
}

/**
 * Redux thunk for handling async once request on Firebase RealTime database
 * @param {Object} action
 */
export const once = (schemeName, object, denormalized) => dispatch => {
  const action = onceGet(schemeName, object, denormalized);
  const { meta } = action;
  const firebaseRef = getFirebaseRefFromAction(action);
  dispatch(action);
  return db
    .ref(firebaseRef)
    .once('value')
    .then(snapshot => {
      dispatch(onceLoaded(meta, snapshot));
      return Promise.resolve(snapshot.val());
    })
    .catch(error => {
      dispatch(onceError(meta, error));
      return Promise.reject(error);
    });
};

/**
 * Action creator for setting an object in firebase RealTime database
 * @param {String} schemeName - type of the object in firebase RealTime database
 * @param {Object} object object to be set
 */
export function setRequest(schemeName, object) {
  return {
    type: SET_REQUEST,
    payload: object,
    meta: {
      schemeName,
      id: object.id,
      ref: getFirebaseRef(schemeName, object),
    },
  };
}

export function setLoaded(meta, object) {
  return {
    type: SET_SUCCESS,
    payload: _.omit(object, ['id']),
    meta,
  };
}

/**
 * Action creator for failed firebase set requests
 * @param {Object} meta - meta object from SET_REQUEST action
 * @param {*} error - error which caused the request to fail
 */
export function setError(meta, error) {
  return {
    type: SET_ERROR,
    error,
    meta,
  };
}

export const set = (schemeName, object) => dispatch => {
  const action = setRequest(schemeName, object);
  const { meta } = action;
  const firebaseRef = getFirebaseRefFromAction(action);

  dispatch(action);

  return db
    .ref(firebaseRef)
    .set(_.omit(object, ['id']))
    .then(() => {
      dispatch(setLoaded(meta, object));
      return Promise.resolve(object);
    })
    .catch(error => {
      dispatch(setError(meta, error));
      return Promise.reject(error);
    });
};

/**
 * Action creator for removing an object in firebase RealTime database
 * @param {String} schemeName - type of the object in firebase RealTime database
 * @param {Object} object object to be removed
 */
export function removeRequest(schemeName, object) {
  return {
    type: REMOVE_REQUEST,
    payload: object,
    meta: {
      schemeName,
      id: object.id,
      ref: getFirebaseRef(schemeName, object),
    },
  };
}

/**
 * @param {Object} meta - meta object from REMOVE_SUCCESS action
 * @param {*} object
 */
export function removeLoaded(meta, object) {
  return {
    type: REMOVE_SUCCESS,
    payload: object,
    meta,
  };
}

/**
 * Action creator for failed firebase remove requests
 * @param {Object} meta - meta object from REMOVE_ERROR action
 * @param {*} error - error which caused the request to fail
 */
export function removeError(meta, error) {
  return {
    type: REMOVE_ERROR,
    error,
    meta,
  };
}

export const remove = (schemeName, object) => dispatch => {
  const action = removeRequest(schemeName, object);
  const { meta } = action;
  const firebaseRef = getFirebaseRefFromAction(action);

  dispatch(action);

  return db
    .ref(firebaseRef)
    .remove()
    .then(() => {
      dispatch(removeLoaded(meta, object));
      return Promise.resolve(object);
    })
    .catch(error => {
      dispatch(removeError(meta, error));
      return Promise.reject(error);
    });
};

/**
 * Action creator for pushing an object in firebase RealTime database list
 * @param {String} schemeName - type of the object in firebase RealTime database
 * @param {Object} object object to be pushed
 */
export function pushRequest(schemeName, object) {
  return {
    type: PUSH_REQUEST,
    payload: object,
    meta: {
      schemeName,
      id: object.id,
      ref: getFirebaseRef(schemeName, object),
    },
  };
}

/**
 * Action creator for successful firebase push requests
 * @param {Object} meta - meta object from PUSH_REQUEST action
 * @param {Object} object object successfully pushed
 */
export function pushLoaded(meta, object) {
  return {
    type: PUSH_SUCCESS,
    payload: object,
    meta,
  };
}

/**
 * Action creator for failed firebase push requests
 * @param {Object} meta - meta object from PUSH_REQUEST action
 * @param {*} error - error which caused the request to fail
 */
export function pushError(meta, error) {
  return {
    type: PUSH_ERROR,
    error,
    meta,
  };
}

export const push = (schemeName, object) => dispatch => {
  const action = pushRequest(schemeName, object);
  const { meta } = action;
  const firebaseRef = getFirebaseRefFromAction(action);

  dispatch(action);

  return db
    .ref(firebaseRef)
    .push(object)
    .then(() => {
      dispatch(pushLoaded(meta, object));
      return Promise.resolve(object);
    })
    .catch(error => {
      dispatch(pushError(meta, error));
      return Promise.reject(error);
    });
};

/**
 * Action creator for saving current authenticated user in the state
 * @param {Object} user - user to be saved
 */
export function saveUser(user) {
  return {
    type: ONCE_GET_SUCCESS,
    payload: {
      ...user,
    },
    meta: {
      schemeName: 'users',
    },
  };
}

/**
 * Action creator for signing in user with an email and a password
 * @param {String} email -  user's email
 * @param {String} password - user's password
 */
export function signInWithEmailAndPassword(email, password) {
  return {
    type: SIGN_IN_REQUEST,
    payload: {
      email,
      password,
    },
  };
}

/**
 * Returns representation of Firebase user object in Stemi Firebase Database
 * @param {Object} firebaseUser
 */
export function stemiUser(firebaseUser) {
  const {
    uid,
    isAnonymous,
    metadata,
    displayName,
    email,
    emailVerified,
    photoURL,
  } = firebaseUser;
  const { creationTime } = metadata;
  return {
    id: uid,
    uid,
    isAnonymous,
    creationTime,
    displayName,
    email,
    emailVerified,
    photoURL,
  };
}

/**
 * Redux thunk for signing in user
 * @param {String} email
 * @param {String} password
 * @returns {Object} Firebase credentials object
 */
export const signIn = (email, password) => dispatch => {
  dispatch(signInWithEmailAndPassword(email, password));
  return auth.signInWithEmailAndPassword(email, password).then(credentials => {
    dispatch(set('users', stemiUser(credentials.user)));
    return credentials;
  });
};

// TODO(Ivan): make sign in factory for different providers
// TODO(Ivan): handle email already exist
export const signInWithFacebook = () => dispatch => {
  let user;
  return auth
    .signInWithPopup(facebookProvider)
    .then(auth => {
      user = stemiUser(auth.user);
      return dispatch(set('users', user));
    })
    .catch(error => {
      dispatch(setError({ schemeName: 'users' }, error));
      return Promise.reject(error);
    });
};

/**
 *
 */
export const signInWithGoogle = () => dispatch => {
  let user;

  return auth
    .signInWithPopup(googleProvider)
    .then(auth => {
      user = stemiUser(auth.user);
      return dispatch(set('users', user));
    })
    .catch(error => {
      dispatch(setError({ schemeName: 'users' }, error));
      return Promise.reject(error);
    });
};

export const sendEmailVerification = () => dispatch => {
  dispatch({ type: VERIFY_REQUEST });
  return auth.currentUser
    .sendEmailVerification({ url: document.location.origin })
    .then(res => dispatch({ type: VERIFY_SUCCESS, payload: res }))
    .catch(error => dispatch({ type: VERIFY_ERROR, error }));
};

/**
 * Redux thunk for creating user with Facebook account
 * @param {String} nickname
 * @param {String} email
 * @param {String} password
 * @param {boolean} receiveUpdates
 */
export const signUpWithFacebook = (
  nickname,
  email,
  password,
  receiveUpdates,
) => dispatch => {
  console.log('signUpWithFacebook' + nickname, email, password, receiveUpdates);
  return Promise.resolve({});
};

/**
 * Redux thunk for creating user with Google account
 * @param {String} nickname
 * @param {String} email
 * @param {String} password
 * @param {boolean} receiveUpdates
 */
export const signUpWithGoogle = (
  nickname,
  email,
  password,
  receiveUpdates,
) => dispatch => {
  console.log('signUpWithGoogle' + nickname, email, password, receiveUpdates);
  return Promise.resolve({});
};

/**
 * Action creator for signing in user with an email and a password
 * @param {String} email -  user's email
 * @param {String} password - user's password
 */
export function signUpWithEmailAndPassword(email, password) {
  return {
    type: SIGN_UP_REQUEST,
    payload: {
      email,
      password,
    },
  };
}

/**
 * Redux thunk for creating user with email and password
 * @param {String} nickname
 * @param {String} email
 * @param {String} password
 * @param {boolean} receiveUpdates
 */
export const signUp = (
  nickname,
  email,
  password,
  receiveUpdates,
) => dispatch => {
  let user = { email, nickname, receiveUpdates };
  dispatch(signUpWithEmailAndPassword(email, password));
  const credential = emailProvider.credential(email, password);
  return auth.currentUser
    .updateProfile({ displayName: nickname })
    .then(() => auth.currentUser.linkWithCredential(credential))
    .then(userCred => {
      Object.assign(user, stemiUser(userCred.user));
      dispatch(set('users', user));
      dispatch(sendEmailVerification());
      return userCred;
    })
    .catch(error => {
      dispatch(setError({ schemeName: 'users' }, error));
      return Promise.reject(error);
    });
};

export const signOut = () => dispatch => {
  return auth
    .signOut()
    .then(() => {
      dispatch(saveUser(null));
      return Promise.resolve();
    })
    .catch(error => Promise.reject(error));
};

const deleteProfileImage = () => {
  const oldUrl = auth.currentUser.photoURL;
  try {
    const ref = storage.refFromURL(oldUrl);
    if (ref) {
      return ref
        .delete()
        .then(() => console.log('Old image deleted'))
        .catch(error => console.error(error));
    }
  } catch (err) {
    console.error(err);
  }
  return new Promise((resolve, reject) => resolve());
};

export const updatePhotoURL = photoURL => dispatch => {
  return deleteProfileImage()
    .then(() => auth.currentUser.updateProfile({ photoURL }))
    .then(() => dispatch(set('users', stemiUser(auth.currentUser))))
    .catch(error => Promise.reject(error));
};

export const uploadPhoto = file => dispatch => {
  const userUID = auth.currentUser.uid;
  const storageRef = storage.ref(
    `users/${userUID}/profilePicture/${file.name}`,
  );

  return storageRef
    .put(file)
    .then(() => {
      storageRef.getDownloadURL().then(url => {
        updatePhotoURL(url)(dispatch);
      });
    })
    .catch(error => Promise.reject(error));
};

export const updateDisplayName = displayName => dispatch => {
  return auth.currentUser
    .updateProfile({ displayName })
    .then(() => dispatch(set('users', stemiUser(auth.currentUser))))
    .catch(error => Promise.reject(error));
};

export const changePassword = (currentPassword, newPassword) => dispatch => {
  const credential = emailProvider.credential(
    auth.currentUser.email,
    currentPassword,
  );

  return auth.currentUser
    .reauthenticateAndRetrieveDataWithCredential(credential)
    .then(() => auth.currentUser.updatePassword(newPassword))
    .catch(error => Promise.reject(error));
};

export const deleteAccount = password => dispatch => {
  const user = auth.currentUser;
  const { uid, email } = user;
  const emptyUser = { id: uid };

  const credential = emailProvider.credential(email, password);

  return auth.currentUser
    .reauthenticateAndRetrieveDataWithCredential(credential)
    .then(data => deleteProfileImage())
    .then(() => auth.currentUser.delete())
    .then(() => {
      dispatch(remove('carts', emptyUser));
      dispatch(remove('users', emptyUser));
    })
    .catch(error => Promise.reject(error));
};
