const errorMessages = {
  'auth/user-not-found': 'No user with this email.',
  'auth/invalid-email': 'The email address is badly formatted.',
  'auth/wrong-password':
    'The password is invalid or the user does not have a password.',
  'auth/weak-password': '',
  'auth/email-already-in-use': ''
};

export function registerErrors(errorList, errorObject) {
  if (errorObject && errorList.includes(errorObject.code)) {
    return errorMessages[errorObject.code]
      ? errorMessages[errorObject.code]
      : errorObject.message;
  }
  return '';
}
