import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  INITIATE_CHECKOUT,
  SECOND_CHECKOUT_STEP,
  THIRD_CHECKOUT_STEP,
  PURCHASE_SUCCESS,
  PRODUCT_CLICK,
  PRODUCT_LIST,
  PRODUCT_DETAILS,
  ADD_PAYMENT_INFO,
  ADD_SHIPPING_INFO,
  VIEW_FAQ,
  VIEW_TESTIMONIAL,
  VIEW_FEATURES,
} from './actions';
import { facebookPixel, googleTagManager } from '../index';

const whiteList = [
  ADD_TO_CART,
  REMOVE_FROM_CART,
  INITIATE_CHECKOUT,
  SECOND_CHECKOUT_STEP,
  THIRD_CHECKOUT_STEP,
  PURCHASE_SUCCESS,
  PRODUCT_CLICK,
  PRODUCT_LIST,
  PRODUCT_DETAILS,
  ADD_PAYMENT_INFO,
  ADD_SHIPPING_INFO,
  VIEW_FAQ,
  VIEW_TESTIMONIAL,
  VIEW_FEATURES,
];

export const analyticsMiddleware = store => next => action => {
  if (!whiteList.includes(action.type)) {
    return next(action);
  }
  try {
    const {
      currency,
      cart,
      totalAmount,
      orderItem,
      transaction,
      productCategory,
      productList,
      product,
      shippingModel,
    } = { ...action.meta };

    switch (action.type) {
      case ADD_TO_CART:
        googleTagManager.addToCart(currency, orderItem);
        facebookPixel.addToCart(orderItem);
        break;
      case REMOVE_FROM_CART:
        googleTagManager.removeFromCart(currency, orderItem);
        break;
      case INITIATE_CHECKOUT:
        googleTagManager.firstCheckoutStep(currency, cart);
        facebookPixel.initiateCheckout(currency, cart, totalAmount);
        break;
      case SECOND_CHECKOUT_STEP:
        googleTagManager.secondCheckoutStep();
        break;
      case THIRD_CHECKOUT_STEP:
        googleTagManager.thirdCheckoutStep();
        break;
      case PURCHASE_SUCCESS:
        googleTagManager.purchaseSuccess(currency, cart, transaction);
        facebookPixel.purchase(currency, cart, totalAmount);
        break;
      case PRODUCT_CLICK:
        googleTagManager.productClick(currency, productCategory, product);
        break;
      case PRODUCT_LIST:
        googleTagManager.productList(currency, productList, productCategory);
        break;
      case PRODUCT_DETAILS:
        googleTagManager.productDetails(currency, product);
        break;
      case ADD_PAYMENT_INFO:
        facebookPixel.addPaymentInfo(currency, cart, totalAmount);
        break;
      case ADD_SHIPPING_INFO:
        facebookPixel.addShippingInformation(shippingModel);
        break;
      case VIEW_FEATURES:
        facebookPixel.viewFeatures(product);
        break;
      case VIEW_TESTIMONIAL:
        facebookPixel.viewTestimonial(product);
        break;
      case VIEW_FAQ:
        facebookPixel.viewFaq(product);
        break;

      default:
    }
  } catch (e) {
    console.error(e);
  }
  return next(action);
};
