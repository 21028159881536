import React from 'react';

import { Link } from 'gatsby';

import { Navigation } from 'playful-ui';
import { AppLinkButton } from 'components';
import { ReactComponent as Logo } from '../../navigation/assets/img-stemi-logo.svg';

export const AuthNavigation = props => (
  <Navigation>
    <Navigation.Item variant="left" className="logo">
      <Link to="/">
        <Logo />
      </Link>
    </Navigation.Item>
    <Navigation.Item variant="right" className="hide-phone">
      <Link className="nav-link" to={props.to}>
        {props.linkText}
      </Link>
    </Navigation.Item>
    <Navigation.Item variant="right">
      <AppLinkButton className="secondary navigation" to={props.to}>
        {props.buttonText}
      </AppLinkButton>
    </Navigation.Item>
  </Navigation>
);
