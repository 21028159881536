import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Row, Column, BodyMediumText, Stepper, ButtonWithoutStyle, Icon } from 'playful-ui';
import { withStemiI18n } from 'stemi-i18n';

import { removeFromCart, addToCart } from 'modules/analytics';
import { addOrderItem as addOrderItemThunk, getDeSanitizedCart } from '../redux';

import { CartItemContainer } from './CartItemContainer';

class CartItemComponent extends React.Component {
  handleItemRemove = () => {
    const { onItemRemove, currency, cartItemId, removeFromCart, cartItem } = this.props;
    onItemRemove(cartItemId);
    removeFromCart({ currency, orderItem: cartItem });
  };

  handleValueChanged = newValue => {
    const {
      onUpdateQuantity,
      currency,
      cartItemId,
      cartItem,
      addToCart,
      removeFromCart,
    } = this.props;
    const newCartItem = { ...cartItem, quantity: newValue };
    if (newValue > cartItem.quantity) {
      addToCart({
        currency,
        orderItem: { ...newCartItem, quantity: newValue - cartItem.quantity },
      });
    } else if (newValue < cartItem.quantity) {
      removeFromCart({
        currency,
        orderItem: { ...newCartItem, quantity: cartItem.quantity - newValue },
      });
    }
    onUpdateQuantity(cartItemId, newValue);
  };

  render() {
    const { currency, currencySymbol, cartItem, cart, cartItemId } = this.props;
    const product = cart[cartItemId];
    const { amount } = product.price[currency];
    const fullAmount = amount + product.options.price[currency].amount;
    const fullAmountString = `${currencySymbol}${fullAmount}`;
    return (
      <CartItemContainer className="v-center">
        <Column flex={689}>
          <Row>
            <img src={product.options.displayImage} alt="product" />
            <Column>
              <BodyMediumText className="product">
                {`${cartItem.productName} (${product.options.displayName})`}
              </BodyMediumText>
            </Column>
          </Row>
        </Column>
        <Column flex={178}>
          <Stepper
            className="small"
            min={1}
            initialValue={cartItem.quantity}
            onValueChanged={this.handleValueChanged}
          />
        </Column>
        <Column flex={83}>
          <BodyMediumText className="price">{fullAmountString}</BodyMediumText>
        </Column>
        <Column flex={58} className="right v-center">
          <ButtonWithoutStyle onClick={this.handleItemRemove} className="x">
            <Icon name="Close" />
          </ButtonWithoutStyle>
        </Column>
      </CartItemContainer>
    );
  }
}

const mapStateToProps = state => ({
  cart: getDeSanitizedCart(state),
});

const mapDispatchToProps = { removeFromCart, addToCart, addOrderItem: addOrderItemThunk };

export const CartItem = compose(
  withStemiI18n,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CartItemComponent);
