import React from 'react';
import { H3, BodyMediumText } from 'playful-ui';

import { LegalContainer } from '../components/LegalContainer';
import { termsAndConditions } from './content/TermsAndConditions';

export class TermsAndConditions extends React.Component {
  static route = '/legal/terms-and-conditions';
  static title = termsAndConditions.pageTitle;

  render() {
    return (
      <LegalContainer>
        <H3>{termsAndConditions.general}</H3>
        <BodyMediumText>{termsAndConditions.generalParagraph}</BodyMediumText>
        <H3>{termsAndConditions.acceptance}</H3>
        <BodyMediumText>
          {termsAndConditions.acceptanceParagraph}
        </BodyMediumText>
        <H3>{termsAndConditions.changes}</H3>
        <BodyMediumText>{termsAndConditions.changesParagraph}</BodyMediumText>
        <H3>{termsAndConditions.registration}</H3>
        <BodyMediumText>
          {termsAndConditions.registrationParagraph}
        </BodyMediumText>
        <H3>{termsAndConditions.use}</H3>
        {termsAndConditions.useParagraph}
        <H3>{termsAndConditions.content}</H3>
        <BodyMediumText>{termsAndConditions.contentParagraph}</BodyMediumText>
        <H3>{termsAndConditions.copyright}</H3>
        {termsAndConditions.copyrightParagraph}
        <H3>{termsAndConditions.warranties}</H3>
        {termsAndConditions.warrantiesParagraph}
        <H3>{termsAndConditions.liability}</H3>
        <BodyMediumText>{termsAndConditions.liabilityParagraph}</BodyMediumText>
        <H3>{termsAndConditions.usage}</H3>
        <BodyMediumText>{termsAndConditions.usageParagraph}</BodyMediumText>
        <H3>{termsAndConditions.products}</H3>
        <BodyMediumText>{termsAndConditions.productParagraph}</BodyMediumText>
        <H3>{termsAndConditions.payment}</H3>
        {termsAndConditions.paymentParagraph}
        <H3>{termsAndConditions.delivery}</H3>
        <BodyMediumText>{termsAndConditions.deliveryParagraph}</BodyMediumText>
        <H3>{termsAndConditions.reservation}</H3>
        <BodyMediumText>
          {termsAndConditions.reservationParagraph}
        </BodyMediumText>
        <H3>{termsAndConditions.complaints}</H3>
        <BodyMediumText>
          {termsAndConditions.complaintsParagraph}
        </BodyMediumText>
        <H3>{termsAndConditions.liabilityOf}</H3>
        <BodyMediumText>
          {termsAndConditions.liabilityOfParagraph}
        </BodyMediumText>
        <H3>{termsAndConditions.generalProvision}</H3>
        <BodyMediumText>
          {termsAndConditions.generalProvisionParagraph}
        </BodyMediumText>
      </LegalContainer>
    );
  }
}
