import React from 'react';
import { H2, Row, VideoModal, Column, H3, BodyLargeText } from 'playful-ui';
import { withStemiI18n } from 'stemi-i18n';

import { TestimonialsContainer } from '../components/TestimonialsContainer';

import { pageName, title, getTestimonials } from './content/Testimonials';

class Component extends React.Component {
  static pageName = pageName;

  render() {
    const { i18n, language } = this.props;
    const testimonials = getTestimonials(i18n);

    return (
      <TestimonialsContainer className="h-center">
        <H2>{title}</H2>
        {testimonials[language].map((t, i) => (
          <Row key={`Testimonial${i}`} className="h-center tablet-vertical">
            <VideoModal
              className="testimonial-video-modal"
              thumbnail={t.thumbnail}
              url={t.thumbnailVideo}
              controls
            />
            <Column>
              <H3>{t.fullName}</H3>
              <BodyLargeText>{t.about}</BodyLargeText>
            </Column>
          </Row>
        ))}
      </TestimonialsContainer>
    );
  }
}

export const Testimonials = withStemiI18n(Component);
