import styled from 'styled-components';

import { Caption } from '../Typography';
import { spacing, fonts, colors, media } from 'theme';
import { Row } from '../Row';

export const Container = styled(Row)`
  & > ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: ${spacing.BASE} 0;
    ${media.phone`
      height: 32px;
      align-items: center;
      cursor: pointer;
    `};
  }
  & > ul li {
    ${fonts.tabNavigation};
    color: ${colors.midnight};
    margin-left: ${spacing.LARGE};
    cursor: pointer;
    opacity: 0.7;
    will-change: opacity;
    transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1);
    ${media.phone`
      margin-right: ${spacing.TINY};
      opacity: 1;
    `};
  }
  & > ul li ${Caption} {
    color: ${colors.midnight};
  }
  & > ul li.active {
    opacity: 1;
    padding-bottom: ${spacing.TINY};
    border-bottom: 3px solid ${colors.midnight};
  }
  & > ul li:hover {
    opacity: 1;
  }
  & > ul li:first-child {
    margin-left: 0;
  }

  & .hide-phone {
    ${media.phone`
      display: none;
    `};
  }
  & .show-phone {
    display: none;
    ${media.phone`
      display: flex;
    `};
  }
`;
