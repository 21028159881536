export const content = i18n => ({
  contactInformation: i18n.t`Contact information`,
  keepMeUpToDate: i18n.t`Keep me up to date on news and exclusive offers`,
  shipping: i18n.t`Shipping address`,
  firstName: i18n.t`First name`,
  lastName: i18n.t`Last name`,
  companyName: i18n.t`Company (optional)`,
  address: i18n.t`Address`,
  apartment: i18n.t`Apartment, suite, etc. (optional)`,
  city: i18n.t`City`,
  country: i18n.t`Country`,
  postalCode: i18n.t`Postal code`,
  phone: i18n.t`Phone`,
  returnToCart: i18n.t`Return to cart`,
  continueToShipping: i18n.t`Continue to shipping`,
});
