import React from 'react';
import classNames from 'classnames';

import { H5 } from 'playful-ui';

export class SubpageMenuItem extends React.Component {
  handleClick = () => {
    const { onClick, index } = this.props;
    onClick(index);
  };

  render() {
    const { index, activeIndex, subpageName } = this.props;
    return (
      <li
        key={index}
        onClick={this.handleClick}
        className={classNames({ active: index === activeIndex })}
      >
        <H5>{subpageName}</H5>
      </li>
    );
  }
}
