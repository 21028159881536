import React from 'react';
import { Trans } from '@lingui/react';

export const overview = {
  pageName: <Trans>Overview</Trans>,
  title: <Trans>Colorize Your Robot</Trans>,
  text: <Trans>Make your STEMI Hexapod unique - dress it up using one of these Color Skins. Because color makes it interesting and fun. Choose the color theme that you like the most and colorize your learning experience!</Trans>,
  card: {
    title: <Trans>Choose your favourite color theme</Trans>,
    options: [
      <Trans>If you are into ghosts maybe Invisible Walker is the skin for you</Trans>,
      <Trans>Bling-bling edition is called Banana Split</Trans>,
      <Trans>Dark Night skin is Batman’s personal favourite.</Trans>,
      <Trans>For those who love blue, there are Misty Mint and Diamond Blue.</Trans>,
      <Trans>Pink edition comes as Cherry Blossom</Trans>,
    ],
  }
};
