import React from 'react';
import { H3, BodyMediumText } from 'playful-ui';

import { LegalContainer } from '../components/LegalContainer';
import { personalDataProtection } from './content/PersonalDataProtection';

export class PersonalDataProtection extends React.Component {
  static route = '/legal/personal-data-protection';
  static title = personalDataProtection.pageTitle;

  render() {
    return (
      <LegalContainer>
        <H3>{personalDataProtection.general}</H3>
        <BodyMediumText>
          {personalDataProtection.generalParagraph}
        </BodyMediumText>
        <H3>{personalDataProtection.changes}</H3>
        <BodyMediumText>
          {personalDataProtection.changesParagraph}
        </BodyMediumText>
        <H3>{personalDataProtection.personal}</H3>
        <BodyMediumText>
          {personalDataProtection.personalParagraph}
        </BodyMediumText>
        <H3>{personalDataProtection.submission}</H3>
        <BodyMediumText>
          {personalDataProtection.submissionParagraph}
        </BodyMediumText>
        <H3>{personalDataProtection.additions}</H3>
        <BodyMediumText>
          {personalDataProtection.additionsParagraph}
        </BodyMediumText>
        <H3>{personalDataProtection.data}</H3>
        <BodyMediumText>{personalDataProtection.dataParagraph}</BodyMediumText>
        <H3>{personalDataProtection.confidentiality}</H3>
        <BodyMediumText>
          {personalDataProtection.confidentialityParagraph}
        </BodyMediumText>
        <H3>{personalDataProtection.otherData}</H3>
        <BodyMediumText>
          {personalDataProtection.otherDataParagraph}
        </BodyMediumText>
        <H3>{personalDataProtection.otherProvision}</H3>
        <BodyMediumText>
          {personalDataProtection.otherProvisionParagraph}
        </BodyMediumText>
      </LegalContainer>
    );
  }
}
