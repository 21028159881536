import styled from 'styled-components';

import {
  BodyLargeText,
  Button,
  Column,
  H1,
  H2,
  ProductHighlight,
  Section,
  media,
  spacing,
} from 'playful-ui';
import { AppLinkButton } from 'components';

export const HomeContainer = styled.div`
  margin-bottom: 90px;
  ${media.tablet`
    margin-bottom: 80px;
  `};

  &
    .hero-background
    ${H1},
    &
    .hero-background
    ${BodyLargeText},
    &
    ${Section}
    > ${H1},
    &
    ${Section}
    > ${H2},
    &
    ${Section}
    > ${BodyLargeText} {
    max-width: 940px;
    text-align: center;
    ${media.tablet`
      max-width: 624px; 
    `};
    ${media.phone`
      max-width: 320px;
    `};
  }
  &
    > .hero-background
    ${AppLinkButton},
    &
    > ${Column}
    > ${Column}
    > a
    > ${Button} {
    position: relative;
    top: ${spacing.BASE_PLUS};
    margin: 0;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 16px 0px;
    ${media.tablet`
      top: ${spacing.BASE};
    `};
  }
  & > ${Column} ${AppLinkButton} {
    position: relative;
    top: ${spacing.BASE};
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 16px 0px;
  }
  & > .hero-background ${Column} > img {
    max-height: 523px;
    max-width: 1146px;
    ${media.tablet`
      max-height: 324px;
      max-width: 624px;
    `};
    ${media.phone`
      max-height: 173px;
      max-width: 335px;
    `};
  }
  & > .hero-background ${Column} > ${ProductHighlight} {
    position: relative;
    top: -50px;
    ${media.tablet`
      top: unset;
    `};
    ${media.phone`
      top: unset;
    `};
  }
  & ${Section}#have-fun {
    margin-top: ${spacing.BASE_PLUS};
  }
  & ${Section}.standard-spacings {
    margin-top: 120px;
    margin-bottom: 80px;
    ${media.tablet`
      margin-top: 80px;
      margin-bottom: ${spacing.LARGE};
    `};
  }
`;
