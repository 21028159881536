import styled from 'styled-components';
import { media } from 'playful-ui';

export const QuestCardsContainer = styled.div`
  display: flex;
  width: 1176px;
  flex-wrap: wrap;
  margin: 50px 0 81px 0;
  ${media.tablet`
    width: 746px;
    `}
  ${media.phone`
    width: 375px;
    `}
  & > a > .quest-card {
    margin: 15px;
    ${media.tablet`
    width: 335px;
    margin: 19px;
    `}
    ${media.phone`
    width: 335px;
    margin: 20px;
    `}
  }
`;
