import React from 'react';
import styled from 'styled-components';

import { Rule, media } from 'playful-ui';

const RulerContainer = styled.div`
  display: flex;
  & ${Rule} {
    display: none;
    ${media.phone`
      display: initial;
    `};
  }
  & ${Rule}.vertical {
    display: initial;
    ${media.phone`
      display: none;
    `};
  }
`;

export const Ruler = ({ style }) => (
  <RulerContainer style={style}>
    <Rule />
    <Rule className="vertical" />
  </RulerContainer>
);
