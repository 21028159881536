import styled from 'styled-components';

import { colors } from 'theme';

export const ToggleContainer = styled.div`
  width: 36px;
  height: 20px;
  position: relative;
`;

export const ToggleLabel = styled.label`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 20px;
  border-radius: 9.5px;
  background-color: ${colors.mischka};
  transition: background-color 0.3s ease;

  &::after {
    content: '';
    cursor: pointer;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: ${colors.white};
    transition: left 0.3s ease;
  }
`;

export const ToggleInput = styled.input`
  width: 10px;
  height: 10px;
  visibility: hidden;

  &:checked ~ ${ToggleLabel} {
    background-color: ${colors.darkblue};
  }

  &:checked ~ ${ToggleLabel}:after {
    left: 18px;
  }

  &:disabled ~ ${ToggleLabel} {
    opacity: 0.3;
    cursor: auto;
  }
`;
