import styled from 'styled-components';

import { colors, spacing, zIndex } from 'theme';

import { Column } from '../Column';
import { H4, BodyXSmallText, Caption } from '../Typography';

export const Container = styled(Column)`
  flex: none;
  width: 362px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out, background-color 0.3s ease-out;
  transition-delay: 0.1s;
  transform: translateY(0);
  overflow: hidden;
  
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
    background-color: ${colors.applyOpacity(colors.mischka, 0.2)};
    transform: translateY(-2px);
  }

  & > ${Column}.image-column {
    position: relative;
    min-height: 182px;
    padding: ${spacing.BASE};
    padding-bottom: ${spacing.SMALL};
    box-sizing: border-box;
  }

  & > ${Column}.image-column > img {
    position: absolute;
    z-index: ${zIndex.ELEMENTS_CONTAINER};
    top: 0;
    left: 0;
    width: 362px;
    height: 182px;
   }

  & > ${Column}.image-column > ${Caption}{
    color: ${colors.white};
  }

  & > ${Column}.image-column > ${BodyXSmallText} {
    color: ${colors.white};
    font-weight: 600;
  }

  & > ${Column}.image-column > ${H4} {
    display: none;
  }

  & > ${Column}.info-column {
    padding: ${spacing.BASE};
    box-sizing: border-box;
    min-height: 177px;
    background-color: ${colors.white};
    border-radius: 4px;
  }

  & > ${Column}.info-column > ${Column}{
    flex: none;
  }

  &.small ${Column}.info-column,
  &.small ${Column}.image-column > ${Caption} {
    display: none;
  }

  &.small ${Column}.image-column > ${H4} {
    display: block;
    color: ${colors.white};
    max-width: 170px;
  }
`;

