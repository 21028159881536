import { once, remove, set } from 'stemi-firebase';

import { roundToTwo, sanitizeCart } from 'modules/cart';
import { purchaseSuccess } from 'modules/analytics';

import { braintreeConfig } from '../braintree';

const { merchants, backendUrl } = braintreeConfig;

export const UPDATE_CHECKOUT_REQUEST = 'stemi.web.UPDATE_CHECKOUT_REQUEST';
export const UPDATE_CHECKOUT_SUCCESS = 'stemi.web.UPDATE_CHECKOUT_SUCCESS';
export const UPDATE_CHECKOUT_FAILURE = 'stemi.web.UPDATE_CHECKOUT_FAILURE';

export const CREATE_CHARGE_REQUEST = 'stemi.web.CREATE_CHARGE_REQUEST';
export const CREATE_CHARGE_SUCCESS = 'stemi.web.CREATE_CHARGE_SUCCESS';
export const CREATE_CHARGE_FAILURE = 'stemi.web.CREATE_CHARGE_FAILURE';

export const GET_CLIENT_TOKEN_REQUEST = 'stemi.web.GET_CLIENT_TOKEN_REQUEST';
export const GET_CLIENT_TOKEN_SUCCESS = 'stemi.web.GET_CLIENT_TOKEN_SUCCESS';
export const GET_CLIENT_TOKEN_FAILURE = 'stemi.web.GET_CLIENT_TOKEN_FAILURE';

export const GET_IPINFO_REQUEST = 'stemi.web.GET_IPINFO_REQUEST';
export const GET_IPINFO_SUCCESS = 'stemi.web.GET_IPINFO_SUCCESS';
export const GET_IPINFO_FAILURE = 'stemi.web.GET_IPINFO_FAILURE';

export const APPROVE_DISCOUNT_REQUEST = 'stemi.web.APPROVE_DISCOUNT_REQUEST';
export const APPROVE_DISCOUNT_SUCCESS = 'stemi.web.APPROVE_DISCOUNT_SUCCESS';
export const APPROVE_DISCOUNT_FAILURE = 'stemi.web.APPROVE_DISCOUNT_FAILURE';

export const updateCheckout = checkoutInfo => ({
  type: UPDATE_CHECKOUT_REQUEST,
  payload: checkoutInfo,
});

export const updateCheckoutSuccess = (checkoutInfo, meta) => ({
  type: UPDATE_CHECKOUT_SUCCESS,
  payload: checkoutInfo,
  meta,
});

export const updateCheckoutFailure = (checkoutInfo, meta) => ({
  type: UPDATE_CHECKOUT_FAILURE,
  payload: checkoutInfo,
  meta,
  error: true,
});

export const createCharge = chargeObject => ({
  type: CREATE_CHARGE_REQUEST,
  payload: { chargeObject },
});

export const createChargeSuccess = (meta, payload) => ({
  type: CREATE_CHARGE_SUCCESS,
  meta,
  payload,
});

export const createChargeFailure = (meta, error) => ({
  type: CREATE_CHARGE_FAILURE,
  meta,
  error,
});

function cartToLineItems(cart, currency) {
  return Object.keys(cart).map(k => {
    const cartItem = cart[k];
    const { amount } = cartItem.price[currency];
    const unitAmount = amount + cartItem.options.price[currency].amount;
    return {
      kind: 'debit', // sale,
      name: `${cartItem.productName} (${cartItem.options.displayName})`,
      quantity: cartItem.quantity,
      totalAmount: roundToTwo(unitAmount * cartItem.quantity),
      unitAmount: roundToTwo(unitAmount),
    };
  });
}

function chargeObject(paymentMethodNonce, cart, checkout, currency) {
  const { customerEmail, shipping, billing } = checkout;
  const lineItems = cartToLineItems(cart, currency);
  return {
    currency,
    chargeBreakdown: checkout.cartBreakdown,
    // https://developers.braintreepayments.com/reference/request/transaction/sale/node#billing.country_code_alpha2
    braintreeSaleObject: {
      paymentMethodNonce,
      merchantAccountId: merchants[currency],
      amount: checkout.cartBreakdown.totalAmount,
      lineItems,
      billing: {
        firstName: billing.firstName,
        lastName: billing.lastName,
        company: billing.company,
        streetAddress: billing.address,
        extendedAddress: billing.apartment,
        postalCode: billing.postal,
        locality: billing.city,
        region: billing.region,
        countryCodeAlpha2: billing.countryCodeAlpha2,
      },
      shipping: {
        firstName: shipping.firstName,
        lastName: shipping.lastName,
        company: shipping.company,
        streetAddress: shipping.address,
        extendedAddress: shipping.apartment,
        postalCode: shipping.postal,
        locality: shipping.city,
        region: shipping.region,
        countryCodeAlpha2: shipping.countryCodeAlpha2,
      },
      customer: {
        firstName: shipping.firstName,
        lastName: shipping.lastName,
        email: customerEmail,
        phone: shipping.phone,
      },
      creditCard: {
        cardholderName: `${billing.firstName} ${billing.lastName}`,
      },
      options: {
        submitForSettlement: true,
      },
    },
  };
}

export const charge = (
  paymentMethodNonce,
  cart,
  checkout,
  currency,
) => dispatch => {
  const co = chargeObject(paymentMethodNonce, cart, checkout, currency);
  dispatch(createCharge(co));
  const { totalAmount, shippingAmount, vatAmount } = checkout.cartBreakdown;
  return fetch('/api/chargeWithBraintree', {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(co),
  }).then(response => {
    return response.json().then(data => {
      if (data.success) {
        dispatch(
          createChargeSuccess(
            { paymentMethodNonce, cart, checkout, currency },
            data,
          ),
        );
        dispatch(
          purchaseSuccess({
            currency,
            cart,
            totalAmount,
            transaction: {
              id: data.transaction.id,
              totalAmount,
              vatAmount,
              shippingAmount,
            },
          }),
        );
        removeCheckoutAttempt(checkout.customerEmail)(dispatch);
        return Promise.resolve(data);
      } else {
        dispatch(
          createChargeFailure(
            { paymentMethodNonce, cart, checkout, currency },
            data,
          ),
        );
        return Promise.reject(data);
      }
    });
  });
};

export const getIpinfo = meta => ({
  type: GET_IPINFO_REQUEST,
  meta,
});

export const getIpinfoSuccess = (payload, meta) => ({
  type: GET_IPINFO_SUCCESS,
  payload,
  meta,
});

export const getIpinfoFailure = (error, meta) => ({
  type: GET_IPINFO_FAILURE,
  meta,
  error,
});

export const detectIpinfo = () => dispatch => {
  dispatch(getIpinfo());
  return fetch('https://ipinfo.io/json')
    .then(response => {
      return response.json().then(data => {
        if (data.error) {
          dispatch(getIpinfoFailure(data.error));
        } else {
          dispatch(getIpinfoSuccess(data));
        }
        return Promise.resolve(data);
      });
    })
    .catch(e => console.error(e));
};

export const getClientToken = (payload, meta) => ({
  type: GET_CLIENT_TOKEN_REQUEST,
  payload,
  meta,
});

export const getClientTokenSuccess = (payload, meta) => ({
  type: GET_CLIENT_TOKEN_SUCCESS,
  payload,
  meta,
});

export const getClientTokenFailure = (error, meta) => ({
  type: GET_CLIENT_TOKEN_FAILURE,
  error,
  meta,
});

export const generateClientToken = merchantAccountId => dispatch => {
  dispatch(getClientToken());
  return fetch('/api/generateClientToken/', {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify({ merchantAccountId }),
  }).then(response =>
    response.json().then(
      data => {
        dispatch(getClientTokenSuccess(data));
        return Promise.resolve(data);
      },
      reject => {
        dispatch(getClientTokenFailure(reject));
        return Promise.reject(reject);
      },
    ),
  );
};

export function addCheckoutAttempt(checkout, cart, route) {
  if (checkout.customerEmail) {
    return set('checkoutAttempt', {
      id: btoa(checkout.customerEmail),
      email: checkout.customerEmail,
      checkout,
      cart: sanitizeCart(cart),
      route,
      time: new Date().toString(),
    });
  }
}

export function removeCheckoutAttempt(email) {
  return remove('checkoutAttempt', { id: btoa(email) });
}

export const approveDiscountRequest = (payload, meta) => ({
  type: APPROVE_DISCOUNT_REQUEST,
  payload,
  meta,
});

export const approveDiscountSuccess = (payload, meta) => ({
  type: APPROVE_DISCOUNT_SUCCESS,
  payload,
  meta,
});

export const approveDiscountFailure = (payload, meta) => ({
  type: APPROVE_DISCOUNT_FAILURE,
  error: true,
  payload,
  meta,
});

export const approveDiscount = (discountCode, customerEmail) => dispatch => {
  dispatch(approveDiscountRequest({ discountCode, customerEmail }));
  return once('discountCodes', { id: discountCode })(dispatch).then(
    discountObject => {
      if (discountObject === null) {
        return Promise.reject({ message: 'No such code.' });
      }
      if (discountObject.expired) {
        return Promise.reject({ message: 'Discount code expired.' });
      }
      return Promise.resolve(discountObject);
    },
  );
};

export const getDiscount = discountCode => {
  return once('discountCodes', { id: discountCode });
};
