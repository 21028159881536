import styled from 'styled-components';
import { spacing, colors } from 'theme';

import { Row } from '../Row';
import { Column } from '../Column';
import { Caption } from '../Typography';

export const QuoteHighlight = styled(Row)`
  & > img {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
  }

  & > ${Column} {
    margin-left: ${spacing.BASE};
    max-width: 635px;
  }

  & > ${Column} h4::after,
  & > ${Column} h3::after {
    content: ' „';
    color: ${colors.tomato};
  }
  & > ${Column} h4::before,
  & > ${Column} h3::before {
    content: '” ';
    color: ${colors.tomato};
  }

  & > ${Column} > ${Caption}:first-of-type {
    margin-top: ${spacing.SMALL};
  }
  & > ${Column} > ${Caption} {
    color: ${colors.midnight};
    opacity: 0.5;
  }
`;
