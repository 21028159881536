import React from 'react';

import styled from 'styled-components';
import { spacing, colors } from 'theme';

import { Icon } from '../../Icon';
import { FooterCategoryText } from '../../Typography';
import { InputButton } from './InputButton';
import { InputText } from './InputText';

const StyledForm = styled.form`
  margin-top: ${spacing.SMALL};
  display: flex;
`;

export class FooterEmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      subscribeSuccessed: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ emailAddress: event.target.value });
  }

  handleSubmit(event) {
    if (this.state.emailAddress) {
      this.props.onEmailButtonClicked(this.state.emailAddress);
      this.setState({ subscribeSuccessed: !!this.state.emailAddress });
    }
    event.preventDefault();
  }

  render() {
    const { placeholderText, subscribeSuccessMessage } = this.props;
    const { subscribeSuccessed } = this.state;
    return (
      <div>
        <StyledForm onSubmit={this.handleSubmit} name="subscribe" method="post">
          <input type="hidden" name="form-name" value="subscribe" />
          <InputText
            type="email"
            name="email"
            value={this.state.emailAddress}
            onChange={this.handleChange}
            placeholder={placeholderText}
          />
          <InputButton type="submit">
            <Icon name="ChevronRight" />
          </InputButton>
        </StyledForm>
        {subscribeSuccessed && (
          <FooterCategoryText
            style={{
              color: colors.tomato,
              fontWeight: 'bold',
              marginTop: spacing.SMALL,
              opacity: 0.8,
            }}
          >
            {subscribeSuccessMessage}
          </FooterCategoryText>
        )}
      </div>
    );
  }
}
