import React from 'react';

import styled from 'styled-components';

import { colors } from 'theme';

const Container = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
`;

const Bar = styled.div`
  flex: ${props => props.percent};
  background-color: ${colors.black};
  opacity: 0.15;
  mix-blend-mode: multiply;
  border-radius: 0 0 ${props => ((props.percent === 100) ? '4' : '0')}px 4px;
  height: 4px;
`;

const Filler = styled.div`
  flex: ${props => 100 - props.percent};
  height: 4px;
  opacity: 0;
`;

export const ProgressBar = ({ percent }) => (
  <Container>
    <Bar percent={percent} />
    <Filler percent={percent} />
  </Container>
);

