import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from '@lingui/react';

import { withStemiI18n } from 'stemi-i18n';
import { H4, Column, Stepper, Row, Navigation, Icon, Caption, BodyMediumText } from 'playful-ui';

import { AppLinkButton } from 'components';
import { productDetails, addToCart } from 'modules/analytics';

import { CartPage } from 'modules/cart/pages/CartPage';
import { addOrderItem } from 'modules/cart/redux/actions';

import { OptionsSelector } from './OptionsSelector';
import { BuyingOptionsContainer } from './BuyingOptionsContainer';

//TODO: there is a problem with options on tablet, 'space-between',
class BuyingOptionsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      selectedOption: 0,
    };
  }

  handleAddToCart = () => {
    const {
      currency,
      productName,
      options,
      price,
      shippingModels,
      addOrderItem,
      addToCart,
    } = this.props;
    const { quantity, selectedOption } = this.state;
    const orderItem = {
      productName,
      price,
      quantity,
      options: options[selectedOption],
      shippingModels,
    };
    addToCart({ currency, orderItem });
    addOrderItem(orderItem);
  };

  handleQuantityChanged = quantity => {
    this.setState({ quantity });
  };

  handleOptionChange = selectedOption => {
    const { currency, productName, options, price, productDetails } = this.props;
    productDetails({
      currency,
      product: {
        productName,
        price,
        options: options[selectedOption],
      },
    });
    this.setState({ selectedOption });
  };

  calculatePrice = sumPrice => {
    const { price, currency, options } = this.props;
    const { quantity, selectedOption } = this.state;
    if (sumPrice) {
      return (price[currency].amount + options[selectedOption].price[currency].amount) * quantity;
    }
    return price[currency].amount + options[selectedOption].price[currency].amount;
  };

  render() {
    const {
      currencySymbol,
      productName,
      buyingOptionsText,
      optionsCaption,
      options,
      handleBackClick,
    } = this.props;
    return (
      <BuyingOptionsContainer className="h-center">
        <Navigation className="subpage" style={{ width: '100%' }}>
          <Navigation.Item variant="left">
            <Row className="v-center product" onClick={handleBackClick}>
              <Icon name="Left" />
              <Caption className="product">{productName}</Caption>
            </Row>
          </Navigation.Item>
        </Navigation>

        <Row className="buying-options">
          <img
            alt="product"
            className="product-image"
            src={options[this.state.selectedOption].displayImage}
          />
          <Column className="buying-options-selection">
            <Caption>
              <Trans>Buying options</Trans>
            </Caption>
            <H4>{buyingOptionsText}</H4>
            <Caption className="margin-bottom-top">
              <Trans>Quantity</Trans>
            </Caption>
            <Stepper
              min={1}
              step={1}
              initialValue={1}
              onValueChanged={this.handleQuantityChanged}
            />
            <Caption className="margin-top">{optionsCaption}</Caption>
            <OptionsSelector
              options={options}
              activeIndex={0}
              onValueChanged={this.handleOptionChange}
            />
            <Column className="buy h-center v-center">
              <BodyMediumText>
                <Trans>Unit Price</Trans>
                {`: ${currencySymbol}${this.calculatePrice(false).toFixed(2)} + `}
                <Trans>Tax</Trans>
              </BodyMediumText>
              <BodyMediumText>
                <Trans>Total</Trans>
                {`: ${currencySymbol}${this.calculatePrice(true).toFixed(2)} + `}
                <Trans>Tax</Trans>
              </BodyMediumText>
              <AppLinkButton
                className="extra-large"
                onClick={this.handleAddToCart}
                to={CartPage.route}
              >
                <Icon name="ShoppingCart" />
                <Trans>ADD TO CART</Trans>
              </AppLinkButton>
            </Column>
          </Column>
        </Row>
      </BuyingOptionsContainer>
    );
  }
}

const mapDispatchToProps = {
  addOrderItem,
  productDetails,
  addToCart,
};

export const BuyingOptions = compose(
  withStemiI18n,
  connect(
    null,
    mapDispatchToProps
  )
)(BuyingOptionsComponent);
