import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import ScrollOrchestratorContext from './ScrollOrchestratorContext';

export function withScroll(Component) {
  class WithScroll extends React.Component {
    render() {
      return (
        <ScrollOrchestratorContext.Consumer>
          {scrollFunctions => <Component {...this.props} {...scrollFunctions} />}
        </ScrollOrchestratorContext.Consumer>
      );
    }
  }

  WithScroll.displayName = `WithScroll(${getDisplayName(Component)})`;
  hoistNonReactStatics(WithScroll, Component);

  return WithScroll;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
