module.exports = [{
      plugin: require('/vercel/path0/web/node_modules/gatsby-plugin-sentry/gatsby-browser'),
      options: {"plugins":[],"dsn":"https://d7f1b20cf4174a7eb2dc59ddc8b1f79d@sentry.io/1511935","enabled":true},
    },{
      plugin: require('/vercel/path0/web/node_modules/gatsby-plugin-sentry/gatsby-browser'),
      options: {"plugins":[],"dsn":"https://d7f1b20cf4174a7eb2dc59ddc8b1f79d@sentry.io/1511935","enabled":true},
    },{
      plugin: require('/vercel/path0/web/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-63953557-1","head":true,"optimizeId":"GTM-KP7TTFK"},
    },{
      plugin: require('/vercel/path0/web/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser'),
      options: {"plugins":[],"pixelId":"1163963920415555"},
    },{
      plugin: require('/vercel/path0/web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
