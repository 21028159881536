import React from 'react';
import { Trans } from '@lingui/react';

export const content = Object.freeze({
  productName: 'Servo Motor Ten Pack',
  buyButtonText: <Trans>BUY NOW</Trans>,
  tagline: <Trans>Gotta Catch 'Em All</Trans>,
  price: {
    USD: {
      amount: 45.00,
      previousAmount: null,
    },
    GBP: {
      amount: 35.00,
      previousAmount: null,
    },
    EUR: {
      amount: 40.00,
      previousAmount: null,
    },
    HRK: {
      amount: 299.0,
      previousAmount: null,
    },
  },
});
