import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { withStemiI18n } from 'stemi-i18n';
import { BodySmallText, Row, Option, spacing, media } from 'playful-ui';

import { OptionWithIndex } from './OptionWithIndex';

const StyledRow = styled(Row)`
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${spacing.BASE};

  & ${Option} {
    margin-top: ${spacing.SMALL};
    ${media.phone`
      min-width: 160px;
      max-width: 160px;
    `};
  };
  & ${Option} > div:first-child {
    margin-bottom: ${spacing.TINY};
  };
  & ${Option} > ${BodySmallText}:last-child {
    font-weight: 600;
  };
`;

class OptionsSelectorComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: this.props.activeIndex,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeIndex !== this.state.activeIndex && typeof this.props.onValueChanged !== 'undefined') {
      this.props.onValueChanged(this.state.activeIndex);
    }
  }

  handleOptionChange = i => this.setState({ activeIndex: i });

  render() {
    const { currency, currencySymbol, options } = this.props;
    return (
      <StyledRow>
        {options.map((option, i) => {
          const { icon, displayName, price } = option;
          return (
            <OptionWithIndex
              key={i}
              index={i}
              className={classNames({ active: i === this.state.activeIndex })}
              onClick={this.handleOptionChange}
            >
              {icon}
              <BodySmallText>{displayName}</BodySmallText>
              {
                typeof price !== 'undefined' &&
                <BodySmallText>
                  {`+ ${currencySymbol}${price[currency].amount.toFixed(2)}`}
                </BodySmallText>
              }
            </OptionWithIndex>
          );
        })}
      </StyledRow>
    );
  }
}

export const OptionsSelector = withStemiI18n(OptionsSelectorComponent);
