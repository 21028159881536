import React from 'react';
import { Trans } from '@lingui/react';

export const overview = {
  pageName: <Trans>Overview</Trans>,
  title: <Trans>Give movement to your inventions</Trans>,
  text: <Trans>Do you want to upgrade your Hexapod with an additional feature? Or maybe you are working on your DIY project? In all these cases STEMI Servo Motor is a great choice for you.</Trans>,
  title2: <Trans>We've got you covered</Trans>,
  text2: <Trans>It is reliable and well built. Together with the servo, in the packaging you will get a servo horn / arm that ensures a tight fit and a good hold to any surface. Also, all the nuts and bolts are included so you won’t have to search for them in the local store, you’ll be able to start creating right away with STEMI Servo motor!</Trans>,
};
