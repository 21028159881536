import styled from 'styled-components';
import {Section, H2, BodyLargeText, Column, Card, spacing, media} from 'playful-ui';


export const OverviewContainer = styled.div`
  margin-bottom: 120px;
  ${media.tablet`
    margin-bottom: 80px;
  `};

  & ${Section} > ${H2}, & ${Section} > ${BodyLargeText} {
    text-align: center;
  }
  & div.small-card > ${Column} > ${BodyLargeText} {
    margin-top: ${spacing.SMALL};
    margin-bottom: ${spacing.BASE};
    ${media.tablet`
      margin-top: ${spacing.TINY};
      margin-bottom: ${spacing.SMALL};
    `};
  }
  
  & div.margin-top {
    margin-top: 80px;
    ${media.phone`
      margin-top: ${spacing.LARGE};
    `};
  }
`;
