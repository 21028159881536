import React from 'react';
import { Trans } from '@lingui/react';
import kozuljThumbnail from '../assets/kozulj-thumbnail.jpg';
import mestrovicThumbnail from '../assets/mestrovic-thumbnail.jpg';
import rimacThumbnail from '../assets/rimac-thumbnail.jpg';
import khanThumbnail from '../assets/khan-thumbnail.jpg';

export const pageName = <Trans>Reviews</Trans>;

export const title = (
  <Trans>Walks like a spider controlled by a smartphone</Trans>
);

export function getTestimonials(i18n) {
  return {
    hr: [
      {
        thumbnail: rimacThumbnail,
        thumbnailVideo: i18n.t`https://www.youtube.com/watch?v=sJncodL09pM`,
        fullName: <Trans>Mate Rimac</Trans>,
        about: <Trans>CEO, Founder at Rimac Automobili</Trans>
      },
      {
        thumbnail: khanThumbnail,
        thumbnailVideo: i18n.t`https://www.youtube.com/watch?v=2vlefq0LBiY`,
        fullName: <Trans>Khan Flicks</Trans>,
        about: <Trans>Product reviewer / animator</Trans>
      },
      {
        thumbnail: kozuljThumbnail,
        thumbnailVideo: i18n.t`https://www.youtube.com/watch?v=gMnwFIYdlQI`,
        fullName: <Trans>Gordan Kožulj</Trans>,
        about: <Trans>Former World, Europe and USA swimming champion</Trans>
      },
      {
        thumbnail: mestrovicThumbnail,
        thumbnailVideo:
          'https://www.youtube.com/watch?time_continue=120&v=DbJ3nPTDgWM',
        fullName: <Trans>Matej Meštrović</Trans>,
        about: <Trans>Piano player and music compositor</Trans>
      }
    ],
    en: [
      {
        thumbnail: rimacThumbnail,
        thumbnailVideo: i18n.t`https://www.youtube.com/watch?v=sJncodL09pM`,
        fullName: <Trans>Mate Rimac</Trans>,
        about: <Trans>CEO, Founder at Rimac Automobili</Trans>
      },
      {
        thumbnail: khanThumbnail,
        thumbnailVideo: i18n.t`https://www.youtube.com/watch?v=2vlefq0LBiY`,
        fullName: <Trans>Khan Flicks</Trans>,
        about: <Trans>Product reviewer / animator</Trans>
      },
      {
        thumbnail: kozuljThumbnail,
        thumbnailVideo: i18n.t`https://www.youtube.com/watch?v=gMnwFIYdlQI`,
        fullName: <Trans>Gordan Kožulj</Trans>,
        about: <Trans>Former World, Europe and USA swimming champion</Trans>
      },
    ]
  };
}
