import React from 'react';
import { Link } from 'gatsby';
import { Trans } from '@lingui/react';
import { withCookies } from 'react-cookie';

import { BodyXSmallText, Button, Icon, NotificationCard } from 'playful-ui';

import { PersonalDataProtection } from 'modules/legal';

const hideCookieNotification = 'Hide cookie notification';

class Notification extends React.Component {
  handleOnClick = () => {
    const { cookies } = this.props;
    cookies.set(hideCookieNotification, true, { path: '/' });
  }

  render() {
    const { cookies } = this.props;
    if (cookies.get(hideCookieNotification)) {
      return null;
    }
    return (
      <NotificationCard>
        <Icon name="Cookie" />
        <BodyXSmallText>
          <Trans>
            We are using cookies to provide the best experience of our site. By continuing to use
            the site, you are agreeing to our <Link to={PersonalDataProtection.route}>use of cookies</Link>.
          </Trans>
        </BodyXSmallText>
        <Button onClick={this.handleOnClick} className="promoted">GOT IT!</Button>
      </NotificationCard>
    );
  }
}

export const CookieNotification = withCookies(Notification);
