import styled from 'styled-components';

import { fonts, colors } from 'theme';

export const InputButton = styled.button`
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  height: 40px;
  width: 56px;
  background-color: ${colors.darkblue};
  border: none;
  color: ${colors.white};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  ${fonts.button} &:hover {
    background: ${colors.persianblue};
  }
  &:focus {
    outline: 0;
  }
  & svg path[fill] {
    fill: currentColor;
  }
`;
