import React from 'react';
import _ from 'lodash';

import { CheckboxContainer, CheckboxInput, CheckboxLabel } from './Checkbox';
import { ToggleContainer, ToggleInput, ToggleLabel } from './Toggle';
import { RadioContainer, RadioInput, RadioLabel } from './Radio';

function createInputButton(type, Container, Input, Label) {
  return class InputButton extends React.Component {
    constructor(props) {
      super(props);
      const { id } = props;
      this.id = id || _.uniqueId('InputButton_');
    }

    render() {
      const { className, ...inputProps } = this.props;
      return (
        <Container className={className}>
          <Input type={type} id={this.id} {...inputProps} />
          <Label htmlFor={this.id} />
        </Container>
      );
    }
  };
}

export const Checkbox = createInputButton(
  'checkbox',
  CheckboxContainer,
  CheckboxInput,
  CheckboxLabel
);

export const Toggle = createInputButton('checkbox', ToggleContainer, ToggleInput, ToggleLabel);

export const Radio = createInputButton('radio', RadioContainer, RadioInput, RadioLabel);
