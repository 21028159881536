import { createSelector } from 'reselect';
import _ from 'lodash';

import { addTraits } from 'stemi-firebase';

import { products } from 'modules/products';

export const getCart = state => addTraits(state.cart);

export const isCartEmpty = createSelector(
  getCart,
  cart => {
    if (cart.isValid() || cart.isLoading()) {
      return _.keys(cart.getData()).length === 0;
    }
    return true;
  }
);

export function calcTotalAmount(cart, currency, shippingModel, vatRate, discountPercentage) {
  if (_.keys(cart).length === 0) {
    return {
      totalAmount: 0,
      cartAmount: 0,
      shippingAmount: null,
      vatAmount: null,
      discountAmount: 0,
    };
  }
  const cartItems = Object.values(cart);
  const cartAmount = cartItems.reduce(
    (total, { quantity, options, price }) =>
      total + (price[currency].amount + options.price[currency].amount) * quantity,
    0
  );
  const shippingAmount = shippingModel ? parseFloat(shippingModel.price[currency].amount) : null;
  const discountAmount = parseInt(discountPercentage)
    ? (cartAmount * discountPercentage) / 100.0
    : 0;
  const vatAmount =
    typeof shippingAmount === 'number' &&
    typeof parseFloat(vatRate) === 'number' &&
    !isNaN(parseFloat(vatRate))
      ? (cartAmount + shippingAmount - discountAmount) * vatRate
      : null;
  return {
    totalAmount: roundToTwo(cartAmount + shippingAmount + vatAmount - discountAmount),
    cartAmount: roundToTwo(cartAmount),
    shippingAmount: roundToTwo(shippingAmount),
    vatAmount: roundToTwo(vatAmount),
    discountAmount: roundToTwo(discountAmount),
  };
}

export function roundToTwo(number) {
  if (typeof parseInt(number) === 'number') {
    return Math.round(number * 1e2) / 1e2;
  }
  return null;
}

export function sanitizeCart(cart) {
  return _.reduce(
    cart,
    (accumulator, value, key) => ({
      ...accumulator,
      [key]: {
        productName: value.productName,
        option: value.options.displayName,
        quantity: value.quantity,
      },
    }),
    {}
  );
}

export function deSanitizeCart(cart) {
  return _.reduce(
    cart,
    (accumulator, value, key) => {
      const product = products[value.productName];
      if (!product) {
        // no product with that name found
        return accumulator;
      }
      const options = product.buyingOptions.options.find(
        option => option.displayName === value.option
      );
      if (!options || value.quantity < 1) {
        // no option with that name found or quantity < 1
        return accumulator;
      }
      return {
        ...accumulator,
        [key]: {
          productName: value.productName,
          price: product.price,
          quantity: value.quantity,
          options,
          shippingModels: product.shippingModels,
        },
      };
    },
    {}
  );
}

export const getDeSanitizedCart = createSelector(
  getCart,
  cart => {
    const deSanitized = deSanitizeCart(cart.getData());
    return { ...cart, ...deSanitized, getData: () => deSanitized };
  }
);
