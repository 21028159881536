import React from 'react';
import { Trans } from '@lingui/react';

export const shippingModels = {
  default: {
    HR: {
      price: {
        USD: { amount: 0.0 },
        GBP: { amount: 0.0 },
        EUR: { amount: 0.0 },
        HRK: { amount: 0.0 },
      },
      description: <Trans>Free Shipping with local post - up to 1 week delivery</Trans>,
    },
    EU: {
      price: {
        USD: { amount: 5.7 },
        GBP: { amount: 4.5 },
        EUR: { amount: 5.0 },
        HRK: { amount: 37.0 },
      },
      description: <Trans>Shipping with local post - up to 1 to 2 weeks delivery</Trans>,
    },
    NA: {
      price: {
        USD: { amount: 8.0 },
        GBP: { amount: 6.0 },
        EUR: { amount: 7.0 },
        HRK: { amount: 52.0 },
      },
      description: <Trans>Shipping with local post - up to 2 to 3 weeks delivery</Trans>,
    },
    default: {
      price: {
        USD: { amount: 10.0 },
        GBP: { amount: 8.0 },
        EUR: { amount: 9.0 },
        HRK: { amount: 66.0 },
      },
      description: <Trans>Shipping with local post - up to 2 to 3 weeks delivery</Trans>,
    },
  },
};
