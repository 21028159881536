import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import SlickSlider from 'react-slick';
import { colors, spacing, media } from 'theme';

// eslint-disable-next-line
const SliderGlobalStyle = createGlobalStyle`
  @import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
  @import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');
`;

const StyledSlider = styled(SlickSlider)`
  .card-image-slider-slick-dots {
    bottom: ${spacing.XLARGE};
  }
  .card-image-slider-slick-dots li {
    width: ${spacing.SMALL};
    height: 0;
    margin: 0;
  }
  .card-image-slider-slick-dots li button {
    padding: 0;
    height: 0;
  }
  .card-image-slider-slick-dots li button::before,
  .card-image-slider-slick-dots li.slick-active button:before {
    color: ${colors.white};
    font-size: ${spacing.TINY};
    line-height: ${spacing.TINY};
    width: ${spacing.TINY};
    height: ${spacing.TINY};
  }
  ${media.phone`
    .card-image-slider-slick-dots {
      bottom: ${spacing.BASE};
    }
  `};
`;

const defaultSliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  dotsClass: 'card-image-slider-slick-dots slick-dots',
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
};

export const Slider = ({ children, ...props }) => (
  <React.Fragment>
    <SliderGlobalStyle />
    <StyledSlider {...defaultSliderSettings} {...props}>
      {children}
    </StyledSlider>
  </React.Fragment>
);
