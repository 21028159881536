import styled from 'styled-components';
import {
  H2,
  Column,
  spacing,
  media
} from 'playful-ui';

export const FaqContainer = styled(Column)`
  margin: 0 ${spacing.BASE_PLUS};
  margin-top: 80px;
  margin-bottom: 120px;
  ${media.tablet`
    margin-top: 48px;
  `}
  ${media.phone`
    margin: 0 ${spacing.TINY};
    margin-top: 24px;
    margin-bottom: 80px;
  `}
  & ${H2} {
    margin-bottom: 80px;
    max-width: 754px;
    text-align: center;
    ${media.tablet`
      max-width: 624px;
      margin-bottom: 48px;
    `}
    ${media.phone`
      max-width: 335px;
      margin-bottom: 40px;
    `}
  }
`;
