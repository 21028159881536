const errorMessages = {
  'auth/wrong-password': 'The password is invalid.',
  'password/empty': "Password can't be empty",
  'password/empty-confirm': "Password can't be empty",
  'password/passwords-dont-match': "Password doesn't match",
};

export function changePasswordErrors(errorList, errorArray) {
  if (errorArray) {
    const errors = errorArray.filter(error => errorList.includes(error.code));
    if (errors.length > 0) {
      return errors.map(error => errorMessages[error.code])[0];
    }
  }

  return '';
}
