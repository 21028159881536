import styled from 'styled-components';

import { media, spacing } from 'theme';
import { Row } from '../Row';
import { Column } from '../Column';
import { H3, BodyLargeText } from '../Typography';
import { Button, LinkButton } from '../Button';
import { ContentContainer } from '../ContentBanner';

export const ContentCardContainer = styled(Row)`
  flex-direction: ${props => (props.right ? 'row-reverse' : 'row')};
  max-width: ${spacing.sum(spacing.XLARGE, 362, 528)};
  margin: 0 ${spacing.XLARGE};

  ${media.tablet`
    max-width: 768px;
    margin: 0 ${spacing.BASE};
  `};
  ${media.phone`
    width: 100%;
    margin: 0 ${spacing.BASE};
    flex-direction: column;
    & ${Column} {
      max-width: 295px;
      align-self: center;
    }
  `};
  & ${Column} {
    justify-content: center;
  }
  & ${H3} {
    margin-bottom: ${spacing.SMALL};
  }
  & ${BodyLargeText} {
    margin-bottom: ${spacing.BASE};
  }
  & ${Button},
  & ${LinkButton},
  & ${Button},
  & ${LinkButton} {
    width: fit-content;
  }

  & img, & video, & .media {
    position: relative;
    width: 528px;
    height: 528px;
    object-fit: cover;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    ${media.tablet`
      width: 369px;
      height: 369px;
    `};
    ${media.phone`
      width: 335px;
      height: 335px;
    `};
    }
  }

  & > img,
  & > video,
  & > .media,
  & > ${ContentContainer} {
    margin-left: ${props => (props.right ? spacing.XLARGE : 0)};
    margin-right: ${props => (props.right ? 0 : spacing.XLARGE)};
    ${media.tablet`
      margin-left: ${props => (props.right ? spacing.BASE : 0)};
      margin-right: ${props => (props.right ? 0 : spacing.BASE)};
    `};
    ${media.phone`
      display: inline-flex;
      margin-bottom: ${spacing.BASE_PLUS};
      margin-left: 0;
      margin-right: 0;
      align-self: ${props => (props.right ? 'flex-end' : 'flex-start')};
    `};
  }
`;
