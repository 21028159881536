import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';

import {
  Caption,
  Column,
  H2,
  HeroBackground,
  MediaOverlay,
  QuestCard,
  Section,
  SectionHeader,
  VideoModal,
} from 'playful-ui';

import { Img, resolveRemoteResource } from 'modules/remote-resources';

import { getAllQuests, onceQuests } from '../../redux';
import { HeroContainer } from './HeroContainer';
import { QuestCardsContainer } from './QuestCardsContainer';

export class Component extends React.Component {
  componentDidMount() {
    this.props.onceQuests();
  }

  renderQuestCards(questIds, quests) {
    if (quests.isValid()) {
      return questIds.map(id => {
        const {
          cardImage,
          name,
          lessons,
          resolvedURLs,
          prerequisites,
        } = quests[id];
        const questImage = (
          <Img alt={'Quest'} src={cardImage} resolvedURLs={resolvedURLs} />
        );
        return (
          <Link to={`/lesson/${lessons[0]}`} key={`QuestLink${id}`}>
            <QuestCard
              className="quest-card"
              title={name}
              questImage={questImage}
              prerequisites={prerequisites}
            />
          </Link>
        );
      });
    }
    return null;
  }

  render() {
    const { adventure, quests } = this.props;
    const { tagline, preview, resolvedURLs } = adventure;
    return (
      <React.Fragment>
        <HeroBackground>
          <HeroContainer>
            <Caption>TECH ADVENTURE</Caption>
            <H2>{tagline}</H2>
            <VideoModal
              className="media"
              key={1}
              thumbnailVideo={resolveRemoteResource(resolvedURLs, preview)}
              url="https://www.youtube.com/watch?v=WNWO5yK1j0M"
              playing
              controls
              mediaOverlay={MediaOverlay}
              overlayIndex={1}
            />
          </HeroContainer>
        </HeroBackground>
        <Section className="textual">
          <SectionHeader index={1} />
          <H2 style={{ textAlign: 'center' }}>Start with a Quest</H2>
        </Section>
        <Column className="h-center">
          <QuestCardsContainer>
            {this.renderQuestCards(adventure.quests, quests)}
          </QuestCardsContainer>
        </Column>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  quests: getAllQuests(state),
});

const mapDispatchToProps = {
  onceQuests,
};

export const Adventure = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
