import React from 'react';
import { compose } from 'recompose';
import { Trans } from '@lingui/react';
import { connect } from 'react-redux';

import {
  Button,
  BodyLargeText,
  Column,
  ContentCard,
  HeroBackground,
  H1,
  H2,
  H3,
  Modal,
  SectionHeader,
  Video,
} from 'playful-ui';
import { withStemiI18n } from 'stemi-i18n';

import { AppLinkButton } from 'components';
import {
  onceAdventures,
  onceQuests,
  onceLessons,
  getAllAdventures,
  getAllLessons,
  getAllQuests,
} from '../redux';

import imgHeroStemiLab from './assets/img-hero-stemi-lab.png';
import imgBuildHexapodRobot from './assets/img-build-hexapod-robot.png';
import imgOldStemi from './assets/img-old-stemi.png';

class StemiLab extends React.Component {
  static route = 'lab';
  static title = 'Stemi Lab';

  constructor(props, context) {
    super(props, context);
    this.state = {
      showVideoModal: false,
    };
  }

  componentDidMount() {
    this.props.onceAdventures();
    this.props.onceQuests();
    this.props.onceLessons();
  }

  handleOpenVideoModal = () => {
    this.setState({ showVideoModal: true });
  };

  handleCloseVideoModal = () => {
    this.setState({ showVideoModal: false });
  };

  render() {
    return (
      <React.Fragment>
        <HeroBackground>
          <img
            alt="Stemi Lab"
            src={imgHeroStemiLab}
            onClick={this.handleOpenVideoModal}
          />
          <Column className="left v-center">
            <H1>
              <Trans>Welcome to the STEMI Lab</Trans>
            </H1>
            <BodyLargeText>
              <Trans>
                STEMI Lab is here to help you access everything you need to
                explore, experiment and build your own amazing stuff—easily.
              </Trans>
            </BodyLargeText>
          </Column>
          <Modal
            isOpen={this.state.showVideoModal}
            onRequestClose={this.handleCloseVideoModal}
          >
            <Video
              url="https://youtu.be/WNWO5yK1j0M"
              playing
              controls
              width="100%"
              height="100%"
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
              }}
            />
          </Modal>
        </HeroBackground>
        <Column style={{ marginBottom: '20px' }} className="h-center">
          <SectionHeader index={1} />
          <Column className="v-center h-center">
            <H2 className="centered">
              <Trans>Choose your tech adventure</Trans>
            </H2>
          </Column>
        </Column>
        <ContentCard>
          <img src={imgBuildHexapodRobot} alt="Build a Hexapod robot" />
          <Column>
            <H3>
              <Trans>Build a Hexapod Robot</Trans>
            </H3>
            <BodyLargeText>
              <Trans>
                By building your STEMI Hexapod robot you'll learn the basics of
                3D modeling, electronics, mobile app coding and Arduino
                programming. The STEMI Hexapod software, as well as all the
                blueprints and 3D models, is Open Source, meaning everyone is
                able to freely modify all of its aspects.
              </Trans>
            </BodyLargeText>
            <AppLinkButton to="/adventure/hexapod2018">START NOW</AppLinkButton>
          </Column>
        </ContentCard>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  adventures: getAllAdventures(state),
  quests: getAllQuests(state),
  lessons: getAllLessons(state),
});

const mapDispatchToProps = {
  onceAdventures,
  onceLessons,
  onceQuests,
};

export const StemiLabPage = compose(
  withStemiI18n,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(StemiLab);
