import _ from 'lodash';

const stemiWebServers = {
  develop: 'stemi-web-develop.firebaseapp.com',
  staging: 'stemi-web-staging.firebaseapp.com',
  production: 'stemi-web-production.firebaseapp.com',
};

export const STEMI_WEB_SERVER = _.get(
  stemiWebServers,
  process.env.GATSBY_FIREBASE_ENV,
  stemiWebServers.production,
);

export const IS_BROWSER =
  typeof window !== 'undefined' && 'HTMLElement' in window;

export const STEMI_FORUM = 'https://learn.stemi.education/forum';
