import styled, { css } from 'styled-components';

import { media, spacing, fonts, colors } from 'theme';
import { BodySmallText, BodyMediumText } from '../Typography';

export const NavItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: ${spacing.LARGE};

  @media (max-width: ${media.sizes.tabletLandscape}px) {
    margin-right: ${spacing.SMALL};
  }

  &.hide-desktop {
    @media (min-width: ${media.sizes.tabletLandscape}px) {
      display: none;
    }
  }
  &.hide-tablet-landscape {
    @media (max-width: ${media.sizes.tabletLandscape}px) and (min-width: ${media.sizes.tablet}px) {
      display: none;
    }
  }
  &.hide-tablet {
    @media (max-width: ${media.sizes.tablet}px) and (min-width: ${media.sizes.phone}px) {
      display: none;
    }
  }
  &.hide-phone {
    @media (max-width: ${media.sizes.phone}px) {
      display: none;
    }
  }

  &.logo {
    margin-right: 56px;
    @media (max-width: ${media.sizes.tabletLandscape}px) {
      margin-right: 40px;
    }
  }

  & a.nav-link {
    ${css`
      ${fonts.navigationLink};
    `};
    text-decoration: none;
    color: ${colors.darkblue};
    transition: color 0.2s cubic-bezier(0, 0, 0.2, 1);
  }
  & .nav-link:hover {
    color: ${colors.steel};
  }

  & .subpage-caption {
    color: ${colors.midnight};
    opacity: 0.7;
    text-decoration: none;
    transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1);
    cursor: pointer;
  }
  & .subpage-caption:hover {
    opacity: 1;
  }
  &.active .subpage-caption {
    opacity: 1;
  }
  & svg.subpage-icon path[fill] {
    fill: ${colors.midnight};
  }
  &.active {
    border-top: 4px solid transparent;
    border-bottom: 4px solid ${colors.midnight};
  }
  & ${BodySmallText}.product-name {
    opacity: 0.5;
  }
  & ${BodyMediumText}.reduced-price {
    font-weight: 600;
    line-height: 1.67;
  }
  & ${BodySmallText}.full-price {
    opacity: 0.5;
    text-decoration: line-through;
    line-height: 1.67;
  }
  & a {
    display: inline-flex;
  }
`;

NavItem.defaultProps = {
  isNavigationItem: true,
};
