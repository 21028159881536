import React from 'react';
import {
  ProductHighlight,
  BodySmallText,
  BodyLargeText,
  Section,
  SectionHeader,
  H2,
  H3,
  SkillsHighlight,
  SmallCard,
  Column,
  QuoteHighlight,
  H4,
  Caption,
  Button,
} from 'playful-ui';
import { Features } from 'modules/homepage';

import { adventure } from './content/Adventure';

import { LatestArticles } from '../../homepage/components/LatestArticles';
import { AdventureContainer } from '../components/AdventureContainer';

export class Adventure extends React.Component {
  static pageName = adventure.pageName;

  handleSubpageChange = e => {
    this.props.handleSubpageChange(e.target.name);
  }

  render() {
    return (
      <AdventureContainer>
        <Section>
          <SmallCard className="small-card">
            <img alt="card" src={adventure.build.img} />
            <Column>
              <H3>{adventure.build.title}</H3>
              <BodyLargeText>{adventure.build.text}</BodyLargeText>
            </Column>
          </SmallCard>
        </Section>

        <Section className="large">
          <Column className="h-center">
            <QuoteHighlight className="v-center">
              <img src={adventure.rimac.img} alt="rimac" />
              <Column>
                <H4>{adventure.rimac.quote}</H4>
                <Caption>{adventure.rimac.name}</Caption>
                <Caption>{adventure.rimac.position}</Caption>
              </Column>
            </QuoteHighlight>
            <Button className="review" name="2" onClick={this.handleSubpageChange} >
              {adventure.btnWatchReview}
            </Button>
          </Column>
        </Section>

        <Section className="large">
          <SmallCard className="small-card" right>
            <img alt="card" src={adventure.design.img} />
            <Column>
              <H3>{adventure.design.title}</H3>
              <BodyLargeText>{adventure.design.text}</BodyLargeText>
            </Column>
          </SmallCard>
        </Section>

        <Section className="large">
          <Column className="h-center">
            <QuoteHighlight className="v-center">
              <img src={adventure.khan.img} alt="Khan flicks" />
              <Column>
                <H4>{adventure.khan.quote}</H4>
                <Caption>{adventure.khan.name}</Caption>
                <Caption>{adventure.khan.position}</Caption>
              </Column>
            </QuoteHighlight>
            <Button className="review" name="2" onClick={this.handleSubpageChange}>
              {adventure.btnWatchReview}
            </Button>
          </Column>
        </Section>

        <Section className="large">
          <SmallCard className="small-card">
            <img alt="card" src={adventure.coding.img} />
            <Column>
              <H3>{adventure.coding.title}</H3>
              <BodyLargeText>{adventure.coding.text}</BodyLargeText>
            </Column>
          </SmallCard>
        </Section>

        <Section className="large textual">
          <SectionHeader index={1} />
          <H2>{adventure.onlineMentors.title}</H2>
          <BodyLargeText>{adventure.onlineMentors.text}</BodyLargeText>
        </Section>

        <Section className="large">
          <ProductHighlight>
            {adventure.productHighlights.map((highlight, i) =>
              <ProductHighlight.Item key={`item${i}`}>
                <highlight.icon />
                <BodySmallText>{highlight.text}</BodySmallText>
              </ProductHighlight.Item>
            )}
          </ProductHighlight>
        </Section>

        <Section className="large">
          <Column className="h-center features">
            <Features />
            <ProductHighlight>
            {adventure.featuresHighlight.map((highlight, i) =>
              <ProductHighlight.Item key={`item${i}`}>
                <highlight.icon />
                <BodySmallText>{highlight.text}</BodySmallText>
              </ProductHighlight.Item>
            )}
          </ProductHighlight>
            <Button className="review" name="1" onClick={this.handleSubpageChange}>
              {adventure.btnFindOutMore}
            </Button>
          </Column>
        </Section>

        <Section className="large textual">
          <SectionHeader index={1} />
          <H2>{adventure.skillsHighlight.title}</H2>
        </Section>
        <SkillsHighlight {...adventure.skillsHighlight} />

        <Section className="large textual">
          <SectionHeader index={3} />
          <H2>{adventure.latestArticles}</H2>
        </Section>
        <LatestArticles />
      </AdventureContainer>
    );
  }
}
