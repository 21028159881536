import React from 'react';

import styled from 'styled-components';

import { media } from 'theme';

import { Row } from '../Row';
import { ContentCardContainer } from './ContentCardContainer';

const ContentCardRow = styled(Row)`
  padding-top: 60px;
  padding-bottom: 60px;

  ${media.phone`
    padding-top: 44px;
    padding-bottom: 44px;
  `};
`;

export const ContentCard = ({ children, right }) => (
  <ContentCardRow className="h-center">
    <ContentCardContainer right={right}>{children}</ContentCardContainer>
  </ContentCardRow>
);
