import React from 'react';
import styled from 'styled-components';
import { spacing, media, fonts, colors } from 'theme';

import { FooterSectionText } from '../Typography';
import { Column } from '../Column';

const FooterSectionColumn = styled(Column)`
  max-width: 165px;
  margin-top: ${spacing.LARGE};
  ${media.tabletLandscape`
    max-width: 145px;
  `};
  ${media.tablet`
    min-width: 214px;
  `};
  ${media.phone`
    min-width: 151px;
  `};
  & a {
    ${fonts.footerCategory};
    margin-bottom: ${spacing.TINY};
    color: ${colors.midnight};
    opacity: 0.8;
    display: inline-block;
    &:hover {
      color: ${colors.tomato};
    }
  }
  & a:last-child {
    margin-bottom: 0;
  }
`;

function createCategory(category, i) {
  const { linkElement, ...props } = category;
  const LinkElement = linkElement || 'a';
  return (
    <LinkElement key={`${i}linkElement`} {...props}>
      {category.text}
    </LinkElement>
  );
}

export const FooterSection = styled(({ footerSection, className }) => (
  <FooterSectionColumn className={className}>
    <FooterSectionText style={{ marginBottom: spacing.SMALL }}>
      {footerSection.sectionTitle}
    </FooterSectionText>
    {footerSection.categories.map((category, index) => createCategory(category, index))}
  </FooterSectionColumn>
))``;
