import React from 'react';
import { Section, SectionHeader, H2 } from 'playful-ui';

import { FeaturesContainer } from '../components/FeaturesContainer';
import { TechnicalSpecifications } from '../components/TechnicalSpecifications';
import { pageName, technicalSpecifications } from './content/Features';

export class Features extends React.Component {
  static pageName = pageName;

  render() {
    return (
      <FeaturesContainer>
        <Section className="textual large" style={{marginTop: 0}}>
          <SectionHeader index={1} />
          <H2>{technicalSpecifications.title}</H2>
        </Section>
        <TechnicalSpecifications
          specs={technicalSpecifications.specifications}
        />
      </FeaturesContainer>
    );
  }
}
