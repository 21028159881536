import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

import { media } from 'theme';

const VideoContainer = styled.div`
  display: flex;
  position: relative;
  padding-top: 56.25%;
  width: 960px;
  ${media.tablet`
    width: 680px;
  `}
  ${media.phone`
    width: 330px;
  `}
`;

export const Video = props => (
  <VideoContainer>
    <ReactPlayer {...props} />
  </VideoContainer>
);
