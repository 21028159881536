import styled from 'styled-components';
import { 
  ButtonWithoutStyle,
  BodySmallText,
  BodyMediumText,
  Row,
  Icon,
  media,
  colors,
  spacing,
  zIndex,
} from 'playful-ui';

export const CheckoutHeaderContainer = styled.div`
  z-index: ${zIndex.NOTIFICATION_AREA};

  & div.home {
    justify-content: center;
    padding: 1.5em 0;
    width: 100%;
    display: none;

    ${media.tablet`
      display: flex;
    `};
  };
  & div.home img {
    height: 54px;
  };

  & ${ButtonWithoutStyle} {
    width: 100%;
    display: none;
    justify-content: center;
    padding: ${spacing.SMALL} 1em;
    background-color: ${colors.whitesmoke};
    border-top: 1px solid ${colors.mischka};
    border-bottom: 1px solid ${colors.mischka};
    ${media.tablet`
      display: flex;
    `};
  };

  & ${ButtonWithoutStyle} ${Icon}, & ${ButtonWithoutStyle} ${BodySmallText} {
    margin-right: ${spacing.SMALL};
    ${media.phone`
      margin-right: ${spacing.TINY};
    `};
  };
  
  & ${ButtonWithoutStyle} > ${Row} {
    width: 532px;
    ${media.phone`
      width: 343px;
    `};
  };

  & ${BodyMediumText}.bold {
    font-weight: 600;
    margin-right: 0;
  }
`;
