import React from 'react';
import { Link } from 'gatsby';
import _ from 'lodash';
import { ExpandableEduTab, LessonName } from 'playful-ui';

import { NavTabColumn } from './NavTabColumn';

export class EduNavTab extends React.Component {
  render() {
    const {
      sections,
      sectionIds,
      lessons,
      lessonId,
      className,
      handleLessonClick,
    } = this.props;
    return (
      <NavTabColumn className={className}>
        {_.map(sectionIds, (sectionId, sectionIndex) => (
          <ExpandableEduTab
            sectionIndex={sectionIndex + 1}
            sectionName={sections[sectionId].sectionName}
            key={`section-${sectionId}`}
            active={sections[sectionId].lessons.indexOf(lessonId) !== -1}
          >
            {_.map(
              sections[sectionId].lessons,
              (sectionLessonId, lessonIndex) => (
                <LessonName
                  key={`lesson-${sectionLessonId}`}
                  active={sectionLessonId === lessonId}
                  onClick={handleLessonClick}
                >
                  <Link to={`/lesson/${sectionLessonId}`}>
                    {lessons[sectionLessonId].title}
                  </Link>
                </LessonName>
              ),
            )}
          </ExpandableEduTab>
        ))}
      </NavTabColumn>
    );
  }
}
