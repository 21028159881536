import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';

import {
  H2,
  BodyLargeText,
  Column,
  Button,
  BodySmallText,
  PulsatingLogo,
} from 'playful-ui';
import * as stemiFirebase from 'stemi-firebase';

import { getCurrentUser } from 'modules/auth';

import { ErrorContainer } from '../components/ErrorContainer';
import { ReactComponent as LogoSignImg } from '../assets/stemi-logo-sign.svg';

class VerifyYourEmail extends React.Component {
  static route = '/error/verify-your-email';

  static propTypes = {
    user: PropTypes.shape({
      emailVerified: PropTypes.bool.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired,
    sendVerificationEmail: PropTypes.func.isRequired,
  };

  state = { message: null };

  sendVerificationEmail = () => {
    const { sendEmailVerification } = this.props;
    this.setState({ message: null });
    sendEmailVerification().then(res => {
      const { error } = res;
      if (error) {
        this.setState({
          message: (
            <>
              <BodySmallText>Error sending verification email.</BodySmallText>
              <BodySmallText>{error.message}</BodySmallText>
            </>
          ),
        });
      } else {
        this.setState({
          message: <BodySmallText>Verification email sent.</BodySmallText>,
        });
      }
    });
  };

  render() {
    const { user } = this.props;
    if (!user.isValid()) {
      return (
        <ErrorContainer noNavigation>
          <LogoSignImg className="stemi-logo" />
          <Column className="h-center v-center">
            <PulsatingLogo />
          </Column>
        </ErrorContainer>
      );
    }
    const { email, emailVerified } = user;
    if (emailVerified) {
      navigate('/');
      return null;
    }
    const { message } = this.state;
    const title = email ? 'Please verify your email' : 'Please SignUp/SignIn';
    return (
      <ErrorContainer noNavigation>
        <LogoSignImg className="stemi-logo" />
        <Column className="h-center v-center">
          <H2>{title}</H2>
          {email && (
            <>
              <BodyLargeText>
                Email with verification link should have been sent to{' '}
                <b>{email}</b> during SignUp. Check your inbox or spam folder.
              </BodyLargeText>
              <BodyLargeText>
                Please click on the verification link in your email. Check your
                inbox or spam folder.
              </BodyLargeText>
              <BodyLargeText>
                In order to keep Stemi community healthy, we need to verify the
                email address <b>{email}</b> really belongs to you. When you
                verify your email, you will have access to Forum and all the
                lessons.
              </BodyLargeText>
              <Button onClick={this.sendVerificationEmail}>
                Send verification email again
              </Button>
            </>
          )}
          {message}
        </Column>
      </ErrorContainer>
    );
  }
}

const mapStateToProps = state => ({
  user: getCurrentUser(state),
});

const mapDispatchToProps = {
  sendEmailVerification: stemiFirebase.sendEmailVerification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyYourEmail);
