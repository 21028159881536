import uuidv4 from 'uuid/v4';

const localStorageKey = 'stemi-browser-id';

function browserId () {
  if (typeof (Storage) !== 'undefined') {
    let stemiCartId = localStorage.getItem(localStorageKey);
    if (stemiCartId) {
      return stemiCartId;
    } else {
      stemiCartId = uuidv4();
      localStorage.setItem(localStorageKey, stemiCartId);
      return stemiCartId;
    }
  } else {
    console.log('Sorry no Web storage support');
    return null;
  }
}

export default browserId();
