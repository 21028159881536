import React from 'react';
import { Trans } from '@lingui/react';

export const content = Object.freeze({
  productName: 'Servo Motor',
  buyButtonText: <Trans>BUY NOW</Trans>,
  tagline: <Trans>The one to move them all</Trans>,
  price: {
    USD: {
      amount: 6.10,
      previousAmount: null,
    },
    GBP: {
      amount: 4.70,
      previousAmount: null,
    },
    EUR: {
      amount: 5.30,
      previousAmount: null,
    },
    HRK: {
      amount: 39.00,
      previousAmount: null,
    },
  },
});
