import styled, { css } from 'styled-components';

import { fonts, colors, media } from 'theme';

export const InputText = styled.input`
  ${css`
    ${fonts.placeholder};
  `} &::placeholder {
    color: ${colors.darkblue};
  }
  flex-grow: 1;
  box-sizing: border-box;
  float: left;
  height: 40px;
  padding: 11px 16px;
  margin: 0;
  border-radius: 4px 0px 0px 4px;
  background-color: ${colors.white};
  border: solid 1px ${colors.darkblue};
  color: ${colors.darkblue};
  ${media.tablet`
    max-width: 311px;
  `};
  ${media.phone`
    max-width: 295px;
  `};
`;
