import React from 'react';
import { Parallax as Parallax3rd } from 'react-scroll-parallax';
import Media from 'react-media';

import { media } from 'theme';

export const Parallax = props => (
  <Media query={`(max-width: ${media.sizes.tablet}px)`}>
    {matches => (
      <Parallax3rd disabled={matches} {...props}>
        {props.children}
      </Parallax3rd>
    )}
  </Media>
);
