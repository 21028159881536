import React from 'react';
import { Trans } from '@lingui/react';

import { BodyMediumText } from 'playful-ui';

export const pageName = <Trans>FAQ</Trans>;

export const faqTitle = <Trans>Answers to our most common questions</Trans>;

export const faq = [
  {
    question: <Trans>What is the recommended age for the STEMI Hexapod?</Trans>,
    answer: (
      <BodyMediumText>
        <Trans>
          In order to get the maximum out of our product (robot and educational
          lessons) we recommend the starting age 12+.
        </Trans>
      </BodyMediumText>
    )
  },
  {
    question: (
      <Trans>What if my kids are younger than the recommended age?</Trans>
    ),
    answer: (
      <BodyMediumText>
        <Trans>
          They can still use our product but they might need your help. We have
          many parents who love our product and see it as a great opportunity to
          spend quality time with their kids.
        </Trans>
      </BodyMediumText>
    )
  },
  {
    question: (
      <Trans>Is adult supervision necessary with the STEMI Hexapod?</Trans>
    ),
    answer: (
      <BodyMediumText>
        <Trans>
          Some children between the age of 8 and 12 may require help from their
          parents while using our product.
        </Trans>
      </BodyMediumText>
    )
  },
  {
    question: <Trans>What can you learn with STEMI Hexapod?</Trans>,
    answer: (
      <BodyMediumText>
        <Trans>
          With STEMI Hexapod you get access to the most modern and state of the
          art STEM curriculum. You can learn 3D modeling while designing a robot
          on your computer, mobile app development for controlling the robot,
          microcontroller programming in Arduino and little bit of electronics.
        </Trans>
      </BodyMediumText>
    )
  },
  {
    question: (
      <Trans>I'm having trouble with STEMI Hexapod, what should I do?</Trans>
    ),
    answer: (
      <BodyMediumText>
        <Trans>
          No problem, we are here to help. Post a question to the STEMI forum
          and one of our team members will gladly help you solve the problem.
        </Trans>
      </BodyMediumText>
    )
  },
  {
    question: (
      <Trans>How long will my kid play and learn with the robot?</Trans>
    ),
    answer: (
      <BodyMediumText>
        <Trans>
          We know that every parent's worst fear is that a brand new toy will be
          forgotten on a shelf. Our curriculum has over 100 lessons, some of
          which are video, some textual and some combined, which equals more
          than 60 hours of user engagement. In addition, our curriculum is made
          to engage the user, enable him to assemble the robot and make
          everything necessary to control it, give the user assignments to solve
          and to encourage the user to create outside the STEMI curriculum on
          his own.
        </Trans>
      </BodyMediumText>
    )
  },
  {
    question: (
      <Trans>
        Do I get a mobile app for controlling the STEMI Hexapod robot?
      </Trans>
    ),
    answer: (
      <BodyMediumText>
        <Trans>
          You will make your own Android mobile app for controlling the robot.
          But if you are too impatient to start controlling and playing with the
          robot you can download Stemi Lab iOS and Android mobile application.
        </Trans>
      </BodyMediumText>
    )
  },
  {
    question: (
      <Trans>What language is spoken in STEMI educational lessons?</Trans>
    ),
    answer: (
      <BodyMediumText>
        <Trans>All of our educational content is currently in English.</Trans>
      </BodyMediumText>
    )
  },
  {
    question: <Trans>What can you do with the robot?</Trans>,
    answer: (
      <Trans>
        <BodyMediumText>You can do really cool stuff like:</BodyMediumText>
        <ul>
          <li key="1">
            <BodyMediumText>Navigate robot with your smartphone</BodyMediumText>
          </li>
          <li key="2">
            <BodyMediumText>Navigate robot with your voice</BodyMediumText>
          </li>
          <li key="3">
            <BodyMediumText>Change the way robot walks</BodyMediumText>
          </li>
          <li key="4">
            <BodyMediumText>
              Program it to dance to your favorite song
            </BodyMediumText>
          </li>
          <li key="5">
            <BodyMediumText>
              Express robot’s mood using 6 programmable RGB lights
            </BodyMediumText>
          </li>
          <li key="6">
            <BodyMediumText>Design and add custom skins</BodyMediumText>
          </li>
        </ul>
      </Trans>
    )
  },
  {
    question: (
      <Trans>Do I need internet connection to use the STEMI Hexapod?</Trans>
    ),
    answer: (
      <BodyMediumText>
        <Trans>
          To control and play with the robot you don’t need internet connection,
          but to access educational content you need to connect to the internet.
        </Trans>
      </BodyMediumText>
    )
  },
  {
    question: <Trans>Do I need to buy additional software?</Trans>,
    answer: (
      <BodyMediumText>
        <Trans>
          No, you’ll get everything you need and all the software we use is free
          of charge.
        </Trans>
      </BodyMediumText>
    )
  },
  {
    question: <Trans>What if I break or lose something?</Trans>,
    answer: (
      <BodyMediumText>
        <Trans>
          That’s perfectly fine, we added extra parts in the packaging if you
          lose or break something. We also give a warranty on our hardware for
          12 months, except servo motors which have 2 months warranty.
        </Trans>
      </BodyMediumText>
    )
  },
  {
    question: <Trans>Knowledge prerequisites?</Trans>,
    answer: (
      <BodyMediumText>
        <Trans>
          None. Literally. All of our educational content is designed for
          beginners with no experience whatsoever.
        </Trans>
      </BodyMediumText>
    )
  },
  {
    question: <Trans>Do I need to buy batteries to use the robot?</Trans>,
    answer: (
      <BodyMediumText>
        <Trans>
          No! We know how annoying that can be and that’s why you get
          rechargeable batteries.
        </Trans>
      </BodyMediumText>
    )
  },
  {
    question: <Trans>How long does the battery last?</Trans>,
    answer: (
      <BodyMediumText>
        <Trans>
          Between 1 and 2 hours, depending on how you use the robot. When the
          battery is empty, all you have to do is charge it again.
        </Trans>
      </BodyMediumText>
    )
  },
  {
    question: <Trans>Can I modify the robot in any way?</Trans>,
    answer: (
      <BodyMediumText>
        <Trans>
          Of course, we even encourage you to do so. All of our code and design
          are open sourced. You can change the robot’s design, modify the source
          code, build skins, add functionalities etc...
        </Trans>
      </BodyMediumText>
    )
  },
  {
    question: (
      <Trans>
        Can I build something else with this kit other than the hexapod robot?
      </Trans>
    ),
    answer: (
      <BodyMediumText>
        <Trans>
          Not at the moment with this kit. Focus of our product - the STEMI
          Hexapod - is not in constructing and programming different shapes, but
          rather going into depth on how to develop something like the Hexapod
          from scratch.
        </Trans>
      </BodyMediumText>
    )
  }
];
