import React from 'react';

import {
  BodyLargeText,
  BodySmallText,
  Button,
  Card,
  Column,
  H1,
  H2,
  HeroBackground,
  SectionHeader,
  ProductHighlight,
  Section,
  Ul,
} from 'playful-ui';

import { AppLinkButton } from 'components';
import { StemiLabPage } from 'modules/lab';

import { HomeContainer } from '../components/HomeContainer';
import { LatestArticles } from '../components/LatestArticles';
import { ReactComponent as Computer } from '../assets/ic-computer.svg';
import { ReactComponent as Bulb } from '../assets/ic-bulb.svg';
import { ReactComponent as Skills } from '../assets/ic-skills.svg';
import { ReactComponent as ShareConnect } from '../assets/ic-share-connect.svg';
import { Features } from '../components/Features';
import { text } from '../content/text';

import sliderImg1 from '../assets/slider-1.png';
import sliderImg2 from '../assets/slider-2.png';
import sliderImg3 from '../assets/slider-3.png';
import sliderImg4 from '../assets/slider-4.png';
import imgHeroHomepage from '../assets/header-main-school.png';

export class HomePage extends React.Component {
  static route = '/';

  addNewsletterSubscriber = email =>
    this.props.addNewsletterSubscriber({ email });

  render() {
    return (
      <HomeContainer>
        <HeroBackground className="hero-background">
          <Column className="h-center">
            <H1>{text.homeTitle}</H1>
            <BodyLargeText>{text.homeSubtext}</BodyLargeText>
            <AppLinkButton className="extra-large" to={StemiLabPage.route}>
              {text.exploreEducation}
            </AppLinkButton>
            <img alt="hero" className="hero-image" src={imgHeroHomepage} />
            <ProductHighlight>
              <ProductHighlight.Item>
                <Computer />
                <BodySmallText>{text.productHighlight1}</BodySmallText>
              </ProductHighlight.Item>
              <ProductHighlight.Item>
                <Bulb />
                <BodySmallText>{text.productHighlight2}</BodySmallText>
              </ProductHighlight.Item>
              <ProductHighlight.Item>
                <Skills />
                <BodySmallText>{text.productHighlight3}</BodySmallText>
              </ProductHighlight.Item>
              <ProductHighlight.Item>
                <ShareConnect />
                <BodySmallText>{text.productHighlight4}</BodySmallText>
              </ProductHighlight.Item>
            </ProductHighlight>
          </Column>
        </HeroBackground>
        <Column className="h-center">
          <Section id="have-fun" className="textual standard-spacings">
            <SectionHeader index={1} />
            <H2>{text.prepareYourStudents}</H2>
            <BodyLargeText>{text.weRadicallyReduce}</BodyLargeText>
          </Section>

          <Column className="h-center">
            <Card
              className="silver"
              images={[sliderImg1, sliderImg2, sliderImg3, sliderImg4]}
            >
              <Ul>
                <li>
                  <BodyLargeText>{text.becomePart}</BodyLargeText>
                </li>
                <li>
                  <BodyLargeText>{text.personalizedGuidance}</BodyLargeText>
                </li>
                <li>
                  <BodyLargeText>{text.getAll}</BodyLargeText>
                </li>
                <li>
                  <BodyLargeText>{text.everythingThat}</BodyLargeText>
                </li>
              </Ul>
            </Card>
          </Column>

          <Section id="bio-inspired" className="textual standard-spacings">
            <SectionHeader index={2} />
            <H2>{text.bioInspired}</H2>
            <BodyLargeText>{text.unrivaledSolution}</BodyLargeText>
          </Section>

          <Column className="h-center">
            <a href="https://robotics.milestonec.com" target="_blank">
              <Button className="extra-large">
                {text.moreAboutTheProgram}
              </Button>
            </a>
            <Features />
          </Column>

          <Section className="textual large">
            <SectionHeader index={3} />
            <H2>{text.latestArticles}</H2>
          </Section>
          <LatestArticles />
        </Column>
      </HomeContainer>
    );
  }
}
