import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from '@lingui/react';
import {
  Column,
  BodyMediumText,
  BodySmallText,
  HeroBackground,
  H2,
  LinkButton,
  Row,
  ProductHighlight,
  Slider,
} from 'playful-ui';

import {
  productDetails,
  viewFAQ,
  viewTestimonial,
  viewFeatures,
} from 'modules/analytics';

import { HeroContainer } from './HeroContainer';
import { SubpageNav } from './SubpageNav';
import { withStemiI18n } from 'stemi-i18n';

class ProductComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      SubpageNavOffsetTop: 0,
      renderProduct: true,
    };
    const { productName, price, currency, dispatch } = props;
    dispatch(productDetails({ currency, product: { productName, price } }));
  }

  componentDidMount() {
    if (document.getElementById('subpage-nav')) {
      this.setState({
        SubpageNavOffsetTop: document.getElementById('subpage-nav').offsetTop,
      });
    }
  }

  handleSubpageNavClick = i => {
    if (window.scrollY >= this.state.SubpageNavOffsetTop) {
      window.scrollTo(0, this.state.SubpageNavOffsetTop);
    }
    const { subpages, dispatch, productName } = { ...this.props };

    switch (subpages[i].pageName.props.id) {
      case 'Features':
        dispatch(viewFeatures({ product: productName }));
        break;
      case 'Reviews':
        dispatch(viewTestimonial({ product: productName }));
        break;
      case 'FAQ':
        dispatch(viewFAQ({ product: productName }));
        break;
      default:
        break;
    }

    this.setState({ activeIndex: i });
  };

  handleBuyNowClick = e => {
    this.setState({ renderProduct: !this.state.renderProduct });
    window.scrollTo(0, 0);
  };

  render() {
    const {
      currency,
      currencySymbol,
      subpages,
      buyButtonText,
      productName,
      tagline,
      price,
      hidePrice,
      productMedia,
      productHighlights,
      BuyingOptions,
    } = this.props;
    const { activeIndex } = this.state;
    const ActiveSubpage = subpages[activeIndex];
    const product = (
      <React.Fragment>
        <HeroBackground>
          <HeroContainer>
            <Row className="v-center">
              <Column id="product-title">
                <H2>{productName}</H2>
                <BodySmallText>{tagline}</BodySmallText>
              </Column>
              {!hidePrice && (
                <Row id="buy-now-row" className="v-center">
                  <Column className="right">
                    <BodyMediumText className="reduced-price">
                      {`${currencySymbol}${price[currency].amount.toFixed(
                        2,
                      )} + `}
                      <Trans>Tax</Trans>
                    </BodyMediumText>
                    {price[currency].previousAmount ? (
                      <BodySmallText className="full-price">
                        {`${currencySymbol}${price[
                          currency
                        ].previousAmount.toFixed(2)}`}
                      </BodySmallText>
                    ) : (
                      ''
                    )}
                  </Column>
                  <LinkButton onClick={this.handleBuyNowClick}>
                    {buyButtonText}
                  </LinkButton>
                </Row>
              )}
            </Row>
            <Slider className="image-slider" autoplaySpeed={7000}>
              {productMedia}
            </Slider>
            {productHighlights && (
              <ProductHighlight>
                {productHighlights.map((highlight, i) => (
                  <ProductHighlight.Item key={`item${i}`}>
                    <highlight.icon />
                    <BodySmallText>{highlight.text}</BodySmallText>
                  </ProductHighlight.Item>
                ))}
              </ProductHighlight>
            )}
          </HeroContainer>
        </HeroBackground>
        <SubpageNav
          id="subpage-nav"
          productName={productName}
          buyButtonText={buyButtonText}
          price={price}
          hidePrice={hidePrice}
          subpages={subpages}
          handleSubpageNavClick={this.handleSubpageNavClick}
          handleBuyNowClick={this.handleBuyNowClick}
          activeIndex={activeIndex}
        />
        <ActiveSubpage handleSubpageChange={this.handleSubpageNavClick} />
      </React.Fragment>
    );
    if (this.state.renderProduct) {
      return product;
    } else {
      return (
        <BuyingOptions
          productName={productName}
          handleBackClick={this.handleBuyNowClick}
        />
      );
    }
  }
}

const mapStateToProps = undefined;

const mapDispatchToProps = dispatch => ({ dispatch });

export const Product = compose(
  withStemiI18n,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ProductComponent);
