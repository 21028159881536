import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Logo } from 'theme/assets/img-stemi-logo.svg';

import Container from './Container';

export const PulsatingLogo = ({ width, height, className }) => (
  <Container width={width} height={height} className={className}>
    <Logo />
  </Container>
);

PulsatingLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

PulsatingLogo.defaultProps = {
  width: '200px',
  height: '61px',
};
