import styled from 'styled-components';

import { media } from 'theme';

import icCloseLarge from './assets/ic-close-large.svg';
import icCloseLargeOutline from './assets/ic-close-large-outline.svg';

export const CloseButton = styled.button`
  width: 48px;
  min-width: 48px;
  height: 48px;
  border: none;
  background: url(${icCloseLargeOutline}) no-repeat top left;
  background-size: 48px 48px;
  transition: all 0.3s ease-out;
  outline: 0;
  padding: 0;
  cursor: pointer;
  ${media.tablet`
    width: 40px;
    min-width: 40px;
    height: 40px;
    background-size: 40px 40px;
  `} &:hover {
    background-image: url(${icCloseLarge});
  }
`;
