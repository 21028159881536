import React from 'react';
import styled from 'styled-components';
import { spacing, colors, media } from 'theme';

import { H3, BodyMediumText } from '../Typography';
import { Column } from '../Column';
import { Row } from '../Row';

const SkillContainer = styled(Column)`
  flex: unset;
  margin-top: ${spacing.LARGE};
  ${media.phone`
    margin-top: ${spacing.BASE_PLUS};
  `}
  
  & ${H3} {
    margin-bottom: ${spacing.SMALL};
  }

  & ${Row} {
    height: 4px;
  }
  & ${Row}.before ${Column}:nth-of-type(1) {
    background-color: ${colors.turquoise};
  }
  & ${Row}.after ${Column}:nth-of-type(1) {
    background-color: ${colors.persianblue};
  }
  & ${Row}.after {
    margin-top: ${spacing.TINY};
  }
  
  & ${BodyMediumText} {
    margin-top: ${spacing.SMALL};
  }
`;

export const Skill = ({ skill }) => (
  <SkillContainer>
    <H3>{skill.title}</H3>
    <Row className="before">
      <Column flex={skill.before} />
      <Column flex={100 - skill.before} />
    </Row>
    <Row className="after">
      <Column flex={skill.after} />
      <Column flex={100 - skill.after} />
    </Row>
    <BodyMediumText>{skill.description}</BodyMediumText>
  </SkillContainer>
);
