import React from 'react';
import { H2, Section, SectionHeader, BodyLargeText, Card, Column, Ul, Caption } from 'playful-ui';

import { content } from './content/PlexiSkinsPage';
import { overview } from './content/Overview';
import { OverviewContainer } from '../components/OverviewContainer';

import ImgBananaSplit from '../hexapod/assets/hexapod-options/img-banana-split.jpg';
import ImgCherryBlossom from '../hexapod/assets/hexapod-options/img-cherry-blossom.jpg';
import ImgDarkNight from '../hexapod/assets/hexapod-options/img-dark-night.jpg';
import ImgDiamondBlue from '../hexapod/assets/hexapod-options/img-diamond-blue.jpg';
import ImgInvisibleWalker from '../hexapod/assets/hexapod-options/img-invisible-walker.jpg';
import ImgMistyMint from '../hexapod/assets/hexapod-options/img-misty-mint.jpg';

export class Overview extends React.Component {
  static pageName = overview.pageName;

  render() {
    return (
      <OverviewContainer>
        <Section className="textual">
          <SectionHeader index={2} />
          <H2>{overview.title}</H2>
          <BodyLargeText>{overview.text}</BodyLargeText>
        </Section>
        <Column className="h-center margin-top">
          <Card
            className="silver"
            images={[
              ImgInvisibleWalker,
              ImgBananaSplit,
              ImgDarkNight,
              ImgMistyMint,
              ImgDiamondBlue,
              ImgCherryBlossom
            ]}
          >
            <Caption>{content.productName}</Caption>
            <H2>{overview.card.title}</H2>
            <Ul>
              {
                overview.card.options.map((text, i) => 
                  <li key={i}><BodyLargeText>{text}</BodyLargeText></li> )
              }
            </Ul>
          </Card>
        </Column>
      </OverviewContainer>
    )
  }
}
