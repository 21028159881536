import {
  APPROVE_DISCOUNT_SUCCESS,
  UPDATE_CHECKOUT_REQUEST,
  GET_IPINFO_SUCCESS,
  GET_CLIENT_TOKEN_SUCCESS,
} from './actions';

export const initialState = Object.freeze({
  customerEmail: '',
  customerToken: null,
  shipping: {
    firstName: '',
    lastName: '',
    companyName: '',
    address: '',
    apartment: '',
    city: '',
    region: '',
    countryCodeAlpha2: '',
    postal: '',
    phone: '',
  },
  billing: {
    firstName: '',
    lastName: '',
    companyName: '',
    address: '',
    apartment: '',
    city: '',
    region: '',
    countryCodeAlpha2: '',
    postal: '',
    phone: '',
  },
  cartBreakdown: {
    totalAmount: null,
    cartAmount: null,
    shippingAmount: null,
    vatAmount: null,
    discountAmount: null,
  },
  discountPercentage: null,
  shippingModel: null,
  vatRate: null,
});

export const reducer = (state = { ...initialState }, action) => {
  const { payload } = action;

  switch (action.type) {
    case UPDATE_CHECKOUT_REQUEST:
      return { ...state, ...payload };

    case GET_IPINFO_SUCCESS:
      const { country, region, city, postal } = payload;
      return {
        ...state,
        shipping: {
          ...state.shipping,
          city,
          postal,
          region,
          countryCodeAlpha2: country,
        },
      };

    case APPROVE_DISCOUNT_SUCCESS:
      return { ...state, discountPercentage: payload.discountPercentage };

    case GET_CLIENT_TOKEN_SUCCESS:
      return { ...state, clientToken: payload.clientToken };

    default:
      return state;
  }
};
