import React from 'react';
import styled from 'styled-components';

import { spacing, colors, zIndex } from 'theme';
import { Button } from '../Button';

export const ContentContainer = styled.div`
  position: relative;
`;

const ContentBannerContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: ${spacing.BASE};
  left: -${spacing.BASE};
  z-index: ${zIndex.CONTENT_BANNER_CONTAINER};

  &:after {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 21px solid transparent;
    border-right: 0px solid transparent;
    border-top: 5px solid ${colors.darkblue};
    border-bottom: 0px solid transparent;
    margin-left: 3px;
  }

  & ${Button} {
    width: fit-content;
    min-width: 40px;
    max-height: 46px;
    font-weight: 800;
    border-width: 1px;
    cursor: auto;
    background-color: white;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: center;
    pointer-events: none;
  }
`;

export const ContentBanner = ({ offset, text, children }) => (
  <ContentContainer>
    {text && (
      <ContentBannerContainer offset={offset}>
        <Button className="secondary">{text}</Button>
      </ContentBannerContainer>
    )}
    {children}
  </ContentContainer>
);
