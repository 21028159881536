import styled from 'styled-components';
import { Column, BodyMediumText, spacing, media, fonts, colors } from 'playful-ui';

export const PaymentMethodContainer = styled(Column)`
  & > ${BodyMediumText}.margin-top {
    margin-top: ${spacing.BASE};
  };

  & > ${BodyMediumText}.margin-top-bot {
    margin: ${spacing.BASE} 0;
  };

  & ${BodyMediumText}.error {
    color: ${colors.crimson};
  };

  & p.bold {
    font-weight: 600;
  }

  & div.buttons {
    margin-top: ${spacing.BASE};
    margin-bottom: ${spacing.SMALL};
    ${media.phone`
      flex-direction: column-reverse;
      align-items: flex-end;
    `};
  }
  & div.buttons a, div.buttons button {
    text-align: center;
    margin-right: ${spacing.TINY};
    margin-bottom: ${spacing.TINY};
    ${media.phone`
      margin-right: 0;
      min-height: 48px;
    `};
  };
  & div.buttons a:last-child { 
    margin-right: 0;
  }

  & div.braintree-heading {
    ${fonts.bodySmall};
  }
  & div#braintree-dropin-container {
    min-height: '140px';
  }


`;
