import { GET_AUTH_SUCCESS } from 'modules/auth';

import { onceCart } from './actions';

const whiteList = [GET_AUTH_SUCCESS];

export default store => next => action => {
  if (!whiteList.includes(action.type)) {
    return next(action);
  }

  switch (action.type) {
    case GET_AUTH_SUCCESS:
      store.dispatch(onceCart(action.payload.uid));
      break;
    default:
  }

  return next(action);
};
