import styled from 'styled-components';

import { colors } from 'theme';

import { H5 } from '../Typography';

export default styled.ul`
  padding: 0;
  margin: 4px 0;
  width: 211px;
  max-width: 211px;
  border-radius: 4px;
  background-color: rgba(244, 244, 247, 0.98);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
  list-style: none;

  & a {
    display: list-item;
  }
  & li {
    cursor: pointer;
    padding: 24px 21px;
    padding-right: 7px;
    box-shadow: 0 1px rgba(213, 214, 222, 0.3);
  }
  & li:hover {
    background-color: rgba(253, 253, 254, 0.69);
  }
  & li.active {
    color: ${colors.persianblue};
  }
  & li:last-child {
    box-shadow: none;
  }
  & li ${H5} {
    color: inherit;
  }
`;
