import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';

import {
  H5,
  Navigation,
  Row,
  Icon,
  spacing,
  ItemListDropdown,
  RoundImage,
} from 'playful-ui';
import { signOut } from 'stemi-firebase';

import { sessionSignOut } from 'modules/auth';
import { ProfilePage } from 'modules/profile';
import { VerifyYourEmail } from 'modules/errorpage';
import { AppLinkButton } from 'components';

import { getCurrentUser } from '../redux';
import { SignInPage } from '../pages/SignIn';
import { SignUpPage } from '../pages/SignUp';

const ProfileImg = styled(RoundImage)`
  margin-right: ${spacing.TINY};
`;

const ProfileDropdown = styled(ItemListDropdown)`
  position: absolute;
  top: 80px;
`;

class AuthNavigationItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      showDropdown: false,
    };
  }

  handleClick = e => {
    const showDropdown =
      this.ref.current &&
      this.ref.current.contains(e.target) &&
      !this.state.showDropdown;
    this.setState({ showDropdown });
  };

  handleSignOut = e => {
    const { signOut } = this.props;
    signOut()
      .then(sessionSignOut)
      .then(() => navigate('/'));
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

  preventClick(e) {
    e.preventDefault();
  }

  render() {
    const { user } = this.props;
    const { photoURL, displayName, emailVerified } = user;
    const { showDropdown } = this.state;
    const userPhoto = photoURL ? (
      <ProfileImg alt="profile" src={photoURL} width={24} />
    ) : (
      <Icon name="Account" style={{ marginRight: spacing.TINY }} />
    );
    return user && !user.isEmpty() ? (
      <React.Fragment>
        <Navigation.Item ref={this.ref} variant="right" className="hide-phone">
          <Row className="v-center">
            {userPhoto}
            <a
              className="nav-link"
              style={{ display: 'flex', alignItems: 'center' }}
              href="dummy"
              onClick={this.preventClick}
            >
              {displayName}
            </a>
            <Icon name="ArrowDown" />
          </Row>
          {showDropdown && (
            <ProfileDropdown>
              {!emailVerified && (
                <Link
                  style={{ display: 'list-item' }}
                  to={VerifyYourEmail.route}
                >
                  <li>
                    <H5>Verify your email</H5>
                  </li>
                </Link>
              )}
              <Link style={{ display: 'list-item' }} to={ProfilePage.route}>
                <li>
                  <H5>Profile</H5>
                </li>
              </Link>
              <li onClick={this.handleSignOut}>
                <H5>SignOut</H5>
              </li>
            </ProfileDropdown>
          )}
        </Navigation.Item>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Navigation.Item variant="right" className="hide-phone">
          <Link className="nav-link" to={SignInPage.route}>
            Sign in
          </Link>
        </Navigation.Item>
        <Navigation.Item variant="right" className="hide-phone">
          <AppLinkButton className="secondary navigation" to={SignUpPage.route}>
            Sign up
          </AppLinkButton>
        </Navigation.Item>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ user: getCurrentUser(state) });

const mapDispatchToProps = { signOut };

export const NavigationItem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthNavigationItem);

NavigationItem.defaultProps = {
  variant: 'right',
  isNavigationItem: true,
};
