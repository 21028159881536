import React from 'react';
import styled from 'styled-components';
import { media, zIndex } from 'theme';

import { Slider } from '../Slider';

const SliderContainer = styled.div`
  width: 624px;
  height: 624px;
  border-radius: 4px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: ${zIndex.SLIDER_CONTAINER};
  ${media.phone`
    width: 360px;
    height: 360px !important;
    border-radius: 0px;
    & .slick-slide div[tabindex] {
      height: 360px;
    }
    & .slick-slide div[tabindex] .parallax-outer {
      transform: translateY(40px);
    }
  `};
`;

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  dotsClass: 'card-image-slider-slick-dots slick-dots',
  autoplay: true,
  autoplaySpeed: 4000,
  arrows: false,
};

export const ImageSlider = ({ children }) => (
  <SliderContainer>
    <Slider {...sliderSettings}>{children}</Slider>
  </SliderContainer>
);
