import React from 'react';

import classNames from 'classnames';

import { H4, BodySmallText } from '../Typography';
import { QuestsHighlightContainer } from './QuestsHighlightContainer';

import macBookPicture from './assets/img-macbook.png';

class NameLi extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    return this.props.onClick(this.props.index);
  }

  render() {
    const { isActive, quest } = this.props;
    return (
      <li className={classNames({ active: isActive })} onClick={this.handleClick}>
        {quest.name}
      </li>
    );
  }
}

function createImage(quest, isActive, index) {
  return (
    <img
      key={index}
      className={classNames({ hidden: !isActive }, { active: isActive }, 'quest-image')}
      src={quest.image}
    />
  );
}

function createDescription(quest, isActive, index) {
  return (
    <div key={index} className={classNames({ hidden: !isActive }, 'description-container')}>
      <H4>{quest.descriptionTitle}</H4>
      <BodySmallText>{quest.description}</BodySmallText>
    </div>
  );
}

export const QuestsHighlight = ({ quests, activeIndex, onClick }) => {
  const moduloActiveIndex = Math.abs(activeIndex % quests.length);

  return (
    <QuestsHighlightContainer className="h-center">
      <ul>
        {quests.map((q, i) => (
          <NameLi
            key={i}
            quest={q}
            isActive={i === moduloActiveIndex}
            index={i}
            onClick={onClick}
          />
        ))}
      </ul>
      <div className="content-container">
        <img src={macBookPicture} />
        <div className="screen-pictures-container">
          {quests.map((q, i) => createImage(q, i === moduloActiveIndex, i))}
        </div>
        <div className="descriptions-container">
          {quests.map((q, i) => createDescription(q, i === moduloActiveIndex, i))}
        </div>
      </div>
    </QuestsHighlightContainer>
  );
};
