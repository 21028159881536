import React from 'react';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';

import { auth } from 'stemi-firebase';

import { getCurrentUser } from '../redux';
import { SignInPage } from '../pages/SignIn';

export const withAuthorization = (
  condition = (firebaseUser, { currentUser }) =>
    currentUser && currentUser.uid === firebaseUser.uid,
  route = SignInPage.route,
) => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      auth.onAuthStateChanged(currentUser => {
        if (!condition(currentUser, this.props)) {
          navigate(route);
        }
      });
    }

    render() {
      const { currentUser } = this.props;
      return currentUser.isValid() && !currentUser.isEmpty() ? (
        <Component {...this.props} />
      ) : null;
    }
  }

  const mapStateToProps = state => ({
    currentUser: getCurrentUser(state),
  });

  return connect(mapStateToProps)(WithAuthorization);
};
