import {
  ONCE_GET_REQUEST,
  ONCE_GET_SUCCESS,
  ONCE_GET_ERROR,
  SET_REQUEST,
  SET_SUCCESS,
  SET_ERROR,
} from './actions';

import { Status } from './status';

export const snapshot = (schemeName, forceRenew) => (state = {}, action) => {
  if (!action.meta || (action.meta && action.meta.schemeName !== schemeName)) {
    return state;
  }

  switch (action.type) {
    case ONCE_GET_ERROR:
    case SET_ERROR:
      return {
        ...state,
        status: Status.ERROR,
      };
    case ONCE_GET_REQUEST:
    case SET_REQUEST:
      return {
        ...state,
        status: Status.LOADING,
      };
    case ONCE_GET_SUCCESS:
    case SET_SUCCESS:
      if (!action.meta.id || forceRenew) {
        return {
          status: Status.VALID,
          data: { ...action.payload },
        };
      }

      return {
        status: Status.VALID,
        data: {
          ...state,
          [action.meta.id]: { ...action.payload },
        },
      };
    default:
      return state;
  }
};
