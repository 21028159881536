import React from 'react';
import styled from 'styled-components';

import { colors, media, spacing } from 'theme';
import { Caption } from '../Typography';
import { Column } from '../Column';

export const SmallCardContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.right ? 'row-reverse' : 'row')};
  justify-content: center;
  width: auto;
  & img,
  & video,
  & .media {
    display: inline-flex;
    width: 528px;
    height: 528px;
    object-fit: cover;
    border-radius: 4px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
  }
  & ${Column} {
    display: inline-flex;
    justify-content: center;
    max-width: 362px;
    margin: 0;
    ${props => (props.right ? 'margin-right' : 'margin-left')}: 60px;
  }
  & ${Caption} {
    margin-bottom: ${spacing.SMALL};
  }
  & ${Caption}::before {
    display: inline-block;
    content: '';
    margin-right: ${spacing.SMALL};
    position: relative;
    top: -3px;
    width: 48px;
    height: 4px;
    background-color: ${colors.darkblue};
  }
  ${media.tablet`
    & img, & video, & .media {
      width: 369px;
      height: 369px;
    }
    & ${Column} {
      width: 339px;
      margin-right: 30px;
      margin-left: 30px;
    }
  }`} ${media.phone`
    flex-direction: column;
    align-items: center;
    & img, & video, & .media {
      width: 335px;
      height: 335px;
      object-fit: cover;
      align-self: ${props => (props.right ? 'flex-end' : 'flex-start')};
    }
    & ${Column} {
      width: 295px;
      justify-content: flex-start;
      margin-top: ${spacing.BASE};
      margin-right: 0;
      margin-left: 0;
    }
  `};
`;

export const SmallCard = ({ className, right, style, children }) => (
  <SmallCardContainer className={className} style={style} right={!!right}>
    {children}
  </SmallCardContainer>
);
