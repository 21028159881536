import styled from 'styled-components';
import { Caption, Row, spacing } from 'playful-ui';

export const SubpageMenuContainer = styled(Row)`
  & div.subpage-menu-container {
    position: relative;
    transition: opacity 0.1s linear;
  }
  & div.subpage-menu-container.hidden {
    opacity: 0;
  }
  & div.subpage-menu-container.displayed {
    opacity: 1;
  }
  & svg {
    padding-left: ${spacing.TINY};
    cursor: pointer;
  }
  & ${Caption}.subpage-caption {
    opacity: 1;
  }
  & ul {
    position: absolute;
    left: -211px;
    top: -34px;
  }
`;
