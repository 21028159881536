import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Column } from '../Column';

const variants = {
  closed: {
    height: 0,
  },
  open: {
    height: 'auto',
  },
};

const Content = styled(motion.div)`
  overflow: hidden;
`;

const ClickableDiv = styled.div`
  cursor: pointer;
`;

export class ExpandableDiv extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  render() {
    const { HeadingContent, children, open, onOpenCloseClick } = this.props;
    return (
      <Column>
        <ClickableDiv onClick={onOpenCloseClick}>
          <HeadingContent />
        </ClickableDiv>
        <Content
          ref={this.ref}
          variants={variants}
          initial="closed"
          animate={open ? 'open' : 'closed'}
        >
          {children}
        </Content>
      </Column>
    );
  }
}
