import styled from 'styled-components';

import { media } from 'theme';

import { NavItem } from './NavItem';

export const NavContainer = styled.div`
  display: flex;
  width: 1146px;
  height: 48px;
  flex-direction: row;
  justify-content: space-between;

  &.subpage {
    height: 80px;
  }

  ${media.tabletLandscape`
    width: 974px;
  `};
  ${media.tablet`
    width: 708px;
  `};
  ${media.phone`
    width 335px;
  `};

  ${NavItem}:last-child {
    margin-right: 0;
  }
`;
