const merchantConfig = Object.freeze({
  sandbox: {
    EUR: 'stemidoo',
    USD: 'stemidooHRK',
    GBP: 'stemiGBP',
    HRK: 'stemiHRK',
  },
  production: {
    EUR: 'stemiEUR',
    USD: 'stemiUSD',
    GBP: 'stemiGBP',
    HRK: 'stemiHRK',
  },
});

const authorizationKeys = Object.freeze({
  sandbox: 'sandbox_b45wp93b_pdvf5cnv3r5twj2r',
  production: 'production_3vp58gdc_bycfqshhpp58pb5x',
});

function getBraintreeConfig() {
  const NODE_ENV = process.env.NODE_ENV;
  const GATSBY_BRAINTREE_ENV = process.env.GATSBY_BRAINTREE_ENV;
  const GATSBY_STEMI_CLOUD_ENV = process.env.GATSBY_STEMI_CLOUD_ENV;
  
  if (NODE_ENV === 'development' || GATSBY_BRAINTREE_ENV === 'sandbox') {
    const backendUrl = GATSBY_STEMI_CLOUD_ENV === 'localhost' ?
      'http://localhost:5000/stemi-web-develop/us-central1' :
      'https://us-central1-stemi-web-develop.cloudfunctions.net';
    return {
      merchants: merchantConfig.sandbox,
      authorizationKey: authorizationKeys.sandbox,
      backendUrl,
    };
  }
  return {
    merchants: merchantConfig.production,
    authorizationKey: authorizationKeys.production,
    backendUrl: 'https://us-central1-stemi-web-production.cloudfunctions.net',
  };
}

export const braintreeConfig = getBraintreeConfig();
