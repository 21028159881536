import styled from 'styled-components';

import { spacing, colors } from 'theme';

import { Icon } from '../Icon';
import { BodyXSmallText } from '../Typography';
import { Button } from '../Button';
import { Column } from '../Column';

export const NotificationCard = styled(Column)`
  position: relative;
  width: 264px;
  padding: ${spacing.SMALL};
  border-radius: 4px;
  background-color: ${colors.white};
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  & > ${BodyXSmallText} {
    color: ${colors.applyOpacity(colors.midnight, 0.69)};
    margin-bottom: ${spacing.TINY};
    line-height: 18px;
  }
  & ${Icon} ~ ${BodyXSmallText} {
    margin-left: ${spacing.BASE_PLUS};
  }
  & ${Icon} ~ ${Button} {
    margin-left: ${spacing.BASE_PLUS};
  }
  & > ${BodyXSmallText} > a {
    text-decoration: none;
    color: ${colors.tomato};
  }
  & > ${Button} {
    max-height: ${spacing.BASE_PLUS};
    min-height: ${spacing.BASE_PLUS};
    min-width: ${spacing.XLARGE};
    padding: 0px ${spacing.SMALL};
  }
  & > ${Icon} {
    position: absolute;
    top: ${spacing.SMALL};
    left: ${spacing.SMALL};
    min-width: ${spacing.BASE};
    min-height: ${spacing.BASE};
  }
`;
