import React from 'react';
import classNames from 'classnames';

import { Caption } from '../../Typography';
import { Icon } from '../../Icon';

import { TabMenuItem } from './TabMenuItem';
import { TabMenuContainer } from './TabMenuContainer';

export class TabsMenu extends React.Component {
  constructor(props) {
    super(props);
    this.tabMenuRef = React.createRef();
    this.state = {
      showTabMenu: false,
    };
  }

  handleClick = e => {
    if (this.tabMenuRef.current.contains(e.target) && !this.state.showTabMenu) {
      this.setState({ showTabMenu: true });
      return;
    }
    this.setState({ showTabMenu: false });
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

  render() {
    const { activeIndex, activeTab, tabs, className, onClick } = this.props;
    const { showTabMenu } = this.state;
    return (
      <div ref={this.tabMenuRef}>
        <TabMenuContainer className={`v-center ${className}`}>
          <Caption className="tab-caption">{activeTab}</Caption>
          <Icon className="tab-icon" name="ExpandMore" />
          <div
            className={classNames(
              'tab-menu-container',
              { hidden: !showTabMenu },
              { displayed: showTabMenu }
            )}
          >
            <ul>
              {showTabMenu &&
                tabs.map((tab, i) => (
                  <TabMenuItem
                    activeIndex={activeIndex}
                    tabName={tab}
                    key={i}
                    index={i}
                    onClick={onClick}
                  />
                ))}
            </ul>
          </div>
        </TabMenuContainer>
      </div>
    );
  }
}
