import styled from 'styled-components';
import { Column, colors } from 'playful-ui';

export const NavTabColumn = styled(Column)`
  flex: unset;
  margin-right: 84px;

  & > ${Column} {
    flex: unset;
  };
  & > ${Column}::after {
    content: "";
    height: 24px;
    width: 2px;
    margin-left: 20px;
    background-color: ${colors.applyOpacity(colors.mischka, 0.2)};
  };
  & > ${Column}:last-child::after {
    height: 0;
    width: 0;
  };
  & a {
    text-decoration: inherit;
    color: inherit;
  }
`;
