import styled from 'styled-components';

import { media, spacing, zIndex, colors } from 'theme';

import { Row } from '../Row';

export const NavOuterContainer = styled(Row)`
  position: absolute;
  z-index: ${zIndex.NAVIGATION};
  top: 0;
  left: 0;
  right: 0;
  padding-top: ${spacing.BASE};
  padding-bottom: 0;
  transition: all 0.25s;
  ${media.tablet`
    padding-top: ${spacing.BASE_PLUS};
  `};
  ${media.phone`
    padding-top: ${spacing.SMALL};
  `};
  &.scrolled {
    position: fixed;
    background: rgba(255, 255, 255, 0.95);
    padding-top: ${spacing.TINY};
    padding-bottom: ${spacing.TINY};
    opacity: 1;
  }
  &.hidden {
    opacity: 0;
    display: none;
  }
  &.subpage {
    position: sticky;
    padding-top: 0;
    background-color: ${colors.white};
    box-shadow: 0 1px ${colors.whitesmoke}, 0 -1px ${colors.whitesmoke};
  }
`;
