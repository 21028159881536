import React from 'react';

import { VideoModal, MediaOverlay } from 'playful-ui';

import { Product } from '../components/Product';
import { Adventure } from '../hexapod/Adventure';
import { Features } from '../hexapod/Features';
import { Testimonials } from '../hexapod/Testimonials';
import { FAQ } from '../hexapod/FAQ';
import videoPreview from '../hexapod/assets/videos/vid-preview.mp4';
import firstFrameVideoPreview from '../hexapod/assets/first_frame_video_preview.png';
import { content } from '../hexapod/content/HexapodPage';
import { buyingOptions } from '../hexapod/content/BuyingOptions';
import { shippingModels } from '../hexapod/content/shippingModels';
import { BuyingOptions } from '../hexapod/BuyingOptions';

const subpages = [Adventure, Features, Testimonials, FAQ];

const productMedia = [
  <VideoModal
    className="media"
    key={1}
    thumbnail={firstFrameVideoPreview}
    thumbnailVideo={videoPreview}
    url="https://www.youtube.com/watch?v=WNWO5yK1j0M"
    playing
    controls
    mediaOverlay={MediaOverlay}
    overlayIndex={1}
  />,
];

export class HexapodPage extends React.Component {
  static route = '/products/hexapod';
  static title = 'STEMI Hexapod';
  static name = content.productName;
  static price = content.price;
  static buyingOptions = buyingOptions;
  static shippingModels = shippingModels;

  render() {
    return (
      <Product
        {...content}
        subpages={subpages}
        productMedia={productMedia}
        BuyingOptions={BuyingOptions}
        hidePrice
      />
    );
  }
}
