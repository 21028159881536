import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { colors } from 'theme';

import { Parallax } from '../Parallax';

import { ReactComponent as Circle1 } from './assets/img-circle-1.svg';
import { ReactComponent as Circle2 } from './assets/img-circle-2.svg';
import { ReactComponent as Circle3 } from './assets/img-circle-3.svg';
import { ReactComponent as Hexagon1 } from './assets/img-hexagon-1.svg';
import { ReactComponent as Square1 } from './assets/img-square-1.svg';
import { ReactComponent as Triangle1 } from './assets/img-triangle-1.svg';
import { ReactComponent as Triangle2 } from './assets/img-triangle-2.svg';

const elements = _.shuffle([Circle1, Circle2, Circle3, Hexagon1, Square1, Triangle1, Triangle2]);

const whitelistedColors = [
  'tomato',
  'gold',
  'dodgerblue',
  'limegreen',
  'crimson',
  'skyblue',
  'turquoise',
  'pink',
  'steel',
  'mediumpurple',
  'slategray',
  'mischka',
  'whitesmoke',
];

export const createElement = (maxTranslation = 100, maxScale = 1.3, disableParallax = false) => {
  const translateX = _.random(-1 * maxTranslation, maxTranslation);
  const translateY = _.random(-1 * maxTranslation, maxTranslation);
  const scale = _.random(1 / maxScale, maxScale, true);
  const color = colors[_.sample(whitelistedColors)];

  const Container = styled.div`
    width: 20%;
    transition: all 2s;
    opacity: 0;
    &.loaded {
      opacity: 1;
      transform: translate3d(${translateX}px, ${translateY}px, 0) scale(${scale})
        rotate(${_.random(360)}deg);
    }
    svg path[fill] {
      fill: ${color};
    }
    svg path[stroke] {
      fill: none;
      stroke: ${color};
    }
  `;

  const parallaxOffset = _.random(0, 50);

  return ({ index = _.random(elements.length), loaded, style }) => {
    const Element = elements[index % elements.length];
    return (
      <Container className={loaded ? 'loaded' : ''} style={style}>
        <Parallax
          y={[`${-1 * parallaxOffset}px`, `${parallaxOffset}px`]}
          x={[`${-1 * parallaxOffset}px`, `${parallaxOffset}px`]}
          disabled={disableParallax}
        >
          <Element />
        </Parallax>
      </Container>
    );
  };
};
