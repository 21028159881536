import { combineReducers } from 'redux';

import { snapshot } from 'stemi-firebase';

import { GET_AUTH_SUCCESS } from './actions';

const user = snapshot('users', 'alwaysRefresh');

const uid = (state = null, action) => {
  switch (action.type) {
    case GET_AUTH_SUCCESS:
      return action.payload.uid;
    default:
      return state;
  }
};

export default combineReducers({
  user,
  uid,
});
