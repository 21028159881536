import React from 'react';
import { H3, BodyMediumText } from 'playful-ui';

import { LegalContainer } from '../components/LegalContainer';
import { paymentConditions } from './content/PaymentConditions';
import { TermsAndConditions } from './TermsAndConditions';

export class PaymentConditions extends React.Component {
  static route = '/legal/payment-conditions';
  static title = paymentConditions.pageTitle;

  render() {
    return (
      <LegalContainer>
        <H3>{paymentConditions.general}</H3>
        <BodyMediumText>{paymentConditions.generalParagraph}</BodyMediumText>
        <H3>{paymentConditions.acceptance}</H3>
        <BodyMediumText>
          {paymentConditions.acceptanceParagraph(TermsAndConditions.route)}
        </BodyMediumText>
        <H3>{paymentConditions.changes}</H3>
        <BodyMediumText>{paymentConditions.changesParagraph}</BodyMediumText>
        <H3>{paymentConditions.payment}</H3>
        {paymentConditions.paymentParagraph}
        <H3>{paymentConditions.protection}</H3>
        <BodyMediumText>{paymentConditions.protectionParagraph}</BodyMediumText>
        <H3>{paymentConditions.complaints}</H3>
        <BodyMediumText>{paymentConditions.complaintsParagraph}</BodyMediumText>
        <H3>{paymentConditions.other}</H3>
        <BodyMediumText>{paymentConditions.otherParagraph}</BodyMediumText>
      </LegalContainer>
    );
  }
}
