import React, { Component } from 'react';

import { auth } from 'stemi-firebase';
import { Row, TextInput, Button } from 'playful-ui';

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  error: null,
};

export class ForgotPasswordPage extends Component {
  static route = 'forgot-password';
  static title = 'STEMI - Forgot password';

  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
      })
      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <Row style={{ height: '73px' }}>
          <TextInput
            errorMessage={error && error.message}
            value={this.state.email}
            onChange={event =>
              this.setState(updateByPropertyName('email', event.target.value))
            }
            type="text"
            placeholder="Email Address"
          />
          <Button
            style={{ marginTop: '0px', marginLeft: '16px' }}
            disabled={isInvalid}
            type="submit"
          >
            Reset My Password
          </Button>
        </Row>
      </form>
    );
  }
}
