import React from 'react';

import { TextAreaContainer } from './TextAreaContainer';

export const TextArea = props => {
  const { children, errorMessage, style, className, ...inputProps } = props;
  return (
    <TextAreaContainer className={className} style={style} errorMessage={errorMessage}>
      {children}
      <textarea {...inputProps} />
      {errorMessage && <p>{errorMessage}</p>}
    </TextAreaContainer>
  );
};
