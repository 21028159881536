import styled from 'styled-components';
import { Column, H4, Caption, spacing, colors } from 'playful-ui';

export const EduContentContainer = styled(Column)`
  max-width: 656px;

  & ${H4} {
    margin-bottom: ${spacing.BASE_PLUS};
  }

  & div.video-wrapper {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
    margin-bottom: ${spacing.BASE};
  }
  & div.video-wrapper > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > ${Caption} {
    color: ${colors.midnight};
    line-height: 48px;
    box-shadow: 0 1px ${colors.applyOpacity(colors.mischka, 0.5)};
    margin-bottom: ${spacing.SMALL};
  }

  & > div.children > div > p {
    margin-bottom: ${spacing.SMALL};
  }
  & > div.children img {
    max-width: 100%;
  }

  & div.buttons {
    padding-top: 16px;
    margin-top: 20px;
    box-shadow: 0 -1px ${colors.applyOpacity(colors.mischka, 0.5)};
  }

  & div.button-back {
    height: 32px;
    opacity: 0.5;
  }
  & div.button-back > svg {
    margin-right: ${spacing.TINY};
  }

  & div.button-fw {
    height: 32px;
  }
  & div.button-fw > svg {
    margin-left: ${spacing.TINY};
    transform: rotate(180deg);
  }
`;
