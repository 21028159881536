export const ADD_TO_CART = 'stemi.web.analytics.ADD_TO_CART';
export const REMOVE_FROM_CART = 'stemi.web.analytics.REMOVE_FROM_CART';
export const INITIATE_CHECKOUT = 'stemi.web.analytics.INITIATE_CHECKOUT';
export const SECOND_CHECKOUT_STEP = 'stemi.web.analytics.SECOND_CHECKOUT_STEP';
export const THIRD_CHECKOUT_STEP = 'stemi.web.analytics.THIRD_CHECKOUT_STEP';
export const PURCHASE_SUCCESS = 'stemi.web.analytics.PURCHASE_SUCCESS';
export const PRODUCT_CLICK = 'stemi.web.analytics.PRODUCT_CLICK';
export const PRODUCT_LIST = 'stemi.web.analytics.PRODUCT_LIST';
export const PRODUCT_DETAILS = 'stemi.web.analytics.PRODUCT_DETAILS';
export const ADD_PAYMENT_INFO = 'stemi.web.analytics.ADD_PAYMENT_INFO';
export const ADD_SHIPPING_INFO = 'stemi.web.analytics.ADD_SHIPPING_INFO';
export const VIEW_TESTIMONIAL = 'stemi.web.analytics.VIEW_TESTIMONIAL';
export const VIEW_FEATURES = 'stemi.web.analytics.VIEW_FEATURES';
export const VIEW_FAQ = 'stemi.web.analytics.VIEW_FAQ';

export const addToCart = (meta) => ({
  type: ADD_TO_CART,
  meta,
})

export const removeFromCart = (meta) => ({
  type: REMOVE_FROM_CART,
  meta,
})

export const initiateCheckout = (meta) => ({
  type: INITIATE_CHECKOUT,
  meta,
})

export const secondCheckoutStep = (meta) => ({
  type: SECOND_CHECKOUT_STEP,
  meta,
})

export const thirdCheckoutStep = (meta) => ({
  type: THIRD_CHECKOUT_STEP,
  meta,
})

export const purchaseSuccess = (meta) => ({
  type: PURCHASE_SUCCESS,
  meta,
})

export const productClick = (meta) => ({
  type: PRODUCT_CLICK,
  meta,
})

export const productList = (meta) => ({
  type: PRODUCT_LIST,
  meta,
})

export const productDetails = (meta) => ({
  type: PRODUCT_DETAILS,
  meta,
})

export const addPaymentInfo = (meta) => ({
  type: ADD_PAYMENT_INFO,
  meta,
})

export const addShippingInfo = (meta) => ({
  type: ADD_SHIPPING_INFO,
  meta,
})

export const viewTestimonial = (meta) => ({
  type: VIEW_TESTIMONIAL,
  meta,
})

export const viewFeatures = (meta) => ({
  type: VIEW_FEATURES,
  meta,
})

export const viewFAQ = (meta) => ({
  type: VIEW_FAQ,
  meta,
})
