import React from 'react';
import styled from 'styled-components';
import { icons } from 'theme';

const IconContainer = ({
  className,
  icon,
  name,
  size = icons.sizes.MEDIUM,
  style,
  onClick,
}) => {
  const IconComponent = icon || icons.names[name];
  return (
    <IconComponent
      onClick={onClick}
      className={className}
      style={{
        ...style,
        height: size,
        width: size,
      }}
    />
  );
};

export const Icon = styled(IconContainer)``;
