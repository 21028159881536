import React from 'react';
import { SmallCard, MediaOverlay, Column, Caption, H3, Section, SectionHeader, H2 } from 'playful-ui';

import { FeaturesContainer } from '../components/FeaturesContainer';
import { TechnicalSpecifications } from '../components/TechnicalSpecifications';
import {
  pageName,
  features,
  technicalSpecifications
} from './content/Features';

export class Features extends React.Component {
  static pageName = pageName;

  render() {
    return (
      <FeaturesContainer>
        {features.map((feature, i) => (
          <SmallCard className="small-card" right={i % 2 === 0} key={i}>
            <MediaOverlay index={1} className="media">
              <video
                autoPlay
                loop
                playsInline
                muted
                type="video/mp4"
                src={feature.media}
              />
            </MediaOverlay>
            <Column>
              <Caption>{i + 1}</Caption>
              <H3>{feature.text}</H3>
            </Column>
          </SmallCard>
        ))}
        <Section className="textual large">
          <SectionHeader index={1} />
          <H2>{technicalSpecifications.title}</H2>
        </Section>
        <TechnicalSpecifications
          specs={technicalSpecifications.specifications}
        />
      </FeaturesContainer>
    );
  }
}
