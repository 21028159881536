import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classNames from 'classnames';
import Link from 'gatsby-link';
import { Trans } from '@lingui/react';

import {
  Button,
  Column,
  Row,
  Icon,
  TextInput,
  BodySmallText,
  BodyXSmallText,
  BodyMediumText,
} from 'playful-ui';
import { withStemiI18n } from 'stemi-i18n';

import { getDeSanitizedCart } from 'modules/cart';

import {
  approveDiscountSuccess,
  approveDiscountFailure,
  updateCheckout,
  initialState,
  getDiscount,
  approveDiscount,
  getCartBreakdown,
  getCheckout,
} from '../redux';

import { WrapperContainer } from './WrapperContainer';
import { InputContainer } from './InputContainer';
import { OrderSummaryContainer } from './OrderSummaryContainer';
import { CheckoutHeader } from './CheckoutHeader';
import { CheckoutContainer } from './CheckoutContainer';
import { OrderItem } from './OrderItem';

import Logo from '../../navigation/assets/img-stemi-logo.svg';

class CheckoutComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderSummeryHidden: true,
      discountCode: null,
      discountMessage: null,
    };
  }

  onSummeryButtonClick = () =>
    this.setState({ orderSummeryHidden: !this.state.orderSummeryHidden });

  handleGoToHome = () => {
    const { resetCheckout } = this.props;
    resetCheckout();
  };

  handleDiscountCodeChange = e => {
    this.setState({ discountCode: e.target.value.toLowerCase() });
  };

  handleSubmitDiscountCode = e => {
    e.preventDefault();
    const {
      approveDiscount,
      checkout,
      approveDiscountSuccess,
      approveDiscountFailure,
    } = this.props;
    const { discountCode } = this.state;
    approveDiscount(discountCode, checkout.customerEmail).then(
      discountObject => {
        this.setState({ discountMessage: discountObject.description });
        approveDiscountSuccess(discountObject);
      },
      fail => {
        this.setState({ discountMessage: fail.message });
        approveDiscountFailure(fail);
      }
    );
  };

  render() {
    const { currencySymbol, cartBreakdown, cart, activePageIndex } = this.props;
    const { orderSummeryHidden, discountMessage } = this.state;
    const buttonText = orderSummeryHidden ? (
      <Trans>Show order summery</Trans>
    ) : (
      <Trans>Hide order summery</Trans>
    );
    const buttonIconName = this.state.orderSummeryHidden ? 'ExpandMore' : 'ExpandLess';
    const { totalAmount, cartAmount, shippingAmount, vatAmount, discountAmount } = cartBreakdown;
    return (
      <CheckoutContainer>
        <CheckoutHeader
          buttonText={buttonText}
          buttonIconName={buttonIconName}
          onButtonClick={this.onSummeryButtonClick}
          totalAmountString={`${currencySymbol}${totalAmount}`}
        />
        <WrapperContainer>
          <InputContainer>
            <Column>
              <Column className="h-center desktop-header">
                <Link onClick={this.handleGoToHome} to="/" className="logo">
                  <img alt="logo" src={Logo} />
                </Link>
                <Row className="v-center navigator">
                  <BodyXSmallText>
                    <Trans>Cart</Trans>
                  </BodyXSmallText>
                  <Icon name="ChevronRight" />
                  <BodyXSmallText className={classNames({ bold: activePageIndex === 1 })}>
                    <Trans>Customer Info</Trans>
                  </BodyXSmallText>
                  <Icon name="ChevronRight" />
                  <BodyXSmallText className={classNames({ bold: activePageIndex === 2 })}>
                    <Trans>Shipping Info</Trans>
                  </BodyXSmallText>
                  <Icon name="ChevronRight" />
                  <BodyXSmallText className={classNames({ bold: activePageIndex === 3 })}>
                    <Trans>Payment Info</Trans>
                  </BodyXSmallText>
                </Row>
              </Column>
              {this.props.children}
              <Column className="copyright">
                <BodyXSmallText>
                  <Trans>Copyright STEMI © 2018. All rights reserved</Trans>
                </BodyXSmallText>
              </Column>
            </Column>
          </InputContainer>
          <OrderSummaryContainer hide={this.state.orderSummeryHidden}>
            <Column>
              <Column className="product-items">
                {_.values(cart.getData()).map((orderItem, i) => (
                  <OrderItem key={i} orderItem={orderItem} />
                ))}
              </Column>
              <Column className="discount-codes">
                <form onSubmit={this.handleSubmitDiscountCode}>
                  <Row className="space-between">
                    <TextInput
                      className="promo-code-input"
                      placeholder="Discount code"
                      onChange={this.handleDiscountCodeChange}
                    />
                    <Button type="submit">
                      <Trans>Get Discount</Trans>
                    </Button>
                  </Row>
                </form>
                {discountMessage && <BodySmallText>{discountMessage}</BodySmallText>}
              </Column>
              <Column className="expenses">
                <Row className="space-between">
                  <BodySmallText>
                    <Trans>Subtotal</Trans>
                  </BodySmallText>
                  <BodySmallText className="bold">{`${currencySymbol}${cartAmount}`}</BodySmallText>
                </Row>
                <Row className="space-between">
                  <BodySmallText>
                    <Trans>Discount</Trans>
                  </BodySmallText>
                  <BodySmallText className="bold">
                    {discountAmount > 0 ? `${currencySymbol}${discountAmount}` : '—'}
                  </BodySmallText>
                </Row>
                <Row className="space-between">
                  <BodySmallText>
                    <Trans>Shipping</Trans>
                  </BodySmallText>
                  <BodySmallText className="bold">
                    {typeof shippingAmount !== 'number' ? (
                      '—'
                    ) : shippingAmount > 0 ? (
                      `${currencySymbol}${shippingAmount}`
                    ) : (
                      <Trans>Free</Trans>
                    )}
                  </BodySmallText>
                </Row>
                <Row className="space-between">
                  <BodySmallText>
                    <Trans>Tax</Trans>*
                  </BodySmallText>
                  <BodySmallText className="bold">
                    {typeof vatAmount !== 'number'
                      ? '—'
                      : vatAmount > 0
                      ? `${currencySymbol}${vatAmount}`
                      : '0%'}
                  </BodySmallText>
                </Row>
              </Column>
              <Row className="total space-between">
                <BodyMediumText className="bold">
                  <Trans>Total</Trans>
                </BodyMediumText>
                <BodyMediumText className="bold">{`${currencySymbol}${totalAmount}`}</BodyMediumText>
              </Row>
              <BodyXSmallText>
                *{' '}
                <Trans>
                  Tax is calculated base on the selected Shipping address country and applied when
                  Shipping method is selected.
                </Trans>
              </BodyXSmallText>
            </Column>
          </OrderSummaryContainer>
        </WrapperContainer>
      </CheckoutContainer>
    );
  }
}

const mapStateToProps = state => ({
  cartBreakdown: getCartBreakdown(state),
  cart: getDeSanitizedCart(state),
  checkout: getCheckout(state),
});

const mapDispatchToProps = {
  getDiscount,
  approveDiscount,
  approveDiscountSuccess,
  approveDiscountFailure,
  resetCheckout: () =>
    updateCheckout({
      shipping: { ...initialState.shipping },
      billing: { ...initialState.billing },
      discountPercentage: null,
      shippingModel: null,
      vatRate: null,
    }),
};

export const Checkout = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStemiI18n
)(CheckoutComponent);
