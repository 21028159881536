import React from 'react';
import { Trans } from '@lingui/react';

export const pageName = <Trans>Features</Trans>;

export const technicalSpecifications = {
  title: <Trans>Technical specifications</Trans>,
  specifications: [
    {
      left: <Trans>Size</Trans>,
      right: ['21 x 12 x 22 mm', '0.74 x 0.42 x 0.78 in']
    },
    {
      left: <Trans>Voltage</Trans>,
      right: ['3v ~ 6V',]
    },
    {
      left: <Trans>Weight</Trans>,
      right: ['9.8 g', '3.6 oz',]
    },
    {
      left: <Trans>Speed</Trans>,
      right: ['0.12 sec / 60° (4.8 V)']
    },
    {
      left: <Trans>Torque</Trans>,
      right: ['1.6 kg/cm']
    },
    {
      left: <Trans>Working Temp</Trans>,
      right: ['- 30 °C to 60 °C']
    },
    {
      left: <Trans>Gears</Trans>,
      right: [<Trans>Nylon</Trans>, <Trans>White color</Trans>]
    },
    {
      left: <Trans>Includes</Trans>,
      right: [
        '3 x M2 nut',
        '3 x M2x6mm bolt',
        '1 x M2.3x4.5mm screw',
        <React.Fragment>
          1 x <Trans>Servo motor</Trans>
        </React.Fragment>,
        <React.Fragment>
          1 x <Trans>Servo horn</Trans>
      </React.Fragment>
      ]
    },
  ]
};
