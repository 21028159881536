import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from '@lingui/react';
import _ from 'lodash';

import { withStemiI18n } from 'stemi-i18n';
import {
  Column,
  Row,
  H2,
  BodySmallText,
  BodyMediumText,
  PulsatingLogo,
} from 'playful-ui';

import { AppLinkButton } from 'components';
import { initiateCheckout } from 'modules/analytics';
import { ProductsPage } from 'modules/products';
import { getCartBreakdown, getCheckout } from 'modules/checkout';

import {
  updateOrderItem as updateOrderItemThunk,
  removeOrderItem as removeOrderItemThunk,
  getDeSanitizedCart,
  isCartEmpty as isCartEmptySelector,
} from '../redux';
import { CartPageContainer } from '../components/CartPageContainer';
import { CartItem } from '../components/CartItem';
import { CartItemsHeader } from '../components/CartItemsHeader';
import { EmptyCart } from '../components/EmptyCart';

class CartPageComponent extends React.Component {
  static route = '/cart';

  handleOnItemRemove = cartItemId => {
    const { currency, removeOrderItem } = this.props;
    removeOrderItem(cartItemId, currency);
  };

  handleUpdateQuantity = (cartItemId, quantity) => {
    const { updateOrderItem } = this.props;
    updateOrderItem(cartItemId, quantity);
  };

  addItemPrice = (total, cartItem) => {
    const { currency } = this.props;
    const { quantity, options, price } = cartItem;
    return (
      total +
      (price[currency].amount + options.price[currency].amount) * quantity
    );
  };

  handleCheckout = () => {
    const { currency, cart, cartBreakdown, initiateCheckout } = this.props;
    initiateCheckout({
      currency,
      cart: cart.getData(),
      totalAmount: cartBreakdown.totalAmount,
    });
  };

  render() {
    const { currencySymbol, isCartEmpty, cart, cartBreakdown } = this.props;
    if (!cart.isValid() && !cart.isLoading()) {
      return (
        <CartPageContainer className="h-center">
          <PulsatingLogo className="pulsating-logo" />
        </CartPageContainer>
      );
    }
    if (isCartEmpty) {
      return (
        <CartPageContainer className="h-center">
          <EmptyCart />
        </CartPageContainer>
      );
    }
    const cartItemIds = _.keys(cart.getData());
    return (
      <CartPageContainer className="h-center">
        <Column className="h-center content">
          <H2 className="full-cart">
            <Trans>Your shopping cart</Trans>
          </H2>
          <Column className="media-size-container">
            <CartItemsHeader />
            {cartItemIds.map(id => (
              <CartItem
                key={id}
                cartItemId={id}
                cartItem={cart[id]}
                onItemRemove={this.handleOnItemRemove}
                onUpdateQuantity={this.handleUpdateQuantity}
              />
            ))}
            <Column className="price-checkout-container">
              <BodySmallText>
                <Trans>Subtotal</Trans>
              </BodySmallText>
              <BodyMediumText>{`${currencySymbol}${
                cartBreakdown.totalAmount
              }`}</BodyMediumText>
              <BodySmallText className="tax">
                <Trans>
                  <i>Excluding tax and shipping</i>
                </Trans>
              </BodySmallText>
              <Row className="buttons">
                <AppLinkButton
                  className="secondary continue-shopping"
                  to={ProductsPage.route}
                >
                  <Trans>CONTINUE SHOPPING</Trans>
                </AppLinkButton>
                <AppLinkButton
                  onClick={this.handleCheckout}
                  to="/checkout/customer-information"
                >
                  <Trans>CHECKOUT</Trans>
                </AppLinkButton>
              </Row>
            </Column>
          </Column>
        </Column>
      </CartPageContainer>
    );
  }
}

const mapStateToProps = state => ({
  cart: getDeSanitizedCart(state),
  cartBreakdown: getCartBreakdown(state),
  checkout: getCheckout(state),
  isCartEmpty: isCartEmptySelector(state),
});

const mapDispatchToProps = {
  removeOrderItem: removeOrderItemThunk,
  updateOrderItem: updateOrderItemThunk,
  initiateCheckout,
};

export const CartPage = compose(
  withStemiI18n,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CartPageComponent);
