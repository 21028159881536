import styled from 'styled-components';

import { colors, spacing } from 'theme';

export const HorizontalRule = styled.hr`
  margin-top: 0;
  margin-bottom: ${spacing.BASE};
  opacity: 0.2;
  color: ${colors.slategray};
`;
