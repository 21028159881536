import styled from 'styled-components';
import { colors, media, spacing } from 'theme';

import { HeroContent } from './HeroContent';
import { ElementsContainer } from './ElementsContainer';
import { H2 } from '../Typography';

export const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 586px;
  ${media.phone`
    min-height: 437px;
  `}
  background-image: linear-gradient(
    1deg,
    rgba(38, 133, 183, 0),
    ${colors.applyOpacity('rgba(10, 39, 158, 0.8)', 0.08)}
  );
  overflow: hidden;

  &.textual {
    min-height: 376px;
    ${media.phone`
      min-height: 335px;
    `};
  }

  &.textual h2,&.textual p {
    text-align: center;
    max-width: 754px;
    ${media.tablet`
      padding-right: 56px;
      padding-left: 56px;
    `};
    ${media.phone`
      padding-right: 20px;
      padding-left: 20px;
    `};
  }

  &.textual ${HeroContent} {
    flex-direction: column;
    padding-top: ${spacing.BASE};
    ${media.tablet`
      padding-top: ${spacing.BASE_PLUS};
      justify-content: center;
      padding-bottom: 0;
    `};
    ${media.phone`
      padding-top: ${spacing.SMALL};
      padding-bottom: 0;
    `};
  }

  &.textual ${HeroContent} > ${H2}{
    margin-bottom: ${spacing.SMALL};
    ${media.phone`
      margin-bottom: ${spacing.TINY};
    `};
  }

  &.textual ${ElementsContainer} {
    background-color: rgb(240, 243,249);
  }
`;
