import React from 'react';
import styled from 'styled-components';

import overlay1 from './assets/img-overlay-1.png';

const overlays = [overlay1];

const StyledContainer = styled.div`
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-blend-mode: multiply;
`;

const MediaOverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* eslint-disable object-curly-newline */
export const MediaOverlay = ({ index, children, className, onClick }) => {
  const overlay = overlays[index - 1];
  return (
    <MediaOverlayContainer className={className}>
      {children}
      <StyledContainer style={{ backgroundImage: `url(${overlay})` }} onClick={onClick} />
    </MediaOverlayContainer>
  );
};
