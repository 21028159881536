import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { navigate } from 'gatsby';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import dropin from 'braintree-web-drop-in';
import LoadingOverlay from 'react-loading-overlay';

import { withStemiI18n } from 'stemi-i18n';
import {
  Button,
  BodySmallText,
  BodyMediumText,
  BodyXSmallText,
  Column,
  Row,
  Radio,
  TextInput,
  Modal,
} from 'playful-ui';

import { AppLinkButton, PlayfulLink } from 'components';
import { thirdCheckoutStep } from 'modules/analytics';
import { CartPage, isCartEmpty as isCartEmptySelector, getDeSanitizedCart } from 'modules/cart';
import { getCartBreakdown, getCheckout } from 'modules/checkout';

import { updateCheckout, charge } from '../redux';
import { Checkout } from '../components/Checkout';
import { BillingAddressContainer } from '../components/BillingAddressContainer';
import { PaymentMethodContainer } from '../components/PaymentMethodContainer';
import { RoundedBox } from '../components/RoundedBox';
import { OrderCompletePage } from './OrderCompletePage';
import { content } from './content/PaymentMethodPage';
import { ShippingMethodPage } from './ShippingMethodPage';
import { CustomerInformationPage } from './CustomerInformationPage';

class PageComponent extends React.Component {
  static route = '/checkout/payment-method';
  static title = 'Payment method';

  constructor(props) {
    super(props);
    const { checkout } = props;
    this.state = {
      billingSameAsShipping: true,
      billing: { ...checkout.billing, countryCodeAlpha2: checkout.shipping.countryCodeAlpha2 },
      paymentMethodRequestable: false,
      isModalOpen: false,
      error: null,
    };
  }

  componentDidMount() {
    const { checkout, isCartEmpty, cartBreakdown } = this.props;
    if (isCartEmpty) {
      typeof window !== 'undefined' && navigate(CartPage.route);
      return;
    } else if (!checkout.shippingModel) {
      typeof window !== 'undefined' && navigate(ShippingMethodPage.route);
      return;
    }
    dropin
      .create({
        authorization: checkout.clientToken,
        selector: '#braintree-dropin-container',
        threeDSecure: {
          amount: cartBreakdown.totalAmount,
        },
      })
      .then(instance => {
        this.dropinInstance = instance;
        this.dropinInstance.on('paymentOptionSelected', event => {
          this.setState({ error: null });
        });
        this.dropinInstance.on('paymentMethodRequestable', event => {
          this.setState({ error: null });
        });
      });
    this.setState({
      billingSameAsShipping: true,
      billing: { ...checkout.billing, countryCodeAlpha2: checkout.shipping.countryCodeAlpha2 },
      paymentMethodRequestable: false,
      isModalOpen: false,
      error: null,
    });
    this.props.dispatch(thirdCheckoutStep());
  }

  handleRadioChange = billingSameAsShipping => () => {
    this.setState({ billingSameAsShipping: billingSameAsShipping });
  };

  updateStateProperty = event => {
    this.setState({
      billing: { ...this.state.billing, [event.target.name]: event.target.value },
    });
  };

  updateCountry = countryCodeAlpha2 => {
    this.setState({
      billing: { ...this.state.billing, countryCodeAlpha2 },
    });
  };

  updateRegion = region => {
    this.setState({
      billing: { ...this.state.billing, region },
    });
  };

  handleGoBack = e => {
    this.props.dispatch(updateCheckout({ shippingModel: null }));
  };

  onNoPaymentMethodRequestable = value => this.setState({ paymentMethodRequestable: value });

  handleSubmit = e => {
    e.preventDefault();
    if (this.dropinInstance) {
      const billing = this.state.billingSameAsShipping
        ? this.props.checkout.shipping
        : this.state.billing;
      this.props.dispatch(updateCheckout({ billing }));
      this.dropinInstance.requestPaymentMethod((requestPaymentMethodErr, payload) => {
        if (requestPaymentMethodErr) {
          console.error(requestPaymentMethodErr);
          const errorMessage =
            requestPaymentMethodErr.message === 'No payment method is available.'
              ? content(this.props.i18n).errorMessage
              : requestPaymentMethodErr.message;
          this.setState({ error: errorMessage });
          this.dropinInstance.clearSelectedPaymentMethod();
        } else {
          const { currency, cart, checkout, cartBreakdown, charge } = this.props;
          this.setState({ isModalOpen: true });
          charge(payload.nonce, cart.getData(), { ...checkout, cartBreakdown, billing }, currency)
            .then(
              resolved => {
                console.log(resolved);
                this.setState({ isModalOpen: false });
                navigate(OrderCompletePage.route);
              },
              rejected => {
                this.setState({ isModalOpen: false, error: rejected.message });
              }
            )
            .catch(error => {
              this.setState({ isModalOpen: false, error: error.message });
            });
        }
      });
    }
  };

  render() {
    const { i18n, checkout, isCartEmpty } = this.props;
    if (!checkout.shippingModel || isCartEmpty) {
      return null;
    }
    const { billing, isModalOpen } = this.state;
    const transPlaceholder = content(i18n);

    const { customerEmail, shippingModel, shipping } = checkout;
    const { firstName, lastName, address, postal, city, countryCodeAlpha2 } = shipping;
    return (
      <Checkout activePageIndex={3}>
        <Modal isOpen={isModalOpen}>
          <LoadingOverlay active={true} spinner color="white" text="Processing payment" />
        </Modal>
        <PaymentMethodContainer>
          <RoundedBox>
            <Column>
              <Row className="space-between v-center">
                <BodySmallText>{transPlaceholder.contact}</BodySmallText>
                <BodyXSmallText>
                  <PlayfulLink onClick={this.handleGoBack} to={CustomerInformationPage.route}>
                    {transPlaceholder.change}
                  </PlayfulLink>
                </BodyXSmallText>
              </Row>
              <BodyXSmallText className="margin-top">{customerEmail}</BodyXSmallText>
            </Column>
            <Column>
              <Row className="space-between v-center">
                <BodySmallText>{transPlaceholder.shipTo}</BodySmallText>
                <BodyXSmallText>
                  <PlayfulLink onClick={this.handleGoBack} to={CustomerInformationPage.route}>
                    {transPlaceholder.change}
                  </PlayfulLink>
                </BodyXSmallText>
              </Row>
              <BodyXSmallText className="margin-top">
                {`${firstName} ${lastName}, ${address}, ${postal} ${city}, ${countryCodeAlpha2}`}
              </BodyXSmallText>
            </Column>
            <Column>
              <Row className="space-between v-center">
                <BodySmallText>{transPlaceholder.method}</BodySmallText>
                <BodyXSmallText>
                  <PlayfulLink onClick={this.handleGoBack} to={ShippingMethodPage.route}>
                    {transPlaceholder.change}
                  </PlayfulLink>
                </BodyXSmallText>
              </Row>
              <BodyXSmallText className="margin-top">
                {shippingModel ? shippingModel.description.props.id : null}
              </BodyXSmallText>
            </Column>
          </RoundedBox>

          <BodyMediumText className="margin-top">{transPlaceholder.paymentMethod}</BodyMediumText>

          <div id="braintree-dropin-container" />

          <BodySmallText>{transPlaceholder.allTransactions}</BodySmallText>

          <BodyMediumText className="error">{this.state.error}</BodyMediumText>

          <BodyMediumText className="margin-top-bot">{transPlaceholder.billing}</BodyMediumText>

          <form name="payment-method" onSubmit={this.handleSubmit}>
            <RoundedBox>
              <Row>
                <Radio
                  onChange={this.handleRadioChange(true)}
                  name="radio_group"
                  id="r1"
                  required
                />
                <label htmlFor="r1">
                  <BodySmallText>{transPlaceholder.SameAsShipping}</BodySmallText>
                </label>
              </Row>

              <Column>
                <Row>
                  <Radio
                    onChange={this.handleRadioChange(false)}
                    name="radio_group"
                    id="r2"
                    required
                  />
                  <label htmlFor="r2">
                    <BodySmallText>{transPlaceholder.differentBilling}</BodySmallText>
                  </label>
                </Row>
                <BillingAddressContainer hide={this.state.billingSameAsShipping}>
                  <TextInput
                    name="firstName"
                    className="input"
                    value={billing.firstName}
                    onChange={this.updateStateProperty}
                    placeholder={transPlaceholder.firstName}
                    required={!this.state.billingSameAsShipping}
                  />
                  <TextInput
                    name="lastName"
                    className="input"
                    value={billing.lastName}
                    onChange={this.updateStateProperty}
                    placeholder={transPlaceholder.lastName}
                    required={!this.state.billingSameAsShipping}
                  />
                  <TextInput
                    name="companyName"
                    className="input"
                    value={billing.companyName}
                    onChange={this.updateStateProperty}
                    placeholder={transPlaceholder.companyName}
                  />
                  <TextInput
                    name="address"
                    className="input"
                    value={billing.address}
                    onChange={this.updateStateProperty}
                    placeholder={transPlaceholder.address}
                    required={!this.state.billingSameAsShipping}
                  />
                  <TextInput
                    name="apartment"
                    className="input"
                    value={billing.apartment}
                    onChange={this.updateStateProperty}
                    placeholder={transPlaceholder.apartment}
                  />
                  <TextInput
                    name="city"
                    className="input"
                    value={billing.city}
                    onChange={this.updateStateProperty}
                    placeholder={transPlaceholder.city}
                    required={!this.state.billingSameAsShipping}
                  />
                  <TextInput
                    name="postal"
                    className="input"
                    value={billing.postal}
                    onChange={this.updateStateProperty}
                    placeholder={transPlaceholder.postalCode}
                    required={!this.state.billingSameAsShipping}
                  />
                  <CountryDropdown
                    name="countryCodeAlpha2"
                    valueType="short"
                    value={billing.countryCodeAlpha2}
                    onChange={this.updateCountry}
                    required={!this.state.billingSameAsShipping}
                  />
                  <RegionDropdown
                    valueType="short"
                    countryValueType="short"
                    country={billing.countryCodeAlpha2}
                    value={billing.region}
                    onChange={this.updateRegion}
                    required={!this.state.billingSameAsShipping}
                  />
                  <TextInput
                    name="phone"
                    className="input"
                    value={billing.phone}
                    onChange={this.updateStateProperty}
                    placeholder={transPlaceholder.phone}
                    required={!this.state.billingSameAsShipping}
                  />
                </BillingAddressContainer>
              </Column>
            </RoundedBox>

            <Row className="space-between buttons">
              <AppLinkButton
                className="secondary large"
                onClick={this.handleGoBack}
                to={ShippingMethodPage.route}
              >
                {transPlaceholder.returnToShippingMethod}
              </AppLinkButton>
              <Button className="large" type="submit">
                {transPlaceholder.completeOrder}
              </Button>
            </Row>
          </form>
        </PaymentMethodContainer>
      </Checkout>
    );
  }
}

const mapStateToProps = state => ({
  cart: getDeSanitizedCart(state),
  checkout: getCheckout(state),
  isCartEmpty: isCartEmptySelector(state),
  cartBreakdown: getCartBreakdown(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators({ charge }, dispatch),
});

export const PaymentMethodPage = compose(
  withStemiI18n,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PageComponent);
