import styled from 'styled-components';

import { spacing, colors, media, fonts } from 'theme';

import { BodySmallText } from '../Typography';
import { Column } from '../Column';
import { Row } from '../Row';

export const SkillsHighlightContainer = styled(Column)`
  width: 100%;
  max-width: 754px;
  margin: 0 30px;
  ${media.phone`
    margin: 0 20px;
  `};

  & > ${Row}.before-after {
    margin-top: ${spacing.SMALL};
  }

  & > ${Row} > ${BodySmallText} {
    text-align: center;
    width: 104px;
  }
  & ${BodySmallText}.before {
    border-top: 4px solid ${colors.turquoise};
  }
  & ${BodySmallText}.after {
    border-top: 4px solid ${colors.persianblue};
  }

  & div.blue-dot {
    margin-top: ${spacing.LARGE};
    ${media.phone`
      margin-top: ${spacing.BASE_PLUS};
    `} align-self: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 178px;
    width: 178px;
    border-radius: 50%;
    background-color: ${colors.darkblue};
  }
  & div.blue-dot h2 {
    ${fonts.h2};
    margin: 0;
    color: ${colors.white};
  }
  & div.blue-dot ${BodySmallText} {
    color: ${colors.white};
  }
`;
