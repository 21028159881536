import styled from 'styled-components';
import {
  Section,
  H2,
  BodyLargeText,
  Column,
  QuoteHighlight,
  ProductHighlight,
  Button,
  spacing,
  media
} from 'playful-ui';


export const AdventureContainer = styled.div`
  margin-bottom: 120px;
  ${media.tablet`
    margin-bottom: 80px;
  `};

  & ${Section} > ${H2}, & ${Section} > ${BodyLargeText} {
    text-align: center;
  }

  & ${QuoteHighlight} {
    ${media.tablet`
      margin: 0 30px;
    `};
    ${media.phone`
      margin: 0 20px;
    `};
  }

  & div.small-card > ${Column} > ${BodyLargeText} {
    margin-top: ${spacing.SMALL};
    margin-bottom: ${spacing.BASE};
    ${media.tablet`
      margin-top: ${spacing.TINY};
      margin-bottom: ${spacing.SMALL};
    `};
  }
  
  & div.margin-top {
    margin-top: 80px;
    ${media.phone`
      margin-top: ${spacing.LARGE};
    `};
  }

  & ${Button} {
    margin-top: ${spacing.LARGE};
  };

  & div.features img:nth-child(2) {
    display: none;
  };
  & div.features ${ProductHighlight} {
    margin-top: ${spacing.BASE};
  }

`;
