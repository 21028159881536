import styled, { css } from 'styled-components';
import { fonts, colors, spacing } from 'theme';

const focusColor = colors.applyOpacity(colors.persianblue, 0.5);

function borderCSS(props) {
  if (props.errorMessage) {
    return `
      margin: 0px;
      border: solid 2px ${colors.crimson};
    `;
  }
  return `
    margin: 1px;
    border: solid 1px ${colors.mischka};
  `;
}

export const StepperContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 335px;
  &.small {
    min-width: 104px;
    display: inline-flex;
  }

  & .input {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 335px;
  }
  &.small .input {
    max-width: 102px;
    min-width: unset;
    align-items: center;
  }

  & .buttons {
    position: absolute;
    top: 17%;
    align-self: flex-end;
    padding-right: ${spacing.TINY};
  }
  &.small .buttons {
    top: 1px;
    align-self: flex-start;
    display: flex;
    width: 103px;
    justify-content: space-between;
    padding: 0;
  }

  & input[type=text] {
    ${css`
      ${fonts.textInput};
    `}
    color: ${colors.midnight};
    min-height: 28px;
    padding-left: ${spacing.SMALL};
    padding-top: ${spacing.TINY};
    padding-bottom: ${spacing.TINY};
    margin: 1px;
    border-radius: 4px;
    background-color: ${colors.applyOpacity('#ffffff', 0.8)};
    ${props => borderCSS(props)}
  }
  &.small input[type=text] {
    padding-top: 3px;
    padding-bottom: 3px;
    max-width: 40px;
    padding-right: ${spacing.TINY};
    padding-left: ${spacing.TINY};
    text-align: center;
    border-radius: 0;
    box-sizing: border-box;
  }
  & input[type=text]:focus {
    outline-width: 0;
    margin: 0px;
    border: solid 2px ${props => (props.errorMessage ? colors.crimson : focusColor)};
  }

  & label, & a {
    margin-top: ${spacing.TINY};
    margin-bottom: ${spacing.TINY};
    opacity: 0.8;
    ${css`
      ${fonts.label};
    `}
    color: ${colors.slategray};
  }
  & a {
    position: absolute;
    right: 0;
    text-decoration: none;
  }
  & p {
    width: fit-content;
    ${fonts.bodyXSmall}
    margin-top: 4px;
    margin-bottom: 4px;
    color: ${colors.crimson};
  }
`;
