import React from 'react';
import styled from 'styled-components';
import {
  Row,
  Column,
  ArticleCard,
  spacing,
  media,
  LinkButton,
} from 'playful-ui';

import { text } from '../content/text';
import imgBlogFunderbeam from './assets/img-blog-funderbeam.png';
import boyHexapod from './assets/img-boy-hexapod.jpg';
import grammarSchool from './assets/img-grammar-school.png';

const StyledRow = styled(Row)`
  margin-top: 80px;
  ${media.tablet`
    margin-top: ${spacing.LARGE};
  `};
  
  & a {
    text-decoration: none;
    margin-bottom: ${spacing.BASE};
  };
  & > ${Column}:first-child {
    margin-right: 30px;
    ${media.tablet`
      margin-right: 0;
    `};
    ${media.phone`
      margin-right: 0;
    `};
  }
`;

const StyledColumn = styled(Column)`
  margin-top: 80px;
  ${media.phone`
    margin-top: 0px;
  `};
`;

export const LatestArticles = () => (
  <React.Fragment>
    <StyledRow className="h-center tablet-vertical mobile-vertical">
      <Column className="right">
        <a href="https://medium.com/@stemi.education/stemi-is-fundraising-on-the-funderbeam-com-ad0c6a52489b">
          <ArticleCard
            image={imgBlogFunderbeam}
            date="Dec 9, 2019 · 3 min read"
            title="Stemi is fundraising on the Funderbeam.com"
            short="Can we radically improve student achievement? The answer is YES."
            author="STEMI"
          />
        </a>
      </Column>
      <Column>
        <a href="https://medium.com/@stemi.education/how-stemi-ignited-stem-spark-in-zrenjanin-grammar-school-25ea6a56343b">
          <ArticleCard
            image={grammarSchool}
            date="Mar 5, 2019 · 4 min read"
            title="How STEMI ignited STEM spark in Zrenjanin Grammar School"
            short="Let's see what students think about the charming hexapods."
            author="STEMI"
            small
          />
        </a>
        <a href="https://medium.com/@stemi.education/stemi-milestones-in-2018-6aa3ad6b7081">
          <ArticleCard
            image={boyHexapod}
            date="Jan 10, 2019 · 3 min read"
            title="STEMI milestones in 2018"
            short="Let’s take a look at what we’ve been up to in the last year."
            author="STEMI"
            small
          />
        </a>
      </Column>
    </StyledRow>
    <StyledColumn className="h-center">
      <LinkButton
        className="secondary"
        href="https://medium.com/@stemi.education/"
      >
        {text.viewAllArticles}
      </LinkButton>
    </StyledColumn>
  </React.Fragment>
);
