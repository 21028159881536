import React from 'react';
import { PlayfulLink } from 'components';
import { Trans } from '@lingui/react';
import { BodyMediumText, A } from 'playful-ui';

import { PersonalDataProtection } from '../PersonalDataProtection';
import { PaymentConditions } from '../PaymentConditions';

export const termsAndConditions = {
  pageTitle: <Trans>General Terms and Conditions</Trans>,
  general: (
    <Trans>
      General Terms and Conditions of Using Pages and the Internet Trade Service
    </Trans>
  ),
  generalParagraph: (
    <Trans>
      Please read these General Terms and Conditions of using Pages and the
      Internet Trade Service (hereinafter: "General Terms and Conditions")
      carefully before beginning of use of{' '}
      <A href="http://stemi.education" target="_blank">
        www.stemi.education
      </A>{' '}
      web page or any of its subdomains (such as{' '}
      <A href="https://learn.stemi.education" target="_blank">
        learn.stemi.education
      </A>, hereinafter: "Pages") and/or services (provider of the service is
      the company STEMI d.o.o. with registered seat in the Republic of Croatia,
      Rijeka, Radmile Matejčić 10, OIB: 91713184849, "STEMI" or the "Service
      Provider". Should you have any questions or uncertainties regarding
      conditions of use, please contact us at the e-mail address:
      info@stemi.education.
    </Trans>
  ),
  acceptance: <Trans>Acceptance of General Terms and Conditions</Trans>,
  acceptanceParagraph: (
    <Trans>
      By accessing or using these Pages or any part of the content or services
      thereof, you accept these General Terms and Conditions as well as any
      other rules and conditions of using the relating Pages and services
      provided through them. In case you do not agree with the above-mentioned,
      please stop using and do not use any further Pages and services provided
      through them.<br />
      <br />
      In these General Terms and Conditions the terms written in bold letters
      shall have the following meaning:<br />
      <br />
      <b>Pages</b> mean the{' '}
      <A href="http://stemi.education" target="_blank">
        www.stemi.education
      </A>{' '}
      web page and all of its subdomains such as:{' '}
      <A href="https://learn.stemi.education" target="_blank">
        learn.stemi.education
      </A>{' '}
      etc.<br />
      <br />
      <b>User</b> means every person, legal or natural, who is accessing Pages
      and who is, pursuant to the given user name and password, authorized to
      purchase products on Pages. Parents are liable for actions performed on
      our Pages by the children in accordance with the positive regulations of
      the Republic of Croatia. A child is every natural person under 18 years,
      pursuant to the positive regulations of the Republic of Croatia.<br />
      <br />
      <b>Internet Trade Service</b> is a service that allows the Users to
      purchase products and services via Pages owned by the Service Provider.
      Internet Trade Service is available exclusively to registered, adult Users
      with the capacity to exercise rights, what they confirm by entering their
      own personal data.<br />
      <br />
      <b>Service Provider</b> is the company STEMI d.o.o. with registered seat
      in the Republic of Croatia, Rijeka, Radmile Matejčić 10, OIB: 91713184849,
      which is engaged in activities from the fields of science, technology,
      engineering and mathematics and the products of which are mainly intended
      for youth as learning tools for robotics, electronic and programming.
    </Trans>
  ),
  changes: <Trans>Changes to General Terms and Conditions and Pages</Trans>,
  changesParagraph: (
    <Trans>
      STEMI reserves the right to make amendments or addendums to these General
      Terms and Conditions at any time and such amendments and addendums enter
      into force on the day of their publication on Pages. Continuation of
      access to Pages or continuation of the use of any part of their content or
      services shall be considered as your acceptance of and consent to such
      amended General Terms and Conditions. STEMI recommends you to check these
      General Terms and Conditions periodically in order to be acquainted with
      possible changes thereto.<br />
      <br />
      STEMI reserves the right to amend, supplement or abolish at any time and
      without prior announcement any part of its business operations, which
      includes Pages, i.e. any part thereof, services, subpages or services
      provided through them. The mentioned right includes, but is not limited to
      the change of availability time, content, availability of new data,
      manners of transmission as well as the right to access or use Pages.<br />
      <br />
      It is your duty and obligation to use Pages in accordance with positive
      regulations and general moral and ethical principles. STEMI is entitled to
      perform control over the content of Pages at any time in order to ensure
      compliance with these General Terms and Conditions and positive
      regulations.
    </Trans>
  ),
  registration: <Trans>Registration of Users </Trans>,
  registrationParagraph: (
    <Trans>
      In order to use certain content and services on these Pages, you have to
      log in into the system with the e-mail address, password and other data
      you will have to indicate in the registration process.<br />
      <br />
      In the registration process and upon filling out the personal profile you
      undertake to indicate accurate, complete and valid data.<br />
      <br />
      In the registration process it is not allowed to use names, nicknames or
      titles which are owned by third parties, unlawful, harmful, threatening,
      abusive, disturbing, defamatory, or in any manner harmful to children and
      minors. It is not allowed to use names of public figures. In the case of
      breach of these rules, STEMI is entitled to deactivate or delete your user
      account.<br />
      <br />
      You are not allowed to indicate names of companies, brands or web pages in
      addition to your name and surname.<br />
      <br />
      STEMI reserves the right to decline registration, as well as to cancel or
      deny the possibility of further use of the user account for which the User
      is registered in the case when he/she is violating these General Terms and
      Conditions or slowing down or hindering the operation of Pages. STEMI
      reserves the right to undertake adequate measures against such Users.<br />
      <br />
      We will collect certain personal data from you and your child in the
      registration process, which is subject to{' '}
      <PlayfulLink to={PersonalDataProtection.route}>
        General Terms and Conditions of Personal Data Protection.
      </PlayfulLink>
    </Trans>
  ),
  use: <Trans>Use of Portal and Interaction of Users</Trans>,
  useParagraph: (
    <Trans>
      <BodyMediumText>
        By activating the user account you accept liability for all activities
        performed by its use on Pages.<br />
        <br />
        You are obliged to look after your user's password and change it from
        time to time. STEMI is not liable for cases of abuse of user's password
        but it will act immediately after you report the abuse in order to
        protect your rights. You can report the abuse via electronic mail to the
        address: info@stemi.education<br />
        <br />Every User is personally liable for his/her interaction and
        communication with other users of Pages and accepts that such
        interaction and communication does not have a confidential content and,
        in accordance with that, STEMI is entitled but not obligated to monitor
        communication conducted via Pages between the users.<br />
        <br />
        You undertake not to use Pages i.e. their content and services in order
        to:<br />
      </BodyMediumText>
      <ul>
        <li>
          <BodyMediumText>
            represent yourself for purposes of your promotion
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            damage, intimidate or threaten third parties, i.e. to spread hate
            speech
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            encourage, favour encouragement or spread hate or discrimination
            based on race or ethnicity or skin colour, sex, language, religion,
            political or other conviction, national or social origin, financial
            status, trade union membership, education, social status, marital or
            family status, age, health condition, disability, genetic
            inheritance, gender identity, expression or sexual orientation, and
            anti Semitism and xenophobia, ideas of fascism, nationalism,
            communist and other totalitarian regimes
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            publish, transfer and/or exchange content contrary to positive
            regulations, moreover the insulting, vulgar, racist, chauvinistic,
            pornographic content which represents the hate speech or is improper
            in any other manner
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            harass, jeopardize, insult, threaten, deceive third parties or in
            any other manner jeopardize their rights
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            publish, transfer and/or exchange information which you know or
            should have known to be false and publishing of which might damage
            other Users and/or Pages
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            publish any unlawful or unauthorized activity
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            transfer or distribute any unlawful content
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            publish, transfer and/or exchange third parties personal data
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            publish, transfer and/or exchange content containing viruses or
            other malicious software or other programs designed for the purpose
            of destruction or limitation of the operation of any computer
            software and/or hardware and telecommunication equipment
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            advertise or ask any other User to purchase or sell any product
            and/or service, use information obtained from the content of Pages
            in order to contact other users for purposes of advertising,
            promotion or selling products without his/her authorization and
            publish, transfer and/or exchange unwanted contents to users without
            their consent or request
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            perform any pyramid schemes, chain letters, junk mail, spam or any
            other form of multiple sending of commercial or other content;
            publish, transfer and/or exchange a content protected by copyright
            or other intellectual property rights of third parties
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            publish, transfer and/or exchange a content protected by copyright
            or other intellectual property rights of third parties; suggest your
            connection with Pages
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>suggest your connection with Pages</BodyMediumText>
        </li>
        <li>
          <BodyMediumText>violate any positive regulation</BodyMediumText>
        </li>
      </ul>
      <br />
      <BodyMediumText>
        You undertake to abide by the warning and instructions of administrators
        and/or moderators of Pages.<br />
        <br />
        Administrators and moderators of Pages are in no case liable for data
        published by the users. They have a right to delete, change, dislocate
        or erase any content of Pages at their own discretion and without prior
        announcement.<br />
        <br />
        All potential disputes arising from mutual relations of the users (for
        the authenticity of data on User, contest content etc.) are resolved
        exclusively between the users. STEMI is not liable for any damage
        occurring as a result of such relations.
      </BodyMediumText>
    </Trans>
  ),
  content: <Trans>Content and Services</Trans>,
  contentParagraph: (
    <Trans>
      Pages allow you to use different contents and functions. Contents are
      created by STEMI, our associates, and partners, but also by you, the
      users.<br />
      <br />
      Publication of your contents on Pages does not mean they represent
      attitudes, opinions i.e. recommendations of STEMI or that we agree with
      them or support them in any manner.<br />
      <br />
      STEMI is not liable for contents published by the users, but users who
      published such content are primarily liable for it.<br />
      <br />
      STEMI is not liable for contents of partners or third parties.<br />
      <br />
      Pages also contain documents, data, information as well as links to other
      web pages created by third parties which will, to the extent necessary and
      possible, be designated as such. STEMI has no control whatsoever over
      mentioned documents, data, information or other web pages and hereby
      entirely waives every liability including but not limited to accuracy,
      integrity, and availability of content on web pages created by third
      parties. STEMI waives every liability for any content displayed on such
      pages of third parties, as well as for any potential products or services
      acquired through such third pages. It shall not be considered that such
      third parties are associated with STEMI in any way simply because of an
      existence of the connection with a web page.<br />
      <br />
      STEMI is not liable to the greatest extent possible pursuant to positive
      regulations for any damage which might be caused by you or other Users in
      relation to the use of or relying on any content published on Pages.<br />
      <br />
      All publications, messages, texts, demonstrations, photographs, video
      records and other materials (hereinafter: the content) published on Pages,
      transferred via Pages or linked from Pages are the sole liability of a
      person from who such content comes from. STEMI gives no warranties
      regarding accuracy, integrity or authenticity of such content.<br />
      <br />
      In case of any claims of third parties towards STEMI on the grounds of
      indemnification of damage, or on any grounds in relation to the content
      you delivered, you undertake to assume the entire liability for such
      damage and to undertake all actions in order to remove such liability from
      STEMI and to reimburse all damages incurred pursuant to such claims of
      third parties to STEMI.<br />
      <br />
      STEMI reserves the right to change, temporarily suspend, delay and stop
      exhibition or provision of any contents and services at any time and for
      any reason. STEMI also has a right to limit the access to certain
      services, contents and parts thereof without prior announcement
    </Trans>
  ),
  copyright: <Trans>Copyright</Trans>,
  copyrightParagraph: (
    <Trans>
      <BodyMediumText>
        All contents, documents, data and information published on Pages,
        including all contents given by Users, are protected by copyright and
        other intellectual property rights and cannot be published, reproduced,
        distributed or in any manner used without expressed prior written
        approval of the holder of a certain right and STEMI.<br />
        <br />
        The user in particularly does not have a right to download, copy, alter,
        edit, distribute, display, delete, send, sell, resell, adjust, change
        the content, create derivatives, include on other web pages or media or
        use in any manner except for private purposes for home use.<br />
        <br />
        The editorial content of Pages is the property of STEMI.<br />
        <br />
        By using these Pages you are given a limited, non-exclusive,
        non-transferable and revocable license to use the content of Pages in a
        manner to view them for personal purposes and with respecting the
        following conditions:<br />
      </BodyMediumText>
      <ul>
        <li>
          <BodyMediumText>
            User uses contents and services at his/her own risk
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            User can use contents and services only with respecting the
            copyrights and other intellectual property rights of its holders and
            STEMI
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            User cannot make any modifications to contents and services without
            express prior written consent of STEMI
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            On all contents and materials used, when the nature of things
            allows, there must be visible notes on copyright, trade mark, brand,
            logo
          </BodyMediumText>
        </li>
      </ul>
      <br />
      <BodyMediumText>
        In order to avoid any doubt, STEMI expressly reserves and does not
        confer to User any other rights in relation to the content of Pages and
        prohibits the use of Pages except as determined in these General Terms
        and Conditions and as potentially allowed by instructions on the very
        Pages.<br />
        <br />
        By publication of your content (contest, profile, comments etc.) on
        Pages, you give STEMI the right to use such content unlimited in time
        and territory and without obligation to pay any compensation. STEMI has
        a right to publish, reproduce, distribute, modify, adjust, translate and
        use such content in any other manner. This does not refer to your
        personal data
      </BodyMediumText>
    </Trans>
  ),
  warranties: <Trans>Warranties of Users</Trans>,
  warrantiesParagraph: (
    <Trans>
      <BodyMediumText>
        Upon publication or upload of your content on Pages, you give STEMI the
        following warranties in respect of such content:<br />
      </BodyMediumText>
      <ul>
        <li>
          <BodyMediumText>
            that you are the author of the concerning content i.e. you are
            authorized by the author or another holder of the copyright or
            another intellectual property right to publish such content
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            that the content is in accordance with all positive regulations
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            that the publication of contents protected by copyright or other
            intellectual property right in any form will not violate rights of
            third parties in any manner, including especially copyright and
            other intellectual property rights, reputation, honor and dignity,
            the right to privacy and other rights of third parties
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            that using these contents on Pages will not cause damage, pecuniary
            or non pecuniary to STEMI, other Users or third parties
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            that contents are, to your best knowledge, complete, authentic and
            accurate
          </BodyMediumText>
        </li>
      </ul>
      <br />
      <BodyMediumText>
        Hereby you agree to assume all potential claims for indemnification of
        damage which will be set up against STEMI due to your breach of these
        General Terms and Conditions including above-mentioned warranties and to
        reimburse all damages and costs incurred due to the breach of the
        aforementioned to STEMI and other damaged users and third parties.
      </BodyMediumText>
    </Trans>
  ),
  liability: <Trans>Liability</Trans>,
  liabilityParagraph: (
    <Trans>
      STEMI is not liable for any damage which might occur due to interruption
      of availability, non-publication, cessation of exhibition or provision of
      any contents and services, i.e. deletion of content or error in the
      functioning of Pages.<br />
      <br />
      You use Pages at your own risk. STEMI, as well as no third party
      associated with it, does not warrant in any manner that the use of Pages
      will not be interrupted or that there will be no difficulties in their
      operation. You accept that the access to these Pages may sometimes be
      interrupted, temporarily unavailable or turned off due to routine page
      maintenance and for other reasons STEMI is not liable for.<br />
      <br />
      You are acquainted and agree with the fact that when using the Internet
      technical problems might sometimes occur with its functioning and by that
      the temporary interruption of services. Such and similar events are beyond
      our control and for that reason, STEMI cannot be liable for any loss of
      data or any other event which might occur while using and providing
      services. STEMI shall in no case be liable for any damages which might
      occur as the result of using or inability to use these Pages.
    </Trans>
  ),
  usage: <Trans>Using the Internet Trade Service</Trans>,
  usageParagraph: (
    <Trans>
      The ordering of products is conducted by electronic means. By click on the
      icon button “Add to Cart”, the selected product is stored in the User's
      bag. For continuation of the process, it is necessary to select “Checkout”
      at “Your cart” page where all selected products are found. It is
      considered the product is ordered at the moment the User completes the
      entire ordering process. The User will receive the message by which the
      Service Provider will confirm the order is received on the registered
      electronic mail address.<br />
      <br />
      If Service Provider is not able to deliver some of the ordered products,
      it will contact the User and inform him/her of that. The User will be
      offered a choice to cancel the order and receive the return of the paid
      amount or to replace the product with another product, as he/she chooses.
    </Trans>
  ),
  products: <Trans>Products and Services Prices</Trans>,
  productParagraph: (
    <Trans>
      Product prices are stipulated in Croatian Kuna (HRK) and payable in
      counter value of domicile currency of the User. Users can view the amount
      of product price converted in American Dollars (USD) and Euros (EUR) on
      the page. Payment is conducted in the currency of User’s country, in the
      amount representing the equivalent of the amount stipulated in HRK.<br />
      <br />
      Prices of products include taxes and delivery cost. The total amount of
      price the User has to pay for the ordered product (including delivery and
      taxes) is visible at the end of the process of placing orders.<br />
      <br />
      Product prices are subject to changes until the moment of placing the
      order of which change the Service Provider will inform the User in clear
      and understandable manner.<br />
      <br />
      Upon selection of certain product, the User can get additional information
      on all additional details via the contact address of Service Provider
      electronic mail.
    </Trans>
  ),
  payment: <Trans>Payment Methods</Trans>,
  paymentParagraph: (
    <Trans>
      <BodyMediumText>
        The user can pay the ordered products in the following manners, whereat
        the payment method is selected at the moment it will be requested on
        Pages:<br />
      </BodyMediumText>
      <ul>
        <li>
          <BodyMediumText>
            bank transfer payment by payment on the bank account of the Service
            Provider
          </BodyMediumText>
        </li>
        <li>
          <BodyMediumText>
            credit or debit cards of credit cards companies with whom the
            Service Provider collaborates
          </BodyMediumText>
        </li>
      </ul>
      <br />
      <BodyMediumText>
        In the case of bank transfer payment, the User will, after placing the
        order, receive the proposal which will include all the necessary data
        for payment and delivery of products via electronic mail.<br />
        <br />
        In case of credit or debit cards payment, the payment is performed upon
        placing the order.<br />
        <br />
        You can learn more about payment methods in the{' '}
        <PlayfulLink to={PaymentConditions.route}>
          General Terms and Conditions of Payment via Internet.
        </PlayfulLink>
        <br />
        <br />
        The sale and purchase agreement is in any way concluded in the moment of
        receiving payment for the ordered product.
      </BodyMediumText>
    </Trans>
  ),
  delivery: <Trans>Delivery</Trans>,
  deliveryParagraph: (
    <Trans>
      Delivery is free of charge.<br />
      <br />
      Price of delivery is visible to the Users in the procedure of placing
      orders.<br />
      <br />
      The term of delivery is 14 days from sending the product. STEMI will send
      an e- mail to the User notifying him/her about sending the product.
    </Trans>
  ),
  reservation: <Trans>Reservation of Products</Trans>,
  reservationParagraph: (
    <Trans>
      Product placed in the cart by the User is not reserved. The product is
      reserved only after confirmation of payment by the User.<br />
      <br />
      It is possible that during the period between placing the product in the
      bag and confirmation of payment the ordered product is no longer
      available, of which the User will be informed.<br />
      <br />
      <b>
        Right of Withdrawal from the Agreement and Return of the Purchased
        Product by the User-consumer in accordance with the Consumers Protection
        Act
      </b>
      <br />
      <br />
      User-consumer has a right to withdraw from a distance or off-premises
      agreement in a period of 30 days without giving any reason.<br />
      <br />
      In the case of sale and purchase agreement, the period from the previous
      paragraph shall begin on the day on which the User-consumer or a third
      party other than the carrier and indicated by the User - consumer acquired
      physical possession of the product which is the object of the agreement.<br />
      <br />
      User-consumer is obliged before expiration of the period for withdrawal
      from the agreement to notify the Service Provider of his/her decision to
      withdraw from the agreement through the form for the withdrawal from the
      agreement which can be found on Pages of the Internet Trade or through any
      other unequivocal statement setting out his decision to withdraw from the
      agreement (electronic or regular mail).<br />
      <br />
      The Service Provider shall deliver to the User – consumer the
      acknowledgment of receipt of the withdrawal statement on a durable medium,
      without a delay.<br />
      <br />
      If the User-consumer exercises his/her right of withdrawal from the
      agreement, the Service Provider shall reimburse all payments received from
      the User-consumer on the basis of the agreement, without a delay and not
      later than 30 days from the day on which he is informed of the
      User-consumer’s decision to withdraw from the agreement, pursuant to the
      abovementioned provisions of this Article.<br />
      <br />
      As an exception to the abovementioned, the Service Provider is not obliged
      to reimburse supplementary costs, if the consumer has expressly opted for
      a type of delivery other than the least expensive type of standard
      delivery offered by the Service Provider.<br />
      <br />
      Unless the Service Provider has offered to collect the goods himself, with
      regard to sale and purchase agreement, the Service Provider may withhold
      the reimbursement until he has received the product back, or until the
      User-consumer has supplied evidence of having sent back the product if
      this would precede the receipt of the product.<br />
      <br />
      The Service Provider shall carry out the reimbursement of paid amounts on
      the basis of the agreement using the same methods of payment the
      User-consumer used for the initial transaction unless the User-consumer
      has expressly agreed otherwise and provided that the User-consumer does
      not have to pay any additional costs as a result of such reimbursement.<br />
      <br />
      Unless the Service Provider has offered to collect the product the
      User-consumer returns himself, the User-consumer must return the product
      without a delay and not later than 30 days from the day on which he has
      communicated his decision to withdraw from the agreement to the Service
      Provider in accordance with provisions of this Article.<br />
      <br />
      It is considered the User-consumer has fulfilled his obligation to return
      the product in due time if, before the expiration of 30 days period from
      the day on which he has communicated his decision to withdraw from the
      agreement to the Service Provider pursuant to the provisions of this
      Article, he sends back the product or hands it over to the Service
      Provider i.e. to a person authorised by the Service Provider to receive
      the product.<br />
      <br />
      The User-consumer shall only bear the direct cost of returning the
      product.<br />
      <br />
      In the case of off-premises contracts where the products have been
      delivered to the User-consumer’s home at the time of the conclusion of the
      agreement, the Service Provider shall at his own expense collect the
      product if, by their nature, those products cannot normally be returned by
      post.<br />
      <br />
      The User-consumer shall be liable for any diminished value of the product
      resulting from the handling of the product other than what is necessary to
      establish the nature, characteristics, and functioning of the product.<br />
      <br />
      The right to withdrawal from the agreement is excluded in cases stipulated
      by the Article 79 of the Consumers Protection Act. Entire information
      regarding the right of consumers to withdraw from the Agreement can be
      found on the link Consumers Protection Act.<br />
      <br />
      Return of delivered product shall be performed to the address of the
      Service Provider stipulated on the web pages of the trade.<br />
      <br />
      Products or groups of products the User-consumer returns have to be
      complete in the original and undamaged package with all pertaining
      undamaged parts, appurtenances, and documents
    </Trans>
  ),
  complaints: <Trans>Complaints</Trans>,
  complaintsParagraph: (
    <Trans>
      In case there has been mistake upon packaging of ordered products and the
      User-consumer has not received the ordered product, but another product,
      he/she is obliged to inform of this the Service Provider. In this case,
      the User-consumer will return the wrongly delivered product at the expense
      of the Service Provider, and the Service Provider will deliver the ordered
      product within 14 days or reimburse the paid amount within 14 days from
      the day of the return of wrongly delivered product (pursuant to the
      request of the User).<br />
      <br />
      In case the User has not received ordered and paid product, he/she is
      obliged to report this via contacts published on the Pages within 14
      calendar days from the day of anticipated receipt of the ordered product.
      The Service Provider is obliged to inform the User about the further
      sequence of complaint resolution.<br />
      <br />
      Return of delivered product shall be performed to the address of the
      Service Provider stipulated on the web pages of the trade.<br />
      <br />
      Products or groups of products the User-consumer returns have to be
      complete in the original and undamaged package with all pertaining
      undamaged parts, appurtenances, and documents.<br />
      <br />
      The user is able to submit complaints by the post office to the address
      STEMI d.o.o. Radmile Matejcic 10 51000 Rijeka Croatia, or electronic mail{' '}
      <A href="mailto:info@stemi.education">info@stemi.education</A> to
      which complaint Service Provider is obliged to answer within 15 days as of
      the day of receiving the complaint.
    </Trans>
  ),
  liabilityOf: <Trans>Liability of Service Provider</Trans>,
  liabilityOfParagraph: (
    <Trans>
      Service Provider is liable for material defects of products in accordance
      with the Croatian Obligations Act.
    </Trans>
  ),
  generalProvision: <Trans>General Provisions</Trans>,
  generalProvisionParagraph: (
    <Trans>
      The relation between you and STEMI is governed by positive regulations of
      the Republic of Croatia. In case of a dispute, the competent court shall
      be the competent court in Zagreb, Republic of Croatia.<br />
      <br />
      These General Terms and Conditions are written in Croatian and English
      language version. In the case of any discrepancy between Croatian and
      English version of these General Terms and Conditions, the Croatian
      version shall prevail.
    </Trans>
  )
};
