import React from 'react';
import styled from 'styled-components';
import { Row } from '../Row';
import { media } from '../../theme';

const SectionsContainer = styled(Row)`
  width: 753px;
  ${media.tabletLandscape`
    width: 637px;
  `};
`;

export const FooterSectionsWrapper = ({ children }) => (
  <React.Fragment>
    <SectionsContainer className="space-between wrapper">
      {children}
    </SectionsContainer>
    {children}
  </React.Fragment>
);
