import React from 'react';
import styled from 'styled-components';
import {
  BodyMediumText,
  Column,
  H4,
  Row,
  colors,
  media,
  spacing
} from 'playful-ui';

const StyledContainer = styled(Column)`
  min-width: 754px;
  margin-top: 80px;
  margin-bottom: 120px;
  ${media.tablet`
    min-width: 748px;
  `}
  ${media.phone`
    margin-bottom: 80px;
    min-width: unset;
  `}

  & > ${Row} {
    box-shadow: 0 1px ${colors.whitesmoke};
    padding-bottom: ${spacing.BASE};
    margin-bottom: ${spacing.BASE};
  }
  & > ${Row} > ${H4} {
    flex: 1;
    margin-right: ${spacing.BASE};
    text-align: right;
    ${media.phone`
      margin-right: unset;
      text-align: center;
      margin-bottom: ${spacing.SMALL};
    `};
  }
  & > ${Row} > ${Column} {
    flex: 1;
    margin-left: ${spacing.BASE};
    text-align: left;
    ${media.phone`
      margin-left: unset;
      text-align: center;
    `};
  }
  & > ${Row}:last-of-type {
    box-shadow: unset;
    padding-bottom: unset;
    ${media.phone`
      margin-bottom: unset;
    `};
  }
`;

export const TechnicalSpecifications = ({ specs, ...props }) => (
  <StyledContainer {...props}>
    {specs.map(({ left, right }, i) => (
      <Row key={`TSR${i}`} className="mobile-vertical">
        <H4>{left}</H4>
        <Column>
          {right.map((text, j) => (
            <BodyMediumText key={`BMT${i}${j}`}>{text}</BodyMediumText>
          ))}
        </Column>
      </Row>
    ))}
  </StyledContainer>
);
