import React from 'react';
import styled from 'styled-components';

import {
  Column,
  media,
  spacing,
  Section,
  H2
} from 'playful-ui';

export const FeaturesContainer = styled(Column)`
  padding-top: 80px;
  align-items: center;
  ${media.tablet`
    padding-top: ${spacing.LARGE};
  `};
  ${media.phone`
    align-items: unset;
  `};

  & > ${Section}.textual > ${H2} {
    text-align: center;
  }
  & > .small-card {
    margin-bottom: ${spacing.XXLARGE};
    ${media.phone`
      margin-bottom: ${spacing.LARGE};
    `};
  }
  & > .small-card.last {
    margin-bottom: 0;
  }
`;

