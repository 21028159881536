import React from 'react';
import styled from 'styled-components';

import { spacing, media } from 'theme';

import { Column } from '../Column';
import { FooterSectionText, FooterCategoryText } from '../Typography';
import { FooterEmailForm } from './FooterEmailForm/';

const FooterNewsletterContainer = styled(Column)`
  margin-top: ${spacing.LARGE};
  min-width: 262px;
  max-width: 262px;
  ${media.tablet`
    max-width: 461px;
  `};
  ${media.phone`
    max-width: 335px;
  `};
`;

export const FooterNewsletter = styled(
  ({ className, title, text, onEmailButtonClicked, placeholderText, subscribeSuccessMessage }) => (
    <FooterNewsletterContainer className={className}>
      <FooterSectionText style={{ marginBottom: spacing.SMALL }}>{title}</FooterSectionText>
      <FooterCategoryText>{text}</FooterCategoryText>
      <FooterEmailForm
        placeholderText={placeholderText}
        onEmailButtonClicked={onEmailButtonClicked}
        subscribeSuccessMessage={subscribeSuccessMessage}
      />
    </FooterNewsletterContainer>
  )
)``;
