import _ from 'lodash';
import { Status } from './status';

export { createSelector } from 'reselect';

export function addTraits({ data = {}, status } = {}) {
  return {
    ...data,
    isValid() {
      return status === Status.VALID;
    },

    isLoading() {
      return status === Status.LOADING;
    },

    isError() {
      return status === Status.ERROR;
    },

    isEmpty() {
      return _.isEmpty(data);
    },

    getData() {
      return data;
    },
  };
}
