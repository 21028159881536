import styled from 'styled-components';
import {
  Row,
  Column,
  H2,
  Icon,
  BodySmallText,
  BodyMediumText,
  colors,
  media,
  spacing,
} from 'playful-ui';

export const CartPageContainer = styled(Row)`
  background-color: ${colors.white};
  border-top: 96px solid #eff1f9;
  border-bottom: 1px solid ${colors.whitesmoke};
  margin-bottom: 200px;
  ${media.phone`
  border-top: 72px solid #eff1f9;
    margin-bottom: 100px;
  `};

  & ${Column}.content {
    max-width: 1146px;
    ${media.tabletLandscape`
      max-width: 974px;
    `};
    ${media.tablet`
      max-width: 708px;
    `};
    ${media.phone`
      max-width: 335px;
    `};
  }

  & .pulsating-logo {
    margin-top: ${spacing.XXLARGE};
    margin-bottom: ${spacing.XXLARGE};
  }

  & div.media-size-container {
    display: inline-flex;
    width: 100%;
    max-width: 1024px;
    ${media.tablet`
      max-width: 664px;
    `}
  }

  & ${Column}.content > ${H2}.full-cart {
    margin-top: ${spacing.LARGE};
    margin-bottom: 80px;
    text-align: center;
    max-width: 754px;

    ${media.phone`
      margin-top: ${spacing.BASE_PLUS};
      margin-bottom: ${spacing.LARGE};
    `};
  }

  & ${Column}.content > ${H2}.empty-cart {
    margin-top: 120px;
    margin-bottom: ${spacing.BASE_PLUS};
    text-align: center;
    max-width: 754px;

    ${media.phone`
      margin-top: 80px;
    `};
  }

  & ${Column}.content > ${Icon}.empty-cart {
    margin-top: ${spacing.LARGE};
    margin-bottom: ${spacing.BASE};
  }

  & ${Column}.price-checkout-container {
    margin-top: ${spacing.SMALL};
    width: 100%;
    align-items: flex-end;
  }

  & ${Column}.price-checkout-container ${BodySmallText} {
    width: fit-content;
    opacity: 0.6;
  }
  & ${BodySmallText}.tax {
    opacity: 0.3;
  }

  & ${Column}.price-checkout-container ${BodyMediumText} {
    font-weight: 600;
    margin: ${spacing.TINY} 0;
  }

  & ${Row}.buttons {
    width: 100%;
    justify-content: space-between;
    margin-top: ${spacing.BASE_PLUS};
    margin-bottom: ${spacing.LARGE};
    ${media.phone`
      flex-direction: column-reverse;
      margin-top: ${spacing.BASE};
      margin-bottom: ${spacing.BASE_PLUS};
      align-items: flex-end;
    `};
  }

  & a.continue-shopping {
    margin-top: ${spacing.TINY};
  }
`;
