
import styled from 'styled-components';

import { Column, Row, media, spacing } from 'playful-ui';

export const Container = styled(Column)`
  & ${Column}.hero-content {
    align-self: flex-start;
    align-items: center;
  }
  & > ${Row} .product-card {
    margin: ${spacing.SMALL};
  }
  & > ${Row}:last-child {
    margin-bottom: ${spacing.XLARGE_PLUS};
  }
  & > div.hero {
    min-height: unset;
  }
  & > div.hero > div:nth-child(2) {
    padding-bottom: 130px;
    ${media.tablet`
      padding-bottom:64px;
    `};
    ${media.phone`
      padding-bottom:40px; 
    `}
  }
`;
