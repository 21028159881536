import React from 'react';
import { withCookies } from 'react-cookie';
import { compose } from 'recompose';

import { BodyXSmallText, CloseButton, Rule, Row, StickyBanner } from 'playful-ui';
import { withStemiI18n } from 'stemi-i18n';

import { EuBannerContainer } from './EuBannerContainer';
import { ReactComponent as ImgEu } from './assets/img-eu.svg';
import { ReactComponent as ImgEuStrukturniFondovi } from './assets/img-eu-strukturni-fondovi.svg';
import { euBannerText } from './content';

const hideEuBanner = 'Hide Eu Banner';
class Banner extends React.Component {
  handleOnClick = () => {
    const { cookies } = this.props;
    cookies.set(hideEuBanner, true, { path: '/' });
  }

  render() {
    const { cookies, i18n } = this.props;
    if (cookies.get(hideEuBanner)) {
      return null;
    }
    return (
      <StickyBanner>
        <EuBannerContainer className="h-center v-center mobile-vertical">
          <Rule className="vertical hide-mobile" />
          <Row className="h-center" id="eu-images">
            <ImgEu id="eu" />
            <ImgEuStrukturniFondovi id="eu-funds" />
          </Row>
          <Rule className="vertical hide-mobile" />
          <BodyXSmallText>
            {euBannerText(i18n)}
          </BodyXSmallText>
          <Rule className="vertical hide-mobile" />
          <CloseButton onClick={this.handleOnClick}/>
          <Rule className="vertical hide-mobile" />
        </EuBannerContainer>
      </StickyBanner>
    );
  }
}

export const EuBanner = compose(withCookies, withStemiI18n)(Banner);
