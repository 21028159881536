import React from 'react';
import styled from 'styled-components';
import { Row, Column, Caption, media, spacing, colors } from 'playful-ui';
import { Trans } from '@lingui/react';

const StyledContainer = styled(Row)`
  width: 100%;
  padding-bottom: ${spacing.SMALL};
  border-bottom: 1px solid ${colors.whitesmoke};

  & ${Caption} {
    color: ${colors.midnight};
  };
  & ${Caption}.price {
    text-align: end;
  };

  & > ${Column}:nth-child(2), & > ${Column}:nth-child(3) {
    ${media.phone`
      display: none;
    `};
  }; 
`;

export const CartItemsHeader = ({ className }) => (
  <StyledContainer className={`v-center ${className}`}>
    <Column flex={689}>
      <Caption>
        <Trans>PRODUCT</Trans>
      </Caption>
    </Column>
    <Column flex={178}>
      <Caption>
        <Trans>QUANTITY</Trans>
      </Caption>
    </Column>
    <Column flex={83}>
      <Caption className="price">
        <Trans>PRICE</Trans>
      </Caption>
    </Column>
    <Column flex={58} />
  </StyledContainer>

)
