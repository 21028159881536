export const spacing = Object.freeze({
  TINY: '8px',
  SMALL: '16px',
  BASE: '24px',
  BASE_PLUS: '32px',
  LARGE: '48px',
  XLARGE: '64px',
  XLARGE_PLUS: '96px',
  XXLARGE: '128px',
  sum: function() {
    return [...arguments].reduce((sum, size) => (sum = sum + parseInt(size)), 0).toString() + 'px';
  },
});
