import styled from 'styled-components';

import { media, spacing } from 'theme';

import { BodyLargeText } from '../Typography';

export const Section = styled.section`
  margin-top: 80px;
  & ${BodyLargeText} {
    margin-top: ${spacing.BASE};
    ${media.phone`
      margin-top: ${spacing.SMALL};
    `};
  }
  &.large {
    margin-top: 120px;
    ${media.phone`
      margin-top: 80px;
    `};
  }
  &.textual {
    width: 754px;
    margin-right: auto;
    margin-left: auto;
  }
  ${media.tablet`
    margin-top: 48px;
    &.textual {
      width: 624px;
    } 
  `} ${media.phone`
    margin-top: 40px;
    &.textual {
      width: 335px;
    } 
  `};
`;
