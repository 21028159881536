import React from 'react';
import {
  H1,
  BodyLargeText,
  BodySmallText,
  CaptionSmall,
  Column,
} from 'playful-ui';

import { text } from '../content/text';
import { ErrorContainer } from '../components/ErrorContainer';
import { ReactComponent as LogoSignImg } from '../assets/stemi-logo-sign.svg';

export class Error404 extends React.Component {
  static route = '/404';

  render() {
    return (
      <ErrorContainer noNavigation>
        <LogoSignImg className="stemi-logo" />
        <Column className="h-center v-center">
          <H1>404</H1>
          <BodySmallText>{text.pageNotFound}</BodySmallText>
          <BodyLargeText>{text.brokenOrRemoved}</BodyLargeText>
          <CaptionSmall>{text.goBack}</CaptionSmall>
        </Column>
      </ErrorContainer>
    );
  }
}
